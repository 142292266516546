import React, {useState, useContext, useEffect} from "react";
import {withRouter} from "react-router-dom";
import clienteAxios from "../../config/axios";
import {CRMContext} from "../../context/CRMContext";
import {FormularioCliente} from "./components/FormularioCliente";
import Swal from "sweetalert2";

function NuevoCliente(props) {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [cliente, guardarCliente] = useState({
    fechaAlta: Date.now(),
  });

  useEffect(() => {
  }, []);

  const agregarCliente = async (e) => {
    // Envía el cliente a la API
    e.preventDefault();

    const datosEnviar = {
      nombre: {
        nombre: cliente.nombre,
        segundoNombre: cliente.segundoNombre,
        apellido: cliente.apellido,
        apellidoMaterno: cliente.apellidoMaterno,
      },
      razonSocial: cliente.razonSocial,
      createdFromAfip: cliente.createdFromAfip,
      documentos: {
        dni: cliente.dni || null,
        dniTipo: cliente.tipoDni || null,
        cuitCuil: cliente.cuitCuil,
      },
      fechaAlta: Date.now(),
      domicilio: {
        provincia: cliente.provincia,
        municipio: cliente.municipio,
        calle: cliente.calle,
        altura: cliente.altura,
        pisoDepto: cliente.pisoDepto,
        cp: cliente.cp
      },
      telefono: {
        movil: cliente.telefonoMovil,
        particular: cliente.telefonoFijo,
      },
      nacionalidad: cliente.nacionalidad,
      sexo: cliente.sexo,
      email: cliente.email,
      fechaNacimiento: cliente.fechaNacimiento,
      estadoCivil: cliente.estadoCivil,
      datosConyuge: {
        nombre: cliente.nombreConyuge,
        segundoNombre: cliente.segundoNombreConyuge,
        apellido: cliente.apellidoConyuge,
        apellidoMaterno: cliente.apellidoMaternoConyuge,
      },
      inmobiliaria: localStorage.getItem("inmobiliariaId"),
    };
    try {
      const rta = await clienteAxios.post("/clientes", datosEnviar, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
        props.history.push("/clientes");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const actualizarState = (e) => {
    // Actualiza los valores del state a medida que se van completando los datos
    guardarCliente({
      ...cliente,
      [e.target.name]: e.target.value,
    });
  };

  const actualizarStateV2 = (data) => {
    guardarCliente({
      ...cliente,
      ...data
    });
  }


  const validarCliente = () => {
    return (
      false
      // !cliente.estadoCivil ||
      // !cliente.provincia ||
      // !cliente.municipio ||
      // !cliente.nacionalidad ||
      // !cliente.sexo ||
      // !cliente.tipoDni
    )
  };

  if (!token) {
    localStorage.clear();
    props.history.push("/login");
  }


  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Nuevo Cliente</h3>
      </div>
      <div className="card-body">
        <form onSubmit={agregarCliente}>
          <FormularioCliente
            actualizarState={actualizarState}
            cliente={cliente}
            actualizarStateV2={actualizarStateV2}
            showSearchBar={true}
            isNew={true}
          />

          <div className="card-footer">
            <button
              type="submit"
              className="btn btn-primary float-right"
              disabled={validarCliente()}
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default withRouter(NuevoCliente);
