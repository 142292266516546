import { React, useContext, useState } from "react";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import NumberFormat from "react-number-format";

export const GetPersonaByCuil = ({ setterFunction, clearFunction, showSpinner, label = 'CUIT / CUIL *', disabled = false, }) => {
    const [showError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Error desconocido");
    const [auth, guardarAuth] = useContext(CRMContext);
    let token = auth.token || localStorage.getItem("token");

    const getPersonaByCuilCuit = async ({ value }) => {
        setError(false);
        clearFunction();
        if (value.length < 11) {
            return;
        }
        showSpinner(true);
        clearFunction();
        try {
            const rta = await clienteAxios.get(`/afip?id=${value}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            showSpinner(false);
            const { cliente: personaApi } = rta.data.data;
            if (rta.data.data.errorMessage) {
                setErrorMessage(rta.data.data.errorMessage);
                setError(true);
                clearFunction();
                return;
            }
            if (personaApi.tipoPersona) {
                setterFunction(personaApi);
            } else {
                clearFunction();
                setErrorMessage('Persona no encontrada');
                setError(true);
            }
        } catch (e) {
            showSpinner(false);
            console.log(e);
            setError(true);
        }
    }


    return (
        <>
            <label>{label}</label>
            {showError &&
                <div className="alert alert-primary" role="alert">
                    {errorMessage}
                </div>
            }
            <NumberFormat
                className="form-control"
                name="numeroCuilCuit"
                placeholder="20-12345678-9"
                format="##-########-#"
                mask="_"
                disabled={disabled}
                onValueChange={getPersonaByCuilCuit}
            />
        </>
    )
}