import React, {useState} from 'react'
import Select from "react-select";
import FormularioVehiculo from "./components/FormularioVehiculo";
import FormularioInmueble from "./components/FormularioInmueble";

function AgregarBien(props) {

  const [tipo, setTipo] = useState('');


  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-10">
            <h3 className="card-title mt-2">Nuevo Bien</h3>
          </div>
          <div className="col-md-2">
            <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label>Tipo:</label>
              <Select
                options={[
                  {
                    label: "Vehículo",
                    value: 'vehiculo'
                  },
                  {
                    label: "Inmueble",
                    value: 'inmueble'
                  },
              ]}
                onChange={(e) => setTipo(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {tipo === 'vehiculo' ? <FormularioVehiculo /> : null}
            {tipo === 'inmueble' ? <FormularioInmueble /> : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgregarBien