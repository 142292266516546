import React, {useEffect, useState} from 'react';
import Select from "react-select";
import NumberFormat from "react-number-format";

function PrecioBoleto(props) {
  const {operacion, setOperacion, setCaracteristicasBoleto} = props;
  const [value, setValue] = useState(null);
  const [valorPersonalizado, setValorPersonalizado] = useState(0);
  const [moneda, setMoneda] = useState(null);


  const values = [
    {
      label: 'Precio de Lista',
      value: 'precioLista',
    },
    {
      label: 'Entregas + Financiado',
      value: 'entregasFinanciado',
    },
    {
      label: 'Personalizado',
      value: 'personalizado',
    },
  ];

  const monedaOptions = [
    {
      label: 'Pesos',
      value: 'pesos'
    },
    {
      label: 'Dólares',
      value: 'dolares'
    },
  ];

  useEffect(()=>{
    const precioBoleto = {
      value,
      valorPersonalizado,
      moneda
    };
    setCaracteristicasBoleto('precioBoleto', precioBoleto);
  }, [value, valorPersonalizado, moneda]);

  return (
    <div className='mb-4'>
      <div className="row">
        <label className="col-md-12">Precio</label>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>Valor</label>
          <Select
            name='tipoValor'
            options={values}
            onChange={(e) => setValue(e.value)}
          />
        </div>
        <div className="col-md-4">
          <label>Moneda</label>
          <Select
            name='tipoValor'
            options={monedaOptions}
            onChange={(e) => setMoneda(e.value)}
          />
        </div>
        {value === 'personalizado' ?
        <div className="col-md-4">
          <label>Valor Personalizado</label>
          <NumberFormat
            prefix={moneda === 'dolares' ? 'u$s ' : '$ '}
            thousandSeparator='.'
            decimalSeparator=','
            className="form-control"
            name='valorPersonalizado'
            onValueChange={(e) => setValorPersonalizado(e.floatValue)}
          />
        </div> : null }
      </div>
    </div>
  );
}

export default PrecioBoleto;