import React, { useContext, useEffect, useState } from 'react'
import clienteAxios from '../../../config/axios';
import { CRMContext } from '../../../context/CRMContext';
import { SelectEstadoCivil } from '../../clientes/components/SelectEstadoCivil';
import SelectNupcias from '../../clientes/components/SelectNupcias';
import DatosConyuge from '../../clientes/components/DatosConyuge';
import DataTable from 'react-data-table-component';
import NumberFormat from 'react-number-format';
import Spinner from '../../layout/Spinner';
import moment from 'moment';
import { SelectNacionalidad } from '../../clientes/components/SelectNacionalidad';
import Swal from 'sweetalert2';


export const EditarFirmantes = (props) => {
  const [loadingInfo, setLoadingInfo] = useState(false)
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const [operacion, setOperacion] = useState({})
  const { id } = props.match.params;

  const requestHeader = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const consultaAPI = async () => {
    try {
      setLoadingInfo(true);
      const response = await clienteAxios.get(
        `/operaciones/${id}`,
        requestHeader
      );
      setOperacion(response.data);
      setFirmantes(response.data.firmantes);
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      } else {
        props.history.push('/');
      }
    } finally {
      setLoadingInfo(false);
    }
  };

  useEffect(() => {
    consultaAPI();
  }, []);

  const enviarOperacion = () => {
    try {
      setLoadingInfo(true)
      if (firmantes.reduce((prev, curr) => (prev + curr.porcentaje), 0) !== 100) {
        return;
      }
      const data = {
        operacion: {
          ...operacion,
          firmantes,
        }
      }
      clienteAxios.put(`/operaciones/editarFirmante/${id}`,
        data,
        requestHeader
      )
      props.history.push(`/operaciones/${id}`)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingInfo(false)
    }
  }


  const [showError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error desconocido");
  const [showSpinner, setShowSpinner] = useState(false);
  const [client, setClient] = useState({
    nacionalidad: '',
    estadoCivil: {},
  });
  const [existentClient, setExistentClient] = useState(false);
  const [firmantes, setFirmantes] = useState([]);
  const [afipId, setAfipId] = useState('');

  const actualizarStateV2 = (state) => {
    setClient(({
      ...client,
      ...state
    }))
  }

  const actualizarState = (e) => {
    setClient({
      ...client,
      [e.target.name]: e.target.value
    });
  }
  useEffect(() => {
    getOrCreateClient(afipId || '')
  }, [afipId]);

  const setSelectedClient = async () => {
    if (operacion.cliente && operacion.cliente.documentos && operacion.cliente.documentos.cuitCuil) {
      await getOrCreateClient(operacion.cliente.documentos.cuitCuil);
    }
  }
  const getOrCreateClient = async (e) => {
    setExistentClient(false);
    setError(false);
    setClient({});
    let clientFound = false;
    const taxId = e;
    if (taxId.length < 11) {
      return;
    }
    setShowSpinner(true);
    let apiClient;

    if (operacion.cliente && operacion.cliente.documentos && operacion.cliente.documentos.cuitCuil && operacion.cliente.documentos.cuitCuil === parseInt(taxId)) {
      apiClient = Object.assign({}, operacion.cliente);
      setClient(apiClient);
      clientFound = true;
      setExistentClient(true);
    } else {

      apiClient = await getClientByTaxId(taxId);
      if (apiClient) {
        apiClient.telefonoMovil = apiClient.telefono?.movil;
        apiClient.telefonoFijo = apiClient.telefono?.particular;
        setClient(apiClient);
        clientFound = true;
        setExistentClient(true);
      } else {
        apiClient = await getClientInfoFromAfip(taxId);
        if (apiClient && apiClient.hasOwnProperty('documentos')) {
          setClient(apiClient);
          clientFound = true;
        }
      }
    }
    if (!clientFound) {
      setClient(operacion.cliente)
      // setErrorMessage('Persona no encontrada');
      // setError(true);
    }

    setShowSpinner(false);
  }

  const getClientByTaxId = async (taxId) => {
    try {
      const clientesConsulta = await clienteAxios.get(`/clientes/cuitCuil/${taxId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (clientesConsulta.data.hasOwnProperty('activo')) {
        return clientesConsulta.data;
      } else {
        return null;
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const getClientInfoFromAfip = async (taxId) => {

    try {
      const rta = await clienteAxios.get(`/afip?id=${taxId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { cliente: personaApi } = rta.data.data;
      if (rta.data.data.errorMessage) {
        return null
      } else {
        return personaApi;
      }
    } catch (e) {
      console.log(e);
    }
  }

  const addFirmante = () => {
    if (!client.porcentaje || client.porcentaje > 100) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El porcentaje debe ser un valor válido",
      });
      return;
    }

    if (!client.documentos?.cuitCuil) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debe ingresar el Cuit o Cuil de un firmante",
      });
      return;
    }

    let index = -1;
    firmantes.forEach((ele, idx) => {
      if (ele.firmante.documentos?.cuitCuil === client.documentos?.cuitCuil) {
        index = idx;
      }
    });

    const { porcentaje, ...rest } = client;
    const firmante = {
      porcentaje,
      firmante: { ...rest }
    }

    if (index >= 0) {
      const firmantesCopy = [...firmantes];
      firmantesCopy[index] = firmante;
      setFirmantes([...firmantesCopy]);
    } else {
      setFirmantes([
        ...firmantes,
        firmante,
      ]);
    }
    setClient({
      nacionalidad: '',
      estadoCivil: {},
    });
    setAfipId('');
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }

  const setFirmantePorcentaje = (e) => {
    setClient({
      ...client,
      porcentaje: e.floatValue
    });
  }

  const columns = [
    {
      name: "Nombre",
      selector: (row) => `${row.firmante.nombre?.apellido || ''} ${row.firmante.apellidoMaterno?.toUpperCase() || ''}${row.firmante.nombre?.apellido ? ', ' : ''} ${row.firmante.nombre?.nombre || ''}${row.firmante.razonSocial ? row.firmante.razonSocial : ''}`,
      sortable: true,
      width: '50%'
    },
    {
      name: "Porcentaje de Propiedad",
      selector: (row) => `${row.porcentaje} %`,
      sortable: true,
    },
    {
      name: "Editar",
      button: true,
      cell: (row, index) => {
        return <button className='btn btn-secondary' onClick={() => editClient(row, index)}>Editar</button>
      }
    },
    {
      name: "Eliminar",
      button: true,
      cell: (row, index) => {
        return <button className='btn btn-danger' onClick={() => deleteCliente(row, index)}>X</button>
      },
    }
  ];

  const editClient = (row, index) => {
    setClient({ ...row.firmante, porcentaje: row.porcentaje });
  }

  const deleteCliente = (row, index) => {
    const firmantesCopy = [...firmantes];
    firmantesCopy.splice(index, 1);
    setFirmantes([...firmantesCopy]);
  }

  if (loadingInfo) {
    return <Spinner />
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Editar Firmantes</h3>
          <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
        </div>
        <div className="card-body">
          {firmantes.length > 0 &&
            <div className='row'>
              <DataTable columns={columns} data={firmantes} />
            </div>
          }

          <div className={`row ${firmantes.length > 0 ? 'mt-5' : ''}`}>
            <div className="col-md-4">
              <label className='col-md-12'>Es igual al cliente?</label>
              <button className='btn btn-success col-md-12' onClick={setSelectedClient}>Seleccionar</button>
            </div>
            <div className="col-md-4 form-group">
              <label>CUIT / CUIL del firmante</label>
              <NumberFormat
                className="form-control"
                name="numeroCuilCuit"
                placeholder="20-12345678-9"
                format="##-########-#"
                mask="_"
                required
                value={afipId}
                onValueChange={(e) => setAfipId(e.value)}
              />
              {showError &&
                <div className="alert alert-primary" role="alert">
                  {errorMessage}
                </div>
              }
            </div>
            <div className="col-md-4">
              <label>% de propiedad:</label>
              <NumberFormat
                readOnly={showSpinner}
                className="form-control"
                value={client.porcentaje || 0}
                onValueChange={setFirmantePorcentaje}
              />
            </div>
          </div>
          {showSpinner &&
            <Spinner />
          }
          {(client && client.nombre && Object.keys(client.nombre).length > 0) &&
            <div className="row">
              <div className="col-md-4 form-group">
                <label>Nombre *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre"
                  name="nombre"
                  value={client?.nombre.nombre}
                  required
                  disabled
                />
              </div>


              <div className="col-md-4 form-group">
                <label>Apellido *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Apellido"
                  name="apellido"
                  value={client?.nombre.apellido}
                  required
                  disabled
                />
              </div>

              {client.fechaNacimiento &&
                <div className="col-md-4 form-group">
                  <label>Fecha de Nacimiento *</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Fecha de Nacimiento"
                    name="fechaNacimiento"
                    value={moment(client?.fechaNacimiento)
                      .utcOffset(180)
                      .format("YYYY-MM-DD")}
                    required
                    disabled
                  />
                </div>
              }
            </div>
          }
          {client.razonSocial &&
            <div className="row">
              <div className="col-md-9 form-group">
                <label>Razón Social *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Razón Social"
                  name="nombre"
                  value={client?.razonSocial}
                  disabled
                />
              </div>

              <div className="col-md-3 form-group">
                <label>CUIL</label>
                <NumberFormat
                  className="form-control"
                  name="dni"
                  value={client?.documentos.cuitCuil}
                  placeholder="20.123.456"
                  format="##.###.###"
                  mask="_"
                  required
                  disabled
                />
              </div>
            </div>
          }
          <div className="row">
            {client.documentos && client.documentos.dni &&
              <div className="col-md-3 form-group">
                <label>Tipo: *</label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Tipo DNI"
                  name="dniTipo"
                  value={client?.documentos.dniTipo}
                  required
                  disabled
                />
              </div>
            }

            {client.documentos && client.documentos.dni &&
              <div className="col-md-3 form-group">
                <label>Documento *</label>
                <NumberFormat
                  className="form-control"
                  name="dni"
                  value={client?.documentos.dni}
                  placeholder="20.123.456"
                  format="##.###.###"
                  mask="_"
                  required
                  disabled
                />
              </div>
            }

          </div>

          {client.domicilio &&
            <>
              <div className="form-group">
                <label>Domicilio</label>
              </div>

              <div className="row">
                <div className="col-md-5 form-group">
                  <label>Provincia *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Provincia"
                    name="provincia"
                    value={client.domicilio.provincia}
                    disabled
                  />
                </div>

                <div className="col-md-5 form-group">
                  <label>Municipio *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Municipio"
                    name="municipio"
                    value={client.domicilio.municipio}
                    disabled
                  />
                </div>
                <div className="col-md-2 form-group">
                  <label>Código Postal</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="CP"
                    name="cp"
                    value={client?.domicilio.cp}
                    required
                    disabled
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 form-group">
                  <label>Calle *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Calle"
                    name="calle"
                    value={client?.domicilio.calle}
                    required
                    disabled
                  />
                </div>

                <div className="col-md-3 form-group">
                  <label>Altura *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Altura"
                    name="altura"
                    value={client?.domicilio.altura}
                    required
                    disabled
                  />
                </div>
                <div className="col-md-3 form-group">
                  <label>Piso / Depto</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Piso / Depto"
                    name="pisoDepto"
                    value={client?.domicilio.pisoDepto}
                    disabled
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 form-group">
                  <label>Teléfono Móvil * (Sin 0 y sin 15)</label>
                  <NumberFormat
                    className="form-control"
                    placeholder="__________"
                    name="telefonoMovil"
                    format="##########"
                    mask="_"
                    onChange={actualizarState}
                    value={client?.telefonoMovil || ''}
                    required
                    readOnly={existentClient}
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Teléfono Fijo (Sin 0)</label>
                  <NumberFormat
                    className="form-control"
                    name="telefonoFijo"
                    format="##########"
                    placeholder="__________"
                    mask="_"
                    onChange={actualizarState}
                    value={client?.telefonoFijo || ''}
                    readOnly={existentClient}
                  />
                </div>
                {(client && client.nombre && Object.keys(client.nombre).length > 0) &&
                  <div className="col-md-4 form-group">
                    <label>Apellido Materno *</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Apellido Materno"
                      name="apellidoMaterno"
                      onChange={actualizarState}
                      value={client?.nombre.apellidoMaterno}
                      disabled={existentClient}
                      required
                    />
                  </div>
                }
              </div>

              <div className="row">
                <div className="col-md-8 form-group">
                  <label>Email *</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    onChange={actualizarState}
                    value={client?.email}
                    required
                    disabled={existentClient}
                  />
                </div>
                {(client && client.nombre && Object.keys(client.nombre).length > 0) &&
                  <SelectNacionalidad
                    actualizarState={actualizarState}
                    cliente={client}
                    token={token}
                    required
                    disabled={existentClient}
                  />
                }
                {client.fechaContratoSocial &&
                  <div className="col-md-4 form-group">
                    <label>Alta de Contrato Social *</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Alta de Contrato Social"
                      name="fechaAlta"
                      value={moment(client?.fechaContratoSocial)
                        .utcOffset(180)
                        .format("YYYY-MM-DD")}
                      disabled
                    />
                  </div>
                }
              </div>
            </>
          }
          {(client && client.nombre && Object.keys(client.nombre).length > 0) &&
            <>
              <SelectEstadoCivil actualizarState={actualizarState} cliente={client} disabled={existentClient} />
              <SelectNupcias cliente={client} actualizarState={actualizarState} disabled={existentClient} />
              <DatosConyuge cliente={client} actualizarState={actualizarStateV2} disabled={existentClient} />
            </>
          }
          <div className="row float-right mt-5">
            <button
              className="btn btn-secondary"
              onClick={addFirmante}
            >Guardar Firmante
            </button>
          </div>
        </div>

        <div className="card-footer">
          <div className="row">
            <div className="col">
              <button type="button" className="btn btn-success float-right" onClick={enviarOperacion}>Actualizar Operación</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
