import React, { useContext, useEffect, useState } from 'react'
import { Link, withRouter } from "react-router-dom";
import clienteAxios from '../../config/axios';
import { CRMContext } from '../../context/CRMContext';
import Spinner from '../layout/Spinner';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';

function ListadoBloqueos(props) {
  const [bloqueos, setBloqueos] = useState([])
  const [loading, setLoading] = useState(true)
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const consultarApi = async () => {
    setLoading(true)
    let url = `/bloqueos`
    try {
      const response = await clienteAxios.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBloqueos(response.data)
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      } else {
        props.history.push("/");
      }
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  const bajaBloqueo = async (id) => {
    Swal.fire({
      title: "¿Estás Seguro?",
      text: "Estás a punto de eliminar un bloqueo",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        let url = `/bloqueos/undo/${id}`
        try {
          await clienteAxios.delete(
            url,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          consultarApi()
        } catch (error) {
          if (error.response?.status === 500) {
            localStorage.clear();
            props.history.push("/login");
          } else {
            props.history.push("/");
          }
          console.log(error);
        }
      }
    });
  }


  function calcularHorasRestantes(fechaObjetivo, horas) {
    // Obtener la fecha y hora actual
    const fechaActual = new Date();

    // Convertir la fecha objetivo a un objeto de fecha
    const fechaObjetivoDate = new Date(fechaObjetivo);

    // Calcular la diferencia en milisegundos entre la fecha objetivo y la fecha actual
    const diferencia = fechaObjetivoDate - fechaActual;

    // Calcular la cantidad de horas restantes
    let horasRestantes = diferencia / (1000 * 60 * 60) + horas;

    // Si el resultado es negativo, devolver un valor negativo
    if (horasRestantes < 0) {
      horasRestantes = -horasRestantes;
    }

    return horasRestantes;
  }

  const newOperation = (row) => {
    const {client, lotes, _id: id} = row;
    const clientId = client.documentos.cuitCuil;
    const lotesIds = lotes.map(lote => lote.lote._id).join(',');
    props.history.push(`/operaciones/nueva?clientId=${clientId}&lotesIds=${lotesIds}&bloqueoId=${id}`);
  }

  useEffect(() => {
    consultarApi()
  }, [])

  const columns = [
    {
      name: "Fecha Alta",
      selector: (row) => row.createdAt,
      sortable: true,
      cell: (row) => {
        return moment(row.createdAt)
          .utcOffset(180)
          .format("DD-MM-YYYY")
      },
    },
    {
      name: "Cliente",
      selector: (row) => row.client.razonSocial ? row.client.razonSocial : `${row.client.nombre.apellido}, ${row.client.nombre.nombre}`,
      sortable: true,
    },
    {
      name: "Lotes",
      cell: (row) => {
        if (row.lotes.length === 1) {
          return `${row.lotes[0].lote.barrio.nombre} - ${row.lotes[0].lote.numeroLote}`
        } else {
          return <div className="pt-3">{row.lotes.map(lote => <p>{lote.lote.barrio.nombre} - {lote.lote.numeroLote}</p>)}</div>
        }
      }
      ,
      sortable: true,
    },
    {
      name: "Asesor Comercial",
      selector: (row) => row.user.nombre,
      sortable: true,
    },
    {
      name: "Inmobiliaria",
      selector: (row) => row.user.inmobiliaria.nombre,
      sortable: true,
    },
    {
      name: "Estado",
      sortable: true,
      cell: (row) => {
        return row.isActive ? 'Activo' : 'Baja'
      }
    },
    {
      name: "Caducidad",
      sortable: true,
      cell: (row) => {
        return `${calcularHorasRestantes(row.createdAt, row.validHours).toFixed(0)} hs`
      }
    },
    {
      name: "Convertir en Reserva",
      button: true,
      cell: (row) => (
        <button type="button" className="btn btn-block btn-success ml-2" disabled={!row.isActive} onClick={() => newOperation(row)}>
          Reserva
        </button>
      ),
    },
    {
      name: "Dar de Baja",
      button: true,
      cell: (row) => (
        <button type="button" className="btn btn-block btn-danger ml-2" disabled={!row.isActive} onClick={() => bajaBloqueo(row._id)}>
          Baja
        </button>
      ),
    },
  ]

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-10">
            <h3 className="card-title">Bloqueos</h3>
          </div>
          <div className="col-md-2">
            <Link to={`/bloqueos/nuevo`}>
              <button type="button" className="btn btn-block btn-success">
                Nuevo Bloqueo
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <DataTable
              columns={columns}
              data={bloqueos}
              pagination={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ListadoBloqueos)