import React from 'react'

const DetalleDuenoOperacion = (props) => {
  const { operacion } = props;

  if(!operacion) return null;

  return (
    <>
      <div className="card-header row">
        <div className="col-md-12">
          <h3 className="card-title">
            <strong>Cliente y Lotes:</strong>
          </h3>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <label>Cliente:</label>
            {operacion.cliente?.razonSocial ?
              <p className='ml-3'>{operacion.cliente?.razonSocial}</p>
              : <p className='ml-3'>{operacion.cliente?.nombre?.apellido}, {operacion.cliente?.nombre?.nombre}</p>
            }
          </div>
          <div className="col">
            <label>Lotes:</label>
            {operacion.lote.map(lote => {
              return (
                <p className='ml-3'>{lote.barrio.nombre} - {lote.numeroLote}</p>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default DetalleDuenoOperacion