import React, {useState} from 'react';
import NumberFormat from "react-number-format";

const GetRedondeo = ({ operacion, setOperacion, disableOptions = true}) => {

  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const updateValueRedondeo = (e) => {
    const value = e.floatValue;
    const operacionCopy = Object.assign({}, operacion);
    if(!operacionCopy.formaDePago.redondeo) {
      operacionCopy.formaDePago.redondeo = {};
    }
    operacionCopy.formaDePago.redondeo.valorEnPesos = value;
    setOperacion({
      ...operacionCopy
    });
  }

  const updateValueNota = (e) => {
    const value = e.target.value;

    const operacionCopy = Object.assign({}, operacion);
    if(!operacionCopy.formaDePago.redondeo) {
      operacionCopy.formaDePago.redondeo = {};
    }
    operacionCopy.formaDePago.redondeo.nota = value;
    setOperacion({
      ...operacionCopy
    });
  }

  if(disableOptions && (!operacion.formaDePago.redondeo.valorEnPesos || operacion.formaDePago.redondeo?.valorEnPesos === 0)) {
    return <></>;
  }

  return (
    <>
      <div className="card-header">
        <h3 className="card-title">Redondeo</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">
          <label>Redondeo: </label>
          <div className="row">
            <div className="col-md-2">
              <label>Valor en Pesos:</label>
              <NumberFormat
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                decimalScale={2}
                value={operacion.formaDePago.redondeo?.valorEnPesos || null}
                onValueChange={updateValueRedondeo}
                readOnly={disableOptions}
              />
            </div>
            <div className="col-md-10">
              <label>Nota:</label>
              <textarea
                className="w-100 form-control"
                name="nota"
                id=""
                cols="100"
                rows="2"
                onChange={updateValueNota}
                disabled={disableOptions}
                value={operacion.formaDePago.redondeo.nota || ''}
              ></textarea>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}


export default GetRedondeo;