import React, { useContext, useEffect, useState } from 'react';
import { CRMContext } from "../../../context/CRMContext";
import GetDolarMercado from "../../utils/GetDolarMercado";
import clienteAxios from "../../../config/axios";
import Select from "react-select";
import NumberFormat from "react-number-format";
import Spinner from "../../layout/Spinner";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";

const FormularioVehiculo = (props) => {
  const [errorMessage, setErrorMessage] = useState("Error desconocido");
  const [showError, setError] = useState(false);
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [selectedForm, setSelectedForm] = useState({});
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [valorDolarMercado, setValorDolarMercado] = useState(0);
  const [valorDolarPropuesto, setValorDolarPropuesto] = useState(0);
  const [valorPropuesto, setValorPropuesto] = useState(0);
  const [formOptions, setFormOptions] = useState({
    marcas: [],
    modelos: [],
    versiones: [],
    anos: [],
  });
  const [selectedOptions, setSelectedOptions] = useState({});
  const [vehicle, setVehicle] = useState({});

  const types = [
    {
      label: 'Auto',
      value: 'cars'
    },
    {
      label: 'Moto',
      value: 'motorcycles'
    }
  ];
  const saveSelectedCar = async () => {
    let bien = {
      tipo: 'vehiculo',
      descripcion: { ...vehicle }
    }

    try {
      const rta = await clienteAxios.post(`/bienes`, bien, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.status === 200) {
        const { _id: id } = rta.data;
        Swal.fire("¡Correcto!", rta.data.msg, "success")
          .then(() => props.history.push(`/bienes/${id}`));
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
    }
  }

  useEffect(async () => {
    const dolarApi = await GetDolarMercado.getDolar();
    const valorDolarMercado = dolarApi.data.compra;
    setValorDolarMercado(valorDolarMercado);
    setValorDolarPropuesto(valorDolarMercado);
  }, []);

  useEffect(() => {
    if (selectedForm) {
      const { ano, brand, model, type, version } = selectedForm;
      if (ano || brand || model || type || version) {
        const valorPesosMercado = ano && ano.value ? ano.value : 0;
        const vehicle = {};
        const details = {
          type: type?.label || undefined,
          brand: brand?.label || undefined,
          brandId: brand?.value || undefined,
          model: model?.label || undefined,
          modelId: model?.value || undefined,
          version: version?.label || undefined,
          versionId: version?.value || undefined,
          year: ano?.label || undefined,
          valorSugerido: valorPropuesto
        }
        const valores = {
          fechaPropuesto: Date.now(),
          cambioPropuesto: valorDolarPropuesto,
          pesosPropuesto: valorPropuesto,
          dolaresPropuesto: valorPropuesto / (valorDolarPropuesto || valorDolarMercado),

          fechaMercado: Date.now(),
          pesosMercado: valorPesosMercado,
          dolaresMercado: valorPesosMercado / valorDolarMercado,
          cambioMercado: valorDolarMercado,
        }
        vehicle.vehiculo = details;
        vehicle.valores = valores;
        setVehicle(vehicle);
      }
    }

  }, [selectedForm, valorPropuesto, valorDolarPropuesto]);
  const consultarMarcas = async () => {
    setLoadingInfo(true);
    setError(false);
    try {
      const response = await clienteAxios.get(
        `/infoauto/brands?baseType=${selectedForm.type.value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFormOptions({
        ...formOptions,
        marcas: response.data.map(({ name, id, prices }) => ({
          label: name,
          value: id,
          isDisabled: !prices,
        }))
      });
      setLoadingInfo(false);
    } catch (error) {
      if (error.response?.status === 500) {
        setErrorMessage(error.response.data.message);
        setError(true);
        setLoadingInfo(false);
      }
      console.log(error);
    }
  };
  const consultarModelos = async () => {
    setLoadingInfo(true);
    setError(false);
    try {
      const response = await clienteAxios.get(
        `/infoauto/groups?baseType=${selectedForm.type.value}&brandId=${selectedForm.brand.value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFormOptions({
        ...formOptions,
        modelos: response.data.map(({ name, id, prices }) => {
          return {
            label: name,
            value: id,
            isDisabled: !prices,
          }
        })
      });
      setLoadingInfo(false);
    } catch (error) {
      if (error.response?.status === 500) {
        setErrorMessage(error.response.data.message);
        setError(true);
        setLoadingInfo(false);
      }
      console.log(error);
    }
  };
  const consultarVersion = async () => {
    setLoadingInfo(true);
    setError(false);
    try {
      const response = await clienteAxios.get(
        `/infoauto/models?baseType=${selectedForm.type.value}&brandId=${selectedForm.brand.value}&groupId=${selectedForm.model.value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFormOptions({
        ...formOptions,
        versiones: response.data.map(({ codia, description, prices }) => {
          return {
            label: description,
            value: codia,
            isDisabled: !prices,
          }
        })
      });
      setLoadingInfo(false);
    } catch (error) {
      if (error.response?.status === 500) {
        setErrorMessage(error.response.data.message);
        setError(true);
        setLoadingInfo(false);
      }
      console.log(error);
    }
  };
  const consultarPrecios = async () => {
    setLoadingInfo(true);
    setError(false);
    try {
      const response = await clienteAxios.get(
        `/infoauto/prices?baseType=${selectedForm.type.value}&codia=${selectedForm.version.value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFormOptions({
        ...formOptions,
        anos: response.data.map(({ price, year }) => {
          return {
            label: year,
            value: price,
          }
        })
      });
      setLoadingInfo(false);
    } catch (error) {
      if (error.response?.status === 500) {
        setErrorMessage(error.response.data.message);
        setError(true);
        setLoadingInfo(false);
      }
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedForm.type) {
      consultarMarcas();
    }
  }, [selectedForm.type]);

  useEffect(() => {
    if (selectedForm.brand) {
      consultarModelos();
    }
  }, [selectedForm.brand]);

  useEffect(() => {
    if (selectedForm.model) {
      consultarVersion();
    }
  }, [selectedForm.model]);

  useEffect(() => {
    if (selectedForm.version) {
      consultarPrecios();
    }
  }, [selectedForm.version]);

  const updateFormType = (e) => {
    const value = {
      type: e,
      brand: null,
      model: null,
      version: null,
      ano: null
    }
    setSelectedOptions(value);
    setSelectedForm(value);
  }

  const updateFormBrand = (e) => {
    const value = {
      type: selectedForm.type,
      brand: e,
      model: null,
      version: null,
      ano: null
    }
    setSelectedOptions(value);
    setSelectedForm(value);
  }

  const updateFormModel = (e) => {
    const value = {
      type: selectedForm.type,
      brand: selectedForm.brand,
      model: e,
      version: null,
      ano: null
    }
    setSelectedOptions(value);
    setSelectedForm(value);
  }

  const updateFormVersion = (e) => {
    const value = {
      type: selectedForm.type,
      brand: selectedForm.brand,
      model: selectedForm.model,
      version: e,
      ano: null
    }
    setSelectedOptions(value);
    setSelectedForm(value);
  }

  const updateFormAno = (e) => {
    const value = {
      type: selectedForm.type,
      brand: selectedForm.brand,
      model: selectedForm.model,
      version: selectedForm.version,
      ano: e
    }
    setSelectedOptions(value);
    setSelectedForm(value);
  }


  return (
    <>
      <div className="row">
        {showError &&
          <div className="alert alert-primary" role="alert">
            {errorMessage}
          </div>
        }
      </div>
      <div className="row">
        <div className='col-md-4 form-group'>
          <label>Tipo</label>
          <Select
            options={types}
            name='type'
            onChange={(e) => {
              updateFormType(e);
            }}
            value={selectedOptions?.type || null}
            isDisabled={loadingInfo}
          />
        </div>

        <div className='col-md-4 form-group'>
          <label>Marca</label>
          <Select
            options={formOptions?.marcas}
            name='brand'
            onChange={(e) => {
              updateFormBrand(e);
            }}
            value={selectedOptions?.brand || null}
            isDisabled={loadingInfo}
          />
        </div>

        <div className='col-md-4 form-group'>
          <label>Modelo</label>
          <Select
            options={formOptions?.modelos}
            name='model'
            onChange={(e) => {
              updateFormModel(e);
            }}
            value={selectedOptions?.model || null}
            isDisabled={loadingInfo}
          />
        </div>

      </div>
      <div className="row">

        <div className='col-md-4 form-group'>
          <label>Versión</label>
          <Select
            options={formOptions?.versiones}
            name='version'
            onChange={(e) => {
              updateFormVersion(e);
            }}
            value={selectedOptions?.version || null}
            isDisabled={loadingInfo}
          />
        </div>

        <div className='col-md-4 form-group'>
          <label>Año</label>
          <Select
            options={formOptions?.anos}
            name='ano'
            onChange={(e) => {
              updateFormAno(e)
            }}
            value={selectedOptions?.ano || null}
            isDisabled={loadingInfo}
          />
        </div>
        <div className="col-md-4 form-group">
          <label>Valor Propuesto *</label>
          <NumberFormat
            className="form-control"
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            onValueChange={(e) => setValorPropuesto(e.floatValue)}
            value={valorPropuesto}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 form-group">
          <label className='mr-3'>Cambio de Mercado:</label>
          <NumberFormat
            className="form-control"
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            value={valorDolarMercado}
            disabled
          />
        </div>
        <div className="col-md-4 form-group">
          <label className='mr-3'>Cambio Propuesto:</label>
          <NumberFormat
            className={`form-control`}
            value={valorDolarPropuesto}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={'$ '}
            onValueChange={(e) => setValorDolarPropuesto(e.floatValue)}
          />
        </div>
        <div className="col-md-4 form-group">
          <label>Valor de Referencia *</label>
          <NumberFormat
            className="form-control"
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            value={selectedOptions?.ano?.value || 0}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          {loadingInfo &&
            <Spinner />
          }
        </div>
        <div className="col-md-4"></div>
      </div>
      <div className="mt-5">
        <div className="d-flex justify-content-center card-footer">
          <button className="btn btn-primary" onClick={saveSelectedCar}>Guardar</button>
        </div>
      </div>
    </>
  );
}

export default withRouter(FormularioVehiculo);