import React, { useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import Swal from "sweetalert2";
import FormularioEstadoCivil from "./components/formularioEstadoCivil";

const NuevoEstadoCivil = (props) => {
  const [estadoCivil, setEstadoCivil] = useState({});
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const actualizarState = (e) => {
    setEstadoCivil({
      ...estadoCivil,
      [e.target.name]: e.target.value,
    });
  };

  const validarEstadoCivil = () => {
    const { nombre } = estadoCivil;
    let valido = !nombre;
    return valido;
  };

  if (!token) {
    localStorage.clear();
    props.history.push("/login");
  }

  const agregarEstadoCivil = async (e) => {
    e.preventDefault();

    try {
      const rta = await clienteAxios.post("/estadoCivil", estadoCivil, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
      props.history.push("/configuracion/estadoCivil");
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title mt-2'>NUEVO ESTADO CIVIL</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className='card-body'>
        <form onSubmit={agregarEstadoCivil}>
          <FormularioEstadoCivil
            actualizarState={actualizarState}
            estadoCivilNuevo={true}
          />

          <div className='card-footer'>
            <button
              type='submit'
              className='btn btn-primary float-right'
              disabled={validarEstadoCivil()}
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(NuevoEstadoCivil);
