import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import NumberFormat from "react-number-format";

import ShowPersonFromCUIX from '../../../../../clientes/components/ShowPersonFromCUIX';
import GetDolarMercado from "../../../../../utils/GetDolarMercado";

class Canje {
  constructor() {
    this.tipo = '';
    this.descripcion = '';
    this.moneda = '';
    this.prestador = '';
    this.valores = {
      fechaPropuesto: Date.now(),
      cambioPropuesto: '',
      pesosPropuesto: '',
      dolaresPropuesto: '',

      fechaMercado: Date.now(),
      cambioMercado: '',
      pesosMercado: '',
      dolaresMercado: '',

      fechaNegociado: '',
      cambioNegociado: '',
      pesosNegociado: '',
      dolaresNegociado: '',

      pesosBoleto: '',
      dolaresBoleto: '',
      cambioBoleto: '',
      fechaBoleto: '',
    }
  }

}

const SelectCanjes = ({ onClose, operacion, setOperacion, goBack, ...props }) => {
  const [valorDolarMercado, setValorDolarMercado] = useState(0);
  const [canjes, setCanjes] = useState(operacion.formaDePago.entregas?.map(entrega => entrega.referencia) || []);

  const tipoOptions = [
    {
      value: 'Servicio',
      label: 'Servicio'
    },
    {
      value: 'Maquinaria',
      label: 'Maquinaria'
    },
    {
      value: 'Productos',
      label: 'Productos'
    },
  ];

  const monedaOptions = [
    {
      label: 'Pesos',
      value: 'pesos'
    },
    {
      label: 'Dólares',
      value: 'dolares'
    },
  ];

  const agregarCanje = () => {
    const canjesLocal = [...canjes];
    const canje = new Canje();
    canje.valores.cambioPropuesto = valorDolarMercado;
    canje.valores.cambioMercado = valorDolarMercado;
    canjesLocal.push(canje);
    setCanjes(canjesLocal);
  }

  const eliminarCanje = (index) => {
    const canjesLocal = [...canjes];
    canjesLocal.splice(index, 1);
    setCanjes(canjesLocal);
  }

  const saveDocumentos = () => {
    onClose();
  }

  const updateCanje = (key, value, index) => {
    const canjesLocal = [...canjes];
    canjesLocal[index][key] = value;
    setCanjes(canjesLocal);
  }

  const updateValoresPropuestos = (key, value, index) => {
    const canjesLocal = [...canjes];
    canjesLocal[index].valores[key] = value;
    setCanjes(canjesLocal);
  }

  const updateValor = (value, index) => {
    const canjesLocal = [...canjes];
    if (canjesLocal[index].moneda === 'dolares') {
      canjesLocal[index].valores.dolaresPropuesto = value;
      canjesLocal[index].valores.pesosPropuesto = value * canjesLocal[index].valores.cambioPropuesto;
    } else {
      canjesLocal[index].valores.pesosPropuesto = value;
      canjesLocal[index].valores.dolaresPropuesto = value / canjesLocal[index].valores.cambioPropuesto;
    }
    setCanjes(canjesLocal);
  }

  const setDolarApi = async () => {
    const dolarApi = await GetDolarMercado.getDolar();
    const valorDolarMercado = dolarApi.data.compra;
    setValorDolarMercado(valorDolarMercado);
  }


  useEffect(async () => {
    setDolarApi();
  }, []);

  useEffect(() => {
    const operacionCopy = { ...operacion };
    if (!Array.isArray(operacion.formaDePago.entregas)) {
      operacion.formaDePago.entregas = [];
    }
    const restoEntregas = operacion.formaDePago.entregas.filter(entrega => entrega.tipo !== 'Canje');
    operacion.formaDePago.entregas = [
      ...restoEntregas,
      ...canjes.map(canje => ({
        tipo: 'Canje',
        referencia: canje
      }))
    ];
    setOperacion(operacionCopy);
  }, [canjes]);


  return (
    <>
      {canjes.map((canje, index) => {
        return (
          <div key={index} className='border-bottom mb-3 pb-3'>
            <div className="row">
              <div className="col form-group">
                <label>Canje nro: {index + 1}</label>
              </div>
            </div>
            <div className="row">
              <div className="col form-group">
                <label>Tipo:</label>
                <Select
                  onChange={(e) => updateCanje('tipo', e.value, index)}
                  value={tipoOptions.find(tipo => tipo.value === canje.tipo)}
                  options={tipoOptions}
                />
              </div>
            </div>
            <div className="row">
              <div className="col form-group">
                <label>Descripción:</label>
                <textarea
                  className="w-100 p-2"
                  name="comentario"
                  id=""
                  cols="100"
                  rows="3"
                  onChange={(e) => updateCanje('descripcion', e.target.value, index)}
                  value={canje.descripcion}
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 form-group">
                <label>Moneda:</label>
                <Select
                  options={monedaOptions}
                  value={monedaOptions.find(moneda => moneda.value === canje.moneda)}
                  onChange={(e) => updateCanje('moneda', e.value, index)}
                />
              </div>

              {canje.moneda === 'dolares' ?
                <>
                  <div className="col-md-3 form-group">
                    <label>Cambio Referencia:</label>
                    <NumberFormat
                      className='form-control'
                      value={canje.valores.cambioMercado}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$ "}
                      disabled
                    />
                  </div>
                  <div className="col-md-3 form-group">
                    <label>Cambio Propuesto:</label>
                    <NumberFormat
                      className='form-control'
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$ "}
                      value={canje.valores.cambioPropuesto}
                      onValueChange={(e) => updateValoresPropuestos('cambioPropuesto', e.floatValue, index)}
                    />
                  </div>
                </>
                : <>
                  <div className="col-md-6"></div>
                </>
              }

              <div className="col-md-3 form-group">
                <label>Valor:</label>
                <NumberFormat
                  className='form-control'
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  prefix={canje.moneda === 'dolares' ? 'u$s ' : '$ '}
                  onValueChange={(e) => updateValor(e.floatValue, index)}
                  value={canje.moneda === 'dolares' ? canje.valores.dolaresPropuesto : canje.valores.pesosPropuesto}
                />
              </div>
            </div>
            <div className="row">
              <div className='col mt-3 form-group'>
                <label>CUIT/CUIL del Prestador</label>
                <NumberFormat
                  className="form-control"
                  name="prestador"
                  placeholder="20-12345678-9"
                  format="##-########-#"
                  mask="_"
                  value={canje.prestador}
                  onValueChange={(e) => updateCanje('prestador', e.value, index)}
                />
              </div>
            </div>
            {canje.prestador ?
              <div className="row mt-4">
                <div className="col">
                  <label>Información del Prestador:</label>
                  <ShowPersonFromCUIX taxId={canje.prestador} />
                </div>
              </div>
              : null}


            <div className="row mt-3">
              <div className="col">
                <div className="form-group  float-right">
                  <button className="btn btn-danger" onClick={() => eliminarCanje(index)}>Eliminar Canje</button>
                </div>
              </div>
            </div>
          </div>
        )
      })}

      <div className="row mt-5">
        <div className="col">
          <div className="form-group">
            <button className="btn btn-primary float-right" onClick={agregarCanje}>Agregar Canje</button>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="row d-flex justify-content-around card-footer">

          <div className="">
            <button className="btn btn-secondary" onClick={goBack}>Anterior</button>
          </div>

          <div className="">
            <button className="btn btn-primary" onClick={saveDocumentos}>Siguiente</button>
          </div>

        </div>
      </div>
    </>
  )
}

export default withRouter(SelectCanjes);