import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import estadosHelper from '../helpers/EstadosHelper';
import moment from "moment";
import listadoOperacionesStore from "../../../store/listadoOperacionesStore";

const estadosBase = {
  100: 'Nueva Operación',
  102: 'Valores Negociados Cargados',
  104: 'Turno de Firma Agendado',
  106: 'Valores Boleto Cargados',
  108: 'Documentación Firmada',
  109: 'Venta Finalizada',
  0: 'Venta Cancelada',
};

export const ListadoOperacionesComp = ({
  loading,
  operaciones,
  url,
  totalRows,
}) => {
  const { paginator, setPaginator, kanbanView, setKanbanView } = listadoOperacionesStore();
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);

  const columns = [
    {
      name: "ID",
      selector: (row) => row._id,
      sortable: true,
    },
    {
      name: "Fecha Alta",
      selector: (row) => row.fechaAlta,
      sortable: true,
      cell: (row) => {
        return moment(row.fechaAlta)
          .utcOffset(180)
          .format("DD-MM-YYYY");
      },
    },
    {
      name: "Último Evento",
      selector: (row) => row.fechaUltimoEvento,
      sortable: true,
      cell: (row) => {
        return moment(row.fechaUltimoEvento)
          .utcOffset(180)
          .format("DD-MM-YYYY");
      },
    },
    {
      name: "Cliente",
      selector: (row) => row.cliente,
      sortable: true,
    },
    {
      name: "Lotes",
      cell: (row) => {
        if (row.lotes.length === 1) {
          return `${row.lotes[0].barrio.nombre} - ${row.lotes[0].numeroLote}`;
        } else {
          return (
            <div className="pt-3">
              {row.lotes.map((lote, index) => (
                <p key={index}>{lote.barrio.nombre} - {lote.numeroLote}</p>
              ))}
            </div>
          );
        }
      },
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.estado,
      sortable: true,
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <Link to={row.url}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const arrayOriginal = operaciones.map((operacion) => {
      const nombre = operacion.cliente.razonSocial ? operacion.cliente.razonSocial : `${operacion.cliente.nombre.apellido}, ${operacion.cliente.nombre.nombre}`;
      return {
        _id: operacion.id,
        fechaAlta: operacion.fechaAlta,
        cliente: nombre,
        lotes: operacion.lote,
        estado: estadosHelper.get(operacion.estado?.id)?.estado || 'Estado intermedio',
        idEstado: operacion.estado?.id,
        button: true,
        url: `${url}/${operacion._id}`,
        fechaUltimoEvento: operacion.historico[operacion.historico.length - 1].fecha,
      };
    });
    setDataOriginal(arrayOriginal);
    setData(arrayOriginal);
  }, [url, operaciones]);

  const buscar = (e) => {
    let filtro = e.target.value.toLowerCase();
    let lotes = '';

    const resultado = dataOriginal.filter((data) => {
      if (data.cliente.toLowerCase().includes(filtro)) {
        return data;
      }
      if (data.estado.toLowerCase().includes(filtro)) {
        return data;
      }
      data.lotes.forEach(lote => {
        lotes += `${lote.barrio.nombre} - ${lote.numeroLote} `;
      });
      if (lotes.toLowerCase().includes(filtro)) {
        lotes = '';
        return data;
      }
      lotes = '';
    });

    setData(resultado);
  };

  const submit = (e) => {
    e.preventDefault();
  };

  const handlePerRowsChange = async (limit) => {
    setPaginator({
      ...paginator,
      limit,
    });
  }

  const handlePageChange = async (page) => {
    setPaginator({
      ...paginator,
      page,
    });
  }

  // Configuracion del datatable
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const toggleView = () => {
    setKanbanView(!kanbanView);
  };

  const renderKanban = () => {
  const columns = Object.entries(estadosBase).map(([key, value]) => {
    return {
      id: key,
      title: value,
      cards: data.filter(item => item.idEstado.toString() === key),
    };
  });

  return (
    <div className="kanban-container">
      <div className="kanban">
        {columns.map(column => (
          <div key={column.id} className="kanban-column">
            <div className="kanban-column-header">
              <h2>{column.title}</h2>
            </div>
            <div className="kanban-column-body">
              {column.cards.map(card => (
                <div key={card._id} className="kanban-card">
                  <p className="mb-2"><strong>{card.cliente}</strong></p>
                  <p><strong>ID:</strong> {card._id}</p>
                  <p><strong>Fecha Alta:</strong> {moment(card.fechaAlta).utcOffset(180).format("DD-MM-YYYY")}</p>
                  <p><strong>Último Evento:</strong> {moment(card.fechaUltimoEvento).utcOffset(180).format("DD-MM-YYYY")}</p>
                  <p><strong>{card.lotes.length > 1 ? `Lotes:` : `Lote:`}</strong></p>
                  {card.lotes.map((lote, index) => (
                    <p key={index}>{lote.barrio.nombre} - {lote.numeroLote}</p>
                  ))}
                  <div className="mt-4 mb-2">
                    <Link to={card.url}>
                      <button type="button" className="btn btn-block btn-success">
                        Ver
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};



  return (
    <>
      <div className="row">
        <div className="col-md-10 mt-4">
          <form onSubmit={submit}>
            <div className="form-group">
              <input
                placeholder="Buscar:"
                onChange={buscar}
                type="text"
                name="buscar"
                className="form-control"
              />
            </div>
          </form>
        </div>
        <div className="col-md-2">
          <button onClick={toggleView} className="btn btn-primary float-right mt-4 mb-4">
            {kanbanView ? "Ver Tabla" : "Ver Kanban"}
          </button>
        </div>
      </div>
      {kanbanView ? (
        renderKanban()
      ) : (
        <DataTable
          progressPending={loading}
          columns={columns}
          data={data}
          pagination
          paginationServer
          paginationComponentOptions={paginationComponentOptions}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationTotalRows={totalRows}
          paginationDefaultPage={paginator.page}
          paginationDefaultRowsPerPage={paginator.limit}
        />
      )}
    </>
  );
};
