import React, {useState, useEffect, useContext} from "react";
import clienteAxios from "../../../config/axios";
import {CRMContext} from "../../../context/CRMContext";

const SelectNupcias = ({cliente, actualizarState, disabled = false}) => {
  const [conyuge, setConyuge] = useState(false);
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  useEffect(() => {
    if (cliente.estadoCivil) {
      const consulta = async () => {
        try {
          if (cliente.estadoCivil.conyuge) {
            setConyuge(cliente.estadoCivil.conyuge)
          }
          const estado = await clienteAxios.get(`/estadoCivil/${cliente.estadoCivil._id}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setConyuge(estado.data.conyuge)
        } catch (error) {

        }

      }
      consulta()

    }
  }, [cliente]);

  if (!conyuge) {
    return (
      <>
      </>
    )
  }

  return (
    <>
      <div className="form-group">
        <label>Cantidad de Nupcias</label>
      </div>
      <div className="col-md-2 form-group">
        <div className="col-md-12 form-group">
          <input
            type="number"
            className="form-control"
            placeholder=""
            name="nupcias"
            onChange={actualizarState}
            value={cliente?.nupcias}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};

export default SelectNupcias;
