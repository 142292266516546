import React, { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

import { get } from '../../config/apiHelper';
import moment from 'moment';

export const ListadoLotesFormaPago = () => {

  const [lotesVendidos, setLotesVendidos] = useState([]);

  const getLotes = async () => {
    const response = await get('/api/bi/findPaymentLotes');
    console.log(response.data);
    setLotesVendidos(response.data);
  }

  useEffect(() => {
    getLotes()
    return () => {
    }
  }, []);

  const columns = [
    {
      name: "Desarrollo",
      selector: (row) => row.desarrollo,
      sortable: true,
    },
    {
      name: "Nro de Lote",
      selector: (row) => row.nroLote,
      sortable: true,
    },
    {
      name: "Nro de Operación",
      selector: (row) => row.nroOperacion,
      sortable: true,
    },
    {
      name: "Estado Operación",
      selector: (row) => row.estadoOperacion,
      sortable: true,
    },
    {
      name: "Precio Lista Dólares",
      selector: (row) => new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "USD",
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(row.precioListaDolares),
      sortable: true,
    },
    {
      name: "Precio Compra Dólares",
      selector: (row) => new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "USD",
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(row.precioCompraDolares),
      sortable: true,
    },
    {
      name: "Precio Lista Pesos",
      selector: (row) => new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(row.precioListaPesos),
      sortable: true,
    },
    {
      name: "Precio Compra Pesos",
      selector: (row) => new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(row.precioCompraPesos),
      sortable: true,
    },
    {
      name: "Fecha de alta de Operación",
      selector: (row) => moment(row.fechaAltaOperacion)
        .utcOffset(180)
        .format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Ver Operación",
      selector: (row) => <Link to={`/operaciones/${row.operacion_id}`}>
        <button type="button" className="btn btn-block btn-success">
          Ver
        </button>
      </Link>,
      sortable: true,
    },
  ];


  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Listado de Lotes Vendidos</h4>
      </div>
      <div className="card-body">
        <DataTable
          columns={columns}
          data={lotesVendidos}
          pagination
          highlightOnHover
          responsive
          striped
          noHeader
        />
      </div>
    </div>
  )
}
