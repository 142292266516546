import React, { useState, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import Swal from "sweetalert2";
import FormularioInmobiliaria from "./components/FormularioInmobiliaria";

const NuevaInmobiliaria = (props) => {
  const [inmobiliaria, guardarInmobiliaria] = useState({});
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const actualizarState = (e) => {
    guardarInmobiliaria({
      ...inmobiliaria,
      [e.target.name]: e.target.value,
    });
  };

  const actualizarStateV2 = (value) => {
    guardarInmobiliaria({
      ...inmobiliaria,
      ...value,
    });
  }

  const validarInmobiliaria = () => {
    const { nombre, telefono, direccion } = inmobiliaria;
    let valido = !nombre || !telefono || !direccion;
    return valido;
  };

  if (!token) {
    localStorage.clear();
    props.history.push("/login");
  }

  const agregarInmobiliaria = async (e) => {
    e.preventDefault();

    try {
      const rta = await clienteAxios.post("/inmobiliarias", inmobiliaria, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
      props.history.push("/configuracion/inmobiliarias");
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title mt-2">NUEVA INMOBILIARIA</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className="card-body">
        <form onSubmit={agregarInmobiliaria}>
          <FormularioInmobiliaria
            actualizarState={actualizarState}
            inmobiliariaNueva={true}
            actualizarStateV2={actualizarStateV2}
            inmobiliaria={inmobiliaria}
          />

          <div className="card-footer">
            <button
              type="submit"
              className="btn btn-primary float-right"
              disabled={validarInmobiliaria()}
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(NuevaInmobiliaria);
