import React, { useEffect, useState } from "react";

const SelectProvinciaMunicipio = ({ provincia, municipio, setState, disabled }) => {

  const updateState = (e) => {
    setState(e);
  }


  return (
    <>
      <div className="col-md-5 form-group">
        <label>Provincia *</label>
        <input
          type="text"
          className="form-control"
          placeholder="Provincia"
          name="provincia"
          onChange={updateState}
          value={provincia}
          disabled={disabled}
        />
      </div>

      <div className="col-md-5 form-group">
        <label>Municipio *</label>
        <input
          type="text"
          className="form-control"
          placeholder="Municipio"
          name="municipio"
          onChange={updateState}
          value={municipio}
          disabled={disabled}
        />
      </div>

    </>
  );

}

export default SelectProvinciaMunicipio;