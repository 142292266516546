import React, { useContext, useEffect, useState } from "react";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert2";

const MostrarBarrio = (props) => {
    const [barrio, setBarrio] = useState({});
    const [escribanias, setEscribanias] = useState([]);
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [linkTo, setlinkTo] = useState('')

    const [auth, guardarAuth] = useContext(CRMContext);
    let token = auth.token || localStorage.getItem("token");

    const [escribaniaSeleccionada, setEscribaniaSeleccionada] = useState({});

    const obtenerArchivo = async (nombreArchivo) => {
        try {
            const rta = await clienteAxios
                .get(`/archivosAdjuntos/${nombreArchivo}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    responseType: "blob",
                })
                .then((response) => {
                    if (response.status === 200) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `${nombreArchivo}`);
                        document.body.appendChild(link);
                        link.click();
                    }
                });
        } catch (error) {
            if (error.response?.status === 500) {
                localStorage.clear();
                // history.push("/login");
            }
            console.log(error);
        }
    };

    const consultarAPI = async () => {
        const { id } = props.match.params;
        try {
            const barrioDB = await clienteAxios.get(`/barrios/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const escribaniasDB = await clienteAxios.get(`/escribania?limit=0&sort=nombre,1`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            escribaniasDB.data = escribaniasDB.data.data.map((escribania) => {
                return {
                    ...escribania,
                    label: escribania.nombre,
                    value: escribania._id
                }
            })

            if (barrioDB.data.escribania != null) {

                const escribania = escribaniasDB.data.find(escribania => {
                    return escribania._id === barrioDB.data.escribania;
                });

                const escribaniaSeleccionada = {
                    value: escribania._id,
                    label: escribania.nombre
                }

                setEscribaniaSeleccionada(escribaniaSeleccionada);
            }
            setBarrio(barrioDB.data);
            setEscribanias(escribaniasDB.data);
            setlinkTo(`/barrios/`)
            setLoadingInfo(false);
        } catch (error) {
            console.log(error);
            if (error.response?.status === 500) {
                localStorage.clear();
                props.history.push("/login");
            }
        }
    }

    useEffect(() => {
        consultarAPI();
    }, []);

    const descargarListaPrecios = async () => {
        try {
            const { id:idBarrio } = props.match.params;
            const rta = await clienteAxios
              .get(`/pdf/listaPrecios/${idBarrio}`, {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
                  responseType: "blob",
              })
              .then((response) => {
                  if (response.status === 200) {
                      const url = window.URL.createObjectURL(new Blob([response]));
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", `Lista de precios - ${barrio.nombre}.pdf`);
                      document.body.appendChild(link);
                      link.click();
                  }
              });
        } catch (e) {
            console.log(e);
            // swal.fire
        }
    }


    if(loadingInfo){
        return <Spinner />
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Información del Desarrollo</h3>
                    <input type="button" value="Volver" className="btn btn-primary mr-3 float-right" onClick={props.history.goBack}/>
                    <Link 
                        to={`/lotes/${barrio._id}`}
                    >
                        <input type="button" value="Ver Lotes" className="btn btn-primary mr-3 float-right" />
                    </Link>
                    <input type="button" value="Descargar Lista de Precios" className="btn btn-primary mr-3 float-right" onClick={descargarListaPrecios}/>
                </div>
                <div className="card-body">
                    <>
                        <div className="m-3">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label>Nombre:</label>
                                    <p>{barrio.nombre}</p>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Ciudad:</label>
                                    <p>{barrio.ciudad}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label>Dirección:</label>
                                    <p>{barrio?.direccion}</p>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label>Escribanía</label>
                                    <p>{escribaniaSeleccionada.label}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 form-group">
                                    <label>Cuotas máximo financiado</label>
                                    <p>{barrio?.cuotasFinanciado}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="card-header">
                                <h4 className="card-title">ARCHIVOS ADJUNTOS</h4>
                            </div>
                            {   
                                barrio.archivosAdjuntos.map((archivo, i) => {
                                    return (
                                        <div className="row m-3" key={i}>
                                            <div className="form-group col-md-10">
                                                <label
                                                    role="button"
                                                    onClick={() => {
                                                        obtenerArchivo(archivo.nombreArchivo);
                                                    }}
                                                >
                                                    {archivo.nombre || 'Archivo Sin Nombre'}
                                                </label>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>


                        <div className="mb-5">
                            <div className="card-header">
                                <h4 className="card-title">INFORMACIÓN EXTRA</h4>
                            </div>
                            {barrio.informacionExtra &&
                                barrio.informacionExtra.map((informacion, i) => {
                                    return (
                                        <div className="row m-3" key={i}>
                                            <div className="form-group col-md-4">
                                                <label>
                                                    {informacion.campo || 'Campo Vacío'}
                                                </label>
                                            </div>
                                            <div className="form-group col-md-8">
                                                <p>{informacion.valor || 'Valor Vacío'}</p>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </>

                </div>
            </div>
        </>
    );
};

export default MostrarBarrio;
