import React, { useContext, useEffect, useState } from "react";

const InformacionExtraBarrios = ({ barrio, setBarrio }) => {
    const [infoExtra, setInfoExtra] = useState({ campo: '', valor: '' });
    const [inputDisabled, setInputDisabled] = useState(true);

    useEffect(() => {
        noInfo();
    }, [infoExtra]);


    const setState = (e) => {
        setInfoExtra({
            ...infoExtra,
            [e.target.name]: e.target.value
        });
    }

    const addInfoExtra = () => {
        if (barrio.informacionExtra) {
            setBarrio({
                ...barrio,
                informacionExtra: [
                    ...barrio.informacionExtra,
                    infoExtra
                ]
            });
        } else {
            setBarrio({
                ...barrio,
                informacionExtra: [
                    infoExtra
                ]
            });
        }
        setInfoExtra({
            campo: '',
            valor: ''
        });
    }

    const isEmpty = (text) => {
        return text.trim().length === 0;
    }

    const noInfo = () => {
        const { campo, valor } = infoExtra;
        setInputDisabled(isEmpty(campo) || isEmpty(valor));
    }

    const eliminarInfoExtra = (e, i) => {
        const informacionExtra = barrio.informacionExtra;
        informacionExtra.splice(i, 1);

        setBarrio({
            ...barrio,
            informacionExtra: informacionExtra
        });
    }

    return (
        <div className="mb-5">
            <div className="card-header">
                <h4 className="card-title">INFORMACIÓN EXTRA</h4>
            </div>
            <div className="card-header row m-3">
                <h5 className="card-title col-md-4">Campo</h5>
                <h5 className="card-title col-md-6">Valor</h5>
            </div>


            {barrio.informacionExtra &&
                barrio.informacionExtra.map((informacion, i) => {
                    return (
                        <div className="row m-3" key={i}>
                            <div className="form-group col-md-4">
                                <label>
                                    {informacion.campo || 'Campo Vacío'}
                                </label>
                            </div>
                            <div className="form-group col-md-6">
                                <p>{informacion.valor || 'Valor Vacío'}</p>
                            </div>
                            <div className="form-group col-md-2">
                                <input
                                    className="btn btn-danger "
                                    type="button"
                                    value="X"
                                    onClick={(e) => eliminarInfoExtra(e, i)}
                                />
                            </div>
                        </div>
                    );
                })
            }

            <div className="row m-3">
                <div className="col-md-4 form-group">
                    <label>Campo:</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Campo"
                        name="campo"
                        onChange={setState}
                        value={infoExtra.campo}
                    />
                </div>
                <div className="col-md-6 form-group">
                    <label>Valor:</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Valor"
                        name="valor"
                        onChange={setState}
                        value={infoExtra.valor}
                    />
                </div>
                <div className="col-md-2 form-group">
                    <label className="col-md-12">Agregar:</label>
                    <input type="button" value="Agregar" className="btn btn-primary col-md-12" onClick={addInfoExtra} disabled={inputDisabled} />
                </div>
            </div>


        </div>
    )
}

export default InformacionExtraBarrios;


