import React, { useContext, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import moment from "moment";
import ShowPersonFromCUIX from "../../clientes/components/ShowPersonFromCUIX";
import GetDolarMercado from '../../utils/GetDolarMercado';
import clienteAxios from "../../../config/axios";
import Swal from "sweetalert2";
import { CRMContext } from "../../../context/CRMContext";
import { withRouter } from "react-router-dom";

class ErrorMessage {
  constructor(message) {
    this.message = message;
  }
}

const FormularioVentaBien = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const { bien, setBien } = props;
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  useEffect(() => {
    setDefaultValues();
  }, []);

  const setDefaultValues = async () => {
    const bienCopy = Object.assign({}, bien);
    if (!bienCopy.venta) {
      bienCopy.venta = {};
    }
    if (bienCopy && bienCopy.venta && !bienCopy.venta.cambioVenta) {
      const dolar = await GetDolarMercado.getDolar();
      bienCopy.venta.cambioVenta = dolar.data.compra;
    }
    if (bienCopy && bienCopy.venta && !bienCopy.venta.fechaVenta) {
      bienCopy.venta.fechaVenta = Date.now();
    }
    setBien(bienCopy);
  }



  const updateFechaVenta = (e) => {
    const fecha = e.target.value;
    const date = new Date();
    const fechaPartes = fecha.split('-');
    date.setFullYear(fechaPartes[0]);
    date.setMonth(fechaPartes[1] - 1);
    date.setDate(fechaPartes[2]);
    if (date.toString() === 'Invalid Date') {
      return;
    }

    const bienCopy = Object.assign({}, bien);
    if (!bienCopy.venta) {
      bienCopy.venta = {};
    }
    bienCopy.venta.fechaVenta = date;
    setBien(bienCopy);
  }

  const updateCambioReferencia = (e) => {
    const valor = e.floatValue;
    if (valor <= 0) {
      return;
    }

    const bienCopy = Object.assign({}, bien);
    if (!bienCopy.venta) {
      bienCopy.venta = {};
    }
    bienCopy.venta.cambioVenta = valor;
    setBien(bienCopy);

  }

  const updateValorEnPesos = (e) => {
    const valor = e.floatValue;
    if (valor <= 0) {
      return;
    }
    updateAmount(valor, false);
  }

  const updateValorEnDolares = (e) => {
    const valor = e.floatValue;
    if (valor <= 0) {
      return;
    }
    updateAmount(valor, true);
  }

  const updateAmount = (amount, amountFromDolares) => {
    const bienCopy = Object.assign({}, bien);
    if (!bienCopy.venta) {
      bienCopy.venta = {};
    }
    if (!bienCopy.venta.cambioVenta) {
      return;
    }
    if (amountFromDolares) {
      bienCopy.venta.dolaresVenta = amount;
      bienCopy.venta.pesosVenta = amount * bienCopy.venta.cambioVenta;
    } else {
      bienCopy.venta.dolaresVenta = amount / bienCopy.venta.cambioVenta;
      bienCopy.venta.pesosVenta = amount;
    }
    setBien(bienCopy);
  }

  const setCUIXComprador = ({ value }) => {
    if (value.length < 11 && value.length > 0) {
      return;
    }
    const bienCopy = Object.assign({}, bien);
    if (!bienCopy.venta) {
      bienCopy.venta = {};
    }
    bienCopy.venta.cuixComprador = value;
    setBien(bienCopy);
  }

  const sendBien = async () => {

    const errorMessages = [];

    const { venta } = bien;
    const { cambioVenta, cuixComprador, dolaresVenta, fechaVenta, pesosVenta } = venta;
    if (!cambioVenta) {
      errorMessages.push(new ErrorMessage('El valor del cambio es requerido'));
    }
    if (!cuixComprador) {
      errorMessages.push(new ErrorMessage('El CUIL/CUIT del comprador es requerido'));
    }
    if (!dolaresVenta) {
      errorMessages.push(new ErrorMessage('El valor en dólares es requerido'));
    }
    if (!pesosVenta) {
      errorMessages.push(new ErrorMessage('El valor en pesos es requerido'));
    }
    if (!fechaVenta) {
      errorMessages.push(new ErrorMessage('La fecha de venta es requerida'));
    }

    if (errorMessages.length > 0) {
      console.log(errorMessages);
      const message = errorMessages.reduce((prev, curr, index) => {
        return index === 0 ? curr.message : prev + ', ' + curr.message;
      }, '');
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
      });
      return;
    }

    try {
      const dataBien = Object.assign({}, bien);
      dataBien.estado = 'Vendido';
      const rta = await clienteAxios.put(`/bienes/${bien._id}`, dataBien, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success")
          .then(() => props.history.push("/bienes"));
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
    }
  }

  const diabledBySent = () => {
    return bien.estado !== 'Disponible';
  }

  return (
    <>
      <div className="card-header">
        <h3 className="card-title">Información sobre la venta:</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">
          <div className="row">
            <div className="col-md-3">
              <label>Fecha:</label>
              <input
                type="date"
                className="form-control"
                placeholder="Fecha"
                name="fechaVenta"
                onChange={updateFechaVenta}
                value={moment(bien.venta?.fechaVenta).format('yyyy-MM-DD')}
                disabled={diabledBySent()}
                required
              />
            </div>
            <div className="col-md-3">
              <label>Cambio de Referencia:</label>
              <NumberFormat
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$  "}
                decimalScale={2}
                value={bien.venta?.cambioVenta || 0}
                onValueChange={updateCambioReferencia}
                disabled={diabledBySent()}
              />
            </div>
            <div className="col-md-3">
              <label>Valor en Pesos:</label>
              <NumberFormat
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$  "}
                decimalScale={2}
                value={bien.venta?.pesosVenta || 0}
                onValueChange={updateValorEnPesos}
                disabled={diabledBySent()}
              />
            </div>
            <div className="col-md-3">
              <label>Valor en Dólares:</label>
              <NumberFormat
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={'u$s  '}
                value={bien.venta?.dolaresVenta || 0}
                onValueChange={updateValorEnDolares}
                disabled={diabledBySent()}
              />
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="col-md-3">
              <label>CUIL / CUIT del Comprador:</label>
              <NumberFormat
                className="form-control"
                name="numeroCuilCuit"
                placeholder="20-12345678-9"
                format="##-########-#"
                mask="_"
                onValueChange={setCUIXComprador}
                value={bien.venta?.cuixComprador || ''}
                disabled={diabledBySent()}
              />
            </div>
          </div>
          {bien && bien.venta && bien.venta.cuixComprador ?
            <ShowPersonFromCUIX
              taxId={bien.venta.cuixComprador}
            /> : null
          }
          {
            !diabledBySent() &&
            <div className="row mt-3">
              <div className="col">
                <input type="button" value="Guardar Venta" onClick={sendBien}
                  className='btn btn-primary float-right mt-3' />
              </div>
            </div>
          }

        </div>
      </div>
    </>
  )
}

export default withRouter(FormularioVentaBien);