import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import DataTable from "react-data-table-component";
import Spinner from "../layout/Spinner";
import moment from "moment";

const ParametrosListado = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  const [loading, setLoading] = useState(true);
  let token = auth.token || localStorage.getItem("token");
  const [parametros, setParametros] = useState([]);

  const consultarApi = async () => {
    try {
      const response = await clienteAxios.get('/parametros?activo=true', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setParametros(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  const columns = [
    {
      name: 'Nombre',
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: 'Valor',
      selector: (row) => row.valor,
      sortable: true,
    },
    {
      name: 'Fecha Actualizado',
      selector: (row) => {
        return moment(row.createdAt)
          .format("DD-MM-YYYY HH:mm");
      },
      sortable: true,
    },
    {
      name: 'Editar',
      button: true,
      cell: (row) => (
        <Link to={`/configuracion/parametros/${row._id}`}>
          <button type="button" className="btn btn-block btn-success">
            Editar
          </button>
        </Link>
      ),
    },
  ]

  useEffect(() => {
    consultarApi();
  }, []);

  moment.locale('es-ar');

  return (
    <>

      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-8">
              <h3 className="card-title mt-2">PARÁMETROS</h3>
            </div>
          </div>
        </div>
        <div className="card-body">
          {loading && <Spinner />}
          {!loading && <>
            <DataTable columns={columns} data={parametros} pagination />
          </>}
        </div>
      </div>
    </>
  )

}

export default withRouter(ParametrosListado);