import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import NumberFormat from 'react-number-format';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import clienteAxios from '../../../../../../config/axios';
import { CRMContext } from '../../../../../../context/CRMContext';
import ShowPersonFromCUIX from '../../../../../clientes/components/ShowPersonFromCUIX';
import GetDolarMercado from "../../../../../utils/GetDolarMercado";
import MostrarPersona from "../../components/MostrarPersona";
import Spinner from '../../../../../layout/Spinner';

const SelectDocumentos = ({ onClose, operacion, setOperacion, goBack, ...props }) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [parametros, setParametros] = useState([]);
  const [valorDolarMercado, setValorDolarMercado] = useState(0);
  const [valorDolarPropuesto, setValorDolarPropuesto] = useState(0);

  const baseAnticipo = {
    moneda: 'ars',
    cambioSugerido: valorDolarMercado,
    monto: null,
  };

  const type = [{
    label: 'Cheque',
    value: 'cheque'
  }, {
    label: 'eCheck',
    value: 'echeck'
  }, {
    label: 'Pagaré',
    value: 'pagare'
  }, {
    label: 'Compromiso de Pago',
    value: 'compromisoDePago'
  }];

  const monedaOptions = [{
    label: '$ Ars',
    value: 'ars'
  }, {
    label: 'u$s',
    value: 'usd'
  }];

  const entidadesFinancieras = [
    { value: "00007", label: "00007 - BANCO DE GALICIA Y BUENOS AIRES S.A.U." },
    { value: "00011", label: "00011 - BANCO DE LA NACION ARGENTINA" },
    { value: "00014", label: "00014 - BANCO DE LA PROVINCIA DE BUENOS AIRES" },
    { value: "00015", label: "00015 - INDUSTRIAL AND COMMERCIAL BANK OF CHINA" },
    { value: "00016", label: "00016 - CITIBANK N.A." },
    { value: "00017", label: "00017 - BANCO BBVA ARGENTINA S.A." },
    { value: "00020", label: "00020 - BANCO DE LA PROVINCIA DE CORDOBA S.A." },
    { value: "00027", label: "00027 - BANCO SUPERVIELLE S.A." },
    { value: "00029", label: "00029 - BANCO DE LA CIUDAD DE BUENOS AIRES" },
    { value: "00034", label: "00034 - BANCO PATAGONIA S.A." },
    { value: "00044", label: "00044 - BANCO HIPOTECARIO S.A." },
    { value: "00045", label: "00045 - BANCO DE SAN JUAN S.A." },
    { value: "00065", label: "00065 - BANCO MUNICIPAL DE ROSARIO" },
    { value: "00072", label: "00072 - BANCO SANTANDER ARGENTINA S.A." },
    { value: "00083", label: "00083 - BANCO DEL CHUBUT S.A." },
    { value: "00086", label: "00086 - BANCO DE SANTA CRUZ S.A." },
    { value: "00093", label: "00093 - BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA M" },
    { value: "00094", label: "00094 - BANCO DE CORRIENTES S.A." },
    { value: "00097", label: "00097 - BANCO PROVINCIA DEL NEUQUÉN SOCIEDAD ANÓ" },
    { value: "00131", label: "00131 - BANK OF CHINA LIMITED SUCURSAL BUENOS AI" },
    { value: "00143", label: "00143 - BRUBANK S.A.U." },
    { value: "00147", label: "00147 - BIBANK S.A." },
    { value: "00150", label: "00150 - HSBC BANK ARGENTINA S.A." },
    { value: "00158", label: "00158 - OPEN BANK ARGENTINA S.A." },
    { value: "00165", label: "00165 - JPMORGAN CHASE BANK, NATIONAL ASSOCIATIO" },
    { value: "00191", label: "00191 - BANCO CREDICOOP COOPERATIVO LIMITADO" },
    { value: "00198", label: "00198 - BANCO DE VALORES S.A." },
    { value: "00247", label: "00247 - BANCO ROELA S.A." },
    { value: "00254", label: "00254 - BANCO MARIVA S.A." },
    { value: "00259", label: "00259 - BANCO ITAU ARGENTINA S.A." },
    { value: "00266", label: "00266 - BNP PARIBAS" },
    { value: "00268", label: "00268 - BANCO PROVINCIA DE TIERRA DEL FUEGO" },
    { value: "00269", label: "00269 - BANCO DE LA REPUBLICA ORIENTAL DEL URUGU" },
    { value: "00277", label: "00277 - BANCO SAENZ S.A." },
    { value: "00281", label: "00281 - BANCO MERIDIAN S.A." },
    { value: "00285", label: "00285 - BANCO MACRO S.A." },
    { value: "00299", label: "00299 - BANCO COMAFI SOCIEDAD ANONIMA" },
    { value: "00300", label: "00300 - BANCO DE INVERSION Y COMERCIO EXTERIOR S" },
    { value: "00301", label: "00301 - BANCO PIANO S.A." },
    { value: "00305", label: "00305 - BANCO JULIO SOCIEDAD ANONIMA" },
    { value: "00309", label: "00309 - BANCO RIOJA SOCIEDAD ANONIMA UNIPERSONAL" },
    { value: "00310", label: "00310 - BANCO DEL SOL S.A." },
    { value: "00311", label: "00311 - NUEVO BANCO DEL CHACO S. A." },
    { value: "00312", label: "00312 - BANCO VOII S.A." },
    { value: "00315", label: "00315 - BANCO DE FORMOSA S.A." },
    { value: "00319", label: "00319 - BANCO CMF S.A." },
    { value: "00321", label: "00321 - BANCO DE SANTIAGO DEL ESTERO S.A." },
    { value: "00322", label: "00322 - BANCO INDUSTRIAL S.A." },
    { value: "00330", label: "00330 - NUEVO BANCO DE SANTA FE SOCIEDAD ANONIMA" },
    { value: "00331", label: "00331 - BANCO CETELEM ARGENTINA S.A." },
    { value: "00332", label: "00332 - BANCO DE SERVICIOS FINANCIEROS S.A." },
    { value: "00338", label: "00338 - BANCO DE SERVICIOS Y TRANSACCIONES S.A." },
    { value: "00339", label: "00339 - RCI BANQUE S.A." },
    { value: "00340", label: "00340 - BACS BANCO DE CREDITO Y SECURITIZACION S" },
    { value: "00341", label: "00341 - BANCO MASVENTAS S.A." },
    { value: "00384", label: "00384 - WILOBANK S.A.U." },
    { value: "00386", label: "00386 - NUEVO BANCO DE ENTRE RÍOS S.A." },
    { value: "00389", label: "00389 - BANCO COLUMBIA S.A." },
    { value: "00426", label: "00426 - BANCO BICA S.A." },
    { value: "00431", label: "00431 - BANCO COINAG S.A." },
    { value: "00432", label: "00432 - BANCO DE COMERCIO S.A." },
    { value: "00435", label: "00435 - BANCO SUCREDITO REGIONAL S.A.U." },
    { value: "00448", label: "00448 - BANCO DINO S.A." },
  ]


  const [anticipos, setAnticipos] = useState(operacion.formaDePago.anticipo.documentos || [{
    ...baseAnticipo
  }]);

  const actualizarState = (e, index) => {
    const { name, value } = e.target;
    const anticiposLocal = [...anticipos];
    anticiposLocal[index][name] = value;
    setAnticipos([...anticiposLocal]);
  }

  const actualizarStateSelect = (name, value, index) => {
    actualizarState({
      target: {
        name,
        value,
      }
    }, index);
  }

  const agregarAnticipo = () => {
    const anticiposLocal = [...anticipos];
    anticiposLocal.push({ ...baseAnticipo });
    setAnticipos(anticiposLocal);
  }

  const eliminarAnticipo = (index) => {
    let anticiposLocal = [...anticipos];
    anticiposLocal.splice(index, 1);
    setAnticipos(anticiposLocal);
  }

  const duplicarAnticipo = (index) => {
    const anticipoDuplicated = { ...anticipos[index] }
    const anticiposCopy = [
      ...anticipos,
      anticipoDuplicated
    ];
    setAnticipos(anticiposCopy);
  }

  const saveDocumentos = () => {
    onClose();
  }

  useEffect(async () => {
    const dolarApi = await GetDolarMercado.getDolar();
    const valorDolarMercado = dolarApi.data.compra;
    setValorDolarMercado(valorDolarMercado);
    setValorDolarPropuesto(valorDolarMercado);
    obtenerParametros();
  }, []);

  useEffect(() => {
    anticipos.forEach(anticipo => {
      if (anticipo.type === 'cheque') {
        processCheque(anticipo);
      }
      anticipo.valores = {
        fechaPropuesto: Date.now(),
        cambioPropuesto: anticipo.moneda && anticipo.moneda === 'usd' ? anticipo.cambioSugerido : valorDolarMercado,
        pesosPropuesto: anticipo.moneda && anticipo.moneda === 'ars' ? anticipo.monto : anticipo.monto * anticipo.cambioSugerido,
        dolaresPropuesto: anticipo.moneda && anticipo.moneda === 'usd' ? anticipo.monto : anticipo.monto / valorDolarPropuesto,

        fechaMercado: Date.now(),
        pesosMercado: anticipo.moneda && anticipo.moneda === 'ars' ? anticipo.monto : anticipo.monto * valorDolarMercado,
        dolaresMercado: anticipo.moneda && anticipo.moneda === 'usd' ? anticipo.monto : anticipo.monto / valorDolarMercado,
        cambioMercado: valorDolarMercado,
      }
    });

    setOperacion({
      ...operacion,
      formaDePago: {
        ...operacion.formaDePago,
        anticipo: {
          ...operacion.formaDePago.anticipo,
          documentos: [...anticipos],
        }
      }
    });
  }, [anticipos]);

  const processCheque = (cheque) => {
      cheque.monto = cheque.base;
  }


  const obtenerParametros = async () => {
    try {
      const parametros = await clienteAxios.get(
        `/parametros?activo=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setParametros(parametros.data);
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
    }
  };

  const getTasaCheque = () => {
    const tasaCheque = parametros.find(param => {
      return param.nombre === "tasaCheques";
    });
    if (tasaCheque && tasaCheque.valor) {
      return parseFloat(tasaCheque.valor);
    } else {
      return 0;
    }
  }

  console.log('operacion', operacion.formaDePago.anticipo.documentos);

  if (valorDolarMercado === 0) {
    return <Spinner />
  }


  return (
    <>
      <label className="col-md-12">Cheques y/o pagarés</label>
      {anticipos.map((anticipo, index) => {
        return (
          <>
            <div key={index}>
              <div className="row mt-5" key={index}>
                <div className='col-md-3 form-group'>
                  <label>Tipo</label>
                  <Select
                    name='type'
                    options={type}
                    value={type.find(option => option.value === anticipo.type)}
                    onChange={(e) => actualizarStateSelect('type', e.value, index)}
                  />
                </div>
                {anticipo && anticipo.type && anticipo.type !== 'cheque' &&
                  <div className='col-md-2 form-group'>
                    <label>Moneda</label>
                    <Select
                      name='moneda'
                      options={monedaOptions}
                      value={monedaOptions.find(option => option.value === anticipo.moneda)}
                      onChange={(e) => actualizarStateSelect('moneda', e.value, index)}
                    />
                  </div>
                }
                {anticipo && anticipo.moneda && anticipo.moneda === 'usd' && anticipo.type !== 'cheque' &&
                  <>
                    <div className="col-md-2 form-group">
                      <label>C. Mercado:</label>
                      <NumberFormat
                        className="form-control"
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"$"}
                        value={valorDolarMercado}
                        disabled
                      />
                    </div>
                    <div className='col-md-2 form-group'>
                      <label>C. propuesto:</label>
                      <NumberFormat
                        className="form-control"
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"$"}
                        value={anticipo.cambioSugerido}
                        onValueChange={(e) => {
                          let enviar = {
                            target: {
                              value: e.floatValue,
                              name: "cambioSugerido",
                            },
                          };
                          actualizarState(enviar, index);
                        }}
                      />
                    </div>
                  </>
                }
                {anticipo && anticipo.moneda && anticipo.moneda === 'ars' &&
                  <div className='col-md-2 form-group'></div>
                }

                {anticipo && anticipo.type && anticipo.type === 'cheque' &&
                  <div className='col-md-2 form-group'>
                    <label>Monto</label>
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      value={anticipo.base}
                      onValueChange={(e) => {
                        let enviar = {
                          target: {
                            value: e.floatValue,
                            name: "base",
                          },
                        };
                        actualizarState(enviar, index);
                      }}
                    />
                  </div>
                }

                {anticipo && anticipo.type && anticipo.type !== 'cheque' &&
                  <div className='col-md-3 form-group'>
                    <label>Monto</label>
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$"}
                      value={anticipo.monto}
                      onValueChange={(e) => {
                        let enviar = {
                          target: {
                            value: e.floatValue,
                            name: "monto",
                          },
                        };
                        actualizarState(enviar, index);
                      }}
                    />
                  </div>
                }
                <div className="form-group col-md-3">
                  <label>Fecha de Pago</label>
                  <input
                    type="date"
                    className="form-control"
                    name="fechaPago"
                    onChange={(e) => actualizarState(e, index)}
                    value={moment(anticipo.fechaPago)
                      .utcOffset(180)
                      .format("YYYY-MM-DD")}
                  />
                </div>
              </div>

              {
                anticipo && anticipo.type === 'cheque' &&
                <>
                  <div className="row">
                    <div className='col-md-3 form-group'>
                      <label>Número</label>
                      <NumberFormat
                        className="form-control"
                        value={anticipo.numeroCheque}
                        format={"########"}
                        mask={"#"}
                        placeholder={"########"}
                        onValueChange={(e) => {
                          let enviar = {
                            target: {
                              value: e.floatValue,
                              name: "numeroCheque",
                            },
                          };
                          actualizarState(enviar, index);
                        }}
                      />
                    </div>
                    <div className='col-md-3 form-group'>
                      <label>Banco</label>
                      <Select
                        name='banco'
                        options={entidadesFinancieras}
                        value={entidadesFinancieras.find(option => option.value === anticipo.banco)}
                        onChange={(e) => {
                          let enviar = {
                            target: {
                              value: e.value,
                              name: 'banco',
                            },
                          };
                          actualizarState(enviar, index);
                        }}
                      />
                    </div>
                    <div className='col-md-3 form-group'>
                      <label>Plaza</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Plaza"
                        name="plaza"
                        onChange={(e) => {
                          let enviar = {
                            target: {
                              value: e.target.value,
                              name: e.target.name,
                            },
                          };
                          actualizarState(enviar, index);
                        }}
                        value={anticipo.plaza}
                        required
                      />
                    </div>
                    <div className='col-md-3 form-group'>
                      <label>Nro Sucursal</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Número de Sucursal"
                        name="sucursal"
                        onChange={(e) => {
                          let enviar = {
                            target: {
                              value: e.target.value,
                              name: e.target.name,
                            },
                          };
                          actualizarState(enviar, index);
                        }}
                        value={anticipo.sucursal}
                        required
                      />
                    </div>
                    <div className='col-md-3 form-group'>
                      <label>CUIT/CUIL librador</label>
                      <NumberFormat
                        className="form-control"
                        name="cuitLibrador"
                        placeholder="20-12345678-9"
                        format="##-########-#"
                        mask="_"
                        value={anticipo.cuitLibrador}
                        onValueChange={(e) => {
                          let enviar = {
                            target: {
                              value: e.floatValue,
                              name: "cuitLibrador",
                            },
                          };
                          actualizarState(enviar, index);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col">
                      <label>Información del Librador del Cheque:</label>
                      <ShowPersonFromCUIX taxId={anticipo.cuitLibrador} />
                    </div>
                  </div>

                </>

              }
              <div className="row border-bottom">
                <div className="col-md-8"></div>
                <div className='col-md-2'>
                  <div className="form-group">
                    <button className="btn btn-danger" onClick={() => eliminarAnticipo(index)}>Eliminar</button>
                  </div>
                </div>
                <div className="col-md-2 ml-auto">
                  <div className="form-group">
                    <button className="btn btn-primary" onClick={() => duplicarAnticipo(index)}>Duplicar</button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      })}

      <div className="row mt-5">
        <div className="col-md-3 ml-auto">
          <div className="form-group">
            <button className="btn btn-primary" onClick={agregarAnticipo}>Agregar Otro</button>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="row d-flex justify-content-around card-footer">

          <div className="">
            <button className="btn btn-secondary" onClick={goBack}>Anterior</button>
          </div>

          <div className="">
            <button className="btn btn-primary" onClick={saveDocumentos}>Siguiente</button>
          </div>

        </div>
      </div>
    </>
  )
}

export default withRouter(SelectDocumentos);