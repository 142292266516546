import React, { useEffect, useState } from 'react';
import clienteAxios from '../../../../../config/axios';
import Select from "react-select";
import NumberFormat from "react-number-format";
import FormaPagoBoleto from "../../../../formaPagoBoleto/FormaPagoBoleto";
import MostrarDatosCargados from '../../../../formaPagoBoleto/MostrarDatosCargados';
import Swal from "sweetalert2";

function GenerarBoletoCompraventa({
  operacion,
  guardarOperacion,
  title = 'Generar el Boleto de Compraventa',
  token,
  enviarOperacion,
  actualizarOperacion,
  ...props
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [firmantesLife, setFirmantesLife] = useState([]);
  const [firmanteSeleccionado, setFirmanteSeleccionado] = useState(null);
  const [mesesPosesion, setMesesPosesion] = useState(null);
  const [caracteristicasBoleto, setCaracteristicasBoleto] = useState({
    precioBoleto: null,
    entregasBoleto: null,
    financiadoBoleto: null,
  });
  const getFirmantes = async () => {
    const response = await clienteAxios.get('/firmantesLife', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        activo: true,
      }
    });
    const firmantes = response.data.data.map((firmante) => {
      const nombreCompleto = firmante.nombre.apellido ? `${firmante.nombre.apellido}, ${firmante.nombre.nombre} ${firmante.nombre.segundoNombre || ''}` : firmante.razonSocial;
      return {
        nombreCompleto,
        numeroCuilCuit: firmante.documentos.cuitCuil,
        email: firmante.email,
        url: `/clientes/editar/${firmante._id}`,
        id: firmante._id,
      };
    });
    setFirmantesLife(firmantes);
  }

  useEffect(() => {
    getFirmantes();
  }, []);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleFirmanteSeleccionado = (e) => {
    setFirmanteSeleccionado(e.value);
  }

  const generarBoleto = async () => {

    if (!firmanteSeleccionado) {
      return;
    }
    try {
      const rta = await clienteAxios.post(`/boletoCompraVenta/${operacion._id}?firmanteLife=${firmanteSeleccionado}&mesesPosesion=${mesesPosesion}`, caracteristicasBoleto,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([rta.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `BoletoCompraVenta.docx`);
      document.body.appendChild(link);
      link.click();
      await actualizarOperacion()
    } catch (error) {
      console.log(error.response);
      await Swal.fire({
        type: "error",
        title: 'Hubo un error al intentar generar el boleto',
        text: "Hubo un error",
      });
    }
  }

  const updateCaracteristicasBoleto = (key, value) => {
    const caracteristicasCopy = { ...caracteristicasBoleto };
    caracteristicasCopy[key] = value;
    setCaracteristicasBoleto(caracteristicasCopy);
  }


  return (
    <>
      < div className="card-header">
        <h3 className="card-title">{title}</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>


        <div className="card-body">
          <FormaPagoBoleto setCaracteristicasBoleto={updateCaracteristicasBoleto} operacion={operacion}
            setOperacion={guardarOperacion} />
          <div className="row">
            <div className="col-md-5">
              <label>Firmante de Life:</label>
              <Select
                className='mt-3'
                onChange={handleFirmanteSeleccionado}
                options={firmantesLife.map((firmante) => ({ label: firmante.nombreCompleto, value: firmante.id }))}
              />
            </div>
            <div className="col-md-3">
              <label>Plazo de entrega:</label>
              <NumberFormat
                className="form-control mt-3"
                suffix=' meses'
                onValueChange={(e) => {
                  setMesesPosesion(e.floatValue)
                }}
              />
            </div>
            <div className="col-md-3">
              <input
                type="button"
                value="Generar Boleto"
                className="btn btn-primary mt-5" // float-right"
                onClick={generarBoleto}
              />
            </div>
          </div>

          {operacion.ultimoBoletoEmitido && operacion?.ultimoBoletoEmitido?.firmanteLife &&
            <>
              <MostrarDatosCargados data={operacion.ultimoBoletoEmitido} />
            </>
          }

          <div className="row mt-3 mb-5">
            <div className="col">
              <button
                className="btn btn-success float-right"
                type="button"
                disabled={!mesesPosesion && !firmanteSeleccionado}
                onClick={enviarOperacion}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GenerarBoletoCompraventa;