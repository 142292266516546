import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import FormularioNacionalidad from "./components/formularioNacionalidad";
import Swal from "sweetalert2";

const EditarNacionalidad = (props) => {
  const [nacionalidad, setNacionalidad] = useState({});
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  useEffect(() => {
    const consultarAPI = async () => {
      let { id } = props.match.params;
      try {
        const nacionalidadDB = await clienteAxios.get(`/nacionalidad/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setNacionalidad(nacionalidadDB.data);
        
      } catch (error) {
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
        console.log(error);
      }
    };

    consultarAPI();
  }, []);

  

  const actualizarState = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setNacionalidad({
      ...nacionalidad,
      [e.target.name]: value,
    });
  };

  const validarNacionalidad = () => {
    const { nombre } = nacionalidad;
    let valido = !nombre;
    return valido;
  };

  const enviarNacionalidad = async (e) => {
    e.preventDefault();

    let { id } = props.match.params;
    try {
      const rta = await clienteAxios.put(`/nacionalidad/${id}`, nacionalidad, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      props.history.push("/configuracion/nacionalidades");
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  // const eliminarNacionalidad = async (e) => {
  //   e.preventDefault();

  //   let { id } = props.match.params;
  //   try {
  //     const rta = await clienteAxios.delete(`/nacionalidad/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (rta.data.estado == "ok") {
  //       Swal.fire("¡Correcto!", rta.data.msg, "success");
  //     } else if (rta.data.estado == "error") {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: rta.data.msg,
  //       });
  //     }
  //     props.history.push("/configuracion/nacionalidades");
  //   } catch (error) {
  //     if (error.response?.status === 500) {
  //       localStorage.clear();
  //       props.history.push("/login");
  //     }
  //     console.log(error);
  //   }
  // };

  if (!nacionalidad.nombre) {
    return <Spinner />;
  }

  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title mt-2'>EDITAR NACIONALIDAD</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className='card-body'>
        <form>
          <FormularioNacionalidad
            actualizarState={actualizarState}
            nacionalidad={nacionalidad}
            nacionalidadNueva={false}
          />

          <div className='card-footer'>
            {/* <button
              type='submit'
              className='btn btn-danger'
              onClick={eliminarNacionalidad}
            >
              Eliminar
            </button> */}

            <button
              type='submit'
              className='btn btn-primary float-right'
              disabled={validarNacionalidad()}
              onClick={enviarNacionalidad}
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(EditarNacionalidad);
