import React, { useState } from 'react'

const MostrarEscribania = ({ escribania }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <>
      <div className='card-header mb-3'>
        <h4 className='card-title'>Escribanía</h4>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">
          <div className='row'>
            <div className='form-group col-md-4'>
              <label htmlFor='exampleInputEmail1'>Escribanía</label>
              <p>{escribania?.nombre}</p>
            </div>

            <div className='form-group col-md-5'>
              <label htmlFor='exampleInputEmail1'>Domicilio</label>
              <p>{escribania?.domicilio}</p>
            </div>

            <div className='form-group col-md-3'>
              <label htmlFor='exampleInputEmail1'>Teléfono</label>
              <p>{escribania?.telefono}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MostrarEscribania;