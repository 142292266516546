import React, { useContext, useEffect, useState } from 'react';
import Select from "react-select";
import { withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import Spinner from "../../../../../layout/Spinner";
import { CRMContext } from "../../../../../../context/CRMContext";
import clienteAxios from "../../../../../../config/axios";
import NumberFormat from "react-number-format";
import GetDolarMercado from "../../../../../utils/GetDolarMercado";

const SelectSoldLotes = ({ operacion, setOperacion, onClose, goBack, parametros, ...props }) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const DESCUENTO_SOBRE_LISTA = 10;
  const [returnSpinner, setReturnSpinner] = useState(false);
  const [lotesSeleccionados, setLotesSeleccionados] = useState(operacion.formaDePago.anticipo.lotes || []);
  const [barrioSeleccionado, setBarrioSeleccionado] = useState(null);
  const [barrios, guardarBarrios] = useState([]);
  const [lotes, guardarLotes] = useState([]);
  const [valorDolarMercado, setValorDolarMercado] = useState(0);
  const [valorDolarPropuesto, setValorDolarPropuesto] = useState(0);

  const formatterUs = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  const formatterAr = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const consultarBarrios = async () => {
    try {
      const barriosConsulta = await clienteAxios.get(
        "/barrios/ori?activo=true&campos=nombre,_id",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      guardarBarrios(
        barriosConsulta.data.map((barrio) => {
          return {
            label: barrio.nombre,
            value: barrio._id,
          };
        })
      );
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  // Al seleccionar el barrio se guarda el id del barrio y se consulta el listado de lotes de dicho barrio a la API
  const consultarLotes = async (e) => {
    const id = e.value;
    setBarrioSeleccionado(id);
    guardarLotes([]);
    setReturnSpinner(true);

    try {
      const lotesConsulta = await clienteAxios.get(`/lotes/sold/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (lotesConsulta.data.length > 0) {
        guardarLotes(
          lotesConsulta.data.map((lote) => {
            return {
              value: lote._id,
              label: `${lote.barrio.nombre} - ${lote.numeroLote}`,
              precioLista: lote.precioLista,
              precioReferencia: lote.precioLista * (1 - DESCUENTO_SOBRE_LISTA / 100),
            };
          })
        );
      }
      setReturnSpinner(false);
    } catch (error) {
      // if (error.response?.status === 500) {
      //   localStorage.clear();
      //   props.history.push("/login");
      // }
      console.log(error);
    }
  };

  const deleteLoteSeleccionado = (idLoteBorrar) => {
    const nuevosLotesSeleccionados = lotesSeleccionados.filter(lote => {
      return lote.id !== idLoteBorrar;
    });
    setLotesSeleccionados(nuevosLotesSeleccionados);
  }

  const guardarLote = (e) => {
    const id = e.value;
    const lote = e.label;
    const precioLista = e.precioLista;
    const precioReferencia = e.precioReferencia;
    const loteYaSeleccionado = lotesSeleccionados.some(lot => lot.id === id);
    if (loteYaSeleccionado) {
      return;
    }
    setLotesSeleccionados([
      ...lotesSeleccionados,
      {
        id,
        lote,
        precioLista,
        precioReferencia,
        valores: {
          fechaPropuesto: Date.now(),
          cambioPropuesto: valorDolarPropuesto,
          pesosPropuesto: precioReferencia * valorDolarPropuesto,
          dolaresPropuesto: precioReferencia,

          fechaMercado: Date.now(),
          pesosMercado: precioLista * valorDolarMercado,
          dolaresMercado: precioLista,
          cambioMercado: valorDolarMercado,
        }
      }
    ]);
  };

  useEffect(async () => {
    consultarBarrios();
    const dolarApi = await GetDolarMercado.getDolar();
    const valorDolar = dolarApi.data.compra;
    setValorDolarMercado(valorDolar);
    setValorDolarPropuesto(valorDolar);
  }, []);

  const saveSelectedLotes = () => {
    onClose();
  }

  const updatePrecioLote = (e, id) => {
    const valor = e.floatValue;
    const lotesSeleccionadosUpdate = lotesSeleccionados.map(lote => {
      if (lote.id === id) {
        lote.valores.dolaresPropuesto = valor;
        lote.valores.pesosPropuesto = valor * valorDolarPropuesto;
      }
      console.log(lote.valores);
      return lote;
    });
    setLotesSeleccionados(lotesSeleccionadosUpdate);
  }


  useEffect(() => {

    const lotesSeleccionadosUpdate = lotesSeleccionados.map(lote => {
      const valores = {
        fechaPropuesto: Date.now(),
        cambioPropuesto: valorDolarPropuesto,
        pesosPropuesto: lote.valores.dolaresPropuesto ? lote.valores.dolaresPropuesto * valorDolarPropuesto : lote.precioReferencia * valorDolarPropuesto,
        dolaresPropuesto: lote.valores.dolaresPropuesto ?? lote.precioReferencia,
        fechaMercado: Date.now(),
        pesosMercado: lote.precioLista * valorDolarMercado,
        dolaresMercado: lote.precioLista,
        cambioMercado: valorDolarMercado,
      }
      lote.valores = valores;
      return lote;
    });

    setOperacion({
      ...operacion,
      formaDePago: {
        ...operacion.formaDePago,
        anticipo: {
          ...operacion.formaDePago.anticipo,
          lotes: lotesSeleccionadosUpdate,
        }
      }
    });
  }, [lotesSeleccionados, valorDolarPropuesto]);

  const columns = [
    {
      name: 'Lote',
      selector: (row) => row.lote,
      sortable: true,
      width: '25%',
    },
    {
      name: 'Valor de Referencia',
      selector: (row) => formatterUs.format(row.precioReferencia),
      sortable: true,
      width: '25%',
    },
    {
      name: 'Valor Propuesto',
      selector: (row) => formatterUs.format(row.precioReferencia),
      sortable: true,
      width: '35%',
      cell: (row) => (
        <NumberFormat
          className={`col-md-4`}
          value={row.dolaresPropuesto}
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={'u$s '}
          onValueChange={(e) => updatePrecioLote(e, row.id)}
        />
      )
    },
    {
      name: 'Eliminar',
      button: true,
      width: '15%',
      cell: (row) => (
        <button onClick={() => { console.log(row); deleteLoteSeleccionado(row.id) }} className="btn-danger btn">X</button>
      )
    }
  ];

  if (!barrios.length) {
    return <Spinner />;
  }


  return (
    <>
      <div className="row">
        <label className="col-md-12">Seleccionar Desarrollo</label>
        <div className="form-group col-md-6">
          <Select
            name="barrio"
            onChange={consultarLotes}
            options={barrios}
          />
        </div>
      </div>
      {
        returnSpinner && <Spinner />
      }
      {
        lotes.length > 0 &&
        <>
          <div className="row">
            <label className="col-md-12">Seleccionar Lote</label>
            <div className="form-group col-md-6">
              <Select
                name="lote"
                options={lotes}
                onChange={guardarLote}
              />
            </div>
          </div>
        </>
      }
      {
        lotes.length === 0 && barrioSeleccionado &&
        <>
          <label className="col-md-12">Seleccionar Lote</label>
          <p><strong>No hay lotes disponibles</strong></p>
        </>
      }
      {
        lotesSeleccionados.length > 0 &&

        <div className="form-group">
          <div className="row">
            <label>Lotes Seleccionados:</label>
            <DataTable columns={columns} data={lotesSeleccionados} responsive />
          </div>
          <div className="row mt-4">
            <label className='mr-3'>Tipo de cambio de Mercado:</label>
            <p>{formatterAr.format(valorDolarMercado)}</p>
          </div>
          <div className="row mt-2">
            <label className='mr-3'>Tipo de cambio Propuesto:</label>
            <NumberFormat
              className={`col-md-4`}
              value={valorDolarPropuesto}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={'$ '}
              onValueChange={(e) => setValorDolarPropuesto(e.floatValue)}
            />
          </div>
          <div className="row mt-2">
            <label className='mr-3'>Total de lotes seleccionados: </label>
            <p>{formatterAr.format(lotesSeleccionados.reduce((total, curr) => total + curr.valores.pesosPropuesto, 0))}</p>
          </div>
        </div>
      }
      <div className="mt-5">
        <div className="row d-flex justify-content-around card-footer">

          <div className="">
            <button className="btn btn-secondary" onClick={goBack}>Anterior</button>
          </div>

          <div className="">
            <button className="btn btn-primary" onClick={saveSelectedLotes}>Siguiente</button>
          </div>

        </div>
      </div>
    </>
  )

}

export default withRouter(SelectSoldLotes);