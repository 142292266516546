import {GetPersonaByCuil} from "../../utils/getPersonaByCuil";
import {useState} from "react";
import Spinner from "../../layout/Spinner";
import NumberFormat from "react-number-format";

const FormularioEscribania = ({actualizarState, escribania, escribaniaNueva, actualizarStateV2}) => {
  const checkActivo = e => {
    e.target.value = e.target.value == 'on' ? true : false;
    actualizarState(e);
  }

  const [spinner, setSpinner] = useState(false);

  const setterFunction = (res) => {

    const {razonSocial, domicilio, nombre} = res;
    const nombreEscribania = razonSocial ? razonSocial : `${nombre.apellido}, ${nombre.nombre}`;
    let direccion = `${domicilio.calle}`;
    if(domicilio.altura) {
      direccion += ` ${domicilio.altura}`;
    }
    if(domicilio.pisoDepto) {
      direccion += ` ${domicilio.pisoDepto}`;
    }
    if(domicilio.ciudad) {
      direccion += `, ${domicilio.ciudad}`;
    }
    if(domicilio.provincia) {
      direccion += `, ${domicilio.provincia}`;
    }
    if(domicilio.cp) {
      direccion += `, CP: ${domicilio.cp}`;
    }

    const state = {
      nombre: nombreEscribania,
      domicilio: direccion,
    }

    actualizarStateV2(state);
  }

  const clearFunction = () => {
    let state = {};
    Object.keys(escribania).forEach( key => {
      state = {
        ...state,
        [key]: null,
      }
    });
    actualizarStateV2(state);
  }

  return (
    <>
      <div className="row">
        <div className="col-md-6 form-group">
          <GetPersonaByCuil
            setterFunction={setterFunction}
            clearFunction={clearFunction}
            showSpinner={setSpinner}
          />
        </div>
      </div>
      {spinner &&
        <Spinner/>
      }
      {escribania?.nombre &&
        <>
          <div className="row">
            <div className="col-md-6 form-group">
              <label>Nombre</label>
              <input
                type="text"
                className="form-control"
                value={escribania?.nombre}
                placeholder="Nombre"
                name="nombre"
                onChange={actualizarState}
              />
            </div>
            <div className="col-md-6 form-group">
              <label>Dirección</label>
              <input
                type="text"
                className="form-control"
                value={escribania?.domicilio}
                placeholder="Dirección"
                name="domicilio"
                onChange={actualizarState}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 form-group">
              <label>Teléfono</label>
              <NumberFormat
                className="form-control"
                name="telefono"
                format="##########"
                placeholder="__________"
                mask="_"
                onChange={actualizarState}
                value={escribania?.telefono}
                required
              />
            </div>
            <div className="col-md-6 form-group">
              <label>Email (para envío de documentación)</label>
              <input
                type="tel"
                className="form-control"
                value={escribania?.email}
                placeholder="Email"
                name="email"
                onChange={actualizarState}
              />
            </div>
            <div className="text-center col-md-2 form-group">
              <label>Activo</label>
              <input
                type="checkbox"
                className="form-control"
                name="activo"
                checked={escribania?.activo}
                onChange={checkActivo}
                disabled={escribaniaNueva}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

export default FormularioEscribania;
