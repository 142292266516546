// Cambiar Usuario API a UsuarioPlano
exports.usuarioAPI2UsuarioPlano = (usuario) =>{
    const usuarioPlano = {
      nombre: usuario.nombre,
      apellido: usuario.apellido,
      email: usuario.email,
      dni: usuario.documentos.dni,
      tipoDni: usuario.documentos.tipoDni,
      cuitCuil: usuario.documentos.cuitCuil,
      provincia: usuario.domicilio.provincia,
      municipio: usuario.domicilio.municipio,
      calle: usuario.domicilio.calle,
      altura: usuario.domicilio.altura,
      pisoDepto: usuario.domicilio.pisoDepto,
      movil: usuario.telefono?.movil,
      fijo: usuario.telefono?.fijo,
      fechaNacimiento: usuario.fechaNacimiento,
      rol: usuario.rol,
      inmobiliaria: usuario.inmobiliaria,
      escribania: usuario.escribania,
      activo: usuario.activo
    }

    return usuarioPlano;

  }

  // Cambiar Usuario Plano a Usuario Formato API
  exports.usuarioPlano2usuarioAPI = (usuario) => {
    const usuarioAPI = {
      nombre: usuario.nombre,
      apellido: usuario.apellido,
      email: usuario.email,
      documentos:{
        dni: usuario.dni,
        tipoDni: usuario.tipoDni,
        cuitCuil: usuario.cuitCuil
      },
      domicilio:{
        provincia: usuario.provincia,
        municipio: usuario.municipio,
        calle: usuario.calle,
        altura: usuario.altura,
        pisoDepto: usuario.pisoDepto,
      },
      telefono: {
        movil: usuario?.movil,
        fijo: usuario?.fijo,
      },
      fechaNacimiento: usuario.fechaNacimiento,
      password: usuario.password,
      rol: usuario.rol,
      inmobiliaria: usuario.inmobiliaria,
      escribania: usuario.escribania,
      activo: usuario.activo,
    }

    return usuarioAPI;
  }

