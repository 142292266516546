import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import clienteAxios from "../../../../../../config/axios";
import { CRMContext } from "../../../../../../context/CRMContext";
import NumberFormat from "react-number-format";
import ExternalPropertyUtils from "../utils/ExternalPropertyUtils";
import GetDolarMercado from "../../../../../utils/GetDolarMercado";

const ExternalProperty = ({ operacion, onClose, setOperacion, goBack, ...props }) => {

  const [inmueble, setInmueble] = useState(operacion.formaDePago.anticipo.inmueble || {
    monedaPrecioSugerido: 'usd',
  });
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [provincias, setProvincias] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [valorDolarMercado, setValorDolarMercado] = useState(0);
  const [valorDolarPropuesto, setValorDolarPropuesto] = useState(0);
  const [valorPropuesto, setValorPropuesto] = useState(0);


  useEffect(async () => {
    consultarAPIProvincias();
    const dolarApi = await GetDolarMercado.getDolar();
    const valorDolarMercado = dolarApi.data.compra;
    setValorDolarMercado(valorDolarMercado);
    setValorDolarPropuesto(valorDolarMercado);
  }, []);

  useEffect(() => {
    consultarAPILocalidades(inmueble.provincia);
  }, [inmueble.provincia]);


  const consultarAPIProvincias = async () => {
    try {
      const response = await clienteAxios.get("/provinciasLocalidades/provincias", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const orderedStates = response.data.sort((a, b) => {
        return a.nombreProvincia > b.nombreProvincia ? 1 : -1
      });

      setProvincias(orderedStates.map(state => ({
        label: state.nombreProvincia,
        value: state.idProvincia,
      })))

    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const consultarAPILocalidades = async (provincia) => {
    if (!provincia) {
      return;
    }
    try {
      const response = await clienteAxios.get(`/provinciasLocalidades/localidades/${provincia}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        const orderedCities = response.data.sort((a, b) => {
          return a.nombreLocalidad > b.nombreLocalidad ? 1 : -1
        });

        setLocalidades(orderedCities.map(city => ({
          label: city.nombreLocalidad,
          value: city._id,
        })))
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const actualizarState = (e) => {
    const { name, value } = e.target;
    setInmueble({
      ...inmueble,
      [name]: value,
    });
  }

  const actualizarStateSelect = (name, value) => {
    actualizarState({
      target: {
        name,
        value,
      }
    });
  }

  const saveExternalProperty = () => {
    onClose();
  }

  useEffect(() => {
    const valores = {
      fechaPropuesto: Date.now(),
      cambioPropuesto: valorDolarPropuesto,
      pesosPropuesto: valorPropuesto * valorDolarPropuesto,
      dolaresPropuesto: valorPropuesto,

      fechaMercado: Date.now(),
      pesosMercado: valorPropuesto * valorDolarMercado / valorDolarPropuesto * valorDolarPropuesto,
      dolaresMercado: valorPropuesto,
      cambioMercado: valorDolarMercado,
    }
    const setInmueble = {
      ...inmueble,
      valores,
    }
    setOperacion({
      ...operacion,
      formaDePago: {
        ...operacion.formaDePago,
        anticipo: {
          ...operacion.formaDePago.anticipo,
          inmueble: setInmueble,
        }
      }
    });
  }, [inmueble, valorPropuesto, valorDolarPropuesto]);

  return (
    <>
      <div className="row">
        <label className="col-md-12">Ubicación</label>
        <div className='col-md-4 form-group'>
          <label>Provincia</label>
          <Select
            options={provincias}
            value={ExternalPropertyUtils.getSelectedOptionExternal(provincias, inmueble.provincia)}
            name='provincia'
            onChange={(e) => actualizarStateSelect('provincia', e.value)}
          />
        </div>
        <div className='col-md-4 form-group'>
          <label>Localidad</label>
          <Select
            options={localidades}
            value={ExternalPropertyUtils.getSelectedOptionExternal(localidades, inmueble.localidad)}
            name='localidad'
            onChange={(e) => actualizarStateSelect('localidad', e.value)}
          />
        </div>
        <div className="col-md-12 form-group">
          <label>Dirección</label>
          <input
            type="text"
            className="form-control"
            placeholder="Dirección"
            name="direccion"
            value={inmueble?.direccion || ''}
            onChange={actualizarState}
          />
        </div>
      </div>

      <div className="row">
        <label className="col-md-12">Características</label>
        <div className='col-md-3 form-group'>
          <label>Tipo de Propiedad</label>
          <Select
            options={ExternalPropertyUtils.getOptionsList('types')}
            value={ExternalPropertyUtils.getSelectedOption('types', inmueble?.type || '')}
            name='type'
            onChange={(e) => actualizarStateSelect('type', e.value)}
          />
        </div>

        <div className='col-md-3 form-group'>
          <label>Ambientes</label>
          <Select
            options={ExternalPropertyUtils.getOptionsList('ambientes')}
            value={ExternalPropertyUtils.getSelectedOption('ambientes', inmueble?.ambientes)}
            name='ambientes'
            onChange={(e) => actualizarStateSelect('ambientes', e.value)}
          />
        </div>

        <div className='col-md-3 form-group'>
          <label>Dormitorios</label>
          <Select
            options={ExternalPropertyUtils.getOptionsList('dormitorios')}
            value={ExternalPropertyUtils.getSelectedOption('dormitorios', inmueble?.dormitorios)}
            name='dormitorios'
            onChange={(e) => actualizarStateSelect('dormitorios', e.value)}
          />
        </div>

        <div className='col-md-3 form-group'>
          <label>Baños</label>
          <Select
            options={ExternalPropertyUtils.getOptionsList('banos')}
            value={ExternalPropertyUtils.getSelectedOption('banos', inmueble?.banos)}
            name='banos'
            onChange={(e) => actualizarStateSelect('banos', e.value)}
          />
        </div>

        <div className='col-md-3 form-group'>
          <label>Garage</label>
          <Select
            options={ExternalPropertyUtils.getOptionsList('garage')}
            value={ExternalPropertyUtils.getSelectedOption('garage', inmueble?.garage)}
            name='garage'
            onChange={(e) => actualizarStateSelect('garage', e.value)}
          />
        </div>

      </div>

      <div className="row">
        <label className="col-md-12">Superficies</label>

        <div className="col-md-4 form-group">
          <label>Superficie Total en m2</label>
          <input
            type="text"
            className="form-control"
            placeholder="Superficie Total"
            name="superficieTotal"
            value={inmueble?.superficieTotal || ''}
            onChange={actualizarState}
          />
        </div>

        <div className="col-md-4 form-group">
          <label>Superficie Cubierta en m2</label>
          <input
            type="text"
            className="form-control"
            placeholder="Superficie Cubierta"
            name="superficieCubierta"
            value={inmueble?.superficieCubierta || ''}
            onChange={actualizarState}
          />
        </div>

        <div className="col-md-4 form-group">
          <label>Superficie Terreno en m2</label>
          <input
            type="text"
            className="form-control"
            placeholder="Superficie Terreno"
            name="superficieTerreno"
            value={inmueble?.superficieTerreno || ''}
            onChange={actualizarState}
          />
        </div>

      </div>


      <div className="row">
        <label className="col-md-12">Otros</label>

        <div className='col-md-4 form-group'>
          <label>Año de Construcción</label>
          <Select
            options={ExternalPropertyUtils.getOptionsList('anoConstruccion')}
            value={ExternalPropertyUtils.getSelectedOption('anoConstruccion', inmueble?.anoConstruccion)}
            name='anoConstruccion'
            onChange={(e) => actualizarStateSelect('anoConstruccion', e.value)}
          />
        </div>

        <div className='col-md-4 form-group'>
          <label>Moneda Expensas</label>
          <Select
            options={ExternalPropertyUtils['tiposMoneda']}
            value={inmueble && inmueble?.monedaExpensas ? ExternalPropertyUtils.getTipoMonedaByValue(inmueble?.monedaExpensas) : {}}
            name='monedaExpensas'
            onChange={(e) => actualizarStateSelect('monedaExpensas', e.value)}
          />
        </div>

        <div className="col-md-4 form-group">
          <label>Expensas</label>
          <NumberFormat
            className="form-control"
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            value={inmueble?.expensas || ''}
            onValueChange={(e) => {
              let enviar = {
                target: {
                  value: e.floatValue,
                  name: "expensas",
                },
              };
              actualizarState(enviar);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className='col-md-6 form-group'>
          <label>Condición</label>
          <Select
            options={ExternalPropertyUtils.getOptionsList('condicion')}
            value={ExternalPropertyUtils.getSelectedOption('condicion', inmueble?.condicion)}
            name='condicion'
            onChange={(e) => actualizarStateSelect('condicion', e.value)}
          />
        </div>


      </div>

      <div className="row">
        <label className="col-md-12">Otras Características</label>

        <div className='col-md-12 form-group'>
          <label>Otros</label>
          <Select
            options={ExternalPropertyUtils.getOptionsList('otros')}
            value={ExternalPropertyUtils.getMultiSelectedOptions('otros', inmueble?.otros || [])}
            name='otros'
            onChange={(elements) => actualizarStateSelect('otros', [...elements.map(ele => (ele.value))])}
            isMulti={true}
          />
        </div>


      </div>


      <div className="row">
        <label className="col-md-12">Precio Sugerido:</label>

        <div className='col-md-2 form-group'>
          <label>Moneda</label>
          <Select
            options={ExternalPropertyUtils['tiposMoneda']}
            name='monedaPrecioSugerido'
            value={{
              value: 'usd',
              label: 'u$s'
            }}
            // value={inmueble && inmueble?.monedaPrecioSugerido ? ExternalPropertyUtils.getTipoMonedaByValue(inmueble?.monedaPrecioSugerido) : {}}
            onChange={(e) => actualizarStateSelect('monedaPrecioSugerido', e.value)}
          />
        </div>

        <>
          <div className="col-md-3 form-group">
            <label className='mr-3'>Cambio de Mercado:</label>
            <NumberFormat
              className="form-control"
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              value={valorDolarMercado}
              disabled
            />
          </div>

          <div className="col-md-3 form-group">
            <label>Tipo de Cambio</label>
            <NumberFormat
              className="form-control"
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              value={valorDolarPropuesto}
              onValueChange={(e) => setValorDolarPropuesto(e.floatValue)}
            />
          </div>
        </>

        <div className="col-md-4 form-group">
          <label>Precio Sugerido</label>
          <NumberFormat
            className="form-control"
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"u$s"}
            value={inmueble.valores?.dolaresPropuesto || valorPropuesto}
            onValueChange={(e) => {
              setValorPropuesto(e.floatValue)
            }}
          />
        </div>
      </div>

      <div className="mt-5">
        <div className="row d-flex justify-content-around card-footer">

          <div className="">
            <button className="btn btn-secondary" onClick={goBack}>Anterior</button>
          </div>

          <div className="">
            <button className="btn btn-primary" onClick={saveExternalProperty}>Siguiente</button>
          </div>

        </div>
      </div>
    </>
  );
}

export default ExternalProperty;