import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import moment from "moment";

const Parametro = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  const [loading, setLoading] = useState(true);
  const [parametro, setParametro] = useState({});
  const [parametroOriginal, setParametroOriginal] = useState({});
  let token = auth.token || localStorage.getItem("token");

  const consultarApi = async (id) => {
    try {
      const response = await clienteAxios.get(`/parametros/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setParametro(response.data);
      setParametroOriginal(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  const actualizarParametro = async () => {
    try {
      const { id } = props.match.params;
      const updateParametro = {
        nombre: parametro.nombre,
        valor: parametro.valor
      }
      setLoading(true);
      await clienteAxios.put(`/parametros/${id}`, updateParametro, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTimeout(() => {
        props.history.push("/configuracion/parametros");
      }, 2500);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  const checkUpdatedValue = () => {
    return parametro.valor !== parametroOriginal.valor;
  }

  useEffect(() => {
    const { id } = props.match.params;
    if (id) {
      consultarApi(id);
    }
  }, []);

  const actualizarState = (e) => {
    setParametro({
      ...parametro,
      [e.target.name]: e.target.value,
    });
  };


  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title mt-2">PARÁMETRO</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className="card-body">
        {loading && <Spinner />}
        {!loading && <>
          <div className="row">
            <div className="col-md-3">
              <label>Nombre:</label>
              <input
                type="text"
                className="form-control"
                value={parametro?.nombre}
                placeholder="Nombre"
                name="nombre"
                disabled
              />
            </div>
            <div className="col-md-3">
              <label>Última actualización:</label>
              <input
                type="text"
                className="form-control"
                value={moment(parametro?.createdAt)
                  .format("DD-MM-YYYY HH:mm")}
                placeholder="Fecha"
                name="createdAt"
                disabled
              />
            </div>
            <div className="col-md-3">
              <label>Valor:</label>
              <input
                type="text"
                className="form-control"
                value={parametro?.valor}
                placeholder="Valor"
                name="valor"
                onChange={actualizarState}
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              <button
                type="submit"
                className="btn btn-primary float-right"
                disabled={!checkUpdatedValue()}
                onClick={actualizarParametro}
              >
                Guardar
              </button>
            </div>
          </div>
        </>}
      </div>
    </div>
  )

}

export default withRouter(Parametro);