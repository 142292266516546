import React, { useContext } from 'react';
import { CRMContext } from '../../context/CRMContext';

const Footer = (props) => {

    const [auth, guardarAuth] = useContext( CRMContext );
    let token = auth.token || localStorage.getItem('token');

    if(!token){
        return null;
    }

    return ( 
            <div>
                <footer className="main-footer">
                    <strong>Life Desarrollos. </strong>
                    All rights reserved.
                    <div className="float-right d-none d-sm-inline-block">
                        <b>Version</b> 1.0.0
                    </div>
                </footer>

            </div>
     );
}
 
export default Footer;

