import React, { useContext, useEffect, useState } from 'react';
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';
import { useForm } from 'react-hook-form';
import Spinner from '../layout/Spinner';
import ShowPersonFromCUIX from '../clientes/components/ShowPersonFromCUIX';
import { get } from '../../config/apiHelper';
import Swal from 'sweetalert2';

export const FormularioEditarInversionista = (props) => {
  const { history } = props;
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [inversionista, setInversionista] = useState({});
  const [taxId, setTaxId] = useState(''); // State for Identificación Tributaria
  const { register, handleSubmit, reset } = useForm();

  const getInversionista = async () => {
    try {
      const inversionistaDb = await get(`/api/inversionistas/${props.match.params.id}`);
      setInversionista(inversionistaDb.data);
      setTaxId(inversionistaDb.data.identificacionTributaria); // Initialize taxId state
      reset({
        nombreCorto: inversionistaDb.data.nombreCorto,
        identificacionTributaria: inversionistaDb.data.identificacionTributaria,
      });
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  const submit = async (data) => {
    try {
      const response = await clienteAxios.put(`/api/inversionistas/${props.match.params.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === 'ok') {
        history.push('/configuracion/inversionistas/listado');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTaxIdChange = (e) => {
    setTaxId(e.target.value); // Update state on change
  };

  const deleteInversionista = async () => {
    Swal.fire({
      title: 'Desea eliminar el inversionista?',
      text: "Esta acción no se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'Deshacer',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await clienteAxios.delete(`/api/inversionistas/${props.match.params.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 204) {
            history.push('/configuracion/inversionistas/listado');
          }
        } catch (error) {
          console.log(error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Hubo un error, intente nuevamente',
          });
        }
      }
    });
  };

  useEffect(() => {
    getInversionista();
  }, []);

  if (!Object.keys(inversionista).length) {
    return <Spinner />;
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title">Editar Inversionista</h3>
          </div>
          <div className="col-md-4">
            <button className="btn btn-primary float-right" onClick={history.goBack}>Volver</button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <button onClick={deleteInversionista} className="btn btn-danger float-right mt-3">Eliminar</button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label>Nombre Corto</label>
                <input
                  type="text"
                  name="nombreCorto"
                  className="form-control"
                  {...register("nombreCorto")}
                />
              </div>
              <div className="form-group">
                <label>Identificación Tributaria</label>
                <input
                  type="text"
                  name="identificacionTributaria"
                  className="form-control"
                  {...register("identificacionTributaria")}
                  onChange={handleTaxIdChange} // Update taxId state on change
                />
              </div>
              <button type="submit" className="btn btn-primary float-right mt-3">Guardar</button>
            </form>
          </div>
        </div>
        {/* Display ShowPersonFromCUIX if identificacionTributaria exists */}
        {taxId && (
          <div className="row">
            <div className="col">
              <ShowPersonFromCUIX taxId={taxId} />
            </div>
          </div>
        )}
      </div>
      <div className="card-footer"></div>
    </div>
  );
};
