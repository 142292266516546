import React, { useState } from 'react'
import moment from "moment/moment"

const moneyArFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const moneyUsFormatter = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "symbol",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

function EntregasDetalle(props) {
  const { detalleEntregas, totalLotesPropuesto } = props;

  return <>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>

    <tr>
      <th></th>
      <th>Subtipo</th>
      <th>Propuesto</th>
      <th>Negociado</th>
      <th>Incidencia(negociado)</th>
    </tr>

    {detalleEntregas.map(detalle => {
      return (
        <tr>
          <td></td>
          <td>{detalle.subtipo}</td>
          <td>{moneyUsFormatter.format(detalle.dolaresPropuesto)}</td>
          <td>{moneyUsFormatter.format(detalle.dolaresNegociado || null)}</td>
          <td>{((detalle.dolaresNegociado / totalLotesPropuesto * 100) || 0).toFixed(2)} %</td>
        </tr>
      )
    })}

    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>

  </>
}

export default function ResumenOperacion(props) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  const [showDetail, setshowDetail] = useState({});
  const { resumen } = props;
  const { lotes, entregas, financiaciones } = resumen;

  // Lotes
  const totalLotesLista = lotes.reduce((prev, curr) => prev += curr.dolaresMercado, 0);
  const totalLotesPropuesto = lotes.reduce((prev, curr) => prev += curr.dolaresPropuesto, 0);
  const porcentajeDescuento = ((1 - totalLotesPropuesto / totalLotesLista) * 100).toFixed(0);

  // Entregas
  const totalEntregasPropuesto = entregas.reduce((prev, curr) => prev += curr.dolaresPropuesto || 0, 0);
  const totalEntregasNegociado = entregas.reduce((prev, curr) => prev += curr.dolaresNegociado || 0, 0)

  const entregasProcesado = {};
  const subEntregas = {};


  entregas.forEach(entrega => {
    if (!entregasProcesado[entrega.tipo]) {
      entregasProcesado[entrega.tipo] = {
        dolaresPropuesto: 0,
        dolaresNegociado: 0,
      }
    }

    entregasProcesado[entrega.tipo].dolaresPropuesto += entrega.dolaresPropuesto;
    entregasProcesado[entrega.tipo].dolaresNegociado += entrega.dolaresNegociado;
  });


  const setterShowDetails = {};
  Object.keys(entregasProcesado).forEach(tipoEntrega => {
    setterShowDetails[tipoEntrega] = false;
    subEntregas[tipoEntrega] = entregas.filter(entrega => (entrega.tipo === tipoEntrega));
  });


  const handleShowDetail = (key) => {
    setshowDetail({
      ...showDetail,
      [key]: showDetail[key] ? !showDetail[key] : true,
    });
  }


  return (
    <>
      <div className="card-header row">
        <div className="col-md-12">
          <h3 className="card-title">
            <strong>Resumen de la operacion:</strong>
          </h3>
          <button
            className="btn btn-primary float-right"
            type="button"
            onClick={handleToggle}
          >
            {isCollapsed ? 'Ver' : 'Ocultar'}
          </button>
        </div>
      </div>
      <div className="card-body ">
        <div className={`collapse${isCollapsed ? '' : ' show'}`}>

          <table className='table mt-4 mb-4'>
            <thead>
              <tr>
                <th>
                  Lotes
                </th>
              </tr>
              <tr>
                <th>Total Lista</th>
                <th>Total Venta</th>
                <th>% de descuento</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{moneyUsFormatter.format(totalLotesLista)}</td>
                <td>{moneyUsFormatter.format(totalLotesPropuesto)}</td>
                <td>{porcentajeDescuento} %</td>
              </tr>
            </tbody>
          </table>


          <table className='table mt-4 mb-4'>
            <thead>
              <tr>
                <th>
                  Entregas
                </th>
              </tr>
              <tr>
                <th style={{width: '20%'}}>Tipo</th>
                <th style={{width: '20%'}}>Total Propuesto</th>
                <th style={{width: '20%'}}>Total Negociado</th>
                <th style={{width: '20%'}}>Incidencia(negociado)</th>
                <th style={{width: '20%'}}>Ver detalle</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(entregasProcesado).map(entrega =>
                <>
                  <tr>
                    <td>{entrega}</td>
                    <td>{moneyUsFormatter.format(entregasProcesado[entrega].dolaresPropuesto)}</td>
                    <td>{moneyUsFormatter.format(entregasProcesado[entrega].dolaresNegociado || null)}</td>
                    <td>{((entregasProcesado[entrega].dolaresNegociado / totalLotesPropuesto * 100) || 0).toFixed(2)} %</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => handleShowDetail(entrega)}
                      >
                        {showDetail[entrega] ? 'Ocultar' : 'Ver'}
                      </button>
                    </td>
                  </tr>
                  {showDetail[entrega] ?
                    <EntregasDetalle detalleEntregas={subEntregas[entrega]} totalLotesPropuesto={totalLotesPropuesto} />
                    : null}
                </>
              )}
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>{moneyUsFormatter.format(totalEntregasPropuesto)}</th>
                <th>{moneyUsFormatter.format(totalEntregasNegociado || null)}</th>
                <th>{((totalEntregasNegociado / totalLotesPropuesto * 100) || 0).toFixed(2)} %</th>
                <th></th>
              </tr>
            </tfoot>
          </table>


          <table className='table mt-4 mb-4'>
            <thead>
              <tr>
                <th>
                  Financiación
                </th>
              </tr>
              <tr>
                <th>Moneda</th>
                <th>Fecha del Primer Pago</th>
                <th>Base índice CAC</th>
                <th>Periodicidad</th>
                <th>Cantidad de Cuotas</th>
                <th>Valor de la Cuota</th>
              </tr>
            </thead>
            <tbody>
              {financiaciones.map(financiacion =>
                <tr>
                  <td>{financiacion.monedaSeleccionada}</td>
                  <td>{moment(financiacion.fechaPagoPrimerCuota).utcOffset(180).format("DD/MM/YYYY")}</td>
                  <td>{financiacion.monedaSeleccionada === 'ars' ? `${moment(financiacion.fechaIndiceCac).utcOffset(180).format("MM/YYYY")}` : '-'}</td>
                  <td>{financiacion.periodicidad}</td>
                  <td>{financiacion.cantidadCuotas}</td>
                  <td>{financiacion.monedaSeleccionada === 'ars' ? moneyArFormatter.format(financiacion.valorCuotaNegociado || null) : moneyUsFormatter.format(financiacion.valorCuotaNegociadoDolares || null)}</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              {!!totalEntregasNegociado && 
                <tr>
                  <th></th>
                  <th></th>
                  <th>Saldo a Financiar</th>
                  <th>
                    {moneyUsFormatter.format(totalLotesPropuesto - totalEntregasNegociado)}
                  </th>
                  <th>Porcentaje a Financiar</th>
                  <th>
                    {((totalLotesPropuesto - totalEntregasNegociado) / totalLotesPropuesto * 100).toFixed(2)} %
                  </th>
              </tr>
              }
            </tfoot>
          </table>


        </div>
      </div>
    </>
  )
}
