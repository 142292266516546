import {React, useState} from "react";
import {GetPersonaByCuil} from "../../utils/getPersonaByCuil";
import Spinner from "../../layout/Spinner";

const DatosConyuge = ({cliente, actualizarState, disabled = false}) => {
  const [showSpinner, setShowSpinner] = useState(false)

  const clearFunction = () => {

  }


  const { conyugeOnlyName } = cliente?.estadoCivil || false;

  const setNombreConyuge = (nombre, valor) => {
    actualizarState({[nombre]: valor})
  }

  const setDatosConyuge = (persona) => {
    const state = {
      cuilConyuge: persona.documentos.cuitCuil,
      apellidoConyuge: persona.nombre.apellido,
      nombreConyuge: persona.nombre.nombre,
    }
    actualizarState(state);
  }

  if (cliente.estadoCivil === undefined || !cliente.estadoCivil.conyuge) {
    return (
      <>
      </>
    )
  } else {
    return (
      <>
        <div className="row">
          <div className="col-md-6 form-group">
            <GetPersonaByCuil
              setterFunction={setDatosConyuge}
              clearFunction={clearFunction}
              showSpinner={setShowSpinner}
              label={'CUIL del Conyuge'}
              disabled={disabled || conyugeOnlyName}
            />
          </div>
        </div>
        {showSpinner &&
          <Spinner/>
        }
        {!showSpinner &&
          <>
            <p>{cliente.estadoCivil.conyuge}</p>
            <div className="form-group">
              <label>Datos del Cónyuge</label>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre"
                  name="nombreConyuge"
                  onChange={(e) => setNombreConyuge(e.target.name, e.target.value)}
                  value={cliente?.nombreConyuge}
                  disabled={true && !conyugeOnlyName}
                />
              </div>

              <div className="col-md-6 form-group">
                <label>Apellido</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Apellido"
                  name="apellidoConyuge"
                  onChange={(e) => setNombreConyuge(e.target.name, e.target.value)}
                  value={cliente?.apellidoConyuge}
                  disabled={true && !conyugeOnlyName}
                />
              </div>

              {/*<div className="col-md-6 form-group">*/}
              {/*  <label>Apellido Materno</label>*/}
              {/*  <input*/}
              {/*    type="text"*/}
              {/*    className="form-control"*/}
              {/*    placeholder="Apellido Materno"*/}
              {/*    name="apellidoMaternoConyuge"*/}
              {/*    // onChange={actualizarState}*/}
              {/*    value={cliente?.apellidoMaternoConyuge}*/}
              {/*  />*/}
              {/*</div>*/}

            </div>
          </>
        }
      </>
    );
  }
};

export default DatosConyuge;
