import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2';
import { animateScroll as scroll } from 'react-scroll';

const WizardFormaPago = ({ operacion, setOperacion, items, isActiveTab, parametros, bloqueoId, ...props }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [showPopUp, setShowPopUp] = useState(true);
  const [showComponent, setShowComponent] = useState(false);
  const [carFormOptions, setCarFormOptions] = useState({
    marcas: [],
    modelos: [],
    versiones: [],
    anos: [],
  });
  const carOptions = { carFormOptions, setCarFormOptions };
  const [selectedOptions, setSelectedOptions] = useState({});
  const carSelectedOptions = { selectedOptions, setSelectedOptions };
  const handleYesClick = () => {
    scroll.scrollToTop();
    setShowComponent(true);
    setShowPopUp(false);
  };

  const handleNoClick = () => {
    scroll.scrollToTop();
    setCurrentItemIndex(currentItemIndex + 1);
  };

  const handleComponentClose = () => {
    if (currentItemIndex < items.length)
      setCurrentItemIndex(currentItemIndex + 1);
    setShowPopUp(true);
    setShowComponent(false);
  };

  const goBack = () => {
    if (currentItemIndex >= 1) {
      scroll.scrollToTop();
      setCurrentItemIndex(currentItemIndex - 1);
    }
  }

  useEffect(() => {
    if (showPopUp && isActiveTab && currentItemIndex <= items.length - 1) {
      Swal.fire({
        title: currentItem.title + '?',
        text: "En caso afirmativo deberá completar los datos",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          handleYesClick()
        } else {
          handleNoClick()
        }
      })
    }
  }, [showPopUp, isActiveTab, currentItemIndex]);

  const currentItem = items[currentItemIndex];
  const changeTab = (index) => {
    scroll.scrollToTop();
    setShowComponent(true);
    setShowPopUp(false);
    setCurrentItemIndex(index);
  }


  return (
    <>
      <div className="card-header">
        <div className="row mb-1">
          <h3 className="card-title m-2">Forma de Pago</h3>
        </div>
        <div className="row mb-1">
          {
            items.map((item, index) => {
              return (
                <button key={index} className={currentItemIndex === index ? 'btn btn-primary m-1 active small-text-buttons' : 'btn btn-primary m-1 small-text-buttons'} onClick={() => changeTab(index)}>{item.button}</button>
              )
            })
          }
        </div>
      </div>
      <div className="card-body">
        <div>
          {currentItem && currentItem.component && showComponent && (
            <>
              <currentItem.component
                parametros={parametros}
                onClose={handleComponentClose}
                operacion={operacion}
                setOperacion={setOperacion}
                carOptions={carOptions}
                carSelectedOptions={carSelectedOptions}
                goBack={goBack}
                bloqueoId={bloqueoId}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(WizardFormaPago);