import React from 'react';

const DetallePanelLateral = ({ operacion }) => {

  const formatMoney = new Intl.NumberFormat("es-AR", {
    currency: "ARS",
    style: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formatPercentage = new Intl.NumberFormat("es-AR", {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const formatUsMoney = new Intl.NumberFormat("es-AR", {
    currency: "USD",
    style: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const getAnticipoEntregas = () => {
    const { entregas } = operacion.formaDePago;
    let totalEntrega = 0;
    if (entregas && Array.isArray(entregas)) {
      entregas.forEach(entrega => {
        totalEntrega += entrega.referencia.valores.pesosPropuesto;
      });
    }
    return totalEntrega;
  }

  const getAnticipoEfectivo = () => {
    const { anticipo, entregas } = operacion.formaDePago;
    let totalAnticipos = 0;
    if (anticipo && Array.isArray(anticipo.efectivo)) {
      anticipo.efectivo.forEach(efectivo => {
        if (efectivo.moneda === 'ars') {
          totalAnticipos += efectivo.monto;
        } else if (efectivo.moneda === 'usd') {
          totalAnticipos += efectivo.monto * efectivo.cambioSugerido;
        }
      });
    }
    if (entregas && Array.isArray(entregas)) {
      entregas.forEach(entrega => {
        totalAnticipos += entrega.referencia.valores.pesosPropuesto;
      });
    }
    return totalAnticipos;
  }
  const getAnticipoDocumentos = () => {
    const { anticipo } = operacion.formaDePago;
    let totalAnticipos = 0;
    anticipo.documentos.forEach(documentos => {
      if (documentos.moneda === 'ars') {
        totalAnticipos += documentos.monto;
      } else if (documentos.moneda === 'usd') {
        totalAnticipos += documentos.monto * documentos.cambioSugerido;
      }
    });
    return totalAnticipos;
  }
  const getAnticipoInmuebles = () => {
    const { anticipo } = operacion.formaDePago;
    let totalAnticipos = 0;
    totalAnticipos += anticipo.inmueble.valores.pesosPropuesto || 0;
    return totalAnticipos;
  }
  const getAnticipoLotes = () => {
    const { anticipo } = operacion.formaDePago;
    let totalAnticipos = 0;
    anticipo.lotes.forEach(lote => {
      totalAnticipos += lote.valores.pesosPropuesto;
    });
    return totalAnticipos;
  }
  const getAnticipoVehiculos = () => {
    const { anticipo } = operacion.formaDePago;
    return anticipo.vehiculo.vehiculo.valorSugerido;
  }


  return (
    <>
      {operacion.lote.length > 0 &&
        <>
          <div className="row">
            <div className="col">
              <label>Precio de lista:</label>
              <table className='table'>
                <thead>
                  <tr>
                    <td>Pesos</td>
                    <td>Dólares</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formatMoney.format(operacion.precioListaOperacion)}</td>
                    <td>{formatUsMoney.format(operacion.precioListaDolarOperacion)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>Total de la compra: (Dto.: {Intl.NumberFormat("es-AR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(operacion.porcentajeDescuentoPrecioLista)} %)</label>
              <table className='table'>
                <thead>
                  <tr>
                    <td>Pesos</td>
                    <td>Dólares</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formatMoney.format(operacion.precioVentaOperacion)}</td>
                    <td>{formatUsMoney.format(operacion.precioVentaDolarOperacion)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      }

      {operacion.formaDePago.anticipo.lotes.length > 0 &&
        <div className="row">
          <div className="col">
            <label>Total entrega de Lotes:</label>
            <p>{formatMoney.format(getAnticipoLotes())}</p>
          </div>
        </div>
      }

      {operacion.formaDePago.anticipo.vehiculo &&
        <div className="row">
          <div className="col">
            <label>Valor entrega de Vehículo:</label>
            <p>{formatMoney.format(getAnticipoVehiculos())}</p>
          </div>
        </div>
      }

      {operacion.formaDePago.anticipo.inmueble &&
        <div className="row">
          <div className="col">
            <label>Valor entrega de Inmueble:</label>
            <p>{formatMoney.format(getAnticipoInmuebles())}</p>
          </div>
        </div>
      }

      {(operacion.formaDePago.anticipo.efectivo) &&
        <div className="row">
          <div className="col">
            <label>Total entrega Efectivo:</label>
            <p>{formatMoney.format(getAnticipoEfectivo())}</p>
          </div>
        </div>
      }

      {((Array.isArray(operacion.formaDePago.entregas) || (Array.isArray(operacion.formaDePago.entregas) && operacion.formaDePago.entregas.lenght > 0))) &&
        <div className="row">
          <div className="col">
            <label>Total entrega Canjes:</label>
            <p>{formatMoney.format(getAnticipoEntregas())}</p>
          </div>
        </div>
      }

      {operacion.formaDePago.anticipo.documentos &&
        <div className="row">
          <div className="col">
            <label>Total entrega Documentos:</label>
            <p>{formatMoney.format(getAnticipoDocumentos())}</p>
          </div>
        </div>
      }

      {operacion.precioVentaOperacion > 0 && operacion.formaDePago.anticipo &&
        <div className="row">
          <div className="col">
            <label>Saldo a
              Financiar: {operacion.saldoFinanciar >= 0 ? formatPercentage.format(operacion.saldoFinanciar / operacion.precioVentaOperacion) : 'ERROR'}</label>
            <p>{operacion.saldoFinanciar >= 0 ? formatMoney.format(operacion.saldoFinanciar) : 'Las entregas exceden el valor de venta'} </p>
          </div>
        </div>
      }
    </>
  );
}

export default DetallePanelLateral;