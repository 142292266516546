import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import queryString from 'query-string';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';

import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import LotesFiltros from "./utils/filter";
import ModificarLotes from "./utils/ModificarLotes";
import listadoLotesStore from "../../store/listadoLotesStore";


const BotonEditar = (props) => {
  if (props.nivelRol >= 3) {
    return (
      <Link to={props.url}>
        <button type="button" className="btn btn-block btn-success">
          Editar
        </button>
      </Link>
    )
  } else {
    return (
      <></>
    )
  }
}

function Lotes(props) {
  const { filter, paginator, setPaginator} = listadoLotesStore();
  let { id: barrioId } = useParams();
  if (!barrioId) {
    barrioId = 'all';
  }
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const [data, setData] = useState({ loading: true, data: [] });
  const [seleccionados, setSeleccionados] = useState([]);
  const [display, setDisplay] = useState("d-none");
  const [dataOriginal, setDataOriginal] = useState([]);
  const nivelRol = localStorage.getItem('rolId');
  const { id } = props.match.params;
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0);
  const [nombreDesarrollo, setNombreDesarrollo] = useState('');

  const getPage = () => {
    return (paginator[barrioId]?.page || 1) - 1
  }

  const getLimit = () => {
    return paginator[barrioId]?.limit || 10
  }


  // Consulta a la API para obtener el listado de lotes del barrio seleccionado
  const consultarAPI = async () => {
    setSpinnerLoading(true);
    let lotesConsulta;
    try {
      if (id === undefined) {
        lotesConsulta = await clienteAxios.get(`/lotes?campos=motivoDisponibilidad,barrio,precioLista,numeroManzana,numeroLote,superficie,tipologia,estado,precioLista&page=${getPage()}&limit=${getLimit()}'&sort=numeroLote,1&${processFilters()}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        lotesConsulta = await clienteAxios.get(`/lotes?${processFilters()}`, {
          params: {
            barrio: id,
            campos: "motivoDisponibilidad,barrio,precioLista,numeroManzana,numeroLote,superficie,tipologia,estado,precioLista,isInversor,orientacion",
            page: getPage(),
            limit: getLimit(),
            sort: "numeroLote,1",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      const arrayOriginal = [];
      setTotalRows(lotesConsulta.data.totalCount)
      setNombreDesarrollo(lotesConsulta.data.data[0]?.barrio?.nombre);
      lotesConsulta.data.data.forEach((dat) => {
        let isInversor = dat.isInversor ? "Sí" : "";
        if (dat.isInversor && dat.inversor !== undefined && dat.inversor?.nombreCorto) {
          isInversor = isInversor + ` (${dat.inversor.nombreCorto})`;
        }

        if (dat.barrio !== undefined) {
          arrayOriginal.push({
            orientacion: dat.orientacion,
            barrio: dat.barrio ? dat.barrio.nombre : '',
            numeroLote: dat.numeroLote,
            numeroManzana: dat.numeroManzana,
            superficie: dat.superficie,
            tipologia: dat.tipologia?.tipo || '',
            // estado: dat.estado.estado,
            estado: dat.motivoDisponibilidad?.nombre || dat.estado?.estado,
            isInversor,
            precio: new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "USD",
              currencyDisplay: "symbol",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }).format(dat.precioLista),
            url: `/lotes/editar/${dat._id}`,
            id: dat._id,
          });
        }
      });

      setData({
        loading: false,
        data: arrayOriginal
      });


      setDataOriginal(arrayOriginal);
      setSpinnerLoading(false);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    consultarAPI();
  }, [paginator]);

  // Datatable
  const columns = [
    {
      name: "Lote",
      selector: (row) => row.numeroLote,
      sortable: true,
    },
    {
      name: "Manzana",
      selector: (row) => row.numeroManzana,
      sortable: true,
    },
    {
      name: "Superficie",
      selector: (row) => row.superficie,
      sortable: true,
    },
    {
      name: "Tipología",
      selector: (row) => row.tipologia,
      sortable: true,
    },
    {
      name: "Orientación",
      selector: (row) => row.orientacion,
      sortable: true,
    },
    {
      name: "Precio",
      selector: (row) => row.precio,
      sortable: true,
    },
    {
      name: "Inversor",
      selector: (row) => row.isInversor,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.estado,
      sortable: true,
      conditionalCellStyles: [
        {
          when: row => row.estado?.includes('Disponible'),
          style: {
            backgroundColor: '#15c97f',
            color: 'white',
          },
        },
        {
          when: row => row.estado?.includes('Bloqueado'),
          style: {
            backgroundColor: '#FFA500',
            color: 'white',
          },
        },
        {
          when: row => row.estado?.includes("No Disponible - Inversor") || (!row.estado?.includes('Disponible') && !row.estado?.includes('Bloqueado')),
          style: {
            backgroundColor: 'gray',
            color: 'white',
          },
        },
      ],
    }

  ];

  if(!id) {
    columns.unshift({
      name: "Desarrollo",
      selector: (row) => row.barrio,
      sortable: true,
    })
  }

  if (nivelRol >= 3) {
    columns.push({
      name: "Editar",
      button: true,
      cell: (row) => (
        <BotonEditar
          url={row.url}
          nivelRol={nivelRol}
        />
      ),
    })
  }

  // Buscador
  const submit = (e) => {
    e.preventDefault();
  };

  const buscar = (e) => {
    const filtro = e.target.value.toLowerCase();

    const resultado = dataOriginal.filter((data) => {
      if (data.barrio.toLowerCase().includes(filtro)) {
        return data;
      }
      if (data.numeroLote.toString().includes(filtro)) {
        return data;
      }
      if (data.numeroManzana.toString().includes(filtro)) {
        return data;
      }
      if (data.superficie.toString().includes(filtro)) {
        return data;
      }
      if (data.tipologia.toLowerCase().includes(filtro)) {
        return data;
      }
      if (data.estado.toLowerCase().includes(filtro)) {
        return data;
      }
    });

    setData({
      loading: false,
      data: resultado
    });
  };

  // Configuracion del datatable
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  // Manejador de seleccionados
  const handleChange = (e) => {
    let displayButton = e.selectedRows.length > 0 ? "" : "d-none";
    setDisplay(displayButton);
    setSeleccionados(e.selectedRows);
  };

  const processFilters = () => {
    // Construir los filtros como objeto
    const filtros = {
      numeroManzana: filter[barrioId]?.numeroManzana || '',
      superficieMin: filter[barrioId]?.superficieMin || '',
      superficieMax: filter[barrioId]?.superficieMax || '',
      tipologia: filter[barrioId]?.tipologia || '',
      precioMin: filter[barrioId]?.precioMin > 0 ? filter[barrioId]?.precioMin : '',
      precioMax: filter[barrioId]?.precioMax || '',
      numeroLote: filter[barrioId]?.numeroLote || '',
      inversor: filter[barrioId]?.inversor || '',
      estado: filter[barrioId]?.estado,
      orientacion: filter[barrioId]?.orientacion,
      motivoDisponibilidad: filter[barrioId]?.motivoDisponibilidad,
    };

    if(filter[barrioId]?.isInversor !== undefined && filter[barrioId]?.isInversor !== null) {
      filtros.isInversor = filter[barrioId]?.isInversor;
    }
    // Convertir los filtros a query params
    return queryString.stringify(filtros);
  };

  const eliminarSeleccionados = async (e) => {
    setSpinnerLoading(true);
    try {
      Swal.fire({
        title: `Estás a punto de eliminar ${seleccionados.length} lotes.`,
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `Cancelar`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const rta = await clienteAxios.delete("/lotes", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: seleccionados,
          });

          if (rta.data.estado == "ok") {
            Swal.fire("¡Correcto!", rta.data.msg, "success")
              .then(() => {
                props.history.push(`/lotes/${id}`);
                consultarAPI();
              })
          } else if (rta.data.estado == "error") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: rta.data.msg,
            });
          }
          setSpinnerLoading(false);
        }
      });

    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };


  const dataExport = data.data.map(lote => {
    return {
      "Desarrollo": lote.barrio,
      "Numero de Lote": lote.numeroLote,
      "Manzana": lote.numeroManzana,
      "Superficie": lote.superficie,
      "Tipología": lote.tipologia,
      "Precio de Lista": lote.precio,
      "Orientacion": lote.orientacion,
      "Estado": lote.estado,
    }
  })

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'data.xlsx');
  };

  const handlePerRowsChange = async (limit) => {
    setPaginator({
      ...paginator,
      [barrioId]: {
        ...paginator[barrioId],
        limit,
      }
    });
  }

  const handlePageChange = async (page) => {
    setPaginator ({
      ...paginator,
      [barrioId]: {
        ...paginator[barrioId],
        page,
      }
    });
  }


  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col">
              <h3 className="card-title mt-2">LOTES {id ? ` - ${nombreDesarrollo || 'S/N'}` : ''}</h3>
              <button className="btn btn-primary float-right ml-3" onClick={props.history.goBack}>Volver</button>
              <button className="btn btn-success float-right ml-3" onClick={exportToExcel}>Exportar</button>
            </div>
          </div>
        </div>

        <div className="card-body">
          <form onSubmit={submit}>
            <div className="form-group">
              <input
                placeholder="Buscar:"
                onChange={buscar}
                type="text"
                name="buscar"
                className="form-control"
              />
            </div>
          </form>
          {spinnerLoading &&
            <Spinner />
          }
          <div className="row">
            <div className="col">
              <LotesFiltros
                fetchApi={consultarAPI}
                history={props.history}
              />
            </div>
          </div>
          {
            Array.isArray(seleccionados) && seleccionados.length > 0 ?
              <ModificarLotes
                seleccionados={seleccionados}
                reload={consultarAPI}
              /> : null
          }
          <DataTable
            columns={columns}
            data={data.data}
            pagination
            paginationServer
            selectableRows={nivelRol >= 3}
            paginationComponentOptions={paginationComponentOptions}
            onSelectedRowsChange={handleChange}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            paginationDefaultPage={getPage() + 1}
            paginationDefaultRowsPerPage={getLimit()}
            paginationPerPage={getLimit()}
          />
        </div>

        <button
          className={"btn btn-primary " + display}
          onClick={eliminarSeleccionados}
        >
          Eliminar Seleccionados
        </button>
      </div>
    </>
  );
}

export default withRouter(Lotes);
