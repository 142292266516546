import React, { useContext, useState } from "react";
import { CRMContext } from "../../context/CRMContext";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";

const EditarLotesMasivo = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [file, setFile] = useState(null);

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const enviarArchivo = async (e) => {
    e.preventDefault();
    const datosEnviar = new FormData();
    datosEnviar.append("file", file);

    try {
      const rta = await clienteAxios.put("/lotes/CSV", datosEnviar, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
        if (rta.data.estado == "ok") {
          Swal.fire("¡Correcto!", rta.data.msg, "success");
        } else if (rta.data.estado == "error") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: rta.data.msg,
          });
        }
        // props.history.push(`/lotes/${barrioSeleccionado}`);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Editar Lotes</h3>
        </div>
        <div className="card-body">
          <form onSubmit={enviarArchivo}>
            <div className="row">
              <div className="col-md-12 orm-group">
                <label>Adjuntar archivo CSV</label>
                <input
                  type="file"
                  name="csv"
                  id=""
                  className="form-control"
                  onChange={selectFile}
                />
              </div>
            </div>

            <div className="card-footer">
              <button
                type="submit"
                className="float-right btn btn-primary"
                value={file}
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditarLotesMasivo;
