import React, {useState, useEffect, useContext} from 'react';
import {Link, withRouter} from "react-router-dom";
import DataTable from "react-data-table-component";
import clienteAxios from "../../config/axios";
import {CRMContext} from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import Swal from "sweetalert2";

const ListadoFirmantesLife = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  const [firmantes, setFirmantes] = useState([]);
  let token = auth.token || localStorage.getItem("token");
  const [showSpinner, setShowSpinner] = useState(true);
  const getFirmantes = async () => {
    setShowSpinner(true);
    const response = await clienteAxios.get('/firmantesLife', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        activo: true,
      }
    });
    const firmantes = response.data.data.map((firmante) => {
      const nombreCompleto = firmante.nombre.apellido ? `${firmante.nombre.apellido}, ${firmante.nombre.nombre} ${firmante.nombre.segundoNombre || ''}` : firmante.razonSocial;
      return {
        nombreCompleto,
        numeroCuilCuit: firmante.documentos.cuitCuil,
        email: firmante.email,
        url: `/configuracion/firmantesLife/editar/${firmante._id}`,
        id: firmante._id,
      };
    });
    setFirmantes(firmantes);
    setShowSpinner(false);
  }

  useEffect(() => {
    getFirmantes();
  }, []);

  const columns = [
    {
      name: "Nombre Completo",
      selector: (row) => row.nombreCompleto,
      sortable: true,
    },
    {
      name: "CUIT / CUIL",
      selector: (row) => row.numeroCuilCuit,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <Link to={row.url}>
          <button type="button" className="btn btn-block btn-success">
            Editar
          </button>
        </Link>
      ),
    },
    {
      name: "Eliminar",
      button: true,
      cell: (row) => (
          <button type="button" className="btn btn-block btn-danger" onClick={() => deleteFirmante(row.id)}>
            Eliminar
          </button>
      ),
    },
  ];

  const deleteFirmante = (id) => {
    Swal.fire({
      title: "¿Estás Seguro?",
      text: "Un firmante eliminado no puede recuperarse",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const eliminar = async () => {
          try {
            const rta = await clienteAxios.delete(
              `/firmantesLife/${id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (rta.data.estado == "ok") {
              Swal.fire("¡Eliminado!", rta.data.msg, "success");
              getFirmantes();
            } else if (rta.data.estado == "error") {
              Swal.fire({
                type: "error",
                title: rta.data.msg,
                text: "Hubo un error",
              });
            }
          } catch (error) {
            if (error.response?.status === 500) {
              localStorage.clear();
              props.history.push("/login");
            }
            console.log(error);
          }
        };
        eliminar();
      }
    });
  }

  if(showSpinner) {
    return <Spinner />
  }


  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-9">
            <h3 className="card-title mt-2">Firmantes Life</h3>
          </div>
          <div className="col-md-2">
            <Link to={`/configuracion/firmantesLife/nuevo`}>
              <button type="button" className="btn btn-block btn-success">
                Nuevo Firmante
              </button>
            </Link>
          </div>
          <div className="col-md-1">
            <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <DataTable
          columns={columns}
          data={firmantes}
        />
      </div>
    </div>
  )
}

export default ListadoFirmantesLife