import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import Spinner from "../layout/Spinner";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Swal from "sweetalert2";

const Comentarios = (props) => {

  const [comentarios, setComentarios] = useState([]);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [nuevoComentario, setNuevoComentario] = useState('');
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  useEffect(() => {
    obtenerComentarios();
  }, []);



  const obtenerComentarios = async () => {
    const id = props.idOperacion;
    try {
      const comentarios = await clienteAxios.get(`/comentariosOperaciones/getByOperacion/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setComentarios(comentarios.data);
      setLoadingInfo(false);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
      setLoadingInfo(false);
    }

  }

  const agregarComentario = async () => {

    const comentario = {
      comentario: nuevoComentario,
      operacion: props.idOperacion
    };

    try {
      const rta = await clienteAxios.post("/comentariosOperaciones", comentario, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.data.estado === "ok") {

        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado === "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
      setNuevoComentario('');
      obtenerComentarios();
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  const spliceComentario = (comentario) => {
    const lineas = comentario.split('\n');
    return lineas;
  }

  if (loadingInfo) return <Spinner />;

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* The time line */}
          <div className="timeline">
            {comentarios.length > 0 ? comentarios.map((comentario, index) => {
              return (
                <div key={index}>
                  <i className="fas fa-envelope bg-blue" />
                  <div className="timeline-item">
                    <span className="time">
                      <i className="fas fa-clock" />{" "}
                      {moment(comentario.fecha)
                        //   .utcOffset(180)
                        .format("DD-MM-YYYY - HH:mm")}
                    </span>
                    <h3 className="timeline-header">
                      {`Usuario: ${comentario.usuario?.apellido || '< Sin Apellido >'}, ${comentario.usuario?.nombre || '< Sin Nombre >'}`} - {comentario.usuario?.inmobiliaria?.nombre || '< Sin Nombre Inmobiliaria>'}
                    </h3>
                    {spliceComentario(comentario.comentario).map((linea) => {
                      return <div className="timeline-body">{linea}</div>
                    })}
                  </div>
                </div>
              );
            }) : ''}
          </div>
          <div className="modal-body col-md-12">
            <p>Nuevo Comentario:</p>
            <textarea
              className="p-4 w-100"
              name="comentario"
              id=""
              cols="100"
              rows="3"
              onChange={(e) => setNuevoComentario(e.target.value)}
              value={nuevoComentario}
            ></textarea>
          </div>
          <input className="btn btn-primary float-right" onClick={agregarComentario} type="button" value="Enviar Comentario" />
        </div>
      </div>
    </>
  );
};

export default Comentarios;
