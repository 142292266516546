import { get, del } from "../../config/apiHelper";

const getTags = async () => {
try {
  const response = await get(`/api/documentacion/tags`);
  return response;
} catch (error) {
  console.log(error)
}
}

const getCategorias = async () => {
  try {
    const response = await get(`/api/documentacion/categorias`);
    return response;
  } catch (error) {
    console.log(error)  
}
}

const getDocumentacion = async (params = {}) => {
  try {
    const response = await get("/api/documentacion", params);
    return response
  } catch (error) {
    console.log(error);
  }
}

const getDocumentacionById = async (id) => {
  try {
    const response = await get(`/api/documentacion/info/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
}

const deleteCategoria = async (categoriaId) => {
  try {
    const response = await del(`/api/documentacion/categorias/${categoriaId}`);
    return response;
  } catch (error) {
    console.log(error);
  }
}



export { getTags, getCategorias, getDocumentacion, deleteCategoria, getDocumentacionById };