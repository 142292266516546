import React, { useContext, useEffect, useState } from "react";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Swal from "sweetalert2";
import FormularioBarrio from "./components/FormularioBarrio";
import EliminarBarrios from "./components/EliminarBarrio";
import { AsignarDesarrolladoras } from "./components/AsignarDesarrolladoras";
import Spinner from "../layout/Spinner";

const EditarBarrio = (props) => {
  const [barrio, setBarrio] = useState({});
  const [escribanias, setEscribanias] = useState([]);
  const [loading, setLoading] = useState(false);

  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const [escribaniaSeleccionada, setEscribaniaSeleccionada] = useState({});

  const consultarAPI = async () => {
    setLoading(true);
    const { id } = props.match.params;
    try {
      const barrioDB = await clienteAxios.get(`/barrios/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const escribaniasDB = await clienteAxios.get(`/escribania?limit=0&sort=nombre,1`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      escribaniasDB.data = escribaniasDB.data.data.map((escribania) => {
        return {
          ...escribania,
          label: escribania.nombre,
          value: escribania._id
        }
      })

      if (barrioDB.data.escribania != null) {

        const escribania = escribaniasDB.data.find(escribania => {
          return escribania._id === barrioDB.data.escribania;
        });

        const escribaniaSeleccionada = {
          value: escribania._id,
          label: escribania.nombre
        }

        setEscribaniaSeleccionada(escribaniaSeleccionada);
      }
      setBarrio(barrioDB.data);
      setEscribanias(escribaniasDB.data);
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    consultarAPI();
  }, []);

  const enviarBarrio = async (e) => {
    e.preventDefault();

    try {
      const rta = await clienteAxios.put(`/barrios/${barrio._id}`, barrio, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
      props.history.push(`/barrios?tipo=${barrio.tipo}`);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };


  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mt-2">Editar Desarrollo</h3>
          <button className="btn btn-primary float-right ml-3" onClick={props.history.goBack}>Volver</button>
          <button className="btn btn-primary float-right ml-3" onClick={() => props.history.push(`/lotes/altaMasiva/${props.match.params.id}`)}>Agregar Lotes</button>
        </div>
        <div className="card-body">
          {(loading || !barrio) ? <Spinner /> :
            <form onSubmit={enviarBarrio}>
              <FormularioBarrio
                barrio={barrio}
                setBarrio={setBarrio}
                enviarBarrio={enviarBarrio}
                barrioNuevo={false}
                escribanias={escribanias}
                escribaniaSeleccionada={escribaniaSeleccionada}
                history={props.history}
                consultarBarrio={consultarAPI}
              />
              <div className="row mt-5 mb-5">
                <div className="col-md-12">
                  <AsignarDesarrolladoras
                    barrio={barrio}
                    setBarrio={setBarrio}
                    consultarBarrio={consultarAPI}
                  />
                </div>
              </div>
              <EliminarBarrios idBarrio={barrio?._id} />
              <button type="submit" className="btn btn-primary float-right">
                Guardar
              </button>
            </form>
          }
        </div>
      </div>
    </>
  );
};

export default EditarBarrio;
