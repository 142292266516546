import NumberFormat from "react-number-format";
import Select from "react-select";
import Spinner from "../../layout/Spinner";
import ArchivosAdjuntosBarrios from "../../operaciones/components/ArchivosAdjuntosBarrios";
import InformacionExtraBarrios from "../../operaciones/components/InformacionExtraBarrios";
import BoletoCompraventa from "./BoletoCompraventa";
import ListaDePrecio from "../../listasDePrecios/ListaDePrecio";
import Masterplan from "./Masterplan";

const FormularioBarrio = ({
                            barrio,
                            setBarrio,
                            barrioNuevo,
                            escribanias,
                            escribaniaSeleccionada,
                            history,
                            consultarBarrio
                          }) => {

  const setState = (e) => {

    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    setBarrio({
      ...barrio,
      [e.target.name]: value,
    });
  };

  const actualizarEscribania = (e) => {
    setBarrio({
      ...barrio,
      escribania: e.value
    })
  }

  const updateTipoBarrio = (e) => {
    const {value} = e;
    setBarrio({
      ...barrio,
      tipo: value
    });
  }

  if (barrioNuevo) {
    barrio.activo = true;
  }

  if (escribanias.length === 0) {
    return <Spinner/>
  }

  const setGastosEscribania = (e, input) => {
    setBarrio({
      ...barrio,
      gastosEscribania: {
        ...barrio.gastosEscribania,
        [input]: e.floatValue
      }
    });
  }

  return (
    <>
      <div className="row">
        <div className="col-md-6 form-group">
          <label>Nombre:</label>
          <input
            type="text"
            className="form-control"
            placeholder="Nombre"
            name="nombre"
            onChange={setState}
            value={barrio?.nombre}
          />
        </div>
        <div className="col-md-6 form-group">
          <label>Ciudad:</label>
          <input
            type="text"
            className="form-control"
            placeholder="Ciudad"
            name="ciudad"
            onChange={setState}
            value={barrio?.ciudad}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 form-group">
          <label>Dirección:</label>
          <input
            type="text"
            className="form-control"
            placeholder="Dirección"
            name="direccion"
            onChange={setState}
            value={barrio?.direccion}
          />
        </div>
        <div className="col-md-6 form-group">
          <label>Escribanía</label>
          <Select
            options={escribanias}
            name="escribania"
            defaultValue={escribaniaSeleccionada}
            onChange={actualizarEscribania}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-3 form-group">
          <label>Tipo</label>
          <Select
            name="tipo"
            onChange={updateTipoBarrio}
            value={barrio ? {label: barrio.tipo, value: barrio.tipo} : null}
            options={[
              {
                value: 'Industrial',
                label: 'Industrial'
              },
              {
                value: 'Urbano',
                label: 'Urbano'
              },
            ]}
          />
        </div>
        <div className="col-md-3 form-group">
          <label>Cuotas máximo financiado</label>
          <input
            type="number"
            className="form-control"
            name="cuotasFinanciado"
            value={barrio?.cuotasFinanciado}
            onChange={setState}
            min={0}
          />
        </div>
        <div className="col-md-3 form-group float-right">
          <label>Activo</label>
          <input
            type="checkbox"
            className="form-control"
            name="activo"
            checked={barrio?.activo}
            onChange={setState}
            disabled={barrioNuevo}
          />
        </div>
      </div>

      <div className="row">
        <label className="col-md-12">Gastos Escribanía:</label>
        <div className="col-md-4 form-group">
          <label>Valor fijo:</label>
          <NumberFormat
            className="form-control"
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            value={barrio.gastosEscribania?.valorFijo || ''}
            onValueChange={(e) => {
              setGastosEscribania(e, 'valorFijo')
            }}
          />
        </div>
        <div className="col-md-4 form-group">
          <label>Porcentaje del valor de venta:</label>
          <NumberFormat
            className={"form-control "}
            name="porcentajegastosEscribania"
            value={barrio.gastosEscribania?.porcentaje || ''}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            fixedDecimalScale={true}
            suffix={'%'}
            onValueChange={(e) => {
              setGastosEscribania(e, 'porcentaje')
            }}
          />
        </div>
        <div className="col-md-4 form-group">
          <label>Valor de costo de lote extra:</label>
          <NumberFormat
            className="form-control"
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            value={barrio.gastosEscribania?.valorPorLoteExtra || ''}
            onValueChange={(e) => {
              setGastosEscribania(e, 'valorPorLoteExtra')
            }}
          />
        </div>
      </div>


      {
        barrio._id &&
        <ArchivosAdjuntosBarrios
          id={barrio._id}
          barrio={barrio}
          history={history}
          consultarBarrio={consultarBarrio}
          setBarrio={setBarrio}
        />
      }
      {
        barrio._id &&
        <InformacionExtraBarrios
          id={barrio._id}
          barrio={barrio}
          history={history}
          setBarrio={setBarrio}
          consultarBarrio={consultarBarrio}
          getBarrio={consultarBarrio}
        />
      }
      {barrio._id ?
        <Masterplan
          id={barrio._id}
          barrio={barrio}
          history={history}
          consultarBarrio={consultarBarrio}
          setBarrio={setBarrio}
        /> : null
      }
      {barrio._id ?
        <BoletoCompraventa
          id={barrio._id}
          barrio={barrio}
          history={history}
          consultarBarrio={consultarBarrio}
          setBarrio={setBarrio}
        /> : null
      }
      {barrio._id ?
        <ListaDePrecio
          history={history}
          consultarBarrio={consultarBarrio}
        /> : null
      }
    </>
  );
};

export default FormularioBarrio;
