import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import clienteAxios from "../../config/axios";
import Spinner from "../layout/Spinner";
import { CRMContext } from "../../context/CRMContext";
import Swal from 'sweetalert2';
import Historico from "./components/Historico";
import ArchivosAdjuntos from "./components/ArchivosAdjuntos";
import Comentarios from "../comentarios/Comentarios";
import MostrarPersona from "./nuevaOperacion/components/components/MostrarPersona";
import MostrarLotes from "./nuevaOperacion/components/components/MostrarLotes";
import CompletarValoresNegociados from "./nuevaOperacion/components/components/CompletarValoresNegociados";
import MostrarEscribania from "./nuevaOperacion/components/components/MostrarEscribania";
import MostrarGastosDeOriginacion from "./nuevaOperacion/components/components/MostrarGastosDeOriginacion";
import ConfirmarValoresNegociados from "./nuevaOperacion/components/components/ConfirmarValoresNegociados";
import CompletarValoresBoleto from "./nuevaOperacion/components/components/CompletarValoresBoleto";
import ConfirmarValoresBoleto from "./nuevaOperacion/components/components/ConfirmarValoresBoleto";
import CargarTurnoFirma from "./nuevaOperacion/components/components/CargarTurnoFirma";
import MostrarTurnoFirma from "./nuevaOperacion/components/components/MostrarTurnoFirma";
import GenerarBoletoCompraventa from "./nuevaOperacion/components/components/GenerarBoletoCompraventa";
import CargarBoletoCompraventa from "./nuevaOperacion/components/components/CargarBoletoCompraventa";
import BotoneraFormularios from "./components/BotoneraFormularios";
import estadosHelper from './helpers/EstadosHelper';
import ResumenOperacion from "./components/ResumenOperacion";
import DetalleDuenoOperacion from "./components/DetalleDuenoOperacion";
import FechaOperacion from "./components/FechaOperacion";


function Operacion(props) {

  // const estados = {
  //   100: 'Reserva Enviada',
  //   101: 'Reserva Respondida',
  //   102: 'Reserva Aceptada',
  //   103: 'Información de Boleto Completa',
  //   104: 'Información del Boleto Confirmada',
  //   105: 'Turno para Firma Agendado',
  //   106: 'Turno para Firma Confirmado',
  //   107: 'Boleto de Compraventa Emitido',
  //   108: 'Boleto de Compraventa Firmado',
  //   109: 'Venta Finalizada'
  // }

  const [isFirmantesCollapsed, setIsFirmantesCollapsed] = useState(true);
  const [showSpinner, setshowSpinner] = useState(false);
  const [estadosOperacions, setEstadosOperacions] = useState([]);
  const handleFirmantesToggle = () => {
    setIsFirmantesCollapsed(!isFirmantesCollapsed);
  };
  const rolId = parseInt(localStorage.getItem('rolId'));
  const isBackOffice = rolId == 3 || rolId == 4 || rolId == 6;

  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  if (!token) {
    props.history.push('/login');
  }
  const [operacion, guardarOperacion] = useState({
    _id: "",
  });

  const { id } = props.match.params;

  const consultaAPI = async (id) => {
    setshowSpinner(true);
    try {
      const operacionConsulta = await clienteAxios.get(
        `/operaciones/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      guardarOperacion(operacionConsulta.data);
      setshowSpinner(false);
    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      } else {
        props.history.push('/');
      }
    } finally {
      setshowSpinner(false);
    }
  };

  const cancelarOperacion = () => {
    const id = operacion._id;

    Swal.fire({
      title: 'Desea cancelar la operación?',
      text: "Esta acción no se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Si, cancelar!',
      cancelButtonText: 'Deshacer',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const operacionCancelar = await clienteAxios.delete(
          `/operaciones/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (operacionCancelar.data.estado === 'ok') {
          Swal.fire(
            'Operación Cancelada!',
            'La operación fue cancelada.',
            'success'
          ).then(() => {
            props.history.push('/operaciones');
          })
        } else {
          Swal.fire(
            'Hubo un error:',
            operacionCancelar.msg,
            'error'
          )
        }
      }
    })

  }

  const getEstadosOperaciones = async () => {
    try {
      const estadosOperaciones = await clienteAxios.get(
        `/estadosOperaciones`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
      setEstadosOperacions(estadosOperaciones.data);
    } catch
    (error) {
      console.log(error);
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
    }
  }

  useEffect(() => {
    getEstadosOperaciones();
    if (props.match.params.id) {
      consultaAPI(id);
    }
  }, []);

  const getNextEstado = (operacion) => {
    const estadoId = getOperacionEstadoId(operacion);
    const actualEstado = estadosHelper.get(estadoId);
    if (actualEstado && actualEstado.getNext()) {
      return actualEstado.getNext().getId();
    } else {
      console.log('Es un estado intermedio, buscamos el próximo estado')
      return estadoId + 1
    }
  }

  const getPreviousEstado = (operacion) => {
    const estadoId = getOperacionEstadoId(operacion);
    const actualEstado = estadosHelper.get(estadoId);
    if (actualEstado) {
      const lastEstado = actualEstado.getLast();
      if (lastEstado) {
        return lastEstado.getId();
      }
    } else {
      console.log('Es un estado intermedio, buscamos el estado anterior')
      return estadoId - 1
    }
  }

  const getActualHistorico = (operacion) => {
    const estadoId = getOperacionEstadoId(operacion);
    const estadoActual = estadosHelper.get(estadoId);
    if (estadoActual) {
      const estadoSiguiente = estadoActual.getNext();
      if (estadoSiguiente) {
        return estadoSiguiente.getEstado();
      }
    }
    return 'Error';
  }

  const getPreviousHistorico = (operacion) => {
    const estadoId = getOperacionEstadoId(operacion);
    const estadoActual = estadosHelper.get(estadoId);
    if (estadoActual) {
      const estadoAnterior = estadoActual.getLast();
      if (estadoAnterior) {
        return estadoAnterior.getEstado();
      }
    }
    return 'Se retrocede el estado';
  }

  const getOperacionEstadoId = (operacion) => {
    const { estado } = operacion;
    if (estado) {
      const { id } = estado;
      return id;
    }
  }

  const enviarOperacionComponents = async () => {
    enviarOperacion();
  }

  const enviarOperacion = async () => {
    if (estadosHelper.get(operacion.estado?.id)?.isLastEstado()) {
      return;
    }
    const historico = {
      fecha: Date.now(),
      accion: getActualHistorico(operacion),
      usuario: localStorage.getItem("idUsuario"),
    };

    const datosEnviar = {
      operacion,
      historico,
      estadoOperacion: getNextEstado(operacion)
    };

    try {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      const rta = await clienteAxios.put(
        `/operaciones/${operacion._id}`,
        datosEnviar,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // props.history.push(`/operaciones`);
      const { data } = rta;
      if (data && data.estado === 'error') {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.msg,
        });
      }
      setshowSpinner(true);
      consultaAPI(id);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
    }
  };

  const retrocederEstado = async () => {
    if (operacion.estado?.id <= 100) {
      return;
    }

    Swal.fire({
      title: 'Desea retroceder el estado de la operación?',
      text: "Esta acción no se puede revertir!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Si, retroceder!',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const historico = {
          fecha: Date.now(),
          accion: getPreviousHistorico(operacion),
          usuario: localStorage.getItem("idUsuario"),
        };

        const datosEnviar = {
          operacion,
          historico,
          estadoOperacion: getPreviousEstado(operacion)
        };

        try {
          const rta = await clienteAxios.put(
            `/operaciones/${operacion._id}`,
            datosEnviar,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // props.history.push(`/operaciones`);
          setshowSpinner(true);
          consultaAPI(id);
        } catch (error) {
          if (error.response?.status === 500) {
            localStorage.clear();
            props.history.push("/login");
          }
        }
      }
    })


  }

  const getNextAction = () => {
    const proximaAccion = {
      100: 'Completar los valores negociados',
      101: 'Confirmar los valores negociados',
      102: 'Agendar el turno para la Firma',
      103: 'Confirmar los valores para el boleto',
      104: 'Completar los valores para el boleto',
      105: 'Confirmar el turno para la Firma',
      106: 'Emitir la documentación de firma',
      107: 'Adjuntar la documentación de firma',
      108: 'Finalizar la Operación',
      109: 'Venta Finalizada'
    }
    return operacion.estado?.id ? proximaAccion[operacion.estado?.id] : ' - ';
  }

  const canConfirm = () => {

    const estadosBackOffice = [100, 102, 104, 107, 108, 109];
    const isBackOffice = rolId == 3 || rolId == 4 || rolId == 6;
    const corresponseToBackOffice = operacion && operacion.estado && estadosBackOffice.includes(operacion.estado?.id);

    return isBackOffice && corresponseToBackOffice;
  }


  if (operacion._id === "" || showSpinner) {
    return <Spinner />;
  }


  return (
    <>
      <div className="card">
        <div className="card-header row">
          <div className="col-md-7">
            <h3 className="card-title mt-2">
              <strong>Operación:</strong>
            </h3>
          </div>
          <div className="col-md-4">
            <input
              className="btn btn-danger float-right"
              type="button"
              value="Cancelar Operación"
              disabled={operacion.estado?.id === 0 || operacion.estado?.id === 11}
              onClick={cancelarOperacion}
            />
          </div>
          <div className="col-md-1">
            <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
          </div>
        </div>
        <div className="card-header row">
          <div className="col-md-8">
            <div className="row ml-3 mb-2">
              <h3 className="card-title">
                <strong>Estado actual:</strong> {estadosHelper.get(operacion.estado?.id)?.estado || 'Estado Intermedio'}
              </h3>
            </div>
            <div className="row ml-3 mb-2">
              <h3 className="card-title">
                <strong>Próxima tarea:</strong> {getNextAction()}
              </h3>
            </div>
          </div>
          <FechaOperacion operacion={operacion} consultaAPI={consultaAPI} />
        </div>



        <div className="card-body">

          <DetalleDuenoOperacion operacion={operacion} />

          <ResumenOperacion resumen={operacion.resumen} />

          <BotoneraFormularios
            operacion={operacion}
            token={token}
          />
          {getOperacionEstadoId(operacion) >= 106 &&
            <div className="mb-5">
              <div className="card-header">
                <h3 className="card-title">Tipo de Operación</h3>
              </div>
              <Select
                className='mt-3 col-md-6 mb-3'
                onChange={(e) => guardarOperacion({
                  ...operacion,
                  tipoOperacion: e.value
                })}
                options={[
                  {
                    label: 'Acuerdo',
                    value: 'Acuerdo'
                  },
                  {
                    label: 'Boleto',
                    value: 'Boleto'
                  }
                ]}
                value={{ value: operacion.tipoOperacion, label: operacion.tipoOperacion }}
                isDisabled={operacion.estado?.id > 106}
              />


              {operacion.tipoOperacion && operacion.tipoOperacion === 'Boleto' ?
                <GenerarBoletoCompraventa
                  operacion={operacion} guardarOperacion={guardarOperacion} token={token}
                  enviarOperacion={enviarOperacionComponents}
                />
                : null}
              {operacion.tipoOperacion && operacion.tipoOperacion === 'Acuerdo' ?
                <p className="m-4">Se deberá generar un acuerdo para firmarse.</p>
                : null}
            </div>
          }
          {
            rolId !== 5 && getOperacionEstadoId(operacion) >= 107 &&
            <CargarBoletoCompraventa operacion={operacion} token={token} enviarOperacion={enviarOperacionComponents} />
          }
          {rolId !== 5 && getOperacionEstadoId(operacion) === 102 &&
            <CargarTurnoFirma
              operacion={operacion} guardarOperacion={guardarOperacion} enviarOperacion={enviarOperacionComponents}
            />
          }
          {getOperacionEstadoId(operacion) >= 105 &&
            <MostrarTurnoFirma
              operacion={operacion} guardarOperacion={guardarOperacion} enviarOperacion={enviarOperacionComponents}
            />
          }
          {(isBackOffice) && rolId !== 5 && getOperacionEstadoId(operacion) === 100 &&
            <CompletarValoresNegociados operacion={operacion} guardarOperacion={guardarOperacion}
              enviarOperacion={enviarOperacionComponents}
              saldoAFinanciar={operacion.saldoAFinanciar} token={token} />
          }
          {rolId !== 5 && getOperacionEstadoId(operacion) === 102 &&
            <ConfirmarValoresNegociados operacion={operacion} guardarOperacion={guardarOperacion}
              enviarOperacion={enviarOperacionComponents}
              saldoAFinanciar={operacion.saldoAFinanciar} token={token} />
          }
          {(isBackOffice) && rolId !== 5 && getOperacionEstadoId(operacion) === 104 &&
            <CompletarValoresBoleto operacion={operacion} guardarOperacion={guardarOperacion}
              enviarOperacion={enviarOperacionComponents}
              saldoAFinanciar={operacion.saldoAFinanciar} token={token} />
          }
          {rolId !== 5 && (getOperacionEstadoId(operacion) >= 106 || getOperacionEstadoId(operacion) === 0) &&
            <ConfirmarValoresBoleto operacion={operacion} guardarOperacion={guardarOperacion}
              enviarOperacion={enviarOperacionComponents}
              saldoAFinanciar={operacion.saldoAFinanciar} token={token} />
          }


          {rolId !== 5 && <MostrarPersona cliente={operacion.cliente} titulo={'Cliente'} />}
          <div className="card-header">
            <h3 className="card-title">Firmantes</h3>
            <button
              className="btn btn-primary float-right ml-3"
              type="button"
              onClick={handleFirmantesToggle}
            >
              {isFirmantesCollapsed ? 'Ver' : 'Ocultar'}
            </button>
            <Link to={`/operaciones/editarFirmantes/${id}`}>
              <button
                className="btn btn-primary float-right ml-3"
                type="button"
              >
                Editar Firmantes
              </button>
            </Link>
          </div>
          <div className={`collapse${isFirmantesCollapsed ? '' : ' show'}`}>
            {operacion.firmantes?.map((firmante, index) => {
              return (
                <MostrarPersona key={index} cliente={firmante.firmante}
                  titulo={`Firmante nro: ${index + 1} de ${operacion.firmantes.length} - Propiedad: ${firmante.porcentaje} %`} />
              )
            })}
          </div>
          {rolId !== 5 && <MostrarLotes lotesSeleccionados={operacion.lote} operacion={operacion} />}


          <MostrarEscribania escribania={operacion.escribania} />
          {rolId !== 5 && <MostrarGastosDeOriginacion operacion={operacion} />}
          <ArchivosAdjuntos
            token={token}
            operacion={operacion}
            consultarOperacion={() => consultaAPI(id)}
            formulario={'homeOperacion'}
          />

          <div className="card-header">
            <h3 className="card-title">Comentarios:</h3>
          </div>
          <div className="card-body">
            <Comentarios idOperacion={operacion._id} />
          </div>

          {rolId !== 5 &&
            <>
              <div className="card-header">
                <h3 className="card-title">Histórico</h3>
              </div>
              <div className="card-body">
                <Historico historico={operacion.historico} />
              </div>
            </>
          }
        </div>
        <div className='card-footer'>
          <div className='row d-flex justify-content-around'>
            {rolId == 4 &&
              <div className="">
                <button
                  type="submit"
                  className="float-right btn btn-primary"
                  onClick={retrocederEstado}
                  data-toggle="modal"
                  data-target="#modal-respuesta"
                  disabled={!operacion || operacion.estado?.id <= 100}

                >
                  Retroceder Estado
                </button>
              </div>
            }
            <div className=''>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  props.history.goBack();
                }}
                className='float-left btn btn-primary'
              >
                Volver
              </button>
            </div>
            <div className="">
              <button
                type="submit"
                className="float-right btn btn-primary"
                onClick={enviarOperacion}
                data-toggle="modal"
                data-target="#modal-respuesta"
                disabled={estadosHelper.get(operacion.estado?.id)?.isLastEstado()}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Operacion);
