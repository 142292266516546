import React, {useEffect, useState} from 'react';
import Select from "react-select";
import NumberFormat from "react-number-format";

function FinanciadoBoleto(props) {
  const {setCaracteristicasBoleto} = props;
  const [value, setValue] = useState(null);
  const [sumarTotal, setSumarTotal] = useState(false);
  const [monedaSumarTotal, setMonedaSumarTotal] = useState(null);
  const [financiados, setFinanciados] = useState([{
    moneda: null,
    periodicidad: null,
    cantidadCuotas: null,
    valor: null,
    primerVencimiento: null,
  }]);
  const values = [
    {
      label: 'Mismo que Operación',
      value: 'mismoOperacion',
    },
    {
      label: 'Personalizado',
      value: 'personalizado',
    },
    {
      label: 'Sin Financiacion',
      value: 'sinFinanciacion',
    }
  ];

  const periodicityValues = [
    {
      label: 'Mensual',
      value: 'mensual'
    },
    {
      label: 'Trimestral',
      value: 'trimestral'
    },
    {
      label: 'Semestral',
      value: 'semestral'
    },
    {
      label: 'Anual',
      value: 'anual'
    },
  ];

  const monedaOptions = [
    {
      label: 'Pesos',
      value: 'pesos'
    },
    {
      label: 'Dólares',
      value: 'dolares',
    },
  ];

  const addFinanciado = () => {
    setFinanciados([
      ...financiados,
      {
        moneda: null,
        periodicidad: null,
        cantidadCuotas: null,
        valor: null,
        primerVencimiento: null,
      }
    ])
  }
// 475e51c68dea
  const removeFinanciado = (i) => {
    setFinanciados(financiados.filter((financiado, index) => index != i));
  }

  const updateFinanciado = (index, key, value) => {
    const financiadosCopy = [...financiados];
    financiadosCopy[index][key] = value;
    setFinanciados(financiadosCopy);
  }

  useEffect(() => {
    const financiadoBoleto = {
      value,
      financiados,
      sumarTotal,
      monedaSumarTotal,
    }
    setCaracteristicasBoleto('financiadoBoleto', financiadoBoleto);
  }, [value, financiados, sumarTotal, monedaSumarTotal]);

  const updateSumarTotal = () => {
    setSumarTotal(!sumarTotal);
    const financiadoBoleto = {
      value,
      financiados,
      sumarTotal: !sumarTotal,
      monedaSumarTotal,
    }
    setCaracteristicasBoleto('financiadoBoleto', financiadoBoleto);
  }



  return (
    <div className='mb-4'>
      <div className="row">
        <label className="col-md-12">Financiado</label>
      </div>
      <div className="row ml-2">
        <div className="col-md-4">
          <label>Valor</label>
          <Select
            name='tipoValor'
            options={values}
            onChange={(e) => setValue(e.value)}
          />
        </div>
      </div>
      {value === 'personalizado' ?
        <>
          <div className="row mt-4 ml-4">
            <div className="col-md-2">
              <label>Sumar Total:</label>
              <input type="checkbox" name="Sumar Total" className='form-control' checked={sumarTotal}
                     onChange={updateSumarTotal}/>
            </div>
            <div className="col-md-4">
              <label>Moneda</label>
              <Select
                isDisabled={!sumarTotal}
                onChange={(e) => setMonedaSumarTotal(e.value)}
                options={monedaOptions}
              />
            </div>
          </div>
        </>
        : null}
      {value === 'personalizado' ?
        financiados.map((financiado, index) => {
          return (
            <div className="row ml-4 mt-4">
              <div className="col-md-2 form-group">
                <label>Cant. Cuotas</label>
                <NumberFormat
                  className="form-control"
                  value={financiado.cantidadCuotas}
                  onValueChange={(e) => updateFinanciado(index, 'cantidadCuotas', e.floatValue)}
                />
              </div>
              <div className="col-md-2 form-group">
                <label>Periodicidad</label>
                <Select
                  options={periodicityValues}
                  value={periodicityValues.find(periodicityValue => periodicityValue.value === financiado.periodicidad)}
                  onChange={(e) => updateFinanciado(index, 'periodicidad', e.value)}
                />
              </div>
              <div className="col-md-2 form-group">
                <label>Moneda</label>
                <Select
                  options={monedaOptions}
                  value={monedaOptions.find(moneda => moneda.value === financiado.moneda)}
                  onChange={(e) => updateFinanciado(index, 'moneda', e.value)}
                />
              </div>
              <div className="col-md-2 form-group">
                <label>Valor Cuota</label>
                <NumberFormat
                  className="form-control"
                  value={financiado.valor}
                  decimalSeparator={','}
                  thousandSeparator={'.'}
                  prefix={financiado.moneda === 'pesos' ? '$ ' : 'u$s '}
                  onValueChange={(e) => updateFinanciado(index, 'valor', e.floatValue)}
                />
              </div>
              <div className="col-md-2 form-group">
                <label>1er vencimiento</label>
                <input
                  type="date"
                  className="form-control"
                  value={financiado.primerVencimiento}
                  onChange={(e) => updateFinanciado(index, 'primerVencimiento', e.target.value)}
                />
              </div>
              <div className="col-md-1 mt-4">
                <button
                  className="btn btn-primary float-right"
                  type="button"
                  onClick={addFinanciado}
                >
                  +
                </button>
              </div>
              <div className="col-md-1 mt-4">
                <button
                  className="btn btn-danger float-right"
                  type="button"
                  disabled={financiados.length <= 1}
                  onClick={() => removeFinanciado(index)}
                >
                  -
                </button>
              </div>
            </div>
          )
        })
        : null
      }
    </div>
  );
}

export default FinanciadoBoleto;