import React, { useState, useEffect, useContext } from "react";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import moment from "moment";

const EditarReadquisicionLotes = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [datosOperacion, setDatosOperacion] = useState({
    loading: true,
    usuario: localStorage.getItem("idUsuario"),
  });

  // Obtener datos de la operacion de readquisicion
  useEffect(() => {
    const getOperacion = async () => {
      try {
        const { id } = props.match.params;
        const readquisicion = await clienteAxios.get(`/readquisicion/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setDatosOperacion(readquisicion.data.data);

      } catch (error) {
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
        console.log(error);
      }
    };

    getOperacion();
  }, []);



  const volver = () => {
    props.history.goBack();
  }


  if (datosOperacion.loading) {
    return (
      <Spinner />
    )
  }


  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Readquisición de Lotes</h3>
        </div>

        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-md-2 form-group">
                <label>
                  Fecha:
                </label>
                <p>{moment(datosOperacion.fechaAlta)
                  .utcOffset(180)
                  .format("DD-MM-YYYY")}</p>
              </div>
              <div className="col-md-5 form-group">
                <label>
                  Desarrollo
                </label>
                <p>{datosOperacion.lote.barrio.nombre}</p>
              </div>

              <div className="col-md-5 form-group">
                <label>Lote</label>
                <p>Número: {datosOperacion.lote.numeroLote}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label>
                  Valor en que se toma el lote:
                </label>
                <p>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS', maximumFractionDigits: 0 }).format(datosOperacion.valor)}</p>
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label>Detalles de la operación</label>
                <p>{datosOperacion.detalle}</p>
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label>Responsable:</label>
                <p>{datosOperacion.usuario.apellido}, {datosOperacion.usuario.nombre}</p>
              </div>
            </div>
          </form>
        </div>
        <div className="card-footer">
          <button
            type="submit"
            className="btn btn-primary float-right"
            onClick={volver}
          >
            Volver
          </button>

        </div>
      </div>
    </>
  );
};

export default EditarReadquisicionLotes;
