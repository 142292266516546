import React from "react";
import moment from "moment";

const Historico = ({ historico }) => {

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* The time line */}
          <div className="timeline">
            {historico.map((historico) => {
              return (
                <div key={historico._id}>
                  <i className="fas fa-envelope bg-blue" />
                  <div className="timeline-item">
                    <span className="time">
                      <i className="fas fa-clock" />{" "}
                      {moment(historico.fecha)
                          // .utcOffset(180)
                        .utcOffset(-180)
                        .format("DD-MM-YYYY - HH:mm")}
                    </span>
                    <h3 className="timeline-header">
                      {`${historico.usuario?.apellido || '< Sin Apellido >'}, ${historico.usuario?.nombre || '< Sin Nombre >'}`}
                    </h3>
                    <div className="timeline-body">{historico.accion}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* /.col */}
      </div>
    </>
  );
};

export default Historico;
