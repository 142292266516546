import React, {useEffect, useRef, useState} from 'react';
import NumberFormat from "react-number-format";
import GetDolarMercado from '../../utils/GetDolarMercado';


const moneyArFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const moneyUsFormatter = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "symbol",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const dateFormatter = new Intl.DateTimeFormat("es-AR");

function InputValue(props) {
  const {
    amount,
    onChangeAmount,
    prefix,
    readOnly = false,
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };


  return (
    <>
      {/*<p>{isFocused ? 'focused' : 'not focused'}</p>*/}
      <NumberFormat
        className="form-control"
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={prefix}
        // decimalScale={2}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={amount}
        onValueChange={(e) => {
          if (isFocused) {
            onChangeAmount(e.floatValue)
          }
        }}
        readOnly={readOnly}
      />
    </>
  )
}

function TablaCotizaciones(props) {

  const {
    cotizaciones,
    setCotizaciones,
    allReadOnly = false,
    showNegociado = true,
    showBoleto = false,
    boletoReadOnly = true,
  } = props;


  const [exchangeRate, setExchangeRate] = useState(cotizaciones && cotizaciones.cambioNegociado ? cotizaciones.cambioNegociado : 0);
  const [amount, setAmount] = useState(0);
  const [amountFromDolares, setAmountFromDolares] = useState(true);

  useEffect(() => {
    const getDolarMercado = async () => {
      const dolarApi = await GetDolarMercado.getDolar();
      const valorDolarMercado = dolarApi.data.compra;
      setExchangeRate(valorDolarMercado);
    }
    getDolarMercado();
  }, []);

  useEffect(() => {
    if (!allReadOnly) {
      setCotizaciones({
        ...cotizaciones,
        fechaNegociado: Date.now(),
        cambioNegociado: exchangeRate,
        pesosNegociado: pesosNegociado,
        dolaresNegociado: dolaresNegociado
      });
    }
    if (!boletoReadOnly) {
      setCotizaciones({
        ...cotizaciones,
        fechaBoleto: Date.now(),
        cambioBoleto: exchangeRate,
        pesosBoleto: pesosNegociado,
        dolaresBoleto: dolaresNegociado
      });
    }
  }, [amount, exchangeRate]);


  let pesosNegociado, dolaresNegociado;
  if (amountFromDolares) {
    dolaresNegociado = amount;
    pesosNegociado = amount * exchangeRate;
  } else {
    pesosNegociado = amount;
    dolaresNegociado = amount / exchangeRate;
  }


  if (!cotizaciones) {
    return (<>
      <div className="row">
        <div className="col">
          <label>NO SE PUDIERON OBTENER LAS COTIZACIONES</label>
        </div>
      </div>
    </>)
  }
  const mercado = {
    fecha: cotizaciones.fechaMercado || new Date(Date.now()),
    cambio: cotizaciones.cambioMercado,
    valorDolar: cotizaciones.dolaresMercado,
    valorPesos: cotizaciones.pesosMercado,
  }

  const propuesto = {
    fecha: cotizaciones.fechaPropuesto,
    cambio: cotizaciones.cambioPropuesto,
    valorDolar: cotizaciones.dolaresPropuesto,
    valorPesos: cotizaciones.pesosPropuesto,
  }

  function handleDolaresChange(e) {
    setAmount(e)
    setAmountFromDolares(true)
  }

  function handlePesosChange(e) {
    setAmount(e)
    setAmountFromDolares(false)
  }

  return (
    <>
      <div className="row mt-2">
        <div className="col-md-12">
          <label>Tipos de Cambio:</label>
        </div>
        <div className="col">
          <table className='table'>
            <thead>
            <tr>
              <th className='col-md-3'>Tipo Valor</th>
              <th className='col-md-2'>Fecha</th>
              <th className='col-md-2'>Cambio</th>
              <th className='col-md-2'>Valor en u$s</th>
              <th className='col-md-2'>Valor en ars</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Mercado</td>
              <td>{mercado?.fecha ? dateFormatter.format(new Date(mercado.fecha)) : 'n/c'}</td>
              <td>{moneyArFormatter.format(mercado?.cambio || 0)}</td>
              <td>{moneyUsFormatter.format(mercado?.valorDolar || 0)}</td>
              <td>{moneyArFormatter.format(mercado?.valorPesos || 0)}</td>
            </tr>
            <tr>
              <td>Propuesto</td>
              <td>{propuesto?.fecha ? dateFormatter.format(new Date(propuesto.fecha)) : 'n/c'}</td>
              <td>{moneyArFormatter.format(propuesto?.cambio || 0)}</td>
              <td>{moneyUsFormatter.format(propuesto?.valorDolar || 0)}</td>
              <td>{moneyArFormatter.format(propuesto?.valorPesos || 0)}</td>
            </tr>
            {!allReadOnly && showNegociado &&
              <tr>
                <td>Negociado</td>
                <td>{dateFormatter.format(Date.now())}</td>
                <td>
                  <NumberFormat
                    className="form-control"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                    decimalScale={2}
                    value={exchangeRate}
                    onValueChange={(e) => setExchangeRate(e.floatValue)}
                    readOnly={allReadOnly}
                  />
                </td>
                <td>
                  <InputValue
                    prefix={'u$s'}
                    onChangeAmount={handleDolaresChange}
                    amount={dolaresNegociado}
                    id={'u$s'}
                    readOnly={allReadOnly}
                  />
                </td>
                <td>
                  <InputValue
                    prefix={'$'}
                    onChangeAmount={handlePesosChange}
                    amount={pesosNegociado}
                    id={'$'}
                    readOnly={allReadOnly}
                  />
                </td>
              </tr>
            }
            {allReadOnly && showNegociado &&
              <tr>
                <td>Negociado</td>
                <td>{dateFormatter.format(Date.now())}</td>
                <td>
                  {moneyArFormatter.format(cotizaciones.cambioNegociado || 0)}
                </td>
                <td>
                  {moneyUsFormatter.format(cotizaciones.dolaresNegociado || 0)}
                </td>
                <td>
                  {moneyArFormatter.format(cotizaciones.pesosNegociado || 0)}
                </td>
              </tr>
            }
            {!boletoReadOnly && showBoleto &&
              <tr>
                <td>Boleto</td>
                <td>{dateFormatter.format(Date.now())}</td>
                <td>
                  <NumberFormat
                    className="form-control"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                    decimalScale={2}
                    value={exchangeRate}
                    onValueChange={(e) => setExchangeRate(e.floatValue)}
                    readOnly={boletoReadOnly}
                  />
                </td>
                <td>
                  <InputValue
                    prefix={'u$s'}
                    onChangeAmount={handleDolaresChange}
                    amount={dolaresNegociado}
                    id={'u$s'}
                    readOnly={boletoReadOnly}
                  />
                </td>
                <td>
                  <InputValue
                    prefix={'$'}
                    onChangeAmount={handlePesosChange}
                    amount={pesosNegociado}
                    id={'$'}
                    readOnly={boletoReadOnly}
                  />
                </td>
              </tr>
            }
            {boletoReadOnly && showBoleto &&
              <tr>
                <td>Boleto</td>
                <td>{dateFormatter.format(Date.now())}</td>
                <td>
                  {moneyArFormatter.format(cotizaciones.cambioBoleto || 0)}
                </td>
                <td>
                  {moneyUsFormatter.format(cotizaciones.dolaresBoleto || 0)}
                </td>
                <td>
                  {moneyArFormatter.format(cotizaciones.pesosBoleto || 0)}
                </td>
              </tr>
            }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default TablaCotizaciones;