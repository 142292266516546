import React, {useContext, useEffect, useState} from 'react';
import queryString from 'query-string';
import clienteAxios from "../../../config/axios";
import {CRMContext} from "../../../context/CRMContext";
import Select from "react-select";

const UsuariosFiltros = ({setQuery, history}) => {
  const [text, setText] = useState('');
  const [estado, setEstado] = useState('');
  const [inmobiliaria, setInmobiliaria] = useState('');
  const [inmobiliarias, setInmobiliarias] = useState([]);
  const [rol, setRol] = useState('')
  const [roles, setRoles] = useState([]);
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const consultarInmobiliarias = async () => {
    try {
      const inmobiliariasDB = await clienteAxios.get(`/inmobiliarias?page=0&limit=0'&sort=nombre,1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const arrayOriginal = inmobiliariasDB.data.data.map((inmobiliaria) => {
        return {
          label: inmobiliaria.nombre,
          value: inmobiliaria._id,
        };
      });
      arrayOriginal.unshift({
        label: 'Todas',
        value: null
      })
      setInmobiliarias(arrayOriginal);
    } catch (error) {
      if (error.response?.status === 500) {
        console.log(error)
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  const consultaRol = async () => {
    try {
      const rolesDB = await clienteAxios.get("/roles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Convertir el array obtenido de la API en uno compatible con el componente Select
      setRoles(
        rolesDB.data.map((rol) => {
          return {
            id: rol.id,
            label: rol.nombre,
            value: rol._id
          };
        })
      );

    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    consultarInmobiliarias();
    consultaRol();
  }, []);
  const handleConsulta = () => {
    // Construir los filtros como objeto
    const filtros = {
      text,
      estado,
      rol,
      inmobiliaria,
    };

    // Convertir los filtros a query params
    const queryParams = queryString.stringify(filtros);
    setQuery(queryParams);
  };

  const estados = [
    {
      label: 'Todas',
      value: null,
    },
    {
      label: 'Activa',
      value: true,
    },
    {
      label: 'Eliminada',
      value: false
    }
  ]

  return (
    <div className="form-group">
      <label>Filtros:</label>
      <div className="row mb-3">
        <div className="col-md-3">
          <label>Rol:</label>
          <Select
            options={roles}
            onChange={e => setRol(e.value)}
          />
        </div>
        <div className="col-md-3">
          <label>Estado:</label>
          <Select
            options={estados}
            onChange={e => setEstado(e.value)}
          />
        </div>
        <div className="col-md-3">
          <label>Inmobiliaria:</label>
          <Select
            options={inmobiliarias}
            onChange={e => setInmobiliaria(e.value)}
          />
        </div>
        <div className="col-md-4">
          <label>Nombre:</label>
          <input
            type="text"
            value={text}
            onChange={e => setText(e.target.value)}
            placeholder="Nombre"
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-9"></div>
        <div className="col-md-3">
          <button className="btn btn-block btn-success float-right" onClick={handleConsulta}>Consultar</button>
        </div>
      </div>
    </div>
  );
};


export default UsuariosFiltros;
