import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Swal from "sweetalert2";
import FormularioBarrio from "./components/FormularioBarrio";

const NuevoBarrio = (props) => {
  const [barrio, setBarrio] = useState({});
  const [escribanias, setEscribanias] = useState([]);

  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  useEffect(() => {
    const consultarAPI = async () => {
      try {
        const escribaniasDB = await clienteAxios.get(`/escribania?limit=0&sort=nombre,1`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        escribaniasDB.data = escribaniasDB.data.data.map((escribania) => {
          return {
            ...escribania,
            label: escribania.nombre,
            value: escribania._id
          }
        });
        setEscribanias(escribaniasDB.data);
      } catch (error) {
        console.log(error);
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
      }
    }
    consultarAPI();
  }, []);


  const enviarBarrio = async (e) => {
    e.preventDefault();

    try {
      const rta = await clienteAxios.post("/barrios", barrio, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
      props.history.push("/barrios");
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Nuevo Desarrollo</h3>
        </div>
        <div className="card-body">
          <form onSubmit={enviarBarrio}>
            <FormularioBarrio
              barrio={barrio}
              setBarrio={setBarrio}
              enviarBarrio={enviarBarrio}
              barrioNuevo={true}
              escribanias={escribanias}
              history={props.history}
            />
            
            <button
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.goBack();
                  }}
                  className="float-left btn btn-primary"
                >
                  Volver
                </button>
            <button type="submit" className="btn btn-primary float-right">
              Guardar
            </button>
          </form>

        </div>
      </div>
    </>
  );
};

export default withRouter(NuevoBarrio);
