import React, { useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import Select from "react-select";

const EscribaniasFiltros = ({ setQuery, history }) => {
  const [text, setText] = useState('');
  const [estado, setEstado] = useState('');


  const handleConsulta = () => {
    // Construir los filtros como objeto
    const filtros = {
      text,
      estado
    };

    // Convertir los filtros a query params
    const queryParams = queryString.stringify(filtros);
    setQuery(queryParams);
  };

  const estados = [
    {
      label: 'Todas',
      value: null,
    },
    {
      label: 'Activa',
      value: true,
    },
    {
      label: 'Eliminada',
      value: false
    }
  ]


  return (
    <div className="form-group">
      <label>Filtros:</label>
      <div className="row mb-3">
        <div className="col-md-4">
          <label>Nombre / Mail / CUIT / CUIL:</label>
          <input
            type="text"
            value={text}
            onChange={e => setText(e.target.value)}
            placeholder="Nombre"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Inmobiliaria:</label>
          <Select
            options={estados}
            onChange={e => setEstado(e.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-9"></div>
        <div className="col-md-3">
          <button className="btn btn-block btn-success float-right" onClick={handleConsulta}>Consultar</button>
        </div>
      </div>
    </div>
  );
};


export default EscribaniasFiltros;
