import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";

export const ConfOperacionesComp = ({ operaciones, url }) => {
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);

  const columns = [
    {
      name: "Fecha Alta",
      selector: (row) => row.fechaAlta,
      sortable: true,
    },
    {
      name: "Último Evento",
      selector: (row) => row.fechaUltimoEvento,
      sortable: true,
    },
    {
      name: "Inmobiliaria",
      selector: (row) => row.inmobiliaria,
      sortable: true,
    },
    {
      name: "Asesor",
      selector: (row) => row.asesor,
      sortable: true,
    },
    {
      name: "Cliente",
      selector: (row) => row.cliente,
      sortable: true,
    },
    {
      name: "Lotes",
      cell: (row) => {
        if (row.lotes.length === 1) {
          return `${row.lotes[0].barrio.nombre} - ${row.lotes[0].numeroLote}`
        } else {
          return <div className="pt-3">{row.lotes.map(lote => <p>{lote.barrio.nombre} - {lote.numeroLote}</p>)}</div>
        }
      }
      ,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.estado,
      sortable: true,
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <Link to={row.url}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const arrayOriginal = operaciones.data.map((operacion) => {

      let url = '/operaciones';

      return {
        fechaAlta: moment(operacion.fechaAlta)
          .utcOffset(180)
          .format("DD-MM-YYYY"),
        cliente: `${operacion.getClientFullName}`,
        lotes: operacion.lote,
        numeroLote: operacion.lote.numeroLote,
        estado: operacion.estado.estado,
        button: true,
        url: `${url}/${operacion._id}`,
        inmobiliaria: operacion.inmobiliaria.nombre,
        asesor: `${operacion.usuario?.apellido || '<Sin Apellido>'}, ${operacion.usuario?.nombre || '<Sin Nombre>'}`,
        fechaUltimoEvento: moment(
          operacion.historico[operacion.historico.length - 1].fecha
        )
          .utcOffset(180)
          .format("DD-MM-YYYY"),
      };

    });
    setDataOriginal(arrayOriginal);
    setData(arrayOriginal);
  }, [url, operaciones]);

  const buscar = (e) => {
    const filtro = e.target.value.toLowerCase();
    let lotes = '';

    const resultado = dataOriginal.filter((data) => {
      data.lotes.forEach(lote => {
        lotes += `${lote.barrio.nombre} ${lote.numeroLote}`
      });
      if (data.cliente.toLowerCase().includes(filtro)) {
        return data;
      }
      if (lotes.toLowerCase().includes(filtro)) {
        return data;
      }
      if (data.estado.toLowerCase().includes(filtro)) {
        return data;
      }

      lotes = '';
    });

    setData(resultado);
  };

  const submit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <form onSubmit={submit}>
        <div className="form-group">
          <input
            placeholder="Buscar:"
            onChange={buscar}
            type="text"
            name="buscar"
            className="form-control"
          />
        </div>
      </form>

      <DataTable columns={columns} data={data} pagination />
    </>
  );
};
