import React from 'react';
import {Link} from "react-router-dom";

function NotFound(props) {
  return (
    <div className="content-wrapper mt-5 pt-5">
      <section className="content">
        <div className="error-page">
          <h2 className="headline text-warning">404</h2>
          <div className="error-content">
            <h3><i className="fas fa-exclamation-triangle text-warning"></i> Oops! Página no encontrada.</h3>
            <p>
              No pudimos encontrar el contenido que estás buscando
              Mientras tanto, podés <Link to={'/'}>regresar al inicio</Link>.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NotFound;