import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import { withRouter } from "react-router-dom";
import { get } from '../../../config/apiHelper';
import Spinner from '../../layout/Spinner';
import useCalendarStore from '../../../store/calendarioFirmasStore';

const CalendarioFirmas = ({ turnos = [], ...props }) => {
    const [operaciones, setOperaciones] = useState([]);
    const [loading, setLoading] = useState(true);
    const { currentDate, setCurrentDate } = useCalendarStore();

    const handleDatesSet = (dateInfo) => {
        // Solo actualiza si la fecha es diferente
        if (currentDate?.toISOString() !== dateInfo.start.toISOString()) {
            setCurrentDate(dateInfo.start);
        }
    };

    const getOperacions = async () => {
        try {
            const response = await get('/operaciones?campos=turnoFirma');
            setOperaciones(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const events = operaciones.map((operacion) => ({
        id: operacion._id,
        title: `${operacion.lote[0].barrio.nombre} - ${operacion.lote[0].numeroLote}`,
        start: operacion.turnoFirma,
        end: new Date(new Date(operacion.turnoFirma).getTime() + 60 * 60000),
    }));

    const handleEventClick = (clickInfo) => {
        props.history.push(`/operaciones/${clickInfo.event.id}`);
    };

    useEffect(() => {
        getOperacions();
    }, []);

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="card-title">Calendario de Firmas</h3>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <FullCalendar
                            events={events}
                            plugins={[timeGridPlugin, dayGridPlugin]}
                            initialView="timeGridWeek"
                            initialDate={currentDate || new Date()} // Usa la fecha guardada en Zustand
                            datesSet={handleDatesSet} // Sincroniza fechas visibles con Zustand
                            eventClick={handleEventClick}
                            slotDuration="00:30:00"
                            allDaySlot={false}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay',
                            }}
                            slotMinTime="08:00:00"
                            slotMaxTime="20:00:00"
                            locale={esLocale}
                            weekends={false}
                            hiddenDays={[0, 6]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(CalendarioFirmas);
