import React, { useState } from 'react';
import clienteAxios from '../../../../../config/axios';
import Swal from 'sweetalert2';

const CargarBoletoCompraventa = ({
  title = 'Subir documentación firmada',
  token,
  operacion,
  enviarOperacion,
  actualizarOperacion,
  ...props
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };


  const [archivo, setArchivo] = useState(null);
  const [nombre, setNombre] = useState('');
  const [validateFileName, setValidateFileName] = useState(false);


  const actualizarNombre = e => {
    setValidateFileName(e.target.value.length > 0);
    setNombre(e.target.value);
  }
  const actualizarArchivo = e => {
    setArchivo(e.target.files[0]);
  }


  const enviarArchivoAdjunto = async () => {

    const datosEnviar = new FormData();
    datosEnviar.append('nombre', nombre);
    datosEnviar.append('archivoAdjunto', archivo);
    datosEnviar.append('idOperacion', operacion._id);
    datosEnviar.append('formulario', props.formulario);

    try {
      const rta = await clienteAxios.post("/archivosAdjuntos", datosEnviar, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
        enviarOperacion();
        await actualizarOperacion();
      } else if (rta.data.estado === "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }

  }

  return (
    <>
      < div className="card-header">
        <h3 className="card-title">{title}</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>


      <div className={`collapse${isCollapsed ? '' : ' show'}`}>


        <div className="card-body">
          <div className="row">
            <div className="col form-group">
              <label>Nombre del Archivo:</label>
              <input type="text" name="nombre" onChange={actualizarNombre} required className="form-control" />
            </div>
          </div>
          <div className="row">
            <div className="col form-group">
              <label>Adjuntar:</label>
              <input type="file" name="archivoAdjunto" id="archivoAdjunto" onChange={actualizarArchivo}
                className="form-control" />
            </div>
          </div>

          <div className="modal-footer justify-content-between">
            <button type="button" className="btn btn-default" data-dismiss="modal">Cancelar</button>
            <button type="button" className="btn btn-primary" onClick={enviarArchivoAdjunto} data-dismiss="modal"
              disabled={!validateFileName}>Enviar
            </button>
          </div>

        </div>
        {/*<div className="row mt-3 mb-5">*/}
        {/*  <div className="col">*/}
        {/*    <button*/}
        {/*      className="btn btn-success float-right"*/}
        {/*      type="button"*/}
        {/*      onClick={enviarOperacion}*/}
        {/*    >*/}
        {/*      Confirmar*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </>
  )
}

export default CargarBoletoCompraventa;