import React, {useEffect, useState} from 'react';
import Select from "react-select";
import NumberFormat from "react-number-format";

function EntregasBoleto(props) {
  const {setCaracteristicasBoleto} = props;
  const [value, setValue] = useState(null);
  const [moneda, setMoneda] = useState(null);
  const [entregas, setEntregas] = useState([{
    moneda: null, valor: null
  }]);
  const values = [
    {
      label: 'Valor Negociado',
      value: 'valorNegociado',
    },
    {
      label: 'Valor Boleto',
      value: 'valorBoleto',
    },
    {
      label: 'Personalizado',
      value: 'personalizado',
    },
  ];

  const monedaOptions = [
    {
      label: 'Pesos',
      value: 'pesos'
    },
    {
      label: 'Dólares',
      value: 'dolares'
    },
  ];

  useEffect(() => {
    if (value === 'personalizado' && entregas.length === 0) {
      setEntregas([{
        moneda: null, valor: null
      }]);
    }
  }, [value])

  const addEntrega = () => {
    setEntregas([
      ...entregas,
      {
        moneda: null, valor: null
      }
    ]);
  }

  const updateEntrega = (index, key, value) => {
    const entregasCopy = [...entregas];
    entregasCopy[index][key] = value;
    setEntregas(entregasCopy);
  }

  const removeEntrega = (i) => {
    setEntregas(entregas.filter((entrega, index) => (index != i)));
  }

  useEffect(() => {
    const entregasBoleto = {
      moneda,
      value,
      entregas
    };
    setCaracteristicasBoleto('entregasBoleto', entregasBoleto);
  }, [moneda, value, entregas]);

  return (
    <div className='mb-4'>
      <div className="row">
        <label className="col-md-12">Entregas</label>
      </div>
      <div className="row ml-2">
        <div className="col-md-4">
          <label>Valor</label>
          <Select
            name='tipoValor'
            options={values}
            onChange={(e) => setValue(e.value)}
          />
        </div>
        <div className="col-md-4">
          <label>Moneda</label>
          <Select
            name='moneda'
            options={monedaOptions}
            onChange={(e) => setMoneda(e.value)}
            isDisabled={value === 'personalizado'}
          />
        </div>
      </div>
      {value === 'personalizado' ?
        entregas.map((entrega, index) => (
          <div className='row mt-3 ml-4' key={index}>
            <div className="col-md-4">
              <label>Moneda</label>
              <Select
                name='moneda'
                options={monedaOptions}
                value={monedaOptions.find(monedaOption => monedaOption.value === entrega.moneda) || ''}
                onChange={(e) => {
                  updateEntrega(index, 'moneda', e.value)
                }}
              />
            </div>
            <div className="col-md-3">
              <label>Valor Personalizado</label>
              <NumberFormat
                className="form-control"
                name='valor'
                decimalSeparator={','}
                thousandSeparator={'.'}
                prefix={entrega.moneda === 'pesos' ? '$ ' : 'u$s '}
                value={entrega.valor}
                onValueChange={(e) => updateEntrega(index, 'valor', e.floatValue)}
              />
            </div>
            <div className="col-md-1 mt-4">
              <button
                className="btn btn-primary float-right"
                type="button"
                onClick={addEntrega}
              >
                +
              </button>
            </div>
            <div className="col-md-1 mt-4">
              <button
                className="btn btn-danger float-right"
                type="button"
                disabled={entregas.length <= 1}
                onClick={() => removeEntrega(index)}
              >
                -
              </button>
            </div>
          </div>)) : null}

    </div>
  );
}

export default EntregasBoleto;