import React, { useEffect, useState } from 'react';
import { get, put } from '../../../config/apiHelper'; // Asegúrate de tener el método `put` para actualizar
import { Checkbox, Button, message, Spin } from 'antd'; // Usando Ant Design para el UI (puedes cambiarlo si prefieres otro)

export const AsignarDesarrolladoras = ({ barrio, setBarrio, consultarBarrio }) => {
  const [desarrolladoras, setDesarrolladoras] = useState([]);
  const [selectedDesarrolladoras, setSelectedDesarrolladoras] = useState([]);
  const [loading, setLoading] = useState(false);

  // Obtener la lista de todas las desarrolladoras (inmobiliarias)
  const getDesarrolladoras = async () => {
    try {
      setLoading(true);
      const response = await get('/inmobiliarias');
      setDesarrolladoras(response.data.sort((a, b) => a.nombre.localeCompare(b.nombre)));
      setLoading(false);
    } catch (error) {
      console.error(error);
      message.error('Error al cargar las desarrolladoras');
      setLoading(false);
    }
  };

  // Actualizar la lista de inmobiliarias asignadas al barrio
  const updateBarrioDesarrolladoras = async (newDesarrolladoras) => {
    try {
      const response = await put(`/barrios/${barrio._id}`, { accesoInmobiliarias: newDesarrolladoras });
      setBarrio(response.data);
      message.success('Desarrolladoras actualizadas correctamente');
    } catch (error) {
      console.error(error);
      message.error('Error al actualizar las desarrolladoras del barrio');
    }
  };

  // Cargar las inmobiliarias y preseleccionar las del barrio actual
  useEffect(() => {
    getDesarrolladoras();
  }, []);

  useEffect(() => {
    if (barrio && barrio.accesoInmobiliarias) {
      setSelectedDesarrolladoras(barrio.accesoInmobiliarias);
    }
  }, [barrio]);

  // Manejar la selección/deselección de desarrolladoras
  const handleCheckboxChange = (inmobiliariaId) => {
    setSelectedDesarrolladoras((prevSelected) => {
      if (prevSelected.includes(inmobiliariaId)) {
        // Si ya está seleccionada, se elimina
        return prevSelected.filter((id) => id !== inmobiliariaId);
      } else {
        // Si no está seleccionada, se agrega
        return [...prevSelected, inmobiliariaId];
      }
    });
  };

  // Agregar todas las desarrolladoras a la lista de seleccionadas
  const handleSelectAll = () => {
    const allIds = desarrolladoras.map((desarrolladora) => desarrolladora._id);
    setSelectedDesarrolladoras(allIds);
  };

  // Quitar todas las desarrolladoras de la lista de seleccionadas
  const handleDeselectAll = () => {
    setSelectedDesarrolladoras([]);
  };

  // Guardar los cambios de desarrolladoras asignadas al barrio
  const handleSave = () => {
    updateBarrioDesarrolladoras(selectedDesarrolladoras)
      .then(() => consultarBarrio())
  };

  return (
    <div>
      <h2>Asignar Desarrolladoras a: {barrio.nombre}</h2>
      {loading ? (
        <Spin tip="Cargando desarrolladoras..." />
      ) : (
        <div>
          <div style={{ marginBottom: '20px' }}>
            <Button type="primary" onClick={handleSelectAll} style={{ marginRight: '10px' }}>
              Agregar Todas
            </Button>
            <Button type="default" onClick={handleDeselectAll}>
              Quitar Todas
            </Button>
          </div>

          <div style={{ maxHeight: '400px', overflowY: 'auto', border: '1px solid #f0f0f0', padding: '10px' }}>
            {desarrolladoras.map((desarrolladora) => (
              <div key={desarrolladora._id} style={{ marginBottom: '10px' }}>
                <Checkbox
                  checked={selectedDesarrolladoras.includes(desarrolladora._id)}
                  onChange={() => handleCheckboxChange(desarrolladora._id)}
                >
                  {desarrolladora.nombre}
                </Checkbox>
              </div>
            ))}
          </div>

          <div style={{ marginTop: '20px' }}>
            <Button type="primary" onClick={handleSave}>
              Guardar Cambios
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
