import React, { useEffect, useState } from 'react';
import Select from "react-select";

import { get } from "../../../config/apiHelper";
import listadoOperacionesStore from "../../../store/listadoOperacionesStore";
// import { useDebounce } from '../../../hooks/useDebounce';



const OperacionesFiltros = ({ history, fetchApi }) => {
  const { filter, setFilter } = listadoOperacionesStore();

  // OBSERVACION: Se comenta el useDebouncer por no hacerse los requests con el useEffect[filter] en el componente ListadoOperaciones.jsx
  // const [numeroDeLote, setNumeroDeLote] = useState(null);
  // const debouncedNumeroDeLote = useDebounce(numeroDeLote, 750);

  // useEffect(() => {
  //   setFilter({ ...filter, numeroDeLote: debouncedNumeroDeLote });
  // }, [debouncedNumeroDeLote])


  const [estadosOperaciones, setEstadosOperaciones] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [desarrollos, setDesarrollos] = useState([]);

  const clientesOptions = clientes.map(cliente => ({ value: cliente._id, label: cliente.razonSocial || `${cliente.nombre.apellido}, ${cliente.nombre.nombre} - ${cliente.documentos.cuitCuil}` }));
  const desarrollosOptions = desarrollos.map(desarrollo => ({ value: desarrollo._id, label: desarrollo.nombre }));


  const getEstadosOperaciones = async () => {
    try {
      const response = await get("/estadosOperaciones");
      let estadosOperaciones = [];

      response.forEach((estado) => {
        if (estado.activo === 'true') {
          estadosOperaciones.push({
            value: estado._id,
            label: estado.estado,
            id: estado.id
          });

        }
      });

      estadosOperaciones.sort((a, b) => a.id - b.id)

      estadosOperaciones.unshift({
        value: null,
        label: 'Todas'
      })
      setEstadosOperaciones(estadosOperaciones);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  const getDesarrollos = async () => {
    try {
      const response = await get("/barrios");
      setDesarrollos(response);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }


  const getClientes = async () => {
    try {
      const response = await get("/clientes");
      setClientes(response.data);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      fetchApi();
    }
  }

  useEffect(() => {
    getEstadosOperaciones();
    getDesarrollos();
    getClientes();
  }, []);


  return (
    <div className="form-group">
      <label>Filtros:</label>
      <div className="row mb-3">
        <div className="col-md-3">
          <label>Fecha alta desde:</label>
          <input
            onKeyDown={handleEnter}
            type="date"
            value={filter.fechaAltaDesde || ''}
            onChange={e => setFilter({ ...filter, fechaAltaDesde: e.target.value })}
            placeholder="Fecha alta desde"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Fecha alta hasta:</label>
          <input
            onKeyDown={handleEnter}
            type="date"
            value={filter.fechaAltaHasta}
            onChange={e => setFilter({ ...filter, fechaAltaHasta: e.target.value })}
            placeholder="Fecha alta hasta"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Estado:</label>
          <Select
            onKeyDown={handleEnter}
            options={estadosOperaciones}
            value={estadosOperaciones.find(estado => estado.value === filter.estado)}
            onChange={e => setFilter({ ...filter, estado: e.value })}
          />
        </div>
        <div className="col-md-3">
          <label>Desarrollo:</label>
          <Select
            onKeyDown={handleEnter}
            options={desarrollosOptions}
            value={desarrollosOptions.filter(desarrollo => filter.barrio?.split(',').includes(desarrollo.value))}
            onChange={e => setFilter({ ...filter, barrio: e.map(e => e.value).join(',') })}
            isMulti={true}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-5">
          <label>Cliente:</label>
          <Select
            onKeyDown={handleEnter}
            options={clientesOptions}
            onChange={e => setFilter({ ...filter, cliente: e.map(e => e.value).join(',') })}
            value={clientesOptions.filter(cliente => filter.cliente?.split(',').includes(cliente.value))}
            isMulti={true}
          />
        </div>
        <div className="col-md-3">
          <label>Número de lote:</label>
          <input
            onKeyDown={handleEnter}
            type="text"
            value={filter.numeroDeLote}
            onChange={e => setFilter({ ...filter, numeroDeLote: e.target.value })}
            // value={numeroDeLote || filter.numeroDeLote}
            // onChange={e => setNumeroDeLote(e.target.value)}
            placeholder="Número de lote"
            className="form-control"
          />
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-3 mt-4 pl-3 pr-3">
          <button className="btn btn-block btn-success float-right" onClick={fetchApi}>Consultar</button>
        </div>
      </div>
    </div>
  );
};


export default OperacionesFiltros;
