import React, { useContext, useEffect } from "react";
import { CRMContext } from "../../context/CRMContext";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";


const Dashboard = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  if(!token) {
    props.history.push('/login');
  }
  const rol = localStorage.getItem('rol');

  useEffect(() => {
    const consultarAPI = async () => {
      try {
        await clienteAxios.get(`/menu/${rol}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
        console.log(error)
      }
    };
    if (rol) {
      consultarAPI()
    } else {
      localStorage.clear();
      props.history.push("/login");
    }

  });

  return (
    <>

    </>
  );
};

export default withRouter(Dashboard);
