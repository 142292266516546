import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";

import Spinner from "../layout/Spinner";

import { ListadoOperacionesComp } from "./components/ListadoOperacionesComp";
import { ConfOperacionesComp } from "./components/ConfOperacionesComp";

function ListadoOperaciones(props) {
  const [operaciones, guardarOperaciones] = useState({
    loading: true,
    operaciones: [],
  });
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const consultarAPI = async () => {
    try {
      const operacionesConsulta = await clienteAxios.get(
        `/operaciones?campos=fechaAlta,historico,cliente,barrio,lote,estado,usuario&estadoOperacion=3,7&sort=id,-1&limit=0`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      guardarOperaciones({
        loading: false,
        operaciones: operacionesConsulta.data,
      });
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    consultarAPI();
  }, []);

  if (operaciones.loading) {
    return <Spinner />;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title mt-2">Confirmar Operaciones</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className="card-body">
        <ConfOperacionesComp operaciones={operaciones.operaciones} url={"/operaciones"} />
      </div>
    </div>
  );
}

export default withRouter(ListadoOperaciones);
