import React, { useEffect, useState } from 'react'
import Select from "react-select";
import clienteAxios from '../../../config/axios';
import DataTable from 'react-data-table-component';

export const SelectLotes = (props) => {

  const { token, lotesSeleccionados, setLotesSeleccionados } = props
  const [barrios, guardarBarrios] = useState([]);
  const [lotes, guardarLotes] = useState([]);

  const formatter = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  useEffect(() => {
    consultarBarrios();
  }, [])

  const consultarBarrios = async () => {
    try {
      const barriosConsulta = await clienteAxios.get(
        "/barrios?activo=true&campos=nombre,_id",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      guardarBarrios(
        barriosConsulta.data.map((barrio) => {
          return {
            label: barrio.nombre,
            value: barrio._id,
          };
        })
      );
    } catch (error) {
      props.history.push("/login");
      console.log(error);
      localStorage.clear();
    }
  };

  const consultarLotes = async (e) => {
    const id = e.value;

    guardarLotes([]);

    try {
      const lotesConsulta = await clienteAxios.get(`/lotes?barrio=${id}&estado=0&sort=numeroLote,1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      guardarLotes(
        lotesConsulta.data.data.map((lote) => {
          let loteInversor = '';
          if (lote.motivoDisponibilidad && lote.motivoDisponibilidad.id === 3) {
            loteInversor = " (Inversor)";
          }
          return {
            value: lote._id,
            label: `Lote nro: ${lote.numeroLote} - Precio: ${formatter.format(lote.precioLista)}${loteInversor}`,
            numeroLote: `Lote nro: ${lote.numeroLote}${loteInversor}`,
            precioLista: lote.precioLista,
            barrio: lote.barrio.nombre,
            restoInfo: { ...lote }
          };
        })
      );
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
    }
  };

  const columns = [
    {
      name: 'Desarrollo',
      selector: (row) => row.barrio,
      sortable: true,
      width: 'auto',
    },
    {
      name: 'Lote',
      selector: (row) => row.lote,
      sortable: true,
      width: 'auto',
    },
    {
      name: 'Manzana',
      selector: (row) => row.restoInfo.numeroManzana,
      sortable: true,
      width: 'auto',
    },
    {
      name: 'm2',
      selector: (row) => row.restoInfo.superficie,
      sortable: true,
      width: 'auto',
    },
    {
      name: 'Precio',
      selector: (row) => formatter.format(row.precioLista),
      sortable: true,
      width: 'auto',
    },
    {
      name: 'Eliminar',
      button: true,
      width: 'auto',
      cell: (row) => (
        <button onClick={() => deleteLoteSeleccionado(row.id)} className="btn-danger btn">X</button>
      )
    }
  ];

  const deleteLoteSeleccionado = (idLoteBorrar) => {
    const nuevosLotesSeleccionados = lotesSeleccionados.filter(lote => {
      return lote.id !== idLoteBorrar;
    });

    setLotesSeleccionados(nuevosLotesSeleccionados);
  }

  const guardarLote = (e) => {
    const id = e.value;
    const lote = e.numeroLote;
    const precioLista = e.precioLista;
    const barrio = e.barrio;
    const restoInfo = e.restoInfo;
    const loteYaSeleccionado = lotesSeleccionados.some(lot => lot.id === id);
    if (loteYaSeleccionado) {
      return;
    }
    const lotes = 
    setLotesSeleccionados([
      ...lotesSeleccionados,
      {
        id,
        lote,
        precioLista,
        barrio,
        restoInfo
      }
    ]);
  };



  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-12">
              <h3 className="card-title">Seleccionar los Lotes</h3>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <label className="col-md-12">Seleccionar Desarrollo</label>
            <div className="form-group col-md-6">
              <Select
                name="barrio"
                onChange={consultarLotes}
                options={barrios}
              />
            </div>
          </div>
          {
            (lotes.length > 0 || lotesSeleccionados.length > 0) && (
              <>
                <div className="row">
                  <label className="col-md-12">Seleccionar Lote</label>
                  <div className="form-group col-md-6">
                    <Select
                      name="lote"
                      options={lotes}
                      onChange={guardarLote}
                    />
                  </div>
                </div>
                {lotesSeleccionados.length > 0 &&
                  <div className="form-group">
                    <div className="row">
                      <label>Lotes Seleccionados:</label>
                      <DataTable columns={columns} data={lotesSeleccionados} responsive />
                    </div>
                  </div>
                }
              </>
            )
          }
        </div>
      </div>
    </>
  )
}
