import React, { useState } from 'react';

const MostrarGastosDeOriginacion = ({ operacion }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <>
      <div className='card-header mb-3'>
        <h4 className='card-title'>Gastos de Originación</h4>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">

          <div className='row'>
            <div className='form-group col-md-6'>
              <label htmlFor='exampleInputEmail1'>
                Comisión inmobiliaria ({operacion.porcentajeComisionInmobiliaria || ''}%)
              </label>
              <p>
                {operacion.comisionInmobiliaria && new Intl.NumberFormat("es-AR", {
                  currency: "ARS",
                  style: "currency",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(operacion.comisionInmobiliaria)}
              </p>
            </div>

            <div className='form-group col-md-6'>
              <label htmlFor='exampleInputEmail1'>Gastos escribanía</label>
              <p>
                {new Intl.NumberFormat("es-AR", {
                  currency: "ARS",
                  style: "currency",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(operacion.gastosEscribania)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MostrarGastosDeOriginacion