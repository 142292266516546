import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { CRMContext } from "../../context/CRMContext";
import clienteAxios from "../../config/axios";
import Spinner from "../layout/Spinner";
import Swal from "sweetalert2";

const ExportarLotes = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [barrios, setBarrios] = useState([]);
  const [barrioSeleccionado, setBarrioSeleccionado] = useState();

  useEffect(() => {
    const getBarriosAPI = async () => {
      try {
        const barriosAPI = await clienteAxios.get("/barrios?activo=true", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setBarrios(
          barriosAPI.data.map((barrio) => {
            return {
              value: barrio._id,
              label: barrio.nombre,
            };
          })
        );
      } catch (error) {
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
        console.log(error);
      }
    };

    getBarriosAPI();
  }, []);

  const selectBarrio = (e) => {
    setBarrioSeleccionado(e.value);
  };

  if (barrios.length == 0) {
    return <Spinner />;
  }

  const enviarArchivo = async (e) => {
    e.preventDefault();

    try {
      const rta = await clienteAxios.get(`/lotes/CSV/${barrioSeleccionado}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([rta.data]));
      const link = document.createElement("a");
      link.href = url;

      // Obtener el nombre del barrio para colocar en el archivo a descargar
      const barrio = barrios.find( barrio => {
          return barrio.value == barrioSeleccionado
      }).label;


      link.setAttribute("download", `lotes-${barrio}.csv`);
      document.body.appendChild(link);
      link.click();


      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Exportar Lotes</h3>
        </div>
        <div className="card-body">
          <form onSubmit={enviarArchivo}>
            <div className="row">
              <div className="col-md-12 orm-group">
                <label>
                  Seleccionar Desarrollo
                </label>
                <Select
                  placeholder="--- Seleccionar ---"
                  options={barrios}
                  onChange={selectBarrio}
                />
              </div>
            </div>

            <div className="card-footer">
              <button type="submit" className="float-right btn btn-primary">
                Seleccionar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ExportarLotes;
