import React, { useContext, useEffect, useState } from 'react'
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';
import { useForm } from 'react-hook-form';

export const FormularioCategoria = (props) => {

  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const { register, handleSubmit } = useForm();

  const submit = async (data) => {
    try {
      const response = await clienteAxios.post(`/api/documentacion/categorias`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        props.history.push('/documentacion/categorias/listado');
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title mt-2">Categorías</h3>
          </div>
          <div className="col-md-4">
            <input type="button" value="Volver" className="btn btn-primary mr-3 float-right" onClick={props.history.goBack} />
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label>Categoria</label>
                <input type="text" name="nombre" className="form-control" {...register("nombre")} />
              </div>
              <input type="submit" value="Guardar" className="btn btn-primary" />
            </form>
          </div>
        </div>
      </div>
      <div className="card-footer"></div>
    </div>
  )
}
