import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";

export const ListadoOperacionesComp = ({ operaciones, url }) => {
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);

  const columns = [
    {
      name: "Fecha Alta",
      selector: (row) => row.fechaAlta,
      sortable: true,
    },
    {
      name: "Desarrollo",
      selector: (row) => row.barrio,
      sortable: true,
    },
    {
      name: "Número de Lote",
      selector: (row) => row.numeroLote,
      sortable: true,
    },
    {
      name: "Valor",
      selector: (row) => row.valor,
      sortable: true,
    },
    {
      name: "Usuario",
      selector: (row) => row.usuario,
      sortable: true,
    },  
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <Link to={row.url}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const arrayOriginal = operaciones.map((operacion) => {
      return {
        fechaAlta: moment(operacion.fecha)
          .utcOffset(180)
          .format("DD-MM-YYYY"),
        barrio: operacion.lote.barrio.nombre,
        numeroLote: operacion.lote.numeroLote,
        valor: new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS', maximumFractionDigits: 0 }).format(operacion.valor),
        button: true,
        url: `${url}/${operacion._id}`,
        usuario: `${operacion.usuario.apellido}, ${operacion.usuario.nombre}`
      };
    });
    setDataOriginal(arrayOriginal);
    setData(arrayOriginal);
  }, [url, operaciones]);

  const buscar = (e) => {
    let filtro = e.target.value.toLowerCase();

    const resultado = dataOriginal.filter((data) => {
      if (data.cliente.toLowerCase().includes(filtro)) {
        return data;
      }
      if (data.barrio.toLowerCase().includes(filtro)) {
        return data;
      }
      if (data.numeroLote.toString().includes(filtro)) {
        return data;
      }
      if (data.estado.toLowerCase().includes(filtro)) {
        return data;
      }
    });

    setData(resultado);
  };

  const submit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <form onSubmit={submit}>
        <div className="form-group">
          <input
            placeholder="Buscar:"
            onChange={buscar}
            type="text"
            name="buscar"
            className="form-control"
          />
        </div>
      </form>

      <DataTable columns={columns} data={data} pagination />
    </>
  );
};
