// apiHelper.js
import clienteAxios from './axios';

export const get = async (url, params = {}) => {
  const response = await clienteAxios.get(url, { params });
  return response.data;
};

export const post = async (url, data) => {
  const response = await clienteAxios.post(url, data);    return response.data;
};
export const put = async (url, data) => {
  const response = await clienteAxios.put(url, data);
  return response.data;
};

export const del = async (url) => {
  const response = await clienteAxios.delete(url);
  return response.data;
};