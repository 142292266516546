import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form';
import { get, post } from '../../../config/apiHelper';
import Spinner from '../../layout/Spinner';


export const FormularioCrearLote = (props) => {
  const { history } = props;

  const [desarrollos, setDesarrollos] = useState([]);
  const [estadosLotes, setEstadosLotes] = useState([]);
  const [motivoDisponibilidad, setMotivoDisponibilidad] = useState([]);
  const [tipologias, setTipologias] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const { register, handleSubmit } = useForm();

  /*
    Obtener el listado de:
      Desarrollos
      EstadosLotes
      Motivos Disponibilidad
      Tipologías
  */

  const getDesarrollos = async () => {
    const response = await get('/barrios?campos=nombre');
    setDesarrollos(
      response.map(option => ({
        value: option._id,
        label: option.nombre
      }))
    );
  }

  const getEstadosLotes = async () => {
    const response = await get('/estadosLotes');
    setEstadosLotes(
      response.map(option => ({
        value: option._id,
        label: option.estado
      }))
    );
  }

  const getMotivosDisponibilidad = async () => {
    const response = await get('/motivoDisponibilidad');
    setMotivoDisponibilidad(
      response.data.map(option => ({
        value: option._id,
        label: option.nombre
      }))
    );
  }

  const getTipologias = async () => {
    const response = await get('/tipologias');
    setTipologias(
      response.map(option => ({
        value: option._id,
        label: option.tipo
      }))
    );
  }


  const submit = async (data) => {
    try {
      setShowSpinner(true);
      const response = await post('/lote', data);
      if (response.estado === 'ok') {
        history.push(`/lotes/${data.barrio}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowSpinner(false);
    }
  }

  useEffect(() => {
    getDesarrollos();
    getEstadosLotes();
    getMotivosDisponibilidad();
    getTipologias();
  }, []);


  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title mt-2">Agregar Lote</h3>
          </div>
          <div className="col-md-4">
            <button className="btn btn-primary float-right" onClick={history.goBack}>Volver</button>
          </div>
        </div>
      </div>
      {showSpinner ? <Spinner /> :
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Desarrollo</label>
                <select
                  required
                  name="barrio"
                  className="form-control"
                  {...register("barrio")}
                >
                  <option value="">Seleccionar</option>
                  {desarrollos.sort((a, b) => (a.label < b.label ? -1 : 1)).map(option => (<option key={option.value} value={option.value}>{option.label}</option>))}
                </select>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>Estado</label>
                  <select
                    required
                    name="estado"
                    className="form-control"
                    {...register("estado")}
                  >
                    <option value="">Seleccionar</option>
                    {estadosLotes.sort((a, b) => (a.label < b.label ? -1 : 1)).map(option => (<option key={option.value} value={option.value}>{option.label}</option>))}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Motivo Disponibilidad</label>
                  <select
                    required
                    name="motivoDisponibilidad"
                    className="form-control"
                    {...register("motivoDisponibilidad")}
                  >
                    <option value="">Seleccionar</option>
                    {motivoDisponibilidad.sort((a, b) => (a.label < b.label ? -1 : 1)).map(option => (<option key={option.value} value={option.value}>{option.label}</option>))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>Tipología</label>
                  <select
                    required
                    name="tipologia"
                    className="form-control"
                    {...register("tipologia")}
                  >
                    <option value="">Seleccionar</option>
                    {tipologias.sort((a, b) => (a.label < b.label ? -1 : 1)).map(option => (<option key={option.value} value={option.value}>{option.label}</option>))}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Es lote inversor</label>
                  <select
                    required
                    name="isInversor"
                    className="form-control"
                    {...register("isInversor")}
                  >
                    <option value={false}>No</option>
                    <option value={true}>Si</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>Precio de Lista</label>
                  <input
                    required
                    type="number"
                    name="precioLista"
                    className="form-control"
                    {...register("precioLista")}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Número de Manzana:</label>
                  <input
                    required
                    type="number"
                    name="numeroManzana"
                    className="form-control"
                    {...register("numeroManzana")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>Número de Lote:</label>
                  <input
                    required
                    type="number"
                    name="numeroLote"
                    className="form-control"
                    {...register("numeroLote")}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Superficie:</label>
                  <input
                    required
                    type="number"
                    name="superficie"
                    className="form-control"
                    {...register("superficie")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button type="submit" className="btn btn-primary float-right mt-3">Guardar</button>
              </div>
            </div>
          </form>
        </div>
      }
      <div className="card-footer"></div>
    </div >
  )
}
