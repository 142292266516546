import React, { useContext, useEffect, useState } from 'react'
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';
import { useForm } from 'react-hook-form';
import Spinner from '../layout/Spinner';

export const FormularioEditarEstado = (props) => {
  const { history } = props;
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [estado, setEstado] = useState({});
  const { register, handleSubmit, reset } = useForm();

  const getEstado = async () => {
    try {
      const estadoDb = await clienteAxios.get(`/estadosOperaciones/${props.match.params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEstado(estadoDb.data);
      reset({
        estado: estadoDb.data.estado,
        activo: estadoDb.data.activo,
        id: estadoDb.data.id,
      });
    }
    catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  const submit = async (data) => {
    try {
      const response = await clienteAxios.put(`/estadosOperaciones/${props.match.params.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.msg === 'ok') {
        history.push('/estadosOperaciones/listado');
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getEstado();
  }, []);

  console.log(estado)

  if (!Object.keys(estado).length) {
    return <Spinner />
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title">Editar Estado</h3>
          </div>
          <div className="col-md-4">
            <button className="btn btn-primary float-right" onClick={history.goBack}>Volver</button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label>Estado</label>
                <input
                  type="text"
                  name="estado"
                  className="form-control"
                  {...register("estado")}
                />
              </div>
              <div className="form-group">
                <label>Id</label>
                <input
                  type="number"
                  name="estado"
                  className="form-control"
                  {...register("id")}
                />
              </div>
              <div className="form-group">
                <label>Activo</label>
                <select
                  name="activo"
                  className="form-control"
                  {...register("activo")}
                >
                  <option value={true}>Sí</option>
                  <option value={false}>No</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary float-right mt-3">Guardar</button>
            </form>
          </div>
        </div>
      </div>
      <div className="card-footer"></div>
    </div >
  )
}
