class AnticiposUtils {

  static getEntregas(operacion) {
    const { entregas } = operacion.formaDePago;
    let totalEntregas = 0;
    if(entregas && Array.isArray(entregas)) {
      entregas.forEach( entrega => {
        totalEntregas += entrega.referencia.valores.pesosPropuesto;
      });
    }
    return totalEntregas;
  }

  static getAnticipoEfectivo(operacion) {
    const {anticipo} = operacion.formaDePago;
    let totalAnticipos = 0;
    anticipo.efectivo.forEach(efectivo => {
      if (efectivo.moneda === 'ars') {
        totalAnticipos += efectivo.monto;
      } else if (efectivo.moneda === 'usd') {
        totalAnticipos += efectivo.monto * efectivo.cambioSugerido;
      }
    });
    return totalAnticipos;
  }

  static getAnticipoDocumentos(operacion) {
    const {anticipo} = operacion.formaDePago;
    let totalAnticipos = 0;
    anticipo.documentos.forEach(documentos => {
      if (documentos.moneda === 'ars') {
        totalAnticipos += documentos.monto;
      } else if (documentos.moneda === 'usd') {
        totalAnticipos += documentos.monto * documentos.cambioSugerido;
      }
    });
    return totalAnticipos;
  }

  static getAnticipoInmuebles(operacion) {
    const {anticipo} = operacion.formaDePago;
    let totalAnticipos = 0;
    totalAnticipos += anticipo.inmueble.valores.pesosPropuesto;
    return totalAnticipos;
  }

  static getAnticipoLotes(operacion) {
    const {anticipo} = operacion.formaDePago;
    let totalAnticipos = 0;
    anticipo.lotes.forEach(lote => {
      totalAnticipos += lote.valores.pesosPropuesto;
    });
    return totalAnticipos;
  }

  static getAnticipoVehiculos(operacion) {
    const {anticipo} = operacion.formaDePago;
    return anticipo.vehiculo.vehiculo.valorSugerido;
  }

  static getAnticiposAsEntregasArray(operacion) {
    const {anticipo, vencimientosPuntuales} = operacion.formaDePago;
    const {efectivo, inmueble, lotes, vehiculo} = anticipo;

  }

}

export default AnticiposUtils;