import React, { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ClientesFiltros from "../clientes/utils/filter";

const ListadoInmobiliarias = (props) => {
  const [inmobiliarias, guardarInmobiliarias] = useState([]);
  const [inmobiliariasOriginal, setInmobiliariasOriginal] = useState([]);
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  let paginator = {
    page: 1,
    limit: 10
  };
  const [totalRows, setTotalRows] = useState(0);
  const [query, setQuery] = useState('');

  const consultarAPI = async () => {
    try {
      const inmobiliariasDB = await clienteAxios.get(`/inmobiliarias?page=${paginator.page - 1}&limit=${paginator.limit}'&sort=nombre,1&${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const arrayOriginal = inmobiliariasDB.data.data.map((inmobiliaria) => {
        return {
          nombre: inmobiliaria.nombre,
          direccion: inmobiliaria.direccion,
          telefono: inmobiliaria.telefono,
          estado: inmobiliaria.activo ? "Activa" : "Inactiva",
          url: `/configuracion/inmobiliarias/editar/${inmobiliaria._id}`,
        };
      });
      setTotalRows(inmobiliariasDB.data.totalCount);
      guardarInmobiliarias(arrayOriginal);
      setInmobiliariasOriginal(arrayOriginal);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    consultarAPI();
  }, [query]);

  // Datatables
  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Dirección",
      selector: (row) => row.direccion,
      sortable: true,
    },
    {
      name: "Teléfono",
      selector: (row) => row.telefono,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.estado,
      sortable: true,
      width: '10%'
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <Link to={row.url}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
      width: '10%'
    },
  ];

  if (inmobiliariasOriginal.length === 0) {
    return <Spinner />;
  }

  // Configuracion del datatable
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const dataExport = inmobiliarias.map(inmobiliaria => {
    return {
      "Nombre": inmobiliaria.nombre,
      "Dirección": inmobiliaria.direccion,
      "Teléfono": inmobiliaria.telefono,
      "Estado": inmobiliaria.estado,
    }
  })

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'data.xlsx');
  };

  const handlePerRowsChange = async (limit) => {
    paginator = {
      ...paginator,
      limit,
    };
    consultarAPI();
  }

  const handlePageChange = async (page) => {
    paginator = {
      ...paginator,
      page,
    };
    consultarAPI();
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title mt-2">INMOBILIARIAS</h3>
          </div>
          <div className="col-md-2">
            <button className="btn btn-block btn-success float-right mb-2" onClick={exportToExcel}>Exportar</button>
          </div>
          <div className="col-md-2">
            <Link to={`/configuracion/inmobiliarias/nueva`}>
              <button type="button" className="btn btn-block btn-success">
                Agregar Inmobiliaria
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body">
        <ClientesFiltros
          setQuery={setQuery}
        />
        <DataTable
          columns={columns}
          data={inmobiliarias}
          pagination
          paginationServer
          paginationComponentOptions={paginationComponentOptions}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationTotalRows={totalRows}
          paginationDefaultPage={paginator.page}
          paginationDefaultRowsPerPage={paginator.limit}
        />
      </div>
    </div>
  );
};

export default withRouter(ListadoInmobiliarias);
