import React, { useContext, useEffect, useState } from 'react'
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';

export const ListadoMotivosDisponibilidad = ({ history, ...props }) => {

  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [motivoDisponibilidad, setMotivoDisponibilidad] = useState([]);

  const getData = async () => {
    try {
      const response = await clienteAxios.get("/motivoDisponibilidad", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMotivoDisponibilidad(response.data.data);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  console.log(motivoDisponibilidad)

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-10">
              <h3 className="card-title">Estados de operaciones</h3>
            </div>

            <div className="col-md-1">
              <button className="btn btn-primary"
                onClick={() => history.push(`/motivoDisponibilidad/nuevo`)}
              >Nuevo</button>
            </div>
            <div className="col-md-1">
              <button className="btn btn-primary float-right" onClick={history.goBack}>Volver</button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nobre</th>
                    <th>Activo</th>
                    <th>Seleccionable Usuario</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {motivoDisponibilidad.map(motivo => (
                    <tr key={motivo._id}>
                      <td>{motivo.id}</td>
                      <td>{motivo.nombre}</td>
                      <td>{motivo.disponible === true ? 'Sí' : 'No'}</td>
                      <td>{motivo.seleccionableUsuario === true ? 'Sí' : 'No'}</td>
                      <td>
                        <button className="btn btn-primary"
                          onClick={() => history.push(`/motivoDisponibilidad/editar/${motivo._id}`)}
                        >Editar</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </>
  )
}
