import React, {useEffect, useContext, useState} from "react";
import DataTable from "react-data-table-component";
import {Link, withRouter} from "react-router-dom";
import clienteAxios from "../../config/axios";
import {CRMContext} from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import * as XLSX from "xlsx";
import {saveAs} from 'file-saver';
import UsuariosFiltros from "./utils/filter";

const ListadoUsuarios = (props) => {
  const [usuarios, guardarUsuarios] = useState([]);
  const [usuariosOriginal, setUsuariosOriginal] = useState([]);
  const [auth, guardarAuth] = useContext(CRMContext);
  const [loading, setLoading] = useState(true);
  let token = auth.token || localStorage.getItem("token");
  let paginator = {
    page: 1,
    limit: 10
  };
  const [totalRows, setTotalRows] = useState(0);
  const [query, setQuery] = useState('');

  const consultarAPI = async () => {
    setLoading(true);
    try {
      const usuariosDB = await clienteAxios.get(`/usuarios?${query}&page=${paginator.page - 1}&limit=${paginator.limit}&sort=apellido,1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const arrayOriginal = usuariosDB.data.data.map((usuario) => {
        return {
          nombreCompleto: `${usuario.apellido}, ${usuario.nombre}`,
          email: usuario.email,
          rol: usuario.rol.nombre,
          inmobiliaria: usuario.inmobiliaria?.nombre || "-",
          url: `/configuracion/usuarios/editar/${usuario._id}`,
          estado: usuario.activo ? "Activo" : "Inactivo",
        };
      });
      setTotalRows(usuariosDB.data.totalCount);
      guardarUsuarios(arrayOriginal);
      setUsuariosOriginal(arrayOriginal);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };
  // Consultar a la API para obtener los datos
  useEffect(() => {
    consultarAPI();
  }, [query]);

  // Datatables
  const columns = [
    {
      name: "Nombre Completo",
      selector: (row) => row.nombreCompleto,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Rol",
      selector: (row) => row.rol,
      sortable: true,
    },
    {
      name: "Inmobiliaria",
      selector: (row) => row.inmobiliaria,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.estado,
      sortable: true,
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <Link to={row.url}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
    },
  ];


  const data = usuarios.map(usuario => {
    return {
      "Nombre Completo": usuario.nombreCompleto,
      "Email": usuario.email,
      "Rol": usuario.rol,
      "Inmobiliaria": usuario.inmobiliaria,
    }
  })

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    saveAs(blob, 'data.xlsx');
  };

  const handlePerRowsChange = async (limit) => {
    paginator = {
      ...paginator,
      limit,
    };
    consultarAPI();
  }

  const handlePageChange = async (page) => {
    paginator = {
      ...paginator,
      page,
    };
    consultarAPI();
  }

  // Configuracion del datatable
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  if(loading) {
    return <Spinner />
  }


  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-8">
              <h3 className="card-title mt-2">USUARIOS</h3>
            </div>
            <div className="col-md-2">
              <button className="btn btn-block btn-success" onClick={exportToExcel}>Exportar</button>
            </div>
            <div className="col-md-2">
              <Link to={`/configuracion/usuarios/nuevo`}>
                <button type="button" className="btn btn-block btn-success">
                  Nuevo Usuario
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body">
          <UsuariosFiltros
            setQuery={setQuery}
          />
          <DataTable
            columns={columns}
            data={usuarios}
            pagination
            paginationServer
            paginationComponentOptions={paginationComponentOptions}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            paginationDefaultPage={paginator.page}
            paginationDefaultRowsPerPage={paginator.limit}
          />
        </div>
      </div>
    </>
  );
};

export default ListadoUsuarios;
