import React, { useContext, useEffect, useState } from "react";

import { CRMContext } from "../../../context/CRMContext";
import clienteAxios from "../../../config/axios";
import Spinner from "../../layout/Spinner";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { SelectTipoDni } from "../../clientes/components/SelectTipoDni";
import moment from "moment";
import SelectProvinciaMunicipio from "../../../shared/SelectProvinciaMunicipio";
import { GetPersonaByCuil } from "../../utils/getPersonaByCuil";

const FormularioUsuario = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  const [roles, setRoles] = useState([]);
  const [inmobiliarias, setInmobiliarias] = useState([]);
  const [escribanias, setEscribanias] = useState([]);
  const [inmobiliariaAdmin, setInmobiliariaAdmin] = useState({});
  const [showInmobiliaria, setShowInmobiliaria] = useState(false);
  const [showEscribania, setShowEscribania] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false);

  let token = auth.token || localStorage.getItem("token");
  const rolId = localStorage.getItem('rolId');
  // Obtener Roles disponibles de la API
  const consultaRol = async () => {
    try {
      const rolesDB = await clienteAxios.get("/roles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Convertir el array obtenido de la API en uno compatible con el componente Select
      setRoles(
        rolesDB.data.map((rol) => {
          return {
            id: rol.id,
            label: rol.nombre,
            value: rol._id
          };
        })
      );

    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  // Obtener las escribanias disponibles de la API
  const consultarEscribanias = async () => {
    try {
      const escribaniasDB = await await clienteAxios.get(
        "/escribania?limit=0&sort=nombre,1&estado=true",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEscribanias(escribaniasDB.data.data.map(escribania => ({
        label: escribania.nombre,
        value: escribania._id
      })));
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }


  // Obtener inmobiliarias disponibles de la API
  const consultaInmobiliarias = async () => {
    try {
      const inmobiliariasDB = await clienteAxios.get(
        "/inmobiliarias?limit=0&sort=nombre,1&estado=true",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Se guarda en el state la inmobiliaria de los admins y operadores de Life
      const inmobiliariasNoAdmin = [];
      inmobiliariasDB.data.data.forEach(inmobiliaria => {
        if (inmobiliaria.admin) {
          setInmobiliariaAdmin(inmobiliaria);
        } else {
          inmobiliariasNoAdmin.push(inmobiliaria);
        }
      });

      setInmobiliarias(
        inmobiliariasNoAdmin.map((inmobiliaria) => {
          return {
            value: inmobiliaria,
            label: inmobiliaria.nombre,
          };
        })
      );

    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };
  useEffect(() => {
    consultaInmobiliarias();
    consultarEscribanias();
    consultaRol();
  }, []);


  // Manejadores de los selects
  // TODO -> Mejorar la logica de esta funcion
  const handleSelectRol = (e) => {
    if(parseInt(e.id) === 5) {
      setShowEscribania(true);
    } else {
      setShowEscribania(false);
    }
    if (parseInt(e.id) === 3 || parseInt(e.id) === 4 || parseInt(e.id) === 5) {
      setShowInmobiliaria(false);
      props.setUsuario({
        ...props.usuario,
        rol: e.value,
        inmobiliaria: inmobiliariaAdmin
      });
    } else {
      setShowInmobiliaria(true);
      props.actualizarState({
        target: {
          value: e.value,
          name: "rol",
        }
      });
    }
  };

  const handleSelectInmobiliaria = (e) => {
    props.actualizarState({
      target: {
        value: e.value,
        name: "inmobiliaria",
      },
    });
  };
  const handleSelectEscribania = (e) => {
    props.actualizarState({
      target: {
        value: e.value,
        name: "escribania",
      },
    });
  };

  // Fecha de nacimiento del usuario si existente
  let fechaNacimiento = "";
  if (props.usuario.fechaNacimiento != undefined) {
    fechaNacimiento = props.usuario.fechaNacimiento;
  }


  const handlerUsuarioActivo = (e) => {
    props.actualizarState({
      target: {
        value: e.target.checked,
        name: e.target.name
      }
    });
  }

  const updateState = (e) => {
    props.actualizarStateV2(e);
  }

  // Spinner hasta que obtenga respuestas de la API
  if (
    roles.length === 0 ||
    // inmobiliarias.length === 0 ||
    props.usuario?.loading
  ) {
    return <Spinner />;
  }

  const setterFunction = (personaApi) => {
    let state = {};
    if (personaApi) {
      const { nombre, documentos, domicilio, fechaNacimiento, razonSocial, fechaContratoSocial } = personaApi;
      if (nombre) {
        Object.keys(nombre).forEach(key => {
          state = {
            ...state,
            [key]: nombre[key]
          };
        });
      }
      if (razonSocial) {
        state = {
          ...state,
          razonSocial
        }
      }
      Object.keys(documentos).forEach(key => {
        state = {
          ...state,
          [key]: documentos[key]
        };
      });
      Object.keys(domicilio).forEach(key => {
        state = {
          ...state,
          [key]: domicilio[key]
        };
      });
      if (fechaNacimiento) {
        state = {
          ...state,
          fechaNacimiento,
        }
      } else {
        state = {
          ...state,
          fechaContratoSocial
        }
      }
      ;
      state = {
        ...state,
        createdFromAfip: true,
      }
      props.actualizarStateV2(state);
    }
  }

  const clearFunction = () => {
    let state = {};
    Object.keys(props.usuario).forEach(key => {
      if (key !== 'rol' && key !== 'inmobiliaria') {
        state = {
          ...state,
          [key]: null,
        }
      }
    });
    props.actualizarStateV2(state);
  }


  return (
    <>
      {props.showSearchBar &&
        <div className="row">
          <div className="col-md-6 form-group">
            <GetPersonaByCuil
              setterFunction={setterFunction}
              clearFunction={clearFunction}
              showSpinner={setShowSpinner}
            />
          </div>
        </div>
      }
      {showSpinner &&
        <Spinner />
      }
      {
        (props.usuario.nombre || props.usuario.apellido) &&
        <>
          <div className="row">
            <div className="col-md-6 form-group">
              <label>Nombre</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nombre"
                name="nombre"
                value={props.usuario?.nombre}
                onChange={props.actualizarState}
                disabled
              />
            </div>
            <div className="col-md-6 form-group">
              <label>Apellido</label>
              <input
                type="text"
                className="form-control"
                placeholder="Apellido"
                name="apellido"
                value={props.usuario?.apellido}
                onChange={props.actualizarState}
                disabled
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="email"
                value={props.usuario?.email}
                onChange={props.actualizarState}
              />
            </div>
            <div className="col-md-4 form-group">
              <label>Fecha de Nacimiento</label>
              <input
                type="date"
                name="fechaNacimiento"
                className="form-control"
                disabled
                value={
                  moment(props.usuario?.fechaNacimiento)
                    .utcOffset(180)
                    .format("YYYY-MM-DD")
                }
              />
            </div>
          </div>

          <div className="row">

            <SelectTipoDni
              tipoDni={props.usuario?.tipoDni}
            />

            <div className="col-md-5 form-group">
              <label>Documento</label>
              <NumberFormat
                className="form-control"
                name="dni"
                disabled
                value={props.usuario?.dni}
                placeholder="20.123.456"
                format="##.###.###"
                mask="_"
              />
            </div>
          </div>
          <div className="form-group">
            <label>Domicilio</label>
          </div>

          <div className="row">
            <SelectProvinciaMunicipio
              updateState={updateState}
              provincia={props.usuario.provincia}
              municipio={props.usuario.municipio}
            />
          </div>

          <div className="row">
            <div className="col-md-8 form-group">
              <label>Calle</label>
              <input
                type="text"
                className="form-control"
                placeholder="Calle"
                name="calle"
                disabled
                value={props.usuario?.calle}
              />
            </div>

            <div className="col-md-2 form-group">
              <label>Altura</label>
              <input
                type="text"
                className="form-control"
                placeholder="Altura"
                name="altura"
                disabled
                value={props.usuario?.altura}
              />
            </div>
            <div className="col-md-2 form-group">
              <label>Piso / Depto</label>
              <input
                type="text"
                className="form-control"
                placeholder="Piso / Depto"
                name="pisoDepto"
                disabled
                value={props.usuario?.pisoDepto}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label>Teléfono Móvil</label>
              <NumberFormat
                className="form-control"
                placeholder="__________"
                name="movil"
                format="##########"
                mask="_"
                onChange={props.actualizarState}
                value={props.usuario?.movil}
              />
            </div>

            <div className="col-md-6 form-group">
              <label>Teléfono Fijo (Sin 0)</label>
              <NumberFormat
                className="form-control"
                name="fijo"
                format="##########"
                placeholder="__________"
                mask="_"
                onChange={props.actualizarState}
                value={props.usuario?.fijo}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                onChange={props.actualizarState}
                autoComplete="off"
              />
            </div>
            <div className="col-md-6 form-group">
              <label>Repetir Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Repetir Password"
                name="repetirPassword"
                onChange={props.actualizarState}
                autoComplete="off"
              />
            </div>
          </div>
        </>
      }
      {rolId >= 2 &&
        <>
          <div className="row">
            <div className="col-md-5 form-group">
              <label>Rol</label>
              <Select
                options={roles}
                name="rol"
                onChange={handleSelectRol}
                placeholder="--- Seleccionar ---"
                defaultValue={{
                  label: props.usuario?.rol.nombre,
                  value: props.usuario?.rol._id,
                }}
              />
            </div>


            {
              (props.usuario?.rol.id == 2 || props.usuario?.rol.id == 1 || showInmobiliaria) &&
              <div className="col-md-5 form-group">
                <label>Inmobiliaria</label>
                <Select
                  options={inmobiliarias}
                  name="inmobiliaria"
                  placeholder="--- Seleccionar ---"
                  onChange={handleSelectInmobiliaria}
                  defaultValue={{
                    label: props.usuario?.inmobiliaria?.nombre,
                    value: props.usuario?.inmobiliaria?._id,
                  }}
                />
              </div>
            }
            {
              (props.usuario?.rol.id == 5 || showEscribania) &&
              <div className="col-md-5 form-group">
                <label>Escribania</label>
                <Select
                  options={escribanias}
                  name="escribania"
                  placeholder="--- Seleccionar ---"
                  onChange={handleSelectEscribania}
                  defaultValue={{
                    label: props.usuario?.escribania?.nombre,
                    value: props.usuario?.escribania?._id,
                  }}
                />
              </div>
            }

            <div className="text-center col-md-2 form-group">
              <label>Usuario Activo</label>
              <input
                type="checkbox"
                className="form-control"
                name="activo"
                checked={props.usuario?.activo}
                onChange={handlerUsuarioActivo}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

export default FormularioUsuario;
