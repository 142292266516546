import React, {useContext, useState} from 'react';
import FormularioAdjuntarArchivoCompraventa from "../../barrios/components/FormularioBoletocompraventa";
import {CRMContext} from "../../../context/CRMContext";
import clienteAxios from "../../../config/axios";
import Swal from "sweetalert2";

// Saber a qué barrio corresponde
// Saber a que tipo de archivo va
// Enviar archivo

function AdjuntarArchivo(props) {

  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [archivo, setArchivo] = useState(null);
  const {idBarrio, imagePlace, consultarBarrio} = props;
  const actualizarArchivo = e => {
    setArchivo(e.target.files[0]);
  }

  const enviarArchivoAdjunto = async () => {
    if (!archivo || !imagePlace || !idBarrio) {
      return;
    }

    const datosEnviar = new FormData();
    datosEnviar.append('file', archivo);
    datosEnviar.append('imagePlace', imagePlace);
    datosEnviar.append('idBarrio', idBarrio);

    try {
      const rta = await clienteAxios.put("/listaPrecios/setFile", datosEnviar, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    } finally {
      setTimeout(() => {
        consultarBarrio();
      }, 500)
    }

  }


  return (
    <>
      <div className="row">
        <input
          className="btn btn-primary float-right"
          type="button"
          value="Adjuntar Archivo"
          data-toggle="modal"
          data-target={`#modal-archivos-lista-precios-${imagePlace}`}
        />
      </div>


      {/* modal PARA ADJUNTAR ARCHIVOS*/
      }
      <div className="modal fade" id={`modal-archivos-lista-precios-${imagePlace}`}>
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Adjuntar Archivo</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col form-group">
                  <label>Adjuntar:</label>
                  <input type="file" name="archivoAdjunto" id="archivoAdjunto" onChange={actualizarArchivo}
                         className="form-control"/>
                </div>
              </div>

              <div className="modal-footer justify-content-between">
                <button type="button" className="btn btn-default" data-dismiss="modal">Cancelar</button>
                <button type="button" className="btn btn-primary" onClick={enviarArchivoAdjunto}
                        data-dismiss="modal">Enviar
                </button>
              </div>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    </>
  )
    ;
}

export default AdjuntarArchivo;