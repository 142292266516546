import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import FormularioUsuario from "./components/FormularioUsuario";
import Swal from 'sweetalert2';

import { usuarioAPI2UsuarioPlano, usuarioPlano2usuarioAPI } from './components/ConversorObjetosUsuario';

const NuevoUsuario = (props) => {
  const [usuario, guardarUsuario] = useState({
    rol: '',
    inmobiliaria: '',
  });
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");


  const actualizarState = (e) => {

    guardarUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const actualizarStateV2 = (e) => {

    guardarUsuario({
      ...usuario,
      ...e
    });
  };

  //
  const verificaFormulario = () => {
    let verifica =
      !(usuario.nombre ||
      usuario.apellido) ||
      !usuario.email ||
      !usuario.password ||
      !usuario.rol ||
      usuario.password !== usuario.repetirPassword;

    return verifica;
  };

  // Enviar usuario a la API
  const enviarUsuario = async (e) => {
    e.preventDefault();

    const usuarioEnviar = usuarioPlano2usuarioAPI(usuario);

    try {
      let rta = await clienteAxios.post("/usuarios", usuarioEnviar, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }

      props.history.push("/configuracion/usuarios");
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title mt-2">NUEVO USUARIO</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className="card-body">
        <form onSubmit={enviarUsuario}>
          <FormularioUsuario
            showSearchBar={true}
            setUsuario={actualizarStateV2}
            actualizarState={actualizarState}
            actualizarStateV2={actualizarStateV2}
            usuario={usuario}
          />

          <div className="card-footer">
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary"
                onClick={(e) => { e.preventDefault(); props.history.goBack() }}
              >
                Volver
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={verificaFormulario()}
              >
                Guardar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(NuevoUsuario);
