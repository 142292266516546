import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import clienteAxios from "../../../config/axios";
import { CRMContext } from "../../../context/CRMContext";


export const SelectInmobiliaria = ({ cliente, actualizarState }) => {

  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const rolId = localStorage.getItem('rolId');
  const [options, setOptions] = useState([]);

  let defaultValue = {
    value: cliente.inmobiliaria ? cliente.inmobiliaria._id : '',
    label: cliente.inmobiliaria ? cliente.inmobiliaria.nombre : ''
  };


  useEffect(() => {
    const consultaAPI = async () => {
      try {
        const inmobiliarias = await clienteAxios.get("/inmobiliarias?estado=true&limit=0&page=0&sort=nombre,1", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOptions(
          inmobiliarias.data.data.map(inmo => {
            return {
              label: inmo.nombre,
              value: inmo._id
            }
          })
        );
      } catch (error) { }
    };
    consultaAPI();

  }, []);

  const actualizarInmobiliaria = (e) => {
    actualizarState({
      target: {
        name: 'inmobiliaria',
        value: e.value
      }
    })
  }

  return (
    <>
      {rolId >= 3 &&
        <div className="row">
          <div className="col-md-12 form-group">
            <label>Inmobiliaria</label>
            <Select
              options={options}
              name="inmobiliaria"
              onChange={actualizarInmobiliaria}
              defaultValue={defaultValue}
              required="true"
            />
          </div>
        </div>
      }
    </>
  );
};
