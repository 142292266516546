import React from "react";
import Select from "react-select";

export const SelectSexo = ({ actualizarState, sexo }) => {
  let defaultValue = {};

  // Lo ideal es que options se reciba de una API o una DB

  const options = [
    {
      label: "M",
      value: "M",
    },
    {
      label: "F",
      value: "F",
    },
    {
      label: "X",
      value: "X",
    }
  ];

  if (sexo != undefined) {
    defaultValue = options.filter((option) => {
      return option.value == sexo;
    })[0];
  }

  const actualizarDni = (e) => {
    const enviar = {
      target: {
        name: "sexo",
        value: e.value,
      },
    };
    actualizarState(enviar);
  };

  return (
    <>
      <div className="col-md-3 form-group">
        <label>Sexo: *</label>
        <Select
          options={options}
          name="tipoDni"
          defaultValue={defaultValue}
          onChange={actualizarDni}
        />
      </div>
    </>
  );
};
