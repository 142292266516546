import React, { useState } from 'react';

const values = [
    { label: 'Mismo que Operación', value: 'mismoOperacion' },
    { label: 'Personalizado', value: 'personalizado' },
    { label: 'Sin Financiacion', value: 'sinFinanciacion' },
    { label: 'Valor Negociado', value: 'valorNegociado' },
    { label: 'Valor Boleto', value: 'valorBoleto' },
    { label: 'Precio de Lista', value: 'precioLista' },
    { label: 'Entregas + Financiado', value: 'entregasFinanciado' },
];

const periodicityValues = [
    { label: 'Mensual', value: 'mensual' },
    { label: 'Trimestral', value: 'trimestral' },
    { label: 'Semestral', value: 'semestral' },
    { label: 'Anual', value: 'anual' },
];

const monedaOptions = [
    { label: 'Pesos', value: 'pesos' },
    { label: 'Dólares', value: 'dolares' },
];

const getLabelFromOptions = (options, value) => {
    const option = options.find((opt) => opt.value === value);
    return option ? option.label : value;
};

const formatDateArg = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString('es-AR', { day: '2-digit', month: '2-digit', year: 'numeric' });
};

const moneyArFormatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

const moneyUsFormatter = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

export default function MostrarDatosCargados({ data }) {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    if (!data) {
        return <></>;
    }

    const { precioBoleto, entregasBoleto, financiadoBoleto, firmanteLife, mesesPosesion } = data;

    const renderEntregasBoleto = () => {
        if (Array.isArray(entregasBoleto.entregas) && entregasBoleto.entregas.length > 0 &&
            entregasBoleto.entregas.every(entrega => entrega.valor !== null && entrega.moneda !== null)) {
            return entregasBoleto.entregas.map((entrega, index) => (
                <tr key={index}>
                    <td>{entrega.moneda ? getLabelFromOptions(monedaOptions, entrega.moneda) : '-'}</td>
                    <td>{entrega.valor !== null ? (entrega.moneda === 'pesos' ? moneyArFormatter.format(entrega.valor) : moneyUsFormatter.format(entrega.valor)) : '-'}</td>
                </tr>
            ));
        }
        return (
            <tr>
                <td>{getLabelFromOptions(monedaOptions, entregasBoleto.moneda)}</td>
                <td>{getLabelFromOptions(values, entregasBoleto.value)}</td>
            </tr>
        );
    };

    const renderFinanciadoBoleto = () => {
        if (Array.isArray(financiadoBoleto.financiados) && financiadoBoleto.financiados.length > 0 &&
            financiadoBoleto.financiados.every(financiado => financiado.valor !== null && financiado.moneda !== null)) {
            return financiadoBoleto.financiados.map((financiado, index) => (
                <tr key={index}>
                    <td>{financiado.moneda ? getLabelFromOptions(monedaOptions, financiado.moneda) : '-'}</td>
                    <td>{financiado.periodicidad ? getLabelFromOptions(periodicityValues, financiado.periodicidad) : '-'}</td>
                    <td>{financiado.cantidadCuotas !== null ? financiado.cantidadCuotas : '-'}</td>
                    <td>{financiado.valor !== null ? (financiado.moneda === 'pesos' ? moneyArFormatter.format(financiado.valor) : moneyUsFormatter.format(financiado.valor)) : '-'}</td>
                    <td>{financiado.primerVencimiento ? formatDateArg(financiado.primerVencimiento) : '-'}</td>
                </tr>
            ));
        }
        return (
            <tr>
                <td>{financiadoBoleto.monedaSumarTotal ? getLabelFromOptions(monedaOptions, financiadoBoleto.monedaSumarTotal) : '-'}</td>
                <td colSpan="4">{getLabelFromOptions(values, financiadoBoleto.value)}</td>
            </tr>
        );
    };

    return (
        <>
            <div className="card-header mb-4 mt-4">
                <h3 className="card-title">Opciones seleccionadas en el último Boleto Emitido</h3>
                <button
                    className="btn btn-primary float-right"
                    type="button"
                    onClick={handleToggle}
                >
                    {isCollapsed ? 'Ver' : 'Ocultar'}
                </button>
            </div>
            <div className={`collapse${isCollapsed ? '' : ' show'}`}>
                <div className="card-body">
                    <label>Precio del Boleto</label>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Valor</th>
                                <th>Valor Personalizado</th>
                                <th>Moneda</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{getLabelFromOptions(values, precioBoleto.value)}</td>
                                <td>{precioBoleto.valorPersonalizado > 0 ? precioBoleto.moneda === 'pesos' ? moneyArFormatter.format(precioBoleto.valorPersonalizado) : moneyUsFormatter.format(precioBoleto.valorPersonalizado) : '-'}</td>
                                <td>{getLabelFromOptions(monedaOptions, precioBoleto.moneda)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <label>Entregas del Boleto</label>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Moneda</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>{renderEntregasBoleto()}</tbody>
                    </table>

                    <label>Financiación del Boleto</label>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Moneda</th>
                                <th>Periodicidad</th>
                                <th>Cantidad de Cuotas</th>
                                <th>Valor Cuota</th>
                                <th>Primer Vencimiento</th>
                            </tr>
                        </thead>
                        <tbody>{renderFinanciadoBoleto()}</tbody>
                    </table>

                    <label>Firmante</label>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Nombre Completo</th>
                                <th>Documento</th>
                                <th>CUIT/CUIL</th>
                                <th>Domicilio</th>
                                <th>Teléfono</th>
                                <th>Email</th>
                                <th>Estado Civil</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{`${firmanteLife.nombre.nombre} ${firmanteLife.nombre.apellido} ${firmanteLife.nombre.apellidoMaterno}`}</td>
                                <td>{`${firmanteLife.documentos.dniTipo} ${firmanteLife.documentos.dni}`}</td>
                                <td>{firmanteLife.documentos.cuitCuil}</td>
                                <td>{`${firmanteLife.domicilio.calle} ${firmanteLife.domicilio.altura}, ${firmanteLife.domicilio.municipio}, ${firmanteLife.domicilio.provincia}, CP: ${firmanteLife.domicilio.cp}`}</td>
                                <td>{firmanteLife.telefono.movil}</td>
                                <td>{firmanteLife.email}</td>
                                <td>{firmanteLife.estadoCivil.nombre}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="row mb-3">
                        <div className="col">
                            <label>Meses de Posesión:</label>
                            <p>{mesesPosesion}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
