import React from "react";

const FormularioEstadoCivil = ({
  actualizarState,
  estadoCivil,
  estadoCivilNuevo,
}) => {
  const checkActivo = (e) => {
    e.target.value = e.target.value == "on" ? true : false;
    actualizarState(e);
  };
  return (
    <>
      <div className='row'>
        <div className='col-md-6 form-group'>
          <label htmlFor='exampleInputEmail1'>Nombre</label>
          <input
            type='text'
            className='form-control'
            value={estadoCivil?.nombre}
            placeholder='Nombre'
            name='nombre'
            onChange={actualizarState}
          />
        </div>
        <div className='text-center col-md-2 form-group'>
          <label>Conyuge</label>
          <input
            type='checkbox'
            className='form-control'
            name='conyuge'
            checked={estadoCivil?.conyuge}
            onChange={checkActivo}
          />
        </div>
        <div className='text-center col-md-2 form-group'>
          <label>Activo</label>
          <input
            type='checkbox'
            className='form-control'
            name='activo'
            checked={estadoCivil?.activo}
            onChange={checkActivo}
            disabled={estadoCivilNuevo}
          />
          </div>
        <div className='text-center col-md-2 form-group'>
          <label>Solo Nombre Conyuge</label>
          <input
            type='checkbox'
            className='form-control'
            name='activo'
            checked={estadoCivil?.conyugeOnlyName}
            onChange={checkActivo}
            disabled={estadoCivilNuevo}
          />
          </div>
          <div className='text-center col-md-6 form-group'>
            <input
            type='text'
            className='form-control'
            value={estadoCivil?.detalle}
            placeholder='Detalle'
            name='detalle'
            onChange={actualizarState}
          />
          
          
        </div>
      </div>
    </>
  );
};

export default FormularioEstadoCivil;
