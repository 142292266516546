import { React, useState, useEffect } from "react";
import Select from "react-select";
import clienteAxios from "../../../config/axios";

export const SelectNacionalidad = ({ actualizarState, cliente, token, disabled = false }) => {
  const [options, setOptions] = useState([]);

  const consultaAPI = async () => {
    try {
      const response = await clienteAxios.get("/nacionalidades",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      const nacionalidades = [];
      response.data.forEach(nacionalidad => {
        if (nacionalidad.activo) {
          nacionalidades.push({
            value: nacionalidad._id,
            label: nacionalidad.nombre
          });
        }
      });
      setOptions(nacionalidades);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    consultaAPI();
  }, []);

  useEffect(()=>{
    if(!!!cliente.nacionalidad && options.length > 0) {
      const defaultNacionalidad = getDefaultNacionalidad();
      actualizarNacionalidad({value: defaultNacionalidad.value});
    }
  }, [options]);

  const actualizarNacionalidad = (e) => {
    const enviar = {
      target: {
        name: "nacionalidad",
        value: e.value,
      },
    };
    actualizarState(enviar);
  };

  const getDefaultNacionalidad = () => {
    if (!Array.isArray(options)) {
      return null;
    }
    return options.find(option => {
      return option.label === 'Argentina';
    })
  }

  const getOptionById = (nacionalidad) => {
    if(nacionalidad && nacionalidad._id) {
      nacionalidad = nacionalidad._id;
    }
    return options.find(option => {
      return option.value === nacionalidad;
    })
  }

  if (!cliente || options.length === 0) {
    return (<></>);
  }


  return (
    <>
      <div className='col-md-3 form-group'>
        <label htmlFor='exampleInputEmail1'>Nacionalidad *</label>
        <Select
          options={options}
          name='nacionalidad'
          onChange={actualizarNacionalidad}
          value={getOptionById(cliente.nacionalidad)}
          isDisabled={disabled}
        />
      </div>
    </>
  );
};
