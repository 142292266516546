import React, {useEffect, useState} from 'react';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import GetDolarMercado from "../../../../../utils/GetDolarMercado";


const SelectCash = ({onClose, operacion, setOperacion, goBack, ...props}) => {
  const [valorDolarMercado, setValorDolarMercado] = useState(0);
  const [valorDolarPropuesto, setValorDolarPropuesto] = useState(0);

  useEffect(async () => {
    const dolarApi = await GetDolarMercado.getDolar();
    const valorDolarMercado = dolarApi.data.compra;
    setValorDolarMercado(valorDolarMercado);
    setValorDolarPropuesto(valorDolarMercado);
  }, []);

  const baseAnticipo = {
    moneda: null,
    cambioSugerido: valorDolarMercado,
    monto: null,
  };
  const monedaOptions = [{
    label: '$ Ars',
    value: 'ars'
  }, {
    label: 'u$s',
    value: 'usd'
  }];
  const [anticipos, setAnticipos] = useState(operacion.formaDePago.anticipo.efectivo || [{
    ...baseAnticipo
  }]);

  const actualizarState = (e, index) => {
    const {name, value} = e.target;
    const anticiposLocal = [...anticipos];
    anticiposLocal[index][name] = value;
    setAnticipos([...anticiposLocal]);
  }

  const actualizarStateSelect = (name, value, index) => {
    actualizarState({
      target: {
        name,
        value,
      }
    }, index);
  }

  const agregarAnticipo = () => {
    const anticiposLocal = [...anticipos];
    anticiposLocal.push({...baseAnticipo});
    setAnticipos(anticiposLocal);
  }

  const eliminarAnticipo = (index) => {
    let anticiposLocal = [...anticipos];
    anticiposLocal.splice(index, 1);
    setAnticipos(anticiposLocal);
  }


  const saveCash = () => {

    onClose();
  }

  useEffect(() => {

    const anticiposProcesados = anticipos.map(anticipo => {
      return {
        ...anticipo,
        valores: {
          fechaPropuesto: Date.now(),
          cambioPropuesto: anticipo.cambioSugerido ? anticipo.cambioSugerido : valorDolarMercado,
          pesosPropuesto: anticipo.moneda === 'ars' ? anticipo.monto : anticipo.monto * anticipo.cambioSugerido,
          dolaresPropuesto: anticipo.moneda === 'usd' ? anticipo.monto : anticipo.monto / valorDolarMercado,

          fechaMercado: Date.now(),
          pesosMercado: anticipo.moneda === 'ars' ? anticipo.monto : anticipo.monto * valorDolarMercado,
          dolaresMercado: anticipo.moneda === 'usd' ? anticipo.monto : anticipo.monto / valorDolarMercado,
          cambioMercado: valorDolarMercado,
        },
      }
    });

    setOperacion({
      ...operacion,
      formaDePago: {
        ...operacion.formaDePago,
        anticipo: {
          ...operacion.formaDePago.anticipo,
          efectivo: [...anticiposProcesados],
        }
      }
    });
  }, [anticipos]);

  return (
    <>
      {anticipos.map((anticipo, index) => {
        return (
          <div className="row" key={index}>
            <label className="col-md-12">Entrega Efectivo</label>
            <div className='col-md-2 form-group'>
              <label>Moneda</label>
              <Select
                name='moneda'
                options={monedaOptions}
                value={monedaOptions.find(option => option.value === anticipo.moneda)}

                onChange={(e) => actualizarStateSelect('moneda', e.value, index)}
              />
            </div>

            {anticipo && anticipo.moneda && anticipo.moneda === 'usd' &&
              <>
                <div className="col-md-3 form-group">
                  <label className='mr-3'>Cambio de Mercado:</label>
                  <NumberFormat
                    className="form-control"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                    value={valorDolarMercado}
                    disabled
                  />
                </div>
                < div className='col-md-3 form-group'>
                  <label>Cambio Propuesto:</label>
                  <NumberFormat
                    className="form-control"
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                    value={anticipo.cambioSugerido}
                    onValueChange={(e) => {
                      let enviar = {
                        target: {
                          value: e.floatValue,
                          name: "cambioSugerido",
                        },
                      };
                      actualizarState(enviar, index);
                    }}
                  />
                </div>
              </>
            }

            {anticipo && anticipo.moneda && anticipo.moneda === 'ars' &&
              <div className='col-md-6 form-group'></div>
            }

            <div className='col-md-2 form-group'>
              <label>Monto</label>
              <NumberFormat
                className="form-control"
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                value={anticipo.monto}
                onValueChange={(e) => {
                  let enviar = {
                    target: {
                      value: e.floatValue,
                      name: "monto",
                    },
                  };
                  actualizarState(enviar, index);
                }}
              />
            </div>
            <div className='col-md-2 form-group'>
              <label className='col-md-12'>Eliminar</label>
              <button className="btn btn-danger" onClick={() => eliminarAnticipo(index)}>Eliminar</button>
            </div>
          </div>
        )
      })}

      <div className="row mt-5">
        <div className="col-md-3 ml-auto">
          <div className="form-group">
            <button className="btn btn-primary" onClick={agregarAnticipo}>Agregar Otro</button>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="row d-flex justify-content-around card-footer">

          <div className="">
            <button className="btn btn-secondary" onClick={goBack}>Anterior</button>
          </div>

          <div className="">
            <button className="btn btn-primary" onClick={saveCash}>Siguiente</button>
          </div>

        </div>
      </div>
    </>
  )

}

export default SelectCash;