class ExternalPropertyUtils {
  static types = [
    'Departamento',
    'Casa',
    'Terrenos y lotes',
    'Cochera',
    'Local',
    'Oficina',
    'Consultorio',
    'Quinta',
    'Chacra',
    'Galpón',
    'Depósito',
    'Campo',
    'Hotel',
    'Fondo de comercio',
    'Edificio',
    'Otros',
  ];

  static ambientes = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '+6',
  ];

  static dormitorios = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '+5',
  ];

  static banos = [
    '0',
    '1',
    '2',
    '3',
    '+4',
  ];

  static garage = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '+5',
  ];

  static anoConstruccion = [
    'Hasta 5 años',
    'Entre 5 y 10 años',
    'Entre 10 y 20 años',
    'Entre 20 y 50 años',
    'Más de 50 años',
  ];

  static condicion = [
    'A estrenar',
    'A refaccionar',
    'En construcción',
    'Refaccionado',
    'Excelente',
    'Muy bueno',
    'Bueno',
    'Regular',
  ];

  static otros = [
    'Acceso de cochera ascensor',
    'Acceso de cochera horizontal',
    'Acceso de cochera rampa fija',
    'Acceso de cochera rampa movil',
    'Agua',
    'Agua corriente',
    'Aire acondicionado',
    'Alarma',
    'Altillo',
    'Amoblado',
    'Apto crédito',
    'Apto profesional',
    'Area de cine',
    'Area de juegos infantiles',
    'Areas verdes',
    'Ascensor',
    'Balcon',
    'Baño',
    'Bar',
    'Baulera',
    'Biblioteca',
    'Bodegas',
    'Centro de reuniónes',
    'Caldera',
    'Cancha de basquetbol',
    'Cancha de fútbol',
    'Cancha de paddle',
    'Cancha de tenis',
    'Chimenea',
    'Cisterna',
    'Cochera',
    'Cocina',
    'Cocina comedor',
    'Comedor',
    'Calefacción',
    'Deck',
    'Desague cloacal',
    'Desayunador',
    'Disposición contrafrente',
    'Disposición frente',
    'Disposición interno',
    'Disposición lateral',
    'Dormitorio',
    'Electricidad',
    'Estacionamiento para visitas',
    'Estudio',
    'Galpón',
    'Gas',
    'Gimnasio',
    'Habitación de servicio',
    'Hidromasaje',
    'Internet',
    'Jardín',
    'Jardín de invierno',
    'Laundy',
    'Lavadero',
    'Living',
    'Living / Comedor',
    'Lobby',
    'Losa radiante',
    'Orientación este',
    'Orientación noreste',
    'Orientación noroeste',
    'Orientación norte',
    'Orientación oeste',
    'Orientación sur',
    'Orientación suroeste',
    'Orientación suroeste',
    'Otro',
    'Parrilla',
    'Patio',
    'Permite mascotas',
    'Pileta',
    'Pileta climatizada',
    'Pileta cubierta',
    'Placard',
    'Playroom',
    'Portón automático',
    'Quincho',
    'Recepción',
    'Sala de reuniónes',
    'Salón de usos múltiples',
    'Sauna',
    'Seguridad',
    'Solarium',
    'Sotano',
    'Teléfono',
    'Televisión por cable',
    'Televisión satelital',
    'Terraza',
    'Tipo de bodega almacen',
    'Tipo de bodega comercial',
    'Tipo de bodega nave industrial',
    'Tipo de campo agrícola',
    'Tipo de campo chacra',
    'Tipo de campo criadero',
    'Tipo de campo floricultura',
    'Tipo de campo forestal',
    'Tipo de campo frutícola',
    'Tipo de campo ganadero',
    'Tipo de campo haras',
    'Tipo de campo otro',
    'Tipo de campo tambero',
    'Toilet',
    'Valet parking',
    'Vestidor',
  ];

  static tiposMoneda = [{
    value: 'usd',
    label: 'u$s'
  }, {
    value: 'ars',
    label: '$ ars'
  }];

  static getTipoMonedaByValue(value) {
    return ExternalPropertyUtils.tiposMoneda.find(tipo => tipo.value === value);
  }

  static getOptionsList(optionName) {
    let options = [];
    if (ExternalPropertyUtils[optionName]) {
      options = ExternalPropertyUtils[optionName].map( option => {
        return {
          label: option,
          value: option
        }
      })
    }
    return options;
  }

  static getSelectedOption(list, option) {
    const optionsList = [...ExternalPropertyUtils.getOptionsList(list)];
    return optionsList.find(optionListElement => optionListElement.value === option);
  }

  static getSelectedOptionExternal(list, value) {
    return list.find( option => option.value === value)
  }

  static getMultiSelectedOptions(list, options) {
    const selectedOptions = [];
    const optionsList = [...ExternalPropertyUtils.getOptionsList(list)];
    options.forEach(optionSearched => {
      const selected = optionsList.find(option => option.value === optionSearched);
      selectedOptions.push(selected);
    });
    return selectedOptions;
  }

}

export default ExternalPropertyUtils;