import React, {useState, useEffect, useContext} from "react";
import {Link, withRouter} from "react-router-dom";
import DataTable from "react-data-table-component";
import clienteAxios from "../../config/axios";
import {CRMContext} from "../../context/CRMContext";

const ListadoEstadoCivil = (props) => {
  const [estadoCivil, setEstadoCivil] = useState([]);
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  useEffect(() => {
    const consultaAPI = async () => {
      try {
        const estadoCivilDB = await clienteAxios.get("/estadoCivil", {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });

        let newEstadoCivil = [];


        estadoCivilDB.data.map((estadoCivil) => {
          return newEstadoCivil.push({
            nombre: estadoCivil.nombre,
            detalle: estadoCivil.detalle,
            conyuge: estadoCivil.conyuge,
            activo: estadoCivil.activo,
            url: `/configuracion/estadoCivil/editar/${estadoCivil._id}`,
          });
        });

        newEstadoCivil.sort((a, b) => {
          if (a.nombre > b.nombre) {
            return 1
          }
          if (a.nombre < b.nombre) {
            return -1
          }
          return 0
        })


        setEstadoCivil(newEstadoCivil);
      } catch (error) {
      }
    };
    consultaAPI();
  }, []);


  const columns = [
    {
      name: "Estado Civil",
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Detalle",
      selector: (row) => row.detalle,
      sortable: true,
    },
    {
      name: "Conyuge",
      selector: (row) => row.conyuge ? "Si" : "No",
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.activo ? "Activo" : "Inactivo",
      sortable: true,
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <Link to={row.url}>
          <button type='button' className='btn btn-block btn-success'>
            Ver
          </button>
        </Link>
      ),
    },
  ];

  return (
    <div className='card'>
      <div className='card-header'>
        <div className='row'>
          <div className='col-md-8'>
            <h3 className='card-title mt-2'>ESTADOS CIVILES</h3>
          </div>
          <div className='col-md-4'>
            <Link to={`/configuracion/estadoCivil/nuevo`}>
              <button type='button' className='btn btn-block btn-success'>
                Agregar Nuevo Estado Civil
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className='card-body'>
        <DataTable columns={columns} data={estadoCivil}/>
      </div>
    </div>
  );
};

export default withRouter(ListadoEstadoCivil);
