import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import queryString from "query-string";

import Spinner from "../layout/Spinner";
import { ListadoOperacionesComp } from "./components/ListadoOperacionesComp";
import OperacionesFiltros from "./utils/filter";
import listadoOperacionesStore from "../../store/listadoOperacionesStore";
import { get } from "../../config/apiHelper";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";




function ListadoOperaciones(props) {
  const { filter, paginator } = listadoOperacionesStore();
  const [updatingData, setUpdatingData] = useState(false)
  const [operaciones, guardarOperaciones] = useState({
    loading: true,
    operaciones: []
  });
  const [totalRows, setTotalRows] = useState(0);

  const rolId = localStorage.getItem("rolId");
  
  useEffect(() => {
    consultarAPI();
  }, [paginator]);
  

  const consultarAPI = async () => {
    setUpdatingData(true);
    let url = `/operaciones?campos=fechaAlta,inmobiliaria,id,historico,cliente,barrio,lote,estado,usuario&sort=id,-1&page=${paginator.page - 1}&limit=${paginator.limit}&${processFilters()}`
    try {
      const response = await get(url);
      guardarOperaciones({
        loading: false,
        operaciones: response.data
      });
      setTotalRows(response.totalCount);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      } else {
        props.history.push("/");
      }
      console.log(error);
    } finally {
      setUpdatingData(false);
    }
  };

  useEffect(() => {
    consultarAPI();
  }, []);

  const processFilters = () => {
    const filtros = {
      fechaAltaDesde: filter.fechaAltaDesde,
      fechaAltaHasta: filter.fechaAltaHasta,
      estado: filter.estado,
      barrio: filter.barrio,
      cliente: filter.cliente,
      numeroDeLote: filter.numeroDeLote,
    };
    return queryString.stringify(filtros);
  };


  const dataExport = operaciones.operaciones.map( operacion => {
    let lotes = "";
    if (operacion.lote.length === 1) {
      lotes = `${operacion.lote[0].barrio.nombre} - ${operacion.lote[0].numeroLote}`
    } else {
      operacion.lote.forEach( lote => {
        lotes += `${lote.barrio.nombre} - ${lote.numeroLote},`
      });
      lotes = lotes.slice(0, -1);
    }
    const nombre = operacion.cliente.razonSocial ? operacion.cliente.razonSocial : `${operacion.cliente.nombre.apellido}, ${operacion.cliente.nombre.nombre} - ${operacion.cliente.documentos.cuitCuil}`;
    return {
      "ID": operacion.id,
      "Fecha Alta": operacion.fechaAlta,
      "Último evento": operacion.historico[operacion.historico.length - 1].fecha,
      "Cliente": nombre,
      "Lote(s)": lotes,
      "Estado": operacion.estado?.estado,
      "Inmobiliaria": operacion.usuario.inmobiliaria.nombre,
      "Asesor Inmobiliario": `${operacion.usuario?.apellido || "Sin Apellido"}, ${operacion.usuario?.nombre || "Sin Nombre"}`,
    }
  })

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'data.xlsx');
  };

  const handleDownload = async () => {
    try {
      Swal.fire({
        title: 'Descargando',
        text: 'Por favor espere...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const response = await clienteAxios.get('/api/lista-operaciones', {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'data.xlsx');
      Swal.close();
    } catch (error) {
      console.error('Failed to download the file:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al intentar descargar el archivo',
      });
    }
  };


  if (operaciones.loading) {
    return <Spinner />;
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-6">
            <h3 className="card-title">Operaciones</h3>
          </div>
          <div className="col-md-2">
            { rolId === '4' ? <button className="btn btn-block btn-success mb-2" onClick={handleDownload}>Descargar Resumen</button> : null }
          </div>
          <div className="col-md-2">
            <button className="btn btn-block btn-success mb-2" onClick={exportToExcel}>Exportar</button>
          </div>
          <div className="col-md-2">
            <Link to={`/operaciones/nueva`}>
              <button type="button" className="btn btn-block btn-success">
                Nueva Operación
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <OperacionesFiltros
              fetchApi={consultarAPI}
            />
          </div>
        </div>
          <ListadoOperacionesComp
            loading={updatingData}
            operaciones={operaciones.operaciones}
            url={"/operaciones"}
            totalRows={totalRows}
            paginator={paginator}
          />
      </div>
    </div>
  );
}

export default withRouter(ListadoOperaciones);
