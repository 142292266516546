import React, {useState} from "react";
import {GetPersonaByCuil} from "../../utils/getPersonaByCuil";
import Spinner from "../../layout/Spinner";
import NumberFormat from "react-number-format";


const FormularioInmobiliaria = ({ actualizarState, inmobiliaria, inmobiliariaNueva, actualizarStateV2 = null }) => {
  const [spinner, setSpinner] = useState(false);
  const checkActivo = e => {
    e.target.value = e.target.value == 'on' ? true : false;
    actualizarState(e);
  }

  const setterFunction = (res) => {
    const {razonSocial, domicilio} = res;
    let direccion = `${domicilio.calle}`;
    if(domicilio.altura) {
      direccion += ` ${domicilio.altura}`;
    }
    if(domicilio.pisoDepto) {
      direccion += ` ${domicilio.pisoDepto}`;
    }
    if(domicilio.ciudad) {
      direccion += `, ${domicilio.ciudad}`;
    }
    if(domicilio.provincia) {
      direccion += `, ${domicilio.provincia}`;
    }
    if(domicilio.cp) {
      direccion += `, CP: ${domicilio.cp}`;
    }

    const state = {
      nombre: razonSocial,
      direccion,
    }

    actualizarStateV2(state);
  }

  const clearFunction = () => {
    let state = {};
    Object.keys(inmobiliaria).forEach( key => {
      state = {
        ...state,
        [key]: null,
      }
    });
    actualizarStateV2(state);
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12 form-group">
          <GetPersonaByCuil
            setterFunction={setterFunction}
            clearFunction={clearFunction}
            showSpinner={setSpinner}
          />
        </div>
      </div>
      {spinner &&
        <Spinner/>
      }
      <div className="row">
        <div className="col-md-6 form-group">
          <label>Nombre</label>
          <input
            type="text"
            className="form-control"
            value={inmobiliaria?.nombre}
            placeholder="Nombre"
            name="nombre"
            onChange={actualizarState}
          />
        </div>
        <div className="col-md-6 form-group">
          <label>Dirección</label>
          <input
            type="text"
            className="form-control"
            value={inmobiliaria?.direccion}
            placeholder="Dirección"
            name="direccion"
            onChange={actualizarState}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 form-group">
          <label>Teléfono</label>
          <NumberFormat 
            className="form-control"
            name="telefono"
            format="##########"
            placeholder="__________"
            mask="_"
            onChange={actualizarState}
            value={inmobiliaria?.telefono}
            required
          />
        </div>
        <div className="text-center col-md-6 form-group">
          <label>Activo</label>
          <input
            type="checkbox"
            className="form-control"
            name="activo"
            checked={inmobiliaria?.activo}
            onChange={checkActivo}
            disabled={inmobiliariaNueva}
          />
        </div>
        
      </div>
    </>
  );
};

export default FormularioInmobiliaria;
