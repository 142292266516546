import React, { useState, useContext } from "react";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
// import jwt from "jsonwebtoken";
import jwt_decode from 'jwt-decode'
import useAuthStore from "../../store/authStore";

const Login = (props) => {
  const [credenciales, guardarCredenciales] = useState({});

  const [auth, guardarAuth] = useContext(CRMContext);
  const { setToken } = useAuthStore();

  const leerDatos = (e) => {
    guardarCredenciales({
      ...credenciales,
      [e.target.name]: e.target.value,
    });
  };

  const iniciarSesion = async (e) => {
    e.preventDefault();

    try {
      const rta = await clienteAxios.post("/login", credenciales);
      const { token } = rta.data;

      const decodeToken = jwt_decode(token);

      const { id, nombre, apellido, rol, inmobiliariaId, rolId } = decodeToken;

      localStorage.setItem("token", token);
      localStorage.setItem("idUsuario", id);
      localStorage.setItem("nombre", nombre);
      localStorage.setItem("apellido", apellido);
      localStorage.setItem("rol", rol);
      localStorage.setItem("inmobiliariaId", inmobiliariaId._id);
      localStorage.setItem("rolId", rolId);

      guardarAuth({
        token,
        auth: true,
      });

      setToken(token);

      // Redireccionar
      if (rolId == 1 || rolId == 2) {
        props.history.push("/operaciones/nueva");
      } else {
        props.history.push("/operaciones");
      }
    } catch (error) {
      if (error.response) {
        Swal.fire({
          type: "error",
          title: "Hubo un error",
          text: error.response.data.mensaje,
        });
      } else {
        Swal.fire({
          type: "error",
          title: "Hubo un error",
          text: "Hubo un error",
        });
      }
    }
  };

  return (
    <>
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo logo-mobile">
            <img src="/dist/../logo192.png" ></img>
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Iniciar Sesión</p>
              <form onSubmit={iniciarSesion}>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={leerDatos}
                    name="email"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={leerDatos}
                    name="password"
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7"></div>
                  {/* /.col */}
                  <div className="col-md-5">
                    <button type="submit" className="btn btn-success btn-block">
                      Iniciar Sesión
                    </button>
                  </div>
                  {/* /.col */}
                </div>
              </form>
              <p className="mb-1">
                {/* <a href="forgot-password.html">Olvidé mi contraseña</a> */}
              </p>
            </div>
            {/* /.login-card-body */}
          </div>
        </div>
        {/* /.login-box */}
      </div>
    </>
  );
};

export default withRouter(Login);
