import React from "react";

const FormularioNacionalidad = ({
  actualizarState,
  nacionalidad,
  nacionalidadNueva,
}) => {
  const checkActivo = (e) => {
    e.target.value = e.target.value == "on" ? true : false;
    actualizarState(e);
  };
  return (
    <>
      <div className='row'>
        <div className='col-md-6 form-group'>
          <label htmlFor='exampleInputEmail1'>Nombre</label>
          <input
            type='text'
            className='form-control'
            value={nacionalidad?.nombre}
            placeholder='Nombre'
            name='nombre'
            onChange={actualizarState}
          />
        </div>
        <div className='text-center col-md-6 form-group'>
          <label>Activo</label>
          <input
            type='checkbox'
            className='form-control'
            name='activo'
            checked={nacionalidad?.activo}
            onChange={checkActivo}
            disabled={nacionalidadNueva}
          />
        </div>
      </div>
    </>
  );
};

export default FormularioNacionalidad;
