import { create } from 'zustand';

const listadoOperacionesStore = create((set) => ({
    filter: {},
    setFilter: (filter) => set({ filter }),
    paginator: {
        page: 1,
        limit: 10
    },
    setPaginator: (paginator) => set({ paginator }),
    kanbanView: false,
    setKanbanView: (kanbanView) => set({ kanbanView }),
}));

export default listadoOperacionesStore;