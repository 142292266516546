import React, { useContext } from "react";
import { CRMContext } from "../../../context/CRMContext";
import clienteAxios from "../../../config/axios";
import FormularioAdjuntarArchivoBarrios from "./FormularioAdjuntarArchivoBarrios";

const ArchivosAdjuntosBarrios = ({ barrio, history, consultarBarrio, setBarrio }) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const obtenerArchivo = async (nombreArchivo) => {
    try {
      const rta = await clienteAxios
        .get(`/archivosAdjuntos/${nombreArchivo}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${nombreArchivo}`);
            document.body.appendChild(link);
            link.click();
          }
        });
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  const eliminarArchivoAdjunto = async (e, i) => {
    const { _id: idArchivo } = barrio.archivosAdjuntos[i];
    try {
      const rta = await clienteAxios.delete(`/barriosArchivosAdjuntos/${idArchivo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          idBarrio: barrio._id,
        },
      });

      consultarBarrio();
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };



  return (
    <div className="mb-3">
      <div className="card-header">
        <h4 className="card-title">ARCHIVOS ADJUNTOS</h4>
        <input
          className="btn btn-primary float-right"
          type="button"
          value="Adjuntar Archivo"
          data-toggle="modal"
          data-target="#modal-archivos"
        />
      </div>

      {/* modal PARA ADJUNTAR ARCHIVOS*/}
      <div className="modal fade" id="modal-archivos">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Adjuntar Archivo</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <FormularioAdjuntarArchivoBarrios
                token={token}
                id={barrio._id}
                consultarBarrio={consultarBarrio}
                setBarrio={setBarrio}
                barrio={barrio}
              />
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}

      {
        barrio.archivosAdjuntos.map((archivo, i) => {
          return (
            <div className="row m-3" key={i}>
              <div className="form-group col-md-10">
                <label
                  role="button"
                  onClick={() => {
                    obtenerArchivo(archivo.nombreArchivo);
                  }}
                >
                  {archivo.nombre || 'Archivo Sin Nombre'}
                </label>
              </div>
              <div className="form-group col-md-2">
                <input
                  className="btn btn-danger "
                  type="button"
                  value="X"
                  onClick={(e) => eliminarArchivoAdjunto(e, i)}
                />
              </div>
            </div>
          );
        })
      }
    </div>
  )
}

export default ArchivosAdjuntosBarrios;


