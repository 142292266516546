import React, { useContext, useState } from 'react';
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';
import { useForm } from 'react-hook-form';
import ShowPersonFromCUIX from '../clientes/components/ShowPersonFromCUIX';
import { post } from '../../config/apiHelper';

export const FormularioAltaInversionista = (props) => {
  const { history } = props;
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [taxId, setTaxId] = useState(''); // State for 'Identificación Tributaria'
  const { register, handleSubmit, reset } = useForm();

  const submit = async (data) => {
    try {
      await post(`/api/inversionistas/`, data);
      history.push('/configuracion/inversionistas/listado');
    } catch (error) {
      console.log(error);
    }
  };

  // Handler for input change
  const handleTaxIdChange = (e) => {
    setTaxId(e.target.value);
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title">Inversionista</h3>
          </div>
          <div className="col-md-4">
            <button className="btn btn-primary float-right" onClick={history.goBack}>Volver</button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  name="nombreCorto"
                  className="form-control"
                  {...register("nombreCorto")}
                />
              </div>
              <div className="form-group">
                <label>Identificación Tributaria (sin guiones)</label>
                <input
                  type="number"
                  name="identificacionTributaria"
                  className="form-control"
                  {...register("identificacionTributaria")}
                  onChange={handleTaxIdChange} // Update state on change
                />
              </div>
              <button type="submit" className="btn btn-primary float-right mt-3">Guardar</button>
            </form>
          </div>
        </div>
        {taxId !== '' && taxId.length === 11 && (
          <div className="row">
            <div className="col">
              <ShowPersonFromCUIX taxId={taxId} />
            </div>
          </div>
        )}
      </div>
      <div className="card-footer"></div>
    </div>
  );
};
