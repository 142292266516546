import React, { useContext, useEffect, useState } from 'react'
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';
import { useForm } from 'react-hook-form';
import { getCategorias, getDocumentacionById } from './utils';
import Spinner from '../layout/Spinner';
import { put } from '../../config/apiHelper';

export const FormularioDocumentacion = (props) => {

  const [auth, guardarAuth] = useContext(CRMContext);
  const isEdition = !!props.match.params.id;
  const [categorias, setCategorias] = useState([]);
  let token = auth.token || localStorage.getItem("token");
  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const setInitialData = async () => {
    const categorias = await getCategorias();
    setCategorias(categorias);
  }

  const fetchDocumentacion = async () => {
    const response = await getDocumentacionById(props.match.params.id);
    reset({
      nombre: response?.nombre,
      categoria: response?.categoria?._id
    });
  }



  useEffect(() => {
    setInitialData().then(() => {
      if (props.match.params.id) {
        setLoading(true);
        fetchDocumentacion()
          .then(() => setLoading(false));
      }
    })
  }, []);

  const createDocumentacion = async (data) => {
    try {
      const datosEnviar = new FormData();
      datosEnviar.append('documento', data['documento'][0]);
      datosEnviar.append('nombre', data['nombre']);
      datosEnviar.append('categoria', data['categoria']);
      const response = await clienteAxios.post(`/api/documentacion/`, datosEnviar, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        props.history.push('/documentacion/archivosAdjuntos');
      }
    } catch (error) {
      console.log(error);
    }
  }

  const updateDocumentacion = async (data) => {
    try {
      const response = await put(`/api/documentacion/${props.match.params.id}`, data, token);
      if (response._id) {
        props.history.push('/documentacion/archivosAdjuntos');
      }
    } catch (error) {
      console.log(error);
    }
  }
  const submit = async (data) => {
    if (isEdition) {
      updateDocumentacion(data);
    } else {
      createDocumentacion(data);
    }
  }

  if (loading) {
    return <Spinner />
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title mt-2">Documentación</h3>
          </div>
          <div className="col-md-4">
            <input type="button" value="Volver" className="btn btn-primary mr-3 float-right" onClick={props.history.goBack} />
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label>Categoria</label>
                <select name="categoria" className="form-control" {...register("categoria")} >
                  <option value="">Seleccionar Categoria</option>
                  {categorias.map(categoria => {
                      return <option key={categoria._id} value={categoria._id}>{categoria.nombre}</option>
                  })}
                </select>
              </div>
              <div className="form-group">
                <label>Nombre Archivo</label>
                <input type='text' name="nombre" className="form-control" {...register("nombre")}></input>
              </div>
              {!props.match.params.id &&
                <div className="form-group">
                  <label>Archivo</label>
                  <input type="file" name="documento" className="form-control" {...register("documento")} />
                </div>
              }
              <input type="submit" value="Guardar" className="btn btn-primary" />
            </form>
          </div>
        </div>
      </div>
      <div className="card-footer"></div>
    </div>
  )
}
