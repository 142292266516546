import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es'
import dayGridPlugin from '@fullcalendar/daygrid';
import { withRouter } from "react-router-dom";
import { get } from '../../../config/apiHelper';
import Spinner from '../../layout/Spinner';


const CalendarioFirmas = ({ turnos = [], ...props }) => {
    const [operaciones, setOperaciones] = useState([]);
    const [loading, setLoading] = useState(true);

    const getOperacions = async () => {
        try {
            const response = await get('/operaciones?campos=turnoFirma');
            console.log(response.data)
            setOperaciones(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const events = operaciones.map((operacion) => ({
        id: operacion._id,
        title: `${operacion.lote[0].barrio.nombre} - ${operacion.lote[0].numeroLote}`,
        start: operacion.turnoFirma,
        end: new Date(new Date(operacion.turnoFirma).getTime() + 60 * 60000),
    }));

    const handleEventClick = (clickInfo) => {
        props.history.push(`/operaciones/${clickInfo.event.id}`);
    };

    useEffect(() => {
        getOperacions();
    }, []);

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="card-title">Calendario de Firmas</h3>
                    </div>

                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <FullCalendar
                            events={events}
                            plugins={[timeGridPlugin, dayGridPlugin]}
                            initialView="timeGridWeek" // Set to display as weekday view with time grid
                            eventClick={handleEventClick}
                            slotDuration="00:30:00" // Duration of slots in the grid
                            allDaySlot={false} // Remove all-day slot to focus on specific hours
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay',
                            }}
                            slotMinTime="08:00:00" // Start at 8 AM
                            slotMaxTime="20:00:00" // End at 8 PM
                            locale={esLocale} // Set calendar language to Spanish
                            weekends={false} // Hide weekends
                            hiddenDays={[0, 6]} // Explicitly hide Sunday (0) and Saturday (6)
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(CalendarioFirmas);