import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import FormularioInmobiliaria from "./components/FormularioInmobiliaria";
import Swal from 'sweetalert2';

const EditarInmobiliaria = (props) => {
  const [inmobiliaria, guardarInmobiliaria] = useState({});
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  useEffect(() => {
    const consultarAPI = async () => {
      let { id } = props.match.params;
      try {
        const inmobiliariaDB = await clienteAxios.get(`/inmobiliarias/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        guardarInmobiliaria(inmobiliariaDB.data);
      } catch (error) {
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
        console.log(error);
      }
    };

    consultarAPI();
  }, []);

  const actualizarState = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    guardarInmobiliaria({
      ...inmobiliaria,
      [e.target.name]: value,
    });
  };

  const validarInmobiliaria = () => {
    const { nombre, direccion, telefono } = inmobiliaria;
    let valido = !nombre || !direccion || !telefono;
    return valido;
  };

  const enviarInmobiliaria = async (e) => {
    e.preventDefault();

    let { id } = props.match.params;
    try {
      const rta = await clienteAxios.put(`/inmobiliarias/${id}`, inmobiliaria, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      props.history.push("/configuracion/inmobiliarias");
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const eliminarInmobiliaria = async (e) => {
    e.preventDefault();

    let { id } = props.match.params;
    try {
      const rta = await clienteAxios.delete(`/inmobiliarias/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
      props.history.push("/configuracion/inmobiliarias");
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }


  }

  if (!inmobiliaria.nombre) {
    return <Spinner />;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title mt-2">EDITAR INMOBILIARIA</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className="card-body">
        <form>

          <FormularioInmobiliaria
            actualizarState={actualizarState}
            inmobiliaria={inmobiliaria}
            inmobiliariaNueva={false}
          />

          <div className="card-footer">

            <button
              type="submit"
              className="btn btn-danger"
              onClick={eliminarInmobiliaria}
            >
              Eliminar
            </button>

            <button
              type="submit"
              className="btn btn-primary float-right"
              disabled={validarInmobiliaria()}
              onClick={enviarInmobiliaria}
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(EditarInmobiliaria);
