import { React, useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import { SelectNacionalidad } from "./SelectNacionalidad";
import { SelectEstadoCivil } from "./SelectEstadoCivil";
import DatosConyuge from "./DatosConyuge";
import SelectNupcias from "./SelectNupcias";
import { CRMContext } from "../../../context/CRMContext";
import Spinner from "../../layout/Spinner";
import clienteAxios from "../../../config/axios";
import { withRouter } from "react-router-dom";

const ShowPersonFromCUIX = ({ taxId, ...props }) => {
  const [showError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error desconocido");
  const [showSpinner, setShowSpinner] = useState(false);

  const [auth, guardarAuth] = useContext(CRMContext);
  const [client, setClient] = useState({
    nacionalidad: '',
    estadoCivil: {},
  });
  const [showSpinnerRepresentante, setShowSpinnerRepresentante] = useState(false);
  const [existentRepresentante, setExistentRepresentante] = useState(false);
  const [showErrorRepresentante, setErrorRepresentante] = useState(false);
  const [errorMessageRepresentante, setErrorMessageRepresentante] = useState("Error desconocido");

  let token = auth.token || localStorage.getItem("token");

  useEffect(() => {
    findPerson(taxId);
  }, [taxId]);

  const findPerson = async (taxId) => {
    setError(false);
    setClient({});
    let clientFound = false;
    if (!taxId || taxId.toString().length < 11) {
      return;
    }

    setShowSpinner(true);

    let apiClient = await getClientByTaxId(taxId);
    if (apiClient) {
      apiClient.telefonoMovil = apiClient.telefono?.movil;
      apiClient.telefonoFijo = apiClient.telefono?.particular;
      setClient(apiClient);
      clientFound = true;
    } else {
      apiClient = await getClientInfoFromAfip(taxId);
      if (apiClient && apiClient.hasOwnProperty('documentos')) {
        setClient(apiClient);
        clientFound = true;
      }
    }
    if (!clientFound) {
      setErrorMessage('Persona no encontrada');
      setError(true);
    }
    setShowSpinner(false);

  }

  const getRepresentante = async (e) => {
    setExistentRepresentante(false);
    setErrorRepresentante(false);
    setClient({
      ...client,
      representante: null,
    });
    let representanteFound = false;
    const taxId = e.value;
    if (taxId.length < 11) {
      return;
    }
    setShowSpinnerRepresentante(true);

    let apiRepresentante = await getClientByTaxId(taxId);
    if (apiRepresentante) {
      apiRepresentante.telefonoMovil = apiRepresentante.telefono?.movil;
      apiRepresentante.telefonoFijo = apiRepresentante.telefono?.particular;
      setClient({
        ...client,
        representante: apiRepresentante,
      });
      representanteFound = true;
      setExistentRepresentante(true);
    } else {
      apiRepresentante = await getClientInfoFromAfip(taxId);
      if (apiRepresentante && apiRepresentante.hasOwnProperty('documentos')) {
        setClient({
          ...client,
          representante: apiRepresentante,
        });
        representanteFound = true;
      }
    }
    if (!representanteFound) {
      setErrorMessageRepresentante('Persona no encontrada');
      setErrorRepresentante(true);
    }
    setShowSpinnerRepresentante(false);
  }

  const getClientByTaxId = async (taxId) => {
    try {
      const clientesConsulta = await clienteAxios.get(`/clientes/cuitCuil/${taxId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (clientesConsulta.data.hasOwnProperty('activo')) {
        return clientesConsulta.data;
      } else {
        return null;
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const getClientInfoFromAfip = async (taxId) => {

    try {
      const rta = await clienteAxios.get(`/afip?id=${taxId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { cliente: personaApi } = rta.data.data;
      if (rta.data.data.errorMessage) {
        return null
      } else {
        return personaApi;
      }
    } catch (e) {
      console.log(e);
    }
  }

  const dummyActualizarState = (param) => {
    console.log("Dummy");
  };

  if (!taxId || taxId.toString().length !== 11) {
    return (
      <>
      </>
    )
  }


  return (
    <>
      {showError &&
        <div className="row">
          <div className="col">

            <div className="alert alert-primary" role="alert">
              {errorMessage}
            </div>
          </div>
        </div>
      }
      {showSpinner &&
        <Spinner />
      }
      {(client.nombre || client.apellido) &&
        <div className="row">
          <div className="col-md-4 form-group">
            <label>Nombre *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre"
              name="nombre"
              value={client?.nombre.nombre}
              required
              disabled
            />
          </div>


          <div className="col-md-4 form-group">
            <label>Apellido *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Apellido"
              name="apellido"
              value={client?.nombre.apellido}
              required
              disabled
            />
          </div>

          {client.fechaNacimiento &&
            <div className="col-md-4 form-group">
              <label>Fecha de Nacimiento *</label>
              <input
                type="date"
                className="form-control"
                placeholder="Fecha de Nacimiento"
                name="fechaNacimiento"
                value={moment(client?.fechaNacimiento)
                  .utcOffset(180)
                  .format("YYYY-MM-DD")}
                required
                disabled
              />
            </div>
          }
        </div>
      }
      {client.razonSocial &&
        <>
          <div className="row">
            <div className="col-md-9 form-group">
              <label>Razón Social *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Razón Social"
                name="nombre"
                value={client?.razonSocial}
                disabled
              />
            </div>

            <div className="col-md-3 form-group">
              <label>CUIL</label>
              <NumberFormat
                className="form-control"
                name="dni"
                value={client?.documentos.cuitCuil}
                placeholder="20.123.456"
                format="##.###.###"
                mask="_"
                required
                disabled
              />
            </div>
          </div>
        </>
      }
      <div className="row">
        {client.documentos && client.documentos.dni &&
          <div className="col-md-3 form-group">
            <label>Tipo: *</label>

            <input
              type="text"
              className="form-control"
              placeholder="Tipo DNI"
              name="dniTipo"
              value={client?.documentos.dniTipo}
              required
              disabled
            />
          </div>
        }

        {client.documentos && client.documentos.dni &&
          <div className="col-md-3 form-group">
            <label>Documento *</label>
            <NumberFormat
              className="form-control"
              name="dni"
              value={client?.documentos.dni}
              placeholder="20.123.456"
              format="##.###.###"
              mask="_"
              required
              disabled
            />
          </div>
        }

      </div>

      {client.domicilio &&
        <>
          <div className="form-group">
            <label>Domicilio</label>
          </div>

          <div className="row">
            <div className="col-md-5 form-group">
              <label>Provincia *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Provincia"
                name="provincia"
                value={client.domicilio.provincia}
                disabled
              />
            </div>

            <div className="col-md-5 form-group">
              <label>Municipio *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Municipio"
                name="municipio"
                value={client.domicilio.municipio}
                disabled
              />
            </div>
            <div className="col-md-2 form-group">
              <label>Código Postal</label>
              <input
                type="text"
                className="form-control"
                placeholder="CP"
                name="cp"
                value={client?.domicilio.cp}
                required
                disabled
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 form-group">
              <label>Calle *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Calle"
                name="calle"
                value={client?.domicilio.calle}
                required
                disabled
              />
            </div>

            <div className="col-md-3 form-group">
              <label>Altura *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Altura"
                name="altura"
                value={client?.domicilio.altura}
                required
                disabled
              />
            </div>
            <div className="col-md-3 form-group">
              <label>Piso / Depto</label>
              <input
                type="text"
                className="form-control"
                placeholder="Piso / Depto"
                name="pisoDepto"
                value={client?.domicilio.pisoDepto}
                disabled
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 form-group">
              <label>Teléfono Móvil * (Sin 0 y sin 15)</label>
              <NumberFormat
                className="form-control"
                placeholder="__________"
                name="telefonoMovil"
                format="##########"
                mask="_"
                value={client?.telefonoMovil || ''}
                required
                readOnly={true}
              />
            </div>
            <div className="col-md-4 form-group">
              <label>Teléfono Fijo (Sin 0)</label>
              <NumberFormat
                className="form-control"
                name="telefonoFijo"
                format="##########"
                placeholder="__________"
                mask="_"
                value={client?.telefonoFijo || ''}
                readOnly={true}
              />
            </div>
            {client && client.nombre &&
              <div className="col-md-4 form-group">
                <label>Apellido Materno *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Apellido Materno"
                  name="apellidoMaterno"
                  value={client?.nombre.apellidoMaterno}
                  disabled={true}
                />

              </div>
            }
          </div>

          <div className="row">
            <div className="col-md-8 form-group">
              <label>Email *</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="email"
                value={client?.email}
                required
                disabled={true}
              />
            </div>
            {(client.nombre || client.apellido) &&
              <SelectNacionalidad
                cliente={client}
                token={token}
                required
                disabled={true}
                actualizarState={dummyActualizarState}
              />
            }
            {client.fechaContratoSocial &&
              <div className="col-md-4 form-group">
                <label>Alta de Contrato Social *</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Alta de Contrato Social"
                  name="fechaAlta"
                  value={moment(client?.fechaContratoSocial)
                    .utcOffset(180)
                    .format("YYYY-MM-DD")}
                  disabled
                />
              </div>
            }
          </div>
        </>
      }
      {client.razonSocial &&
        <>
          <div className="row">
            <label>
              Información del representante:
            </label>
          </div>
          <div className="row">
            <div className="col-md-4 form-group">
              <label>Ingrese CUIL del representante:</label>
              <NumberFormat
                className="form-control"
                name="numeroCuilCuit"
                placeholder="20-12345678-9"
                format="##-########-#"
                mask="_"
                required
              />
              {showErrorRepresentante &&
                <div className="alert alert-primary" role="alert">
                  {errorMessageRepresentante}
                </div>
              }
            </div>
          </div>
          {showSpinnerRepresentante &&
            <Spinner />
          }
        </>
      }
      {(client.representante?.nombre || client.representante?.apellido) &&
        <div className="row">
          <div className="col-md-4 form-group">
            <label>Nombre *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre"
              name="nombre"
              value={client.representante?.nombre.nombre}
              required
              disabled
            />
          </div>


          <div className="col-md-4 form-group">
            <label>Apellido *</label>
            <input
              type="text"
              className="form-control"
              placeholder="Apellido"
              name="apellido"
              value={client.representante?.nombre.apellido}
              required
              disabled
            />
          </div>

          {client.representante?.fechaNacimiento &&
            <div className="col-md-4 form-group">
              <label>Fecha de Nacimiento *</label>
              <input
                type="date"
                className="form-control"
                placeholder="Fecha de Nacimiento"
                name="fechaNacimiento"
                value={moment(client.representante?.fechaNacimiento)
                  .utcOffset(180)
                  .format("YYYY-MM-DD")}
                required
                disabled
              />
            </div>
          }
        </div>
      }
      {client.representante?.domicilio &&
        <>
          <div className="form-group">
            <label>Domicilio</label>
          </div>

          <div className="row">
            <div className="col-md-5 form-group">
              <label>Provincia *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Provincia"
                name="provincia"
                value={client.representante?.domicilio.provincia}
                disabled
              />
            </div>

            <div className="col-md-5 form-group">
              <label>Municipio *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Municipio"
                name="municipio"
                value={client.representante?.domicilio.municipio}
                disabled
              />
            </div>
            <div className="col-md-2 form-group">
              <label>Código Postal</label>
              <input
                type="text"
                className="form-control"
                placeholder="CP"
                name="cp"
                value={client.representante?.domicilio.cp}
                required
                disabled
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 form-group">
              <label>Calle *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Calle"
                name="calle"
                value={client.representante?.domicilio.calle}
                required
                disabled
              />
            </div>

            <div className="col-md-3 form-group">
              <label>Altura *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Altura"
                name="altura"
                value={client.representante?.domicilio.altura}
                required
                disabled
              />
            </div>
            <div className="col-md-3 form-group">
              <label>Piso / Depto</label>
              <input
                type="text"
                className="form-control"
                placeholder="Piso / Depto"
                name="pisoDepto"
                value={client.representante?.domicilio.pisoDepto}
                disabled
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 form-group">
              <label>Teléfono Móvil * (Sin 0 y sin 15)</label>
              <NumberFormat
                className="form-control"
                placeholder="__________"
                name="telefonoMovil"
                format="##########"
                mask="_"
                value={client?.representante.telefonoMovil || ''}
                required
                readOnly={true}
              />
            </div>
            <div className="col-md-4 form-group">
              <label>Teléfono Fijo (Sin 0)</label>
              <NumberFormat
                className="form-control"
                name="telefonoFijo"
                format="##########"
                placeholder="__________"
                mask="_"
                value={client?.representante.telefonoFijo || ''}
                readOnly={true}
              />
            </div>
            {client && client.nombre &&
              <div className="col-md-4 form-group">
                <label>Apellido Materno *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Apellido Materno"
                  name="apellidoMaterno"
                  value={client?.representante.nombre.apellidoMaterno}
                  disabled={true}
                />

              </div>
            }
          </div>

          <div className="row">
            <div className="col-md-8 form-group">
              <label>Email *</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="email"
                value={client.representante?.email}
                required
                disabled={true}
              />
            </div>
            {(client.representante?.nombre || client.representante?.apellido) &&
              <SelectNacionalidad
                cliente={client.representante}
                token={token}
                required
                disabled={true}
                actualizarState={dummyActualizarState}
              />
            }
          </div>
        </>
      }
      {(client.nombre || client.apellido) &&
        <>
          <SelectEstadoCivil cliente={client} disabled={true} actualizarState={dummyActualizarState} />
          <SelectNupcias cliente={client} disabled={true} actualizarState={dummyActualizarState} />
          <DatosConyuge cliente={client} disabled={true} actualizarState={dummyActualizarState} />
        </>
      }
    </>
  );
};

export default withRouter(ShowPersonFromCUIX);