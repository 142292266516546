import React from 'react';
import clienteAxios from "../../../config/axios";
import Swal from "sweetalert2";

function BotoneraFormularios(props) {
  const {operacion, token} = props;

  const getFormularioReserva = async() => {
    try {
      const rta = await clienteAxios.get(`/formularios/reserva/${operacion._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([rta.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Formulario Reserva - ${operacion.cliente.razonSocial ?? operacion.cliente.nombre.apellido} .docx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error.response);
      await Swal.fire({
        type: "error",
        title: 'Hubo un error al intentar generar el formulario',
        text: "Hubo un error",
      });
    }
  }

  return (
    <>
      <div className="card-header row">
        <div className="col-md-12">
          <h3 className="card-title">
            <strong>Acciones:</strong>
          </h3>
        </div>
      </div>
      <div className="row mt-3 mb-5">
        <div className="col d-flex justify-content-around ">
          <button className='btn btn-primary' disabled>Generar Formulario de Operación</button>
          <button className='btn btn-primary' disabled>Generar Formulario de Firma</button>
          <button className='btn btn-primary' onClick={getFormularioReserva}>Generar Formulario de Reserva</button>
        </div>
      </div>
    </>
  );
}

export default BotoneraFormularios;