import React, { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import EscribaniasFiltros from "./utils/filter";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

const ListadoEscribanias = (props) => {
  const [escribanias, setEscribanias] = useState([]);
  const [escribaniasOriginal, setescribaniasOriginal] = useState([]);
  const [auth, guardarAuth] = useContext(CRMContext);
  const [loading, setLoading] = useState(true);
  let token = auth.token || localStorage.getItem("token");
  const [query, setQuery] = useState('');
  let paginator = {
    page: 1,
    limit: 10
  };
  const [totalRows, setTotalRows] = useState(0);
  const consultarAPI = async () => {
    try {
      const escribaniasDB = await clienteAxios.get(`/escribania?${query}&page=${paginator.page - 1}&limit=${paginator.limit}&sort=nombre,1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const arrayOriginal = escribaniasDB.data.data.map((escribania) => {
        return {
          nombre: escribania.nombre,
          direccion: escribania.domicilio,
          telefono: escribania.telefono,
          email: escribania.email,
          estado: escribania.activo ? "Activa" : "Inactiva",
          url: `/configuracion/escribanias/editar/${escribania._id}`,
        };
      });
      setTotalRows(escribaniasDB.data.totalCount);
      setEscribanias(arrayOriginal);
      setescribaniasOriginal(arrayOriginal);
      setLoading(false);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    consultarAPI();
  }, [query]);

  // Datatables
  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      sortable: true,
      width: '20%'
    },
    {
      name: "Dirección",
      selector: (row) => row.direccion,
      sortable: true,
      width: '20%'
    },
    {
      name: "Teléfono",
      selector: (row) => row.telefono,
      sortable: true,
      width: '20%'
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: '20%'
    },
    {
      name: "Estado",
      selector: (row) => row.estado,
      sortable: true,
      width: '10%'
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <Link to={row.url}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
      width: '10%'
    },
  ];

  const data = escribanias.map( escribania => {
    return {
      "Nombre": escribania.nombre,
      "Dirección": escribania.direccion,
      "Teléfono": escribania.telefono,
      "Email": escribania.email,
      "Estado": escribania.estado ? 'Activo' : 'Inactivo',
    }
  })

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'data.xlsx');
  };

  const handlePerRowsChange = async (limit) => {
    paginator = {
      ...paginator,
      limit,
    };
    consultarAPI();
  }

  const handlePageChange = async (page) => {
    paginator = {
      ...paginator,
      page,
    };
    consultarAPI();
  }

  // Configuracion del datatable
  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title">Listado de Escribanías</h3>
          </div>
          <div className="col-md-2">
            <button className="btn btn-block btn-success mb-2" onClick={exportToExcel}>Exportar</button>
          </div>
          <div className="col-md-2">
            <Link to={`/configuracion/escribanias/nueva`}>
              <button type="button" className="btn btn-block btn-success">
                Agregar Escribanía
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body">
        <EscribaniasFiltros
          setQuery={setQuery}
        />
        <DataTable
          columns={columns}
          data={escribanias}
          pagination
          paginationServer
          paginationComponentOptions={paginationComponentOptions}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationTotalRows={totalRows}
          paginationDefaultPage={paginator.page}
          paginationDefaultRowsPerPage={paginator.limit}
        />
      </div>
    </div>
  );
};

export default withRouter(ListadoEscribanias);
