import { React, useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { SelectNacionalidad } from "../clientes/components/SelectNacionalidad";
import { SelectEstadoCivil } from "../clientes/components/SelectEstadoCivil";
import DatosConyuge from "../clientes/components/DatosConyuge";
import SelectNupcias from "../clientes/components/SelectNupcias";
import { CRMContext } from "../../context/CRMContext"
import Spinner from "../layout/Spinner";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";

const FormularioFirmantesLife = (props) => {
  const { id } = props.match.params;
  const [showError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error desconocido");
  const [showSpinner, setShowSpinner] = useState(false);

  const [auth, guardarAuth] = useContext(CRMContext);
  const [firmanteLife, setFirmanteLife] = useState({
    nacionalidad: '',
    estadoCivil: {},
  });
  const token = auth.token || localStorage.getItem("token");
  const existentClient = false;

  useEffect(() => {
    if (id) {
      getFirmante(id);
    }
  }, []);

  const getPerdonByTaxId = async (e) => {
    const taxId = e.value;
    if (taxId.length < 11) {
      return;
    }
    setShowSpinner(true);
    try {
      const response = await clienteAxios.get(`/afip?id=${taxId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { cliente: personaApi } = response.data.data;
      if (response.data.data.errorMessage) {
        setErrorMessage(response.data.data.errorMessage);
        return null
      } else {
        console.log(personaApi);
        setFirmanteLife(personaApi);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setShowSpinner(false);
    }
  };

  const getFirmante = async (id) => {
    if (!id) {
      return;
    }
    setShowSpinner(true);
    try {
      const response = await clienteAxios.get(`/firmantesLife/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFirmanteLife(response.data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setShowSpinner(false);
    }
  }

  const actualizarStateV2 = (state) => {
    setFirmanteLife(({
      ...firmanteLife,
      ...state
    }))
  }

  const actualizarState = (e) => {
    setFirmanteLife({
      ...firmanteLife,
      [e.target.name]: e.target.value
    });
  }

  const actualizarTelefonoMovil = (e) => {
    const telefono = e.floatValue;
    const firmanteCopy = Object.assign({}, firmanteLife);
    if (!firmanteCopy.telefono) {
      firmanteCopy.telefono = {};
    }
    firmanteCopy.telefono.movil = telefono;
    setFirmanteLife({
      ...firmanteCopy
    });
  }

  const actualizarTelefonoParticular = (e) => {
    const telefono = e.floatValue;
    const firmanteCopy = Object.assign({}, firmanteLife);
    if (!firmanteCopy.telefono) {
      firmanteCopy.telefono = {};
    }
    firmanteCopy.telefono.particular = telefono;
    setFirmanteLife({
      ...firmanteCopy
    });
  }

  const actualizarApellidoMaterno = (e) => {
    const apellidoMaterno = e.target.value;
    const firmanteCopy = Object.assign({}, firmanteLife);
    firmanteCopy.nombre.apellidoMaterno = apellidoMaterno;
    setFirmanteLife({
      ...firmanteCopy
    });
  }

  const enviarFirmante = async (e) => {
    e.preventDefault();
    if (id) {
      await updateFirmante();
    } else {
      await createFirmante();
    }
  }

  const updateFirmante = async () => {

    const response = await clienteAxios.put(`/firmantesLife/${id}`,
      firmanteLife,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    if (response.data.estado == "ok") {
      await Swal.fire("¡Correcto!", response.data.msg, "success");
      props.history.push("/configuracion/firmantesLife");
    } else if (response.data.estado == "error") {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.data.msg,
      });
    }
  }

  const updateNombre = (e) => {
    const { name, value } = e.target;
    setFirmanteLife({
      ...firmanteLife,
      nombre: {
        ...firmanteLife.nombre,
        [name]: value
      },
    })
  }

  const createFirmante = async () => {
    try {
      const firmanteCopy = Object.assign({}, firmanteLife);
      const response = await clienteAxios.post('/firmantesLife',
        firmanteCopy,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      if (response.data.estado == "ok") {
        await Swal.fire("¡Correcto!", response.data.msg, "success");
        props.history.push("/configuracion/firmantesLife");
      }
    } catch (error) {
      console.log('Error: ', error)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.msg,
      });
    }
  }

  const updateDomicilio = (e) => {
    const { name, value } = e.target;
    const firmanteCopy = { ...firmanteLife };
    firmanteCopy.domicilio[name] = value;
    setFirmanteLife(firmanteCopy);
  }


  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-11">
            <h3 className="card-title mt-2">Firmante</h3>
          </div>
          <div className="col-md-1">
            <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <form onSubmit={enviarFirmante}>
          <div className="row">
            <div className="col-md-4 form-group">
              <label>Ingrese el CUIT / CUIL de la persona</label>
              <NumberFormat
                className="form-control"
                name="numeroCuilCuit"
                placeholder="20-12345678-9"
                format="##-########-#"
                mask="_"
                onValueChange={getPerdonByTaxId}
              />
              {showError &&
                <div className="alert alert-primary" role="alert">
                  {errorMessage}
                </div>
              }
            </div>
          </div>
          {showSpinner &&
            <Spinner />
          }
          {(firmanteLife && firmanteLife.nombre && Object.keys(firmanteLife.nombre).length > 0) &&
            <div className="row">
              <div className="col-md-4 form-group">
                <label>Nombre *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre"
                  name="nombre"
                  value={firmanteLife?.nombre.nombre}
                  onChange={updateNombre}
                  required
                />
              </div>


              <div className="col-md-4 form-group">
                <label>Apellido *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Apellido"
                  name="apellido"
                  value={firmanteLife?.nombre.apellido}
                  onChange={updateNombre}
                  required
                />
              </div>

              {firmanteLife.fechaNacimiento &&
                <div className="col-md-4 form-group">
                  <label>Fecha de Nacimiento *</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Fecha de Nacimiento"
                    name="fechaNacimiento"
                    value={moment(firmanteLife?.fechaNacimiento)
                      .utcOffset(180)
                      .format("YYYY-MM-DD")}
                    required
                  />
                </div>
              }
            </div>
          }
          <div className="row">
            {firmanteLife.documentos && firmanteLife.documentos.dni &&
              <div className="col-md-3 form-group">
                <label>Tipo: *</label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Tipo DNI"
                  name="dniTipo"
                  value={firmanteLife?.documentos.dniTipo}
                  required
                />
              </div>
            }

            {firmanteLife.documentos && firmanteLife.documentos.dni &&
              <div className="col-md-3 form-group">
                <label>Documento *</label>
                <NumberFormat
                  className="form-control"
                  name="dni"
                  value={firmanteLife?.documentos.dni}
                  placeholder="20.123.456"
                  format="##.###.###"
                  mask="_"
                  required
                />
              </div>
            }

          </div>

          {firmanteLife.domicilio &&
            <>
              <div className="form-group">
                <label>Domicilio</label>
              </div>

              <div className="row">
                <div className="col-md-5 form-group">
                  <label>Provincia *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Provincia"
                    name="provincia"
                    value={firmanteLife.domicilio.provincia}
                    onChange={updateDomicilio}
                  />
                </div>

                <div className="col-md-5 form-group">
                  <label>Municipio *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Municipio"
                    name="municipio"
                    value={firmanteLife.domicilio.municipio}
                    onChange={updateDomicilio}
                  />
                </div>
                <div className="col-md-2 form-group">
                  <label>Código Postal</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="CP"
                    name="cp"
                    value={firmanteLife?.domicilio.cp}
                    onChange={updateDomicilio}
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 form-group">
                  <label>Calle *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Calle"
                    name="calle"
                    value={firmanteLife?.domicilio.calle}
                    onChange={updateDomicilio}
                    required
                  />
                </div>

                <div className="col-md-3 form-group">
                  <label>Altura *</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Altura"
                    name="altura"
                    value={firmanteLife?.domicilio.altura}
                    onChange={updateDomicilio}
                    required
                  />
                </div>
                <div className="col-md-3 form-group">
                  <label>Piso / Depto</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Piso / Depto"
                    name="pisoDepto"
                    value={firmanteLife?.domicilio.pisoDepto}
                    onChange={updateDomicilio}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group">
                  <label>Teléfono Móvil * (Sin 0 y sin 15)</label>
                  <NumberFormat
                    className="form-control"
                    placeholder="__________"
                    name="telefonoMovil"
                    format="##########"
                    mask="_"
                    onValueChange={actualizarTelefonoMovil}
                    value={firmanteLife?.telefono?.movil || ''}
                    required
                  />
                </div>
                <div className="col-md-4 form-group">
                  <label>Teléfono Fijo (Sin 0)</label>
                  <NumberFormat
                    className="form-control"
                    name="telefonoFijo"
                    format="##########"
                    placeholder="__________"
                    mask="_"
                    onValueChange={actualizarTelefonoParticular}
                    value={firmanteLife?.telefono?.particular || ''}
                  />
                </div>
                {firmanteLife && firmanteLife.nombre &&
                  <div className="col-md-4 form-group">
                    <label>Apellido Materno *</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Apellido Materno"
                      name="apellidoMaterno"
                      onChange={actualizarApellidoMaterno}
                      value={firmanteLife?.nombre.apellidoMaterno}
                      required
                    />

                  </div>
                }
              </div>

              <div className="row">
                <div className="col-md-8 form-group">
                  <label>Email *</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    onChange={actualizarState}
                    value={firmanteLife?.email}
                    required

                  />
                </div>
                {(firmanteLife.nombre || firmanteLife.apellido) &&
                  <SelectNacionalidad
                    actualizarState={actualizarState}
                    cliente={firmanteLife}
                    token={token}
                    required
                  />
                }
                {firmanteLife.fechaContratoSocial &&
                  <div className="col-md-4 form-group">
                    <label>Alta de Contrato Social *</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Alta de Contrato Social"
                      name="fechaAlta"
                      value={moment(firmanteLife?.fechaContratoSocial)
                        .utcOffset(180)
                        .format("YYYY-MM-DD")}
                    />
                  </div>
                }

              </div>
            </>
          }
          {(firmanteLife.nombre || firmanteLife.apellido) &&
            <>
              <SelectEstadoCivil actualizarState={actualizarState} cliente={firmanteLife} />
              <SelectNupcias cliente={firmanteLife} actualizarState={actualizarState} />
              <DatosConyuge cliente={firmanteLife} actualizarState={actualizarStateV2} />
            </>
          }
          <div className="card-footer">
            <button
              type="submit"
              className="btn btn-primary float-right"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormularioFirmantesLife;