import React, { useContext, useEffect, useState } from 'react'
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';

export const Logs = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const rolId = localStorage.getItem("rolId");
  const [info, setInfo] = useState([])
  const [error, setError] = useState([])

  const consultarInfo = async () => {
    try {
      const logs = await clienteAxios.get(`/api/logs/info`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setInfo(logs.data.split('\n'))
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  const consultarError = async () => {
    try {
      const logs = await clienteAxios.get(`/api/logs/error`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setError(logs.data.split('\n'))
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    consultarError()
    consultarInfo()
  }, [])

  console.log(info);

  if (rolId !== '4') {
    return (
      <div className="card">
        <div className="card-header">
          <h3>Forbidden</h3>
        </div>
      </div>
    )
  }


  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title mt-2">LOGS</h3>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <h4>ERROR</h4>
            <ul>
              {error.map((i, index) => (
                <li key={index}>{i}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h4>INFO</h4>
            <ul>
              {info.map((i, index) => (
                <li key={index}>{i}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
