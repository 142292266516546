import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import NumberFormat from "react-number-format";

const ReadquisicionLotes = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [barrios, setBarrios] = useState([]);
  const [lotes, setLotes] = useState([]);
  const [datosOperacion, setDatosOperacion] = useState({
    usuario: localStorage.getItem("idUsuario"),
  });

  useEffect(() => {
    const getBarriosAPI = async () => {
      try {
        const barriosAPI = await clienteAxios.get("/barrios?activo=true", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBarrios(
          barriosAPI.data.map((barrio) => {
            return {
              value: barrio._id,
              label: barrio.nombre,
            };
          })
        );
      } catch (error) {
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
        console.log(error);
      }
    };

    getBarriosAPI();
  }, []);

  const selectBarrio = (e) => {
    const getLotesAPI = async () => {
      try {
        const lotesAPI = await clienteAxios.get(`/lotes/${e.value}?estado=2`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLotes(
          lotesAPI.data.map((lote) => {
            return {
              value: lote._id,
              label: `Número: ${lote.numeroLote}`,
            };
          })
        );
      } catch (error) {
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
        console.log(error);
      }
    };

    getLotesAPI();
  };

  const selectLote = (e) => {
    setDatosOperacion({
      ...datosOperacion,
      lote: e.value,
    });
  };

  const setDetalle = (e) => {
    setDatosOperacion({
      ...datosOperacion,
      detalle: e.target.value,
    });
  };

  const enviarFormulario = async (e) => {
    e.preventDefault();

    try {
      const rta = await clienteAxios.post("/readquisicion", datosOperacion, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
    props.history.push('/operaciones/readquisicion');
  };
  
  const volver = () => {
    props.history.goBack();
  }

  const validarDatos = () => {
    return !(
      datosOperacion.detalle != undefined &&
      datosOperacion.lote != undefined &&
      datosOperacion.valor != undefined
    )
  }



  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Readquisición de Lotes</h3>
        </div>

        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-md-6 form-group">
                <label>
                  Seleccionar Desarrollo
                </label>
                <Select
                  placeholder="--- Seleccionar ---"
                  options={barrios}
                  onChange={selectBarrio}
                />
              </div>

              <div className="col-md-6 form-group">
                <label>Seleccionar Lote</label>
                <Select
                  placeholder="--- Seleccionar ---"
                  options={lotes}
                  onChange={selectLote}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label>
                  Valor en que se toma el lote:
                </label>
                <NumberFormat
                  className="form-control"
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$"}
                  onValueChange={(e) => {
                    setDatosOperacion({
                      ...datosOperacion,
                      valor: e.floatValue,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label>Detalles de la operación</label>
                <textarea
                  className="form-control"
                  name="detalles"
                  cols="200"
                  rows="7"
                  onChange={setDetalle}
                ></textarea>
              </div>
            </div>
          </form>
        </div>
        <div className="card-footer">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={volver}
          >
            Volver
          </button>

          <button
            type="submit"
            className="btn btn-primary float-right"
            onClick={enviarFormulario}
            disabled={validarDatos()}
          >
            Guardar
          </button>

        </div>
      </div>
    </>
  );
};

export default ReadquisicionLotes;
