import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom'

import { CRMContext } from '../../context/CRMContext';

import { SelectLotes } from './components/SelectLotes';
import { SelectClient } from './components/SelectClient';
import { AddComments } from './components/AddComments';
import Spinner from '../layout/Spinner';
import clienteAxios from '../../config/axios';

function NuevoBloqueo(props) {
  const [auth, guardarAuth] = useContext(CRMContext);
  const [showSpinner, setShowSpinner] = useState(false)
  let token = auth.token || localStorage.getItem("token");
  const [lotesSeleccionados, setLotesSeleccionados] = useState([]);
  const [client, setClient] = useState({
    nacionalidad: '',
    estadoCivil: {},
  });

  const solicitarBloqueo = async () => {

    if (!lotesSeleccionados.length || !client.email) {
      return;
    }
    const bloqueo = {
      lotes: lotesSeleccionados.map(lote => lote.id),
      client
    }
    console.log(bloqueo)
    try {
      setShowSpinner(true)
      const response = await clienteAxios.post(`/bloqueos`,
        bloqueo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      if(response.status === 201) {
        props.history.push('/bloqueos')
      }
    } catch (error) {
      if (error.response?.status === 500) {
        // localStorage.clear();
        // props.history.push("/login");
      }
      console.log(error);
    } finally {
      setShowSpinner(false)
    }

  }

  if (!token || showSpinner) {
    return <Spinner />
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-12">
            <h3 className="card-title">Nuevo Bloqueo</h3>
            <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <SelectLotes token={token} setLotesSeleccionados={setLotesSeleccionados} lotesSeleccionados={lotesSeleccionados} />
        <SelectClient client={client} setClient={setClient} token={token} />
        {/* <AddComments /> */}
        <div className="row">
          <div className="col">
            <button className='btn btn-primary float-right m-3' onClick={solicitarBloqueo}>Solicitar Bloqueo</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(NuevoBloqueo)