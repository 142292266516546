import React, { useContext, useEffect, useState } from 'react'
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';

export const ListadoInversionistas = ({ history, ...props }) => {

  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [inversionistas, setInversionistas] = useState([]);

  const getInversionistas = async () => {
    try {
      const estadosOperacionesDb = await clienteAxios.get("/api/inversionistas", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setInversionistas(estadosOperacionesDb.data.data);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  useEffect(() => {
    getInversionistas();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-10">
              <h3 className="card-title">Inversionistas</h3>
            </div>
            <div className="col-md-1">
              <button className="btn btn-primary"
                onClick={() => history.push(`/configuracion/inversionistas/nuevo`)}
              >Nuevo</button>
            </div>
            <div className="col-md-1">
              <button className="btn btn-primary float-right" onClick={history.goBack}>Volver</button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Nombre Corto</th>
                    <th>Identificación Tributaria</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {inversionistas.map(inversionista => (
                    <tr key={inversionista._id}>
                      <td>{inversionista.nombreCorto}</td>
                      <td>{inversionista.identificacionTributaria}</td>
                      <td>
                        <button className="btn btn-primary"
                          onClick={() => history.push(`/configuracion/inversionistas/editar/${inversionista._id}`)}
                        >Editar</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </>
  )
}
