import React, { useState, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import clienteAxios from '../../config/axios';
import { CRMContext } from '../../context/CRMContext';
import Swal from 'sweetalert2';
import Spinner from '../layout/Spinner';

const ExcelUploader = (props) => {

  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const [columns, setColumns] = useState([]);
  const [nombreDesarrollo, setNombreDesarrollo] = useState('');
  const [data, setData] = useState([]);
  const [mappings, setMappings] = useState({});
  const [jsonPreview, setJsonPreview] = useState(null);
  const [loadingInfo, setLoadingInfo] = useState(false);

  const entityKeys = [
    { key: 'numeroLote', value: 'Número de Lote' },
    { key: 'precioLista', value: 'Precio de Lista' },
    { key: 'superficie', value: 'Superficie' },
  ]

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

      setColumns(sheetData[0]);
      setData(sheetData.slice(1));
    };

    reader.readAsBinaryString(file);
  };

  const handleMappingChange = (column, entityKey) => {
    setMappings((prev) => ({ ...prev, [column]: entityKey }));
  };

  const handlePreview = () => {
    const jsonPreviewData = data.map((row) => {
      const mappedRow = {};
      columns.forEach((col, index) => {
        if (mappings[col]) {
          mappedRow[mappings[col]] = row[index];
        }
      });
      return mappedRow;
    });
    setJsonPreview({
      barrio: props.match.params.id,
      lotes: jsonPreviewData
    });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const submit = async () => {
    try {
      setLoadingInfo(true);
      const response = await clienteAxios.put('/lotes/updateByNroLote',
        jsonPreview,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      if (response.data.estado === 'ok') {
        Swal.fire('Correcto', response.data.msg, 'success')
          .then(() => props.history.push(`/lotes/${props.match.params.id}`));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingInfo(false);
    }
  }

  const getNombreDesarrollo = async () => {
    try {
      const response = await clienteAxios.get(`/barrios/${props.match.params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      return response.data.nombre;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getNombreDesarrollo().then((nombre) => setNombreDesarrollo(nombre));
  }, []);


  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Actualizar lotes masivamente - {nombreDesarrollo}</h3>
          <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
        </div>
        <div className='card-body'>
          {loadingInfo ? <Spinner /> : null}
          {!loadingInfo ?
            <div className="container mt-5">
              <div {...getRootProps()} className="border border-secondary rounded p-5 text-center">
                <input {...getInputProps()} />
                <p>Arrastre y suelte el Excel aquí o haga click para seleccionar uno</p>
              </div>

              {columns.length > 0 && (
                <div className="mt-4">
                  <h3>Columnas</h3>
                  <div className="row">
                    {columns.map((col) => (
                      <div className="col-md-6 mb-3 form-group" key={col}>
                        <label className="form-label">{col}</label>
                        <select className="form-control" onChange={(e) => handleMappingChange(col, e.target.value)}>
                          <option value="">Select entity key</option>
                          {entityKeys.map((key) => (
                            <option key={key.key} value={key.key}>{key.value}</option>
                          ))}
                        </select>
                      </div>
                    ))}
                  </div>
                  <button className="btn btn-primary" onClick={handlePreview}>Previsualizar Datos</button>
                </div>
              )}

              {jsonPreview && (
                <div className="mt-4">
                  <h3>JSON Preview</h3>
                  <pre className="bg-light p-3 border rounded">{JSON.stringify(jsonPreview, null, 2)}</pre>
                  <button className="btn btn-success" onClick={submit}>Enviar</button>
                </div>
              )}
            </div>
            : null}
        </div>
      </div>
    </>
  );
};

export default ExcelUploader;
