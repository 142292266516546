import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link, withRouter } from "react-router-dom";
import clienteAxios from '../../config/axios';
import { CRMContext } from '../../context/CRMContext';
import Spinner from '../layout/Spinner';


const Bienes = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const [bienes, setBienes] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCurrency = (value) => {
    if (!Number(value)) {
      value = 0;
    }
    return new Intl.NumberFormat("es-AR", {
      currency: "ARS",
      style: "currency",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  const getDate = (value) => {
    if (!value) {
      return '';
    }
    return new Intl.DateTimeFormat("es-Ar").format(value);
  }

  const getTipo = (value) => {
    const tipo = {
      inmueble: 'Inmueble',
      vehiculo: 'Vehículo',
    }
    return tipo[value] || 'Desconocido';
  }

  const getEstado = (value) => {
    return value.estado;
  }

  const getDetalle = (row) => {
    const { tipo, descripcion } = row;
    if (tipo === 'inmueble') {
      return `${descripcion && descripcion.type ? `Tipo: ${descripcion.type}` : 'Desconocido'}${descripcion && descripcion.direccion ? ` - Dirección: ${descripcion && descripcion.direccion}  ` : ''}`
    } else if (tipo === 'vehiculo') {
      return `Marca: ${descripcion.vehiculo?.brand} - Modelo: ${descripcion.vehiculo?.model} - Version: ${descripcion.vehiculo?.version} - Año: ${descripcion.vehiculo?.year}`
    }
  }

  const consultarAPI = async () => {
    try {
      const apiResponse = await clienteAxios.get("/bienes", {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });

      setBienes(apiResponse.data.data);
      setLoading(false);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    consultarAPI();
  }, []);

  const columns = [
    {
      name: 'Alta',
      selector: 'fechaAlta',
      sortable: true,
      width: '10%',
      format: (row) => getDate(new Date(row.fechaAlta)), // Format the date for display
    },
    {
      name: 'Estado',
      selector: (row) => getEstado(row),
      sortable: true,
      width: '10%'
    },
    {
      name: 'Tipo',
      selector: (row) => getTipo(row.tipo),
      sortable: true,
      width: '10%'
    },
    {
      name: 'Detalle',
      selector: (row) => getDetalle(row),
      sortable: true,
      width: '60%'
    },
    {
      name: 'Ver',
      button: true,
      cell: (row) => (
        <Link to={`/bienes/${row._id}`}>
          <button type="button" className="btn btn-block btn-success">
            Ver
          </button>
        </Link>
      ),
      width: '10%'
    },
  ];
  const data = bienes;

  const compareDates = (a, b, field) => {
    const dateA = new Date(a[field]);
    const dateB = new Date(b[field]);

    return dateA - dateB;
  };

  const handleSort = (column, direction) => {
    const sortedData = data.slice().sort((a, b) => {
      if (direction === 'asc') {
        return compareDates(a, b, column.selector);
      } else {
        return compareDates(b, a, column.selector);
      }
    });

    setBienes(sortedData);
  };

  if (loading) {
    return (
      <Spinner />
    )
  }



  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-10">
            <h3 className="card-title">Bienes</h3>
          </div>
          <div className="col-md-2">
            <Link to={`/bienes/nuevo`}>
              <button type="button" className="btn btn-block btn-success">
                Agregar Bien
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body">

        {/* <div className="form-group">
          <input
            placeholder="Buscar:"
            // onChange={buscar}
            type="text"
            name="buscar"
            className="form-control"
          />
        </div> */}


        <DataTable columns={columns} data={bienes} pagination onSort={handleSort} defaultSortField="fechaAlta" defaultSortAsc={false} />
      </div>
    </div>
  );
}

export default withRouter(Bienes);