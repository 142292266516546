import React from "react";

export const SelectTipoDni = ({ tipoDni, disabled, setState }) => {


  return (
    <>
      <div className="col-md-3 form-group">
        <label>Tipo: *</label>

        <input
          type="text"
          className="form-control"
          placeholder="Tipo DNI"
          name="dniTipo"
          value={tipoDni}
          required
          onChange={setState}
          disabled={disabled}
        />
      </div>
    </>
  );
};
