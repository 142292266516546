import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';


import GetOrCreateClient from "../../../clientes/components/GetOrCreateClient";
import SelectLotes from "./SelectLotes";
import SelectFormaPago from "./wizardFormaPago/components/SelectFormaPago";
import { CRMContext } from "../../../../context/CRMContext";
import DetallePanelLateral from "./DetallePanelLateral";
import GetOrCreateFirmante from "./GetOrCreateFirmantes";
import clienteAxios from "../../../../config/axios";
import GetDolarMercado from "../../../utils/GetDolarMercado";
import AnticiposUtils from "./utils/AnticiposUtils";

const NuevaOperacion = (props) => {

  const [queryParams, setQueryParams] = useState({});

  const getQueryParamsFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams) {
      params[key] = value;
    }
    setQueryParams(params);
  };


  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [parametros, setParametros] = useState([]);
  const [valorDolar, setValorDolar] = useState(0);
  const [operacion, setOperacion] = useState({
    cliente: null,
    lote: [],
    usuario: localStorage.getItem('idUsuario'),
    precioListaOperacion: 0,
    formaDePago: {
      forma: '',
      fechaIndiceCac: '',
      anticipo: {
        lotes: [],
      },
      pagoContado: [],
      vencimientosPuntuales: [],
      financiacion: {
        cantidadCuotas: 1,
        valorCuotaPropuesto: 0,
        fechaPagoPrimerCuota: '',
      }
    },
    comisionInmobiliaria: 0,
    porcentajeComisionInmobiliaria: 3,
    porcentajeDescuentoPrecioLista: 0,
    gastosEscribania: 0,
    precioVentaOperacion: 0,
    precioListaDolarOperacion: 0,
    precioVentaDolarOperacion: 0,
    inmobiliaria: localStorage.getItem('inmobiliariaId'),
  });

  useEffect(() => {
    const precioListaOperacion = operacion.lote.reduce((prev, curr) => prev + curr.precioLista, 0);
    const precioVentaOperacion = precioListaOperacion * (1 - operacion.porcentajeDescuentoPrecioLista / 100);
    const comisionInmobiliaria = precioVentaOperacion * valorDolar * 0.03;
    setOperacion({
      ...operacion,
      precioListaDolarOperacion: precioListaOperacion,
      precioVentaDolarOperacion: precioVentaOperacion,
      precioListaOperacion: precioListaOperacion * valorDolar,
      precioVentaOperacion: precioVentaOperacion * valorDolar,
      comisionInmobiliaria,
    });
  }, [operacion.lote, operacion.porcentajeDescuentoPrecioLista, valorDolar]);

  useEffect(() => {
    const getDolarApi = async () => {
      const dolarApi = await GetDolarMercado.getDolar();
      const valorDolar = dolarApi.data.compra;
      setValorDolar(valorDolar);
    }
    const obtenerParametros = async () => {
      try {
        const parametros = await clienteAxios.get(
          `/parametros?activo=true`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setParametros(parametros.data);
      } catch (error) {
        console.log(error);
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
      }
    };
    obtenerParametros();
    getDolarApi();
    getQueryParamsFromURL();
  }, []);

  useEffect(() => {
    let totalAnticipos = 0;
    const { anticipo } = operacion.formaDePago;
    if (!anticipo) {
      return;
    }

    if (anticipo.efectivo) {
      totalAnticipos += AnticiposUtils.getAnticipoEfectivo(operacion);
    }

    if (anticipo.documentos) {
      totalAnticipos += AnticiposUtils.getAnticipoDocumentos(operacion);
    }

    if (anticipo.inmueble) {
      totalAnticipos += AnticiposUtils.getAnticipoInmuebles(operacion);
    }

    if (anticipo.lotes) {
      totalAnticipos += AnticiposUtils.getAnticipoLotes(operacion);
    }

    if (anticipo.vehiculo) {
      totalAnticipos += AnticiposUtils.getAnticipoVehiculos(operacion);
    }

    if(operacion.formaDePago.entregas) {
      totalAnticipos += AnticiposUtils.getEntregas(operacion);
    }

    setOperacion({
      ...operacion,
      totalAnticipos,
      saldoFinanciar: operacion.precioVentaOperacion - totalAnticipos
    });

  }, [operacion.precioVentaOperacion, operacion.formaDePago.anticipo, operacion.formaDePago.entregas])

  const [activeStep, setActiveStep] = useState(0); // Add this line inside your component

  const goToPreviousStep = () => {
    scroll.scrollToTop();
    setActiveStep(prevStep => prevStep - 1);
  };

  const goToNextStep = () => {
    scroll.scrollToTop();
    setActiveStep(prevStep => prevStep + 1);
  };


  if (!token) {
    localStorage.clear();
    props.history.push("/login");
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Nueva Operación</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-9 col-sm-12">
              <div className="card card-primary card-tabs">
                <div className="card-header p-0 pt-1">
                  <ul className="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                    <li className="nav-item">
                      <a className={`nav-link ${activeStep === 0 ? 'active' : ''} `} id="custom-tabs-one-home-tab"
                        data-toggle="pill"
                        href="src/components/operaciones/nuevaOperacion#custom-tabs-one-home" role="tab"
                        aria-controls="custom-tabs-one-home"
                        onClick={() => setActiveStep(0)}
                        aria-selected="true">Cliente</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeStep === 1 ? 'active' : ''} `} id="custom-tabs-one-home-tab"
                        data-toggle="pill"
                        href="src/components/operaciones/nuevaOperacion#custom-tabs-one-home" role="tab"
                        aria-controls="custom-tabs-one-home"
                        onClick={() => setActiveStep(1)}
                        aria-selected="true">Firmantes</a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeStep === 2 ? 'active' : ''} `} id="custom-tabs-one-profile-tab"
                        data-toggle="pill"
                        href="src/components/operaciones/nuevaOperacion#custom-tabs-one-profile" role="tab"
                        aria-controls="custom-tabs-one-profile"
                        onClick={() => setActiveStep(2)}
                        aria-selected="false">Lotes
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${activeStep === 3 ? 'active' : ''} `} id="custom-tabs-one-messages-tab"
                        data-toggle="pill"
                        href="src/components/operaciones/nuevaOperacion#custom-tabs-one-messages" role="tab"
                        aria-controls="custom-tabs-one-messages" onClick={() => setActiveStep(3)}
                        aria-selected="false">Forma de Pago</a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="tab-content" id="custom-tabs-one-tabContent">
                    <div className={`tab-pane fade ${activeStep === 0 ? 'active show' : ''}`} id="custom-tabs-one-home"
                      role="tabpanel"
                      aria-labelledby="client">
                      <div className="card-header">
                        <h3 className="card-title">Ingresar Cliente</h3>
                      </div>
                      <div className="card-body">
                        <GetOrCreateClient
                          clienteFromBloqueo={queryParams.clientId}
                          operacion={operacion}
                          setOperacion={setOperacion}
                        />
                      </div>
                    </div>
                    <div className={`tab-pane fade ${activeStep === 1 ? 'active show' : ''}`} id="custom-tabs-one-home"
                      role="tabpanel"
                      aria-labelledby="client">
                      <div className="card-header">
                        <h3 className="card-title">Ingresar Firmantes</h3>
                      </div>
                      <div className="card-body">
                        <GetOrCreateFirmante
                          operacion={operacion}
                          setOperacion={setOperacion}
                        />
                      </div>
                    </div>
                    <div className={`tab-pane fade ${activeStep === 2 ? 'active show' : ''}`} id="custom-tabs-one-home"
                      role="tabpanel"
                      aria-labelledby="custom-tabs-one-profile-tab">
                      <div className="card-header">
                        <h3 className="card-title">Seleccionar Lotes</h3>
                      </div>
                      <div className="card-body">
                        <SelectLotes
                          lotesFromBloqueo={queryParams.lotesIds}
                          operacion={operacion}
                          setOperacion={setOperacion}
                          valorDolar={valorDolar}
                          setValorDolar={setValorDolar}
                        />
                      </div>
                    </div>
                    <div className={`tab-pane fade ${activeStep === 3 ? 'active show' : ''}`} id="custom-tabs-one-home"
                      role="tabpanel"
                      aria-labelledby="custom-tabs-one-messages-tab">
                      <SelectFormaPago
                        operacion={operacion}
                        setOperacion={setOperacion}
                        isActiveTab={activeStep === 3}
                        parametros={parametros}
                        bloqueoId={queryParams.bloqueoId}
                      />
                    </div>
                  </div>
                </div>
                {activeStep != 3 &&
                  <div className="card-footer">
                    <div className="row d-flex justify-content-around card-footer">
                      <button className="btn btn-secondary" onClick={goToPreviousStep}
                        disabled={activeStep <= 0}>Anterior
                      </button>
                      <button className="btn btn-primary" onClick={goToNextStep} disabled={activeStep >= 3}>Siguiente
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="col-md-3">
              <DetallePanelLateral
                operacion={operacion}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(NuevaOperacion);
