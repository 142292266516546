import React, { useContext, useEffect, useState } from 'react';
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { getCategorias, getDocumentacion } from './utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export const ListadoDocumentacion = ({ history, ...props }) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [documentacion, setDocumentacion] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState('');
  const [minFecha, setMinFecha] = useState('');
  const [maxFecha, setMaxFecha] = useState('');
  const [nombre, setNombre] = useState('');

  const getFromAPI = async () => {
    try {
      const params = {};
      if (selectedCategoria) {
        params.categoria = selectedCategoria;
      }
      if (minFecha) {
        params.fechaAltaDesde = minFecha;
      }
      if (maxFecha) {
        params.fechaAltaHasta = maxFecha;
      }
      if (nombre) {
        params.nombre = nombre;
      }

      const response = await getDocumentacion(params)
      setDocumentacion(response);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  const fetchCategorias = async () => {
    try {
      const categorias = await getCategorias();
      setCategorias(categorias);
    } catch (error) {
      console.log(error)
    }
  }


  const obtenerArchivo = async (idArchivo, nombre, fileName) => {
    try {
      clienteAxios
        .get(`/api/documentacion/${idArchivo}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileName}`);
            document.body.appendChild(link);
            link.click();
          }
        });
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  };

  const eliminarArchivo = async (idArchivo) => {
    Swal.fire({
      title: "¿Estás Seguro?",
      text: "Un archivo eliminado no puede recuperarse",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await clienteAxios.delete(`/api/documentacion/${idArchivo}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          if (response.status === 200) {
            Swal.fire("¡Correcto!", "Archivo eliminado correctamente", "success");
            await getFromAPI()
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Hubo un error al eliminar el archivo",
            });
          }
        } catch (error) {
          if (error.response?.status === 500) {
            localStorage.clear();
            history.push("/login");
          }
          console.log(error);
        }
      }
    }
    )

  }

  useEffect(() => {
    getFromAPI()
    fetchCategorias()
  }, [selectedCategoria, minFecha, maxFecha, nombre]);


  const columns = [
    {
      name: "Fecha",
      width: 'auto',
      selector: (row) => moment(row.fechaCreacion).utcOffset(180).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Nombre",
      width: 'auto',
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Categoria",
      width: 'auto',
      selector: (row) => row.categoria?.nombre || '',
      sortable: true,
    },
    {
      name: "Propietario",
      width: 'auto',
      selector: (row) => `${row.owner?.apellido || 'N/C'}, ${row.owner?.nombre || 'N/C'}`,
      sortable: true,
    },
    {
      name: "Descargar",
      width: "10%",
      selector: (row) => <button type="button" className="btn btn-block btn-success" onClick={() => obtenerArchivo(row._id, row.nombre, row.fileName)}>
        Descargar
      </button>,
      sortable: true,
    },
    {
      name: "Editar",
      width: "10%",
      selector: (row) => <Link to={`/documentacion/editar/${row._id}`} ><button type="button" className="btn btn-block btn-success">
        Editar
      </button></Link>,
      sortable: true,
    },
    {
      name: "Eliminar",
      width: "10%",
      selector: (row) => <button type="button" className="btn btn-block btn-danger" onClick={() => eliminarArchivo(row._id)}>
        Eliminar
      </button>,
      sortable: true,
    }
  ]

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-6">
              <h3 className="card-title mt-2">Documentación</h3>
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary float-right" onClick={() => history.push('/documentacion/nuevo')}>Nuevo</button>
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary float-right" onClick={() => history.push('/documentacion/categorias/listado')}>Categorias</button>
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary float-right" onClick={history.goBack}>Volver</button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <input placeholder='Nombre del Archivo' className='form-control' type="text" name="nombre" id="nombre" onChange={(e) => { setNombre(e.target.value) }} />
            </div>
            <div className="col-md-3">
              <select
                className="form-control"
                value={selectedCategoria}
                onChange={(e) => setSelectedCategoria(e.target.value)}
              >
                <option value="">Seleccionar Categoria</option>
                {categorias?.map(categoria => (
                  <option key={categoria._id} value={categoria._id}>
                    {categoria.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                value={minFecha}
                onChange={(e) => setMinFecha(e.target.value)}
                placeholder="Min Fecha"
              />
            </div>
            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                value={maxFecha}
                onChange={(e) => setMaxFecha(e.target.value)}
                placeholder="Max Fecha"
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-10"></div>
            <div className="col-md-2 mt-2 float-right">
              <button className="btn btn-secondary" onClick={getFromAPI}>Aplicar Filtros</button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <DataTable
                pagination
                columns={columns}
                data={documentacion}
              />
            </div>
          </div>
        </div >
        <div className="card-footer"></div>
      </div >
    </>
  );
};
