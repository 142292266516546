import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import TablaCotizaciones from "../../../components/TablaCotizaciones";

const moneyArFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const moneyUsFormatter = new Intl.NumberFormat("es-AR", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "symbol",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export default function MostrarLotes({ lotesSeleccionados, operacion }) {

  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const lotesCompleto = lotesSeleccionados.map((lote) => {
    const valoresLote = operacion.valoresLotes.find( valor => valor.idLote === lote._id);
    return({
      ...lote,
      ...valoresLote
    })
  });

  if(!lotesCompleto) {
    return <></>;
  }

  return (
    <>
      <div className="card-header">
        <h3 className="card-title">Lotes Seleccionados</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col">
              <label>Valor descuento sobre precio de lista:</label>
              <p>{operacion.porcentajeDescuentoPrecioLista ? Intl.NumberFormat("es-AR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(operacion.porcentajeDescuentoPrecioLista) : 0} %</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label>Lotes Seleccionados:</label>
            </div>
          </div>
          {lotesCompleto.map((lote, index) => {

            return (
              <div className='border-bottom mb-5' key={index}>
                <div className='mb-4'>
                  <div className="row">
                    <div className="col">
                      <label>Lote Nro: {lote.numeroLote}</label>
                    </div>
                  </div>
                  <table className='table'>
                    <thead>
                    <tr>
                      <th>Desarrollo</th>
                      <th>Manzana</th>
                      <th>Número de lote</th>
                      <th>Superficie</th>
                      <th>Tipología</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>{lote.barrio.nombre}</td>
                      <td>{lote.numeroManzana}</td>
                      <td>{lote.numeroLote}</td>
                      <td>{lote.superficie} m2</td>
                      <td>{lote.tipologia.tipo}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <TablaCotizaciones cotizaciones={lote.valores} setCotizaciones={()=>{}} allReadOnly={true} showNegociado={false}/>
              </div>
            );
          })}
        </div>
      </div>
    </>
  )
}
