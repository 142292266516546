import moment from 'moment';
import React, {useState} from 'react';

function MostrarTurnoFirma({operacion, title = 'Turno de Firma', guardarOperacion, enviarOperacion}) {

  const [selectedDateTime, setSelectedDateTime] = useState(null);

  const handleDateTimeChange = (date) => {
    setSelectedDateTime(date);
  };

  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleTurno = (e) => {
    const {value} = e.target;
    guardarOperacion({
      ...operacion,
      turnoFirma: value
    });
  }
  return (
    <>
      < div className="card-header">
        <h3 className="card-title">{title}</h3>
        <button
          className="btn btn-primary float-right"
          type="button"
          onClick={handleToggle}
        >
          {isCollapsed ? 'Ver' : 'Ocultar'}
        </button>
      </div>
      <div className={`collapse${isCollapsed ? '' : ' show'}`}>


        <div className="card-body">

          < div className="card-header">
            <h3 className="card-title">Turno de Firma</h3>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <p>{moment(operacion.turnoFirma)
                  .format("DD-MM-YYYY HH:mm")}</p>
              </div>
            </div>
            {/* <div className="row mt-3 mb-5">
              <div className="col">
                <button
                  className="btn btn-success float-right"
                  type="button"
                  onClick={enviarOperacion}
                >
                  Confirmar
                </button>
              </div>
            </div> */}
          </div>

        </div>
      </div>
    </>
  )
}

export default MostrarTurnoFirma;