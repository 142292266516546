import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import FormularioUsuario from "./components/FormularioUsuario";
import Swal from 'sweetalert2';

import { usuarioAPI2UsuarioPlano, usuarioPlano2usuarioAPI } from './components/ConversorObjetosUsuario';

const EditarUsuario = (props) => {
  const [usuario, setUsuario] = useState({ loading: true });
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const getUsuario = async () => {
    const usuarioId = props.match.params.id;

    try {
      const usuarioAPI = await clienteAxios.get(`/usuarios/${usuarioId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const usuario = usuarioAPI2UsuarioPlano(usuarioAPI.data);

      setUsuario({
        loading: false,
        _id: usuarioAPI.data._id,
        ...usuario,
      });

    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  useEffect(() => {

    getUsuario();
  }, []);

  const actualizarState = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const actualizarStateV2 = (datos) => {
    setUsuario({
      ...usuario,
      ...datos
    });
  };

  const verificaFormulario = () => {
    let verifica =
      (!usuario.nombre && !usuario.apellido) || !usuario.email || !usuario.rol;

    return verifica;
  };

  const enviarUsuario = async (e) => {
    e.preventDefault();

    const usuarioEnviar = usuarioPlano2usuarioAPI(usuario);

    try {
      let rta = await clienteAxios.put(
        `/usuarios/${usuario._id}`,
        usuarioEnviar,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }

      props.history.push("/configuracion/usuarios");
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
    }
  };

  if (usuario.loading) {
    return <Spinner />;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title mt-2">EDITAR USUARIO</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className="card-body">
        <form onSubmit={enviarUsuario}>
          <FormularioUsuario
            setUsuario={setUsuario}
            actualizarState={actualizarState}
            actualizarStateV2={actualizarStateV2}
            usuario={usuario}
          />

          <div className="card-footer">
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-primary"
                onClick={(e) => { e.preventDefault(); props.history.goBack() }}
              >
                Volver
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={verificaFormulario()}
              >
                Guardar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(EditarUsuario);
