import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { CRMContext } from "../../context/CRMContext";
import clienteAxios from "../../config/axios";
import Spinner from "../layout/Spinner";
import Swal from "sweetalert2";

const AgregarLotes = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [barrios, setBarrios] = useState([]);
  const [barrioSeleccionado, setBarrioSeleccionado] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const getBarriosAPI = async () => {
      try {
        const barriosAPI = await clienteAxios.get("/barrios?activo=true", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setBarrios(
          barriosAPI.data.map((barrio) => {
            return {
              value: barrio._id,
              label: barrio.nombre,
            };
          })
        );
      } catch (error) {
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
        console.log(error);
      }
    };

    getBarriosAPI();
  }, []);

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const selectBarrio = (e) => {
    setBarrioSeleccionado(e.value);
  };

  if (barrios.length == 0) {
    return <Spinner />;
  }

  const enviarArchivo = async (e) => {
    e.preventDefault();
    const datosEnviar = new FormData();
    datosEnviar.append("barrio", barrioSeleccionado);
    datosEnviar.append("file", file);

    try {
      const rta = await clienteAxios.post("/lotes", datosEnviar, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success")
          .then(() => {
            props.history.push(`/lotes/${barrioSeleccionado}`);
          });

      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };
  const checkBeforeSend = () => {
    return barrioSeleccionado === null || barrioSeleccionado === undefined;
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Agregar Lotes</h3>
        </div>
        <div className="card-body">
          <form onSubmit={enviarArchivo}>
            <div className="row">
              <div className="col-md-12 orm-group">
                <label>Seleccionar Desarrollo</label>
                <Select
                  placeholder="--- Seleccionar ---"
                  options={barrios}
                  onChange={selectBarrio}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 orm-group">
                <label>Adjuntar archivo CSV</label>
                <input
                  type="file"
                  name="csv"
                  id=""
                  className="form-control"
                  onChange={selectFile}
                />
              </div>
            </div>

            <div className="card-footer">
              <button
                type="submit"
                className="float-right btn btn-primary"
                value={file}
                disabled={checkBeforeSend()}
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AgregarLotes;
