import React, { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import { Link, withRouter } from "react-router-dom";
import clienteAxios from "../../../config/axios";
import { CRMContext } from "../../../context/CRMContext";

const EliminarBarrios = (props) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const eliminarBarrio = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "¿Estás Seguro?",
      text: "Un desarrollo eliminado no puede recuperarse",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const eliminar = async () => {
          try {
            const rta = await clienteAxios.delete(
              `/barrios/${props.idBarrio}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (rta.data.estado == "ok") {
              Swal.fire("¡Eliminado!", rta.data.msg, "success");
            } else if (rta.data.estado == "error") {
              Swal.fire({
                type: "error",
                title: rta.data.msg,
                text: "Hubo un error",
              });
            }
            props.history.push('/barrios');
          } catch (error) {
            if (error.response?.status === 500) {
              localStorage.clear();
              props.history.push("/login");
            }
            console.log(error);
          }
        };
        eliminar();
      }
    });
  };

  return (
    <>
      <button className="btn btn-danger" onClick={eliminarBarrio}>
        Eliminar Desarrollo
      </button>
    </>
  );
};

export default withRouter(EliminarBarrios);
