import React, { useContext, useEffect, useState } from 'react';
import Select from "react-select";
import { withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import Spinner from "../../../layout/Spinner";
import { CRMContext } from "../../../../context/CRMContext";
import clienteAxios from "../../../../config/axios";
import NumberFormat from "react-number-format";
import GetDolarMercado from "../../../utils/GetDolarMercado";
import DescuentoLotes from "./components/DescuentoLotes";

const SelectLotes = ({ operacion, setOperacion, valorDolar, setValorDolar, lotesFromBloqueo, ...props }) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const [returnSpinner, setReturnSpinner] = useState(false);
  const [lotesSeleccionados, setLotesSeleccionados] = useState([]);
  const [barrios, guardarBarrios] = useState([]);
  const [lotes, guardarLotes] = useState([]);
  const [isDescuentoOutOfRange, setIsDescuentoOutOfRange] = useState(false);
  const [isComisionOutOfRange, setIsComisionOutOfRange] = useState(false);
  const [valorDolarMercado, setValorDolarMercado] = useState(0);

  const formatterAr = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const getLoteFromId = async(id) => {
    const response = await clienteAxios.get(`/lote/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }

  const handleLotesFromBloqueo = async () => {
    let lotesDB = [];
    try {
      if (lotesFromBloqueo) {
        lotesDB = await Promise.all(lotesFromBloqueo.split(',').map(id => getLoteFromId(id)));
        const lotesProcessed = lotesDB.map( lote => processLoteFromDB(lote));
        guardarLoteMasivo(lotesProcessed);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleLotesFromBloqueo();
  }, [lotesFromBloqueo]);


  useEffect(async () => {
    if (operacion.formaDePago.anticipo.lotes) {
      guardarLotes(operacion.formaDePago.anticipo.lotes);
    }
    consultarBarrios();
    const dolarApi = await GetDolarMercado.getDolar();
    const valorDolar = dolarApi.data.compra;
    setValorDolarMercado(valorDolar);
  }, [])

  const formatter = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const consultarBarrios = async () => {
    try {
      const barriosConsulta = await clienteAxios.get(
        "/barrios?activo=true&campos=nombre,_id",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      guardarBarrios(
        barriosConsulta.data.map((barrio) => {
          return {
            label: barrio.nombre,
            value: barrio._id,
          };
        })
      );
    } catch (error) {
      props.history.push("/login");
      console.log(error);
      localStorage.clear();
    }
  };

  const consultarLotes = async (e) => {
    const id = e.value;

    guardarLotes([]);
    setReturnSpinner(true);

    try {
      const lotesConsulta = await clienteAxios.get(`/lotes?barrio=${id}&estado=0&sort=numeroLote,1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      guardarLotes(
        lotesConsulta.data.data.map((lote) => {
          let loteInversor = '';
          if (lote.motivoDisponibilidad && lote.motivoDisponibilidad.id === 3) {
            loteInversor = " (Inversor)";
          }
          return {
            value: lote._id,
            label: `Lote nro: ${lote.numeroLote} - Precio: ${formatter.format(lote.precioLista)}${loteInversor}`,
            numeroLote: `Lote nro: ${lote.numeroLote}${loteInversor}`,
            precioLista: lote.precioLista,
            barrio: lote.barrio.nombre,
            restoInfo: { ...lote }
          };
        })
      );
      setReturnSpinner(false);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  const deleteLoteSeleccionado = (idLoteBorrar) => {
    const nuevosLotesSeleccionados = lotesSeleccionados.filter(lote => {
      return lote.id !== idLoteBorrar;
    });

    setLotesSeleccionados(nuevosLotesSeleccionados);
  }

  // Guarda el lote seleccionado en el objeto de datos de la operacion
  const guardarLote = (e) => {
    const newLote = loteDbToLoteOperacion(e);
    const { id } =  newLote;
    const loteYaSeleccionado = lotesSeleccionados.some(lot => lot.id === id);
    if (loteYaSeleccionado) {
      return;
    }
    setLotesSeleccionados([
      ...lotesSeleccionados,
      newLote
    ]);
  };

  const guardarLoteMasivo = (lotes) => {
    const processedLotes = lotes.map( lote => loteDbToLoteOperacion(lote));
    const filteredLotes = processedLotes.filter( lote => !lotesSeleccionados.some(lot => lot.id === lote.id))
    setLotesSeleccionados([
      ...lotesSeleccionados,
      ...filteredLotes
    ]);
  }

  const processLoteFromDB = (lote) => {
    let loteInversor = '';
    if (lote.motivoDisponibilidad && lote.motivoDisponibilidad.id === 3) {
      loteInversor = " (Inversor)";
    }
    return {
      value: lote._id,
      label: `Lote nro: ${lote.numeroLote} - Precio: ${formatter.format(lote.precioLista)}${loteInversor}`,
      numeroLote: `Lote nro: ${lote.numeroLote}${loteInversor}`,
      precioLista: lote.precioLista,
      barrio: lote.barrio.nombre,
      restoInfo: { ...lote }
    };
  }

  const loteDbToLoteOperacion = (loteDb) => {
    return {
      id: loteDb.value,
      lote: loteDb.numeroLote,
      precioLista: loteDb.precioLista,
      barrio: loteDb.barrio,
      restoInfo: loteDb.restoInfo
    }
  }


  const columns = [
    {
      name: 'Desarrollo',
      selector: (row) => row.barrio,
      sortable: true,
      width: 'auto',
    },
    {
      name: 'Lote',
      selector: (row) => row.lote,
      sortable: true,
      width: 'auto',
    },
    {
      name: 'Manzana',
      selector: (row) => row.restoInfo.numeroManzana,
      sortable: true,
      width: 'auto',
    },
    {
      name: 'm2',
      selector: (row) => row.restoInfo.superficie,
      sortable: true,
      width: 'auto',
    },
    {
      name: 'Precio',
      selector: (row) => formatter.format(row.precioLista),
      sortable: true,
      width: 'auto',
    },
    {
      name: 'Eliminar',
      button: true,
      width: 'auto',
      cell: (row) => (
        <button onClick={() => deleteLoteSeleccionado(row.id)} className="btn-danger btn">X</button>
      )
    }
  ];


  const setComisionAsesorComercial = (e) => {
    if (e.floatValue > 4 || e.floatValue < 0) {
      setIsComisionOutOfRange(true);
    } else {
      setIsComisionOutOfRange(false);
    }
    setOperacion({
      ...operacion,
      porcentajeComisionInmobiliaria: e.floatValue || 0
    });
  }


  if (!barrios.length || !valorDolarMercado) {
    return <Spinner />;
  }

  return (
    <>
      <div className="row">
        <label className="col-md-12">Seleccionar Desarrollo</label>
        <div className="form-group col-md-6">
          <Select
            name="barrio"
            onChange={consultarLotes}
            options={barrios}
          />
        </div>
      </div>
      {
        returnSpinner && <Spinner />
      }
      {
        (lotes.length > 0 || lotesSeleccionados.length > 0) && (
          <>
            <div className="row">
              <label className="col-md-12">Seleccionar Lote</label>
              <div className="form-group col-md-6">
                <Select
                  name="lote"
                  options={lotes}
                  onChange={guardarLote}
                />
              </div>
            </div>
            {lotesSeleccionados.length > 0 &&
              <div className="form-group">
                <div className="row">
                  <label>Lotes Seleccionados:</label>
                  <DataTable columns={columns} data={lotesSeleccionados} responsive />
                </div>
                <div className="row mt-4">
                  <label className='mr-3'>Tipo de cambio de Mercado:</label>
                  <p>{formatterAr.format(valorDolarMercado)}</p>
                </div>
                <div className="row mt-2">
                  <label className='mr-3'>Tipo de cambio Propuesto:</label>
                  <NumberFormat
                    className={`col-md-4`}
                    value={valorDolar}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={'$ '}
                    onValueChange={(e) => setValorDolar(e.floatValue)}
                  />
                </div>

                <div className="row mt-2">
                  <label className='mr-3'>Total de lotes seleccionados: </label>
                  <p>{formatter.format(lotesSeleccionados.reduce((total, curr) => total + curr.precioLista, 0)) || ''}</p>
                </div>
                <div className="row mt-4">
                  <div className="col-md-8">

                    <DescuentoLotes
                      operacion={operacion}
                      setOperacion={setOperacion}
                      maxDscto={100}
                      lotesSeleccionados={lotesSeleccionados}
                      valorDolar={valorDolar}
                      valorDolarMercado={valorDolarMercado}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Comisión Inmobiliaria [0~4]%:</label>
                    <NumberFormat
                      className={`form-control ${isComisionOutOfRange ? 'red-border' : ''}`}
                      value={operacion.porcentajeComisionInmobiliaria}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      suffix={' %'}
                      min={0}
                      max={4}
                      onValueChange={setComisionAsesorComercial}
                    />
                  </div>
                </div>
              </div>
            }

          </>

        )
      }
    </>
  )

}

export default withRouter(SelectLotes);