import React, { useState } from 'react';
import clienteAxios from '../../../config/axios';
import Swal from 'sweetalert2';

const FormularioAdjuntarArchivo = (props) => {

  const [archivo, setArchivo] = useState(null);
  const [nombre, setNombre] = useState('');
  const [validateFileName, setValidateFileName] = useState(false);


  const actualizarNombre = e => {
    setValidateFileName(e.target.value.length > 0);
    setNombre(e.target.value);
  }
  const actualizarArchivo = e => {
    setArchivo(e.target.files[0]);
  }

  const enviarArchivoAdjunto = async () => {

    const datosEnviar = new FormData();
    datosEnviar.append('nombre', nombre);
    datosEnviar.append('archivoAdjunto', archivo);
    datosEnviar.append('idOperacion', props.idOperacion);
    datosEnviar.append('formulario', props.formulario);

    try {
      const rta = await clienteAxios.post("/archivosAdjuntos", datosEnviar, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${props.token}`,
        },
      });
      if (rta.data.estado == "ok") {
        Swal.fire("¡Correcto!", rta.data.msg, "success");
      } else if (rta.data.estado == "error") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: rta.data.msg,
        });
      }
      props.consultarOperacion();
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }

  }

  return (
    <>
      <div className="row">
        <div className="col form-group">
          <label>Nombre del Archivo:</label>
          <input type="text" name="nombre" onChange={actualizarNombre} required className="form-control" />
        </div>
      </div>
      <div className="row">
        <div className="col form-group">
          <label>Adjuntar:</label>
          <input type="file" name="archivoAdjunto" id="archivoAdjunto" onChange={actualizarArchivo} className="form-control" />
        </div>
      </div>

      <div className="modal-footer justify-content-between">
        <button type="button" className="btn btn-default" data-dismiss="modal">Cancelar</button>
        <button type="button" className="btn btn-primary" onClick={enviarArchivoAdjunto} data-dismiss="modal" disabled={!validateFileName}>Enviar</button>
      </div>
    </>
  )
}

export default FormularioAdjuntarArchivo;