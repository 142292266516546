import React, { useContext, useEffect, useState } from 'react'
import { CRMContext } from '../../context/CRMContext';
import clienteAxios from '../../config/axios';
import { useForm } from 'react-hook-form';
import Spinner from '../layout/Spinner';

export const FormularioAltaMotivo = (props) => {
  const { history } = props;
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");
  const [motivo, setMotivo] = useState({});
  const { register, handleSubmit, reset } = useForm();


  const submit = async (data) => {
    try {
      await clienteAxios.post(`/motivoDisponibilidad/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      history.push('/motivoDisponibilidad/listado');
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title">Alta Motivo</h3>
          </div>
          <div className="col-md-4">
            <button className="btn btn-primary float-right" onClick={history.goBack}>Volver</button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmit(submit)}>
              <div className="form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  name="nombre"
                  className="form-control"
                  {...register("nombre")}
                />
              </div>
              <div className="form-group">
                <label>Activo</label>
                <select
                  name="disponible"
                  className="form-control"
                  {...register("disponible")}
                >
                  <option value={true}>Sí</option>
                  <option value={false}>No</option>
                </select>
              </div>
              <div className="form-group">
                <label>Seleccionable Usuario</label>
                <select
                  name="seleccionableUsuario"
                  className="form-control"
                  {...register("seleccionableUsuario")}
                >
                  <option value={true}>Sí</option>
                  <option value={false}>No</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary float-right mt-3">Guardar</button>
            </form>
          </div>
        </div>
      </div>
      <div className="card-footer"></div>
    </div >
  )
}
