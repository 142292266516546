import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";

import Spinner from "../layout/Spinner";

import { ListadoOperacionesComp } from "./components/ListadoOperacionesComp";

function ListadoReadquisicionLotes(props) {
  const [operaciones, guardarOperaciones] = useState([]);
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  useEffect(() => {
    const consultarAPI = async () => {
      try {
        const operacionesConsulta = await clienteAxios.get(
          `/readquisicion`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );


        guardarOperaciones(operacionesConsulta.data.data);
      } catch (error) {
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
        console.log(error);
      }
    };

    consultarAPI();
  }, []);

  // if (!operaciones.length) {
  //   return <Spinner />;
  // }



  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-md-8">
            <h3 className="card-title">Operaciones de Readquisición</h3>
          </div>
          <div className="col-md-4">
            <Link to={`/operaciones/readquisicion/nueva`}>
              <button type="button" className="btn btn-block btn-success">
                Nueva Operación de Readquisición
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="card-body">
        <ListadoOperacionesComp
          operaciones={operaciones}
          url={`/operaciones/readquisicion`}
        />
      </div>
    </div>
  );
}

export default withRouter(ListadoReadquisicionLotes);
