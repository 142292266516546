import React from 'react';
import Select from "react-select";

function CuadroResumenBoleto(props) {
  return (
    <div className='mb-4'>
      <div className="row">
        <label className="col-md-12">Resumen</label>
      </div>
      <div className="row">
        <div className="col-md-12">
          <textarea
            cols='100'
            rows='10'
            value={`Precio: Se efectúa esta compraventa por el precio total de  pesos veinte y seis millones quinientos cuatro mil seiscientos setenta y cero ($ 26.504.671), de cuyo importe la representante de la parte vendedora percibe en este acto,  La entrega de pesos diez millones ($ 10.000.000) en efectivo y el saldo de dieciséis millones quinientos cuatro mil seiscientos setenta y cero pesos ($ 16.504.671) , la parte compradora se compromete a abonarlo en treinta (30) cuotas mensuales y consecutivas de quinientos cincuenta mil ciento cincuenta y seis Pesos ($ 550.156), cada una, con vencimiento del 1 al 10 de cada mes, a partir de enero de 2024, ajustándose las cuotas por la variación del costo del índice de la cámara de la construcción (base septiembre de 2023), que se publica mensualmente (indicador CAC); salvo que las partes convengan prórroga en contrario, en el domicilio y/o cuenta bancaria de la parte “vendedora”, o donde ésta posteriormente lo indique por medio fehaciente.--`}
            disabled='true'
          />
        </div>
      </div>
    </div>
  );
}

export default CuadroResumenBoleto;