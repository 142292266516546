import moment from 'moment';
import React, { useState } from 'react'
import { put } from '../../../config/apiHelper';
import Swal from 'sweetalert2';

const FechaOperacion = (props) => {
  const { operacion, consultaAPI } = props;
  const rolId = parseInt(localStorage.getItem('rolId'));
  const [changing, setChanging] = useState(false);
  const [fechaOperacion, setFechaOperacion] = useState(operacion.fechaAlta);

  const updateOperacion = async () => {
    try {
      if (!fechaOperacion) {
        Swal.error('Error', 'Debe ingresar una fecha de operación', 'error');
        return;
      }
      const data = {
        operacion: {
          ...operacion,
          fechaAlta: fechaOperacion
        },
        estadoOperacion: operacion.estado.id,
        historico: {
          accion: 'Modificación de Fecha de Operación',
          usuario: localStorage.getItem('idUsuario'),
        }
      }
      const response = await put(`/operaciones/${operacion._id}`, data);
      if (response.estado === 'ok') {
        consultaAPI(operacion._id);
        setChanging(false);
      }
    } catch (error) {
      Swal.error('Error', 'No se pudo modificar la fecha de operación', 'error');
    }
  }

  return (
    <div className="col-md-4">
      {changing ?
        <>
          <div className="row">
            <div className="form-group">
              <label>Fecha de Operación:</label>
              <input type="date" value={fechaOperacion} onChange={(e) => setFechaOperacion(e.target.value)} className="form-control" />
              <button className='btn btn-danger ml-3 mt-3' onClick={() => setChanging(false)}>Cancelar</button>
              <button className='btn btn-primary ml-3 mt-3' onClick={() => updateOperacion()}>Guardar</button>
            </div>
          </div>
        </> :
        <>
          <div className="row">
            <div className="card-title">
              <strong>Fecha de Operación:</strong> {moment(operacion.fechaAlta)
                .utcOffset(180)
                .format("DD-MM-YYYY")}
              {rolId === 4 && <button className="btn btn-primary ml-3" onClick={() => setChanging(true)}>Modificar</button>}
            </div>
          </div>
          <div className="row">
            <div className="card-title">
              <strong>Fecha de Creación:</strong> {moment(operacion.fechaCreacion ?? operacion.fechaAlta)
                .utcOffset(180)
                .format("DD-MM-YYYY")}
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default FechaOperacion