import React, { useEffect, useState, useContext } from "react";
import { CRMContext } from "../../context/CRMContext";
import { Link, withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import clienteAxios from "../../config/axios";

const ListadoNacionalidades = (props) => {
  const [nacionalidades, setNacionalidades] = useState([]);

  const [auth, guardarAuth] = useContext(CRMContext);
  const idUsuario = localStorage.getItem("idUsuario");
  let token = auth.token || localStorage.getItem("token");

  useEffect(() => {
    const consultaAPI = async () => {
      try {
        const nacionalidadesDB = await clienteAxios.get("/nacionalidades", {
          headers: {
            Authorization: `Bearer ${token}`,
            Id: idUsuario,
          },
        });

        let newNacionalidades = [];



        nacionalidadesDB.data.map((nacionalidad) => {
          return newNacionalidades.push({
            nombre: nacionalidad.nombre,
            activo: nacionalidad.activo,
            url: `/configuracion/nacionalidades/editar/${nacionalidad._id}`,
          });
        });

        newNacionalidades.sort((a, b) => {
          if (a.nombre > b.nombre) {
            return 1
          }
          if (a.nombre < b.nombre) {
            return -1
          }
          return 0
        })



        setNacionalidades(newNacionalidades);
      } catch (error) { }
    };
    consultaAPI();
  }, []);



  const columns = [
    {
      name: "Nacionalidad",
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.activo ? "Activo" : "Inactivo",
      sortable: true,
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <Link to={row.url}>
          <button type='button' className='btn btn-block btn-success'>
            Ver
          </button>
        </Link>
      ),
    },
  ];

  return (
    <div className='card'>
      <div className='card-header'>
        <div className='row'>
          <div className='col-md-8'>
            <h3 className='card-title mt-2'>NACIONALIDADES</h3>
          </div>
          <div className='col-md-4'>
            <Link to={`/configuracion/nacionalidades/nueva`}>
              <button type='button' className='btn btn-block btn-success'>
                Agregar Nueva Nacionalidad
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className='card-body'>
        <DataTable columns={columns} data={nacionalidades} />
      </div>
    </div>
  );
};

export default withRouter(ListadoNacionalidades);
