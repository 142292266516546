import React, { useState, useContext, useEffect } from 'react'
import Select from "react-select";
import Swal from 'sweetalert2';
import clienteAxios from '../../../config/axios';
import { CRMContext } from "../../../context/CRMContext";
import NumberFormat from "react-number-format";
import { get, put } from '../../../config/apiHelper';

function ModificarLotes(props) {
  const { seleccionados, history, reload } = props;

  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  const [estados, setEstados] = useState([]);
  const [estadoSeleccionado, setEstadoSeleccionado] = useState(null);
  const [tipoUpdateSeleccionado, setTipoUpdateSeleccionado] = useState('');
  const [valorUpdate, setValorUpdate] = useState(0);
  const [motivoDisponibilidad, setMotivoDisponibilidad] = useState([]);
  const [motivoSeleccionado, setMotivoSeleccionado] = useState(null);
  const [operacionRealizada, setOperacionRealizada] = useState({
    tipo: '',
    valor: 0,
    lotesSeleccionados: [],
    inversa: 0,
  });
  const [tipologias, setTipologias] = useState([]);
  const [tipologiaSeleccionada, setTipologiaSeleccionada] = useState(null);
  const [desarrollos, setDesarrollos] = useState([]);
  const [desarrolloSeleccionado, setDesarrolloSeleccionado] = useState(null);

  const getEstados = async () => {
    try {
      const response = await clienteAxios.get("/estadosLotes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { data } = response;
      const estados = data.map(estado => ({
        label: estado.estado,
        value: estado._id
      }));
      setEstados(estados);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  const getTipologias = async () => {
    try {
      const response = await clienteAxios.get("/tipologias", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { data } = response;
      const tipologias = data.map(tipo => ({
        label: tipo.tipo,
        value: tipo._id
      }));
      setTipologias(tipologias);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  const getMotivoDisponibilidad = async () => {
    try {
      const response = await clienteAxios.get("/motivoDisponibilidad", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { data } = response.data;
      const motivos = data.map(motivo => ({
        label: motivo.nombre,
        value: motivo._id
      }));
      setMotivoDisponibilidad(motivos);
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  const getDesarrollos = async () => {
    try {
      const response = await get("/barrios");
      setDesarrollos(response.map(desarrollo => ({
        label: desarrollo.nombre,
        value: desarrollo._id
      })));
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        history.push("/login");
      }
      console.log(error);
    }
  }

  const modificarLotes = async () => {
    try {
      Swal.fire({
        title: `Estás a punto de modificar ${seleccionados.length} lotes.`,
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: "Modificar",
        denyButtonText: `Cancelar`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            ids: seleccionados.map(lote => (lote.id))
          }
          if (motivoSeleccionado) {
            data.motivoDisponibilidad = motivoSeleccionado;
          }
          if (estadoSeleccionado) {
            data.estado = estadoSeleccionado;
          }

          const rta = await clienteAxios.put("/updateEstadoLotes",
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

          if (rta.data.estado == "ok") {
            Swal.fire("¡Correcto!", rta.data.msg, "success")
              .then(() => {
                reload();
              })
          } else if (rta.data.estado == "error") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: rta.data.msg,
            });
          }
        }
      });

    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  const modificarDesarrollo = async () => {
    try {
      Swal.fire({
        title: `Estás a punto de modificar ${seleccionados.length} lotes.`,
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: "Modificar",
        denyButtonText: `Cancelar`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            ids: seleccionados.map(lote => (lote.id))
          }
          if (desarrolloSeleccionado) {
            data.barrio = desarrolloSeleccionado;
          }

          const rta = await put('/lotes/updateDesarrolloLotes', data);

          if (rta?.estado == "ok") {
            Swal.fire("¡Correcto!", rta.msg, "success")
              .then(() => {
                reload();
              })
          } else if (rta?.estado == "error") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: rta.data.msg,
            });
          }
        }
      });

    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  const modificarTipologiaLotes = async () => {
    try {
      if (!tipologiaSeleccionada) {
        return;
      }
      Swal.fire({
        title: `Estás a punto de modificar ${seleccionados.length} lotes.`,
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: "Modificar",
        denyButtonText: `Cancelar`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            ids: seleccionados.map(lote => (lote.id)),
            tipologia: tipologiaSeleccionada
          }

          const rta = await clienteAxios.put("/updateTipologiasLotes",
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

          if (rta.data.estado == "ok") {
            Swal.fire("¡Correcto!", rta.data.msg, "success")
              .then(() => {
                reload();
              })
          } else if (rta.data.estado == "error") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: rta.data.msg,
            });
          }
        }
      });

    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  }

  const modificarPrecioLotes = async (seleccionados, tipoUpdateSeleccionado, valorUpdate) => {
    try {
      Swal.fire({
        title: `Estás a punto de modificar ${seleccionados.length} lotes.`,
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: "Modificar",
        denyButtonText: `Cancelar`
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            ids: seleccionados.map(lote => (lote.id))
          }
          if (tipoUpdateSeleccionado) {
            data.tipoUpdate = tipoUpdateSeleccionado;
          }
          if (valorUpdate) {
            data.valorUpdate = valorUpdate;
          }

          const rta = await clienteAxios.put("/lotes/updatesPreciosLotes",
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

          if (rta.data.estado == "ok") {
            Swal.fire("¡Correcto!", rta.data.msg, "success")
              .then(() => {
                setOperacionRealizada({
                  tipo: tipoUpdateSeleccionado,
                  valorUpdate: valorUpdate,
                  lotesSeleccionados: seleccionados,
                  inversa: tipoUpdateSeleccionado === 'valorFijo' ? -valorUpdate : (-100) * (1 - (100 / (100 + valorUpdate)))
                });
                reload();
              })
          } else if (rta.data.estado == "error") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: rta.data.msg,
            });
          }
        }
      });

    } catch (error) {
      console.log(error);
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
    }
  }

  useEffect(() => {
    getEstados();
    getMotivoDisponibilidad();
    getTipologias();
    getDesarrollos();
  }, []);

  return (
    <>
      <div className="form-group">
        <div className="row mt-4 mb-4">
          <div className="col-md-12 mb-2">
            <label>Modificar Tipología</label>
          </div>
          <div className="col-md-4">
            <label>Tipología:</label>
            <Select
              options={tipologias}
              onChange={e => setTipologiaSeleccionada(e.value)}
            />
          </div>
          <div className="col-md-4">
          </div>
          <div className="col-md-3 mt-4">
            <button className="btn btn-block btn-success float-right" onClick={modificarTipologiaLotes}
              disabled={!tipologiaSeleccionada}>Modificar Tipología
            </button>
          </div>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col-md-12 mb-2">
            <label>Modificar Estados</label>
          </div>
          <div className="col-md-4">
            <label>Estado:</label>
            <Select
              options={estados}
              onChange={e => setEstadoSeleccionado(e.value)}
            />
          </div>
          <div className="col-md-4">
            <label>Motivo de Disponibilidad:</label>
            <Select
              options={motivoDisponibilidad}
              onChange={e => setMotivoSeleccionado(e.value)}
            />
          </div>
          <div className="col-md-3 mt-4">
            <button className="btn btn-block btn-success float-right" onClick={modificarLotes}
              disabled={!motivoSeleccionado || !estadoSeleccionado}>Modificar Estado
            </button>
          </div>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col-md-12 mb-2">
            <label>Modificar Precios</label>
          </div>
          <div className="col-md-4">
            <label>Tipo:</label>
            <Select
              options={[
                {
                  label: 'Porcentaje',
                  value: 'porcentaje'
                },
                {
                  label: 'Valor Fijo',
                  value: 'valorFijo'
                },
                {
                  label: 'Valor Final',
                  value: 'valorFinal'
                },
              ]}
              onChange={e => setTipoUpdateSeleccionado(e.value)}
            />
          </div>
          <div className="col-md-4">
            <label>Valor:</label>
            <NumberFormat
              disabled={!tipoUpdateSeleccionado}
              className='form-control'
              prefix={tipoUpdateSeleccionado === 'valorFijo' ? 'u$s ' : ''}
              suffix={tipoUpdateSeleccionado === 'porcentaje' ? ' %' : ''}
              onValueChange={e => setValorUpdate(e.floatValue)}
            />
          </div>
          <div className="col-md-3 mt-4">
            <button className="btn btn-block btn-success float-right" onClick={() => modificarPrecioLotes(seleccionados, tipoUpdateSeleccionado, valorUpdate)}
              disabled={!valorUpdate || !tipoUpdateSeleccionado}>Modificar Precio
            </button>
          </div>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col-md-12 mb-2">
            <label>Cambiar Desarrollo</label>
          </div>
          <div className="col-md-4">
            <label>Desarrollo:</label>
            <Select
              options={desarrollos}
              onChange={e => setDesarrolloSeleccionado(e.value)}
            />
          </div>
          <div className="col-md-4">
          </div>
          <div className="col-md-3 mt-4">
            <button className="btn btn-block btn-success float-right" onClick={modificarDesarrollo}
              disabled={!desarrolloSeleccionado}>Modificar Desarrollo
            </button>
          </div>
        </div>
        {operacionRealizada && operacionRealizada.tipo && operacionRealizada.valorUpdate && operacionRealizada.inversa ?
          <div className="row mt-2 mb-2">
            <div className="col-md-12">
              <label>Operación realizada</label>
            </div>
            <div className="col-md-4">
              <label className='col-12'>Tipo:</label>
              {operacionRealizada.tipo === 'porcentaje' ? 'Porcentaje' : 'Valor Fijo'}
            </div>
            <div className="col-md-4">
              <label className='col-12'>Valor:</label>
              {operacionRealizada.tipo === 'porcentaje' ? `${operacionRealizada.valorUpdate.toFixed(4)} % ` : `u$s ${operacionRealizada.valorUpdate}`}
            </div>
            <div className="col-md-3">
              <button className="btn btn-block btn-success float-right" onClick={() => modificarPrecioLotes(operacionRealizada.lotesSeleccionados, operacionRealizada.tipo, operacionRealizada.inversa)}
                disabled={!valorUpdate || !tipoUpdateSeleccionado}>Revertir Operación
              </button>
            </div>
          </div> : null}
      </div>
    </>
  )
}

export default ModificarLotes