import React from 'react';
import SelectSoldLotes from "./SelectSoldLotes";
import SelectVehicle from "./SelectVehicle";
import ExternalProperty from "./ExternalProperty";
import SelectCash from "./SelectCash";
import {withRouter} from "react-router-dom";
import WizardFormaPago from "../WizardFormaPago";
import SelectVencimientosPuntuales from './SelectDocumentos';
import SelectCanjes from './selectCanjes';
import SelectFinanciacion from './SelectFinanciacion';
import ResumenOperacion from './ResumenOperacion';
import SelectFinanciacionMultiple from './SelectFinanciacionMultiple';

const SelectFormaPago = ({operacion, setOperacion, isActiveTab, parametros, bloqueoId, ...props}) => {

  const items = [
    {
      title: 'Entrega Lote Life',
      button: 'Lote Life',
      component: SelectSoldLotes
    },
    {
      title: 'Entrega Vehículo',
      button: 'Vehículo',
      component: SelectVehicle
    },
    {
      title: 'Entrega Inmueble',
      button: 'Inmueble',
      component: ExternalProperty
    },
    {
      title: 'Entrega Efectivo',
      button: 'Efectivo',
      component: SelectCash
    },
    {
      title: 'Entrega Cheques o Pagarés',
      button: 'Documentos',
      component: SelectVencimientosPuntuales
    },
    {
      title: 'Entrega Canjes',
      button: 'Canjes',
      component: SelectCanjes
    },
    {
      title: 'Saldo Financiado',
      button: 'Financiación',
      // component: SelectFinanciacion
      component: SelectFinanciacionMultiple
    },
    {
      title: 'Resumen de la Operación',
      button: 'Resumen',
      component: ResumenOperacion
    }
  ];


  return (
    <>
      <WizardFormaPago
        operacion={operacion}
        setOperacion={setOperacion}
        items={items}
        isActiveTab={isActiveTab}
        parametros={parametros}
        bloqueoId={bloqueoId}
      />
    </>
  )
}

export default withRouter(SelectFormaPago);
