const estadosBase = {
  100: 'Nueva Operación',
  102: 'Valores Negociados Cargados',
  104: 'Turno de Firma Agendado',
  106: 'Valores Boleto Cargados',
  108: 'Documentación Firmada',
  109: 'Venta Finalizada'
}

class Estado {
    constructor(id, estado) {
      this.id = id;
      this.estado = estado;
      this.anterior = null;
      this.posterior = null;
      this.lastEstado = false;
    }

    getId() {
      return this.id;
    }

    getEstado() {
      return this.estado;
    }

    setLast(last) {
      this.anterior = last;
    }

    getLast() {
      return this.anterior;
    }

    setNext(next) {
      this.posterior = next;
    }

    getNext() {
      return this.posterior;
    }

    setIsLastEstado(isLast = false) {
      this.lastEstado = isLast
    }

    isLastEstado() {
      return this.lastEstado
    }
}

const estados = new Map();

let keys = Object.keys(estadosBase);
keys = keys.map(key => (parseInt(key)));

for(let i = 0; i < keys.length; i++) {
  const id = keys[i];
  const estado = new Estado(id, estadosBase[id]);
  estados.set(id, estado);
  if(i > 0) {
    estado.setLast(estados.get(keys[i - 1]));
    estados.get(keys[i-1]).setNext(estado);
  }
  if(i === keys.length - 1) {
    estado.setIsLastEstado(true)
  }
}

// Se agrega el estado cancelado
const estadoCancelado = new Estado(0, 'Operación Cancelada');
estados.set(estadoCancelado.id, estadoCancelado);


export default estados;