import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
import Spinner from "../layout/Spinner";
import FormularioEstadoCivil from "./components/formularioEstadoCivil";
import Swal from "sweetalert2";

const EditarEstadoCivil = (props) => {
  const [estadoCivil, setEstadoCivil] = useState({});
  const [auth, guardarAuth] = useContext(CRMContext);
  let token = auth.token || localStorage.getItem("token");

  useEffect(() => {
    const consultarAPI = async () => {
      let { id } = props.match.params;
      try {
        const estadoCivilDB = await clienteAxios.get(`/estadoCivil/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setEstadoCivil(estadoCivilDB.data);
        
      } catch (error) {
        if (error.response?.status === 500) {
          localStorage.clear();
          props.history.push("/login");
        }
        console.log(error);
      }
    };

    consultarAPI();
  }, []);

  

  const actualizarState = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setEstadoCivil({
      ...estadoCivil,
      [e.target.name]: value,
    });
  };

  const validarEstadoCivil = () => {
    const { nombre } = estadoCivil;
    let valido = !nombre;
    return valido;
  };

  const enviarEstadoCivil = async (e) => {
    e.preventDefault();

    let { id } = props.match.params;
    try {
      const rta = await clienteAxios.put(`/estadoCivil/${id}`, estadoCivil, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      props.history.push("/configuracion/estadoCivil");
    } catch (error) {
      if (error.response?.status === 500) {
        localStorage.clear();
        props.history.push("/login");
      }
      console.log(error);
    }
  };

  // const eliminarNacionalidad = async (e) => {
  //   e.preventDefault();

  //   let { id } = props.match.params;
  //   try {
  //     const rta = await clienteAxios.delete(`/nacionalidad/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     if (rta.data.estado == "ok") {
  //       Swal.fire("¡Correcto!", rta.data.msg, "success");
  //     } else if (rta.data.estado == "error") {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: rta.data.msg,
  //       });
  //     }
  //     props.history.push("/configuracion/nacionalidades");
  //   } catch (error) {
  //     if (error.response?.status === 500) {
  //       localStorage.clear();
  //       props.history.push("/login");
  //     }
  //     console.log(error);
  //   }
  // };

  if (!estadoCivil.nombre) {
    return <Spinner />;
  }

  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title mt-2'>EDITAR ESTADO CIVIL</h3>
        <button className="btn btn-primary float-right" onClick={props.history.goBack}>Volver</button>
      </div>
      <div className='card-body'>
        <form>
          <FormularioEstadoCivil
            actualizarState={actualizarState}
            estadoCivil={estadoCivil}
            estadoCivilNuevo={false}
          />

          <div className='card-footer'>
            {/* <button
              type='submit'
              className='btn btn-danger'
              onClick={eliminarNacionalidad}
            >
              Eliminar
            </button> */}

            <button
              type='submit'
              className='btn btn-primary float-right'
              disabled={validarEstadoCivil()}
              onClick={enviarEstadoCivil}
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(EditarEstadoCivil);
