import React, { useEffect, useState } from 'react';
import NumberFormat from "react-number-format";
import Select from "react-select";

const DescuentoLotes = (props) => {
  const { operacion, setOperacion, lotesSeleccionados, minDscto = 0, maxDscto = 15, valorDolar, valorDolarMercado } = props;
  const [isDescuentoOutOfRange, setIsDescuentoOutOfRange] = useState(false);
  const [tipoDescuento, setTipoDescuento] = useState(null);
  const [valorDescuento, setValorDescuento] = useState(0);

  const PESOS_ARS = '$ARS';
  const DOLARES = 'u$s';
  const PORCENTAJE = '%';
  
  const setPorcentajeDescuento = () => {
    const value = valorDescuento;
    let porcentajeDescuentoPrecioLista = 0;
    if (value === 0 || !value) {
      porcentajeDescuentoPrecioLista = 0;
    } else if (tipoDescuento === PORCENTAJE) {
      if (value > maxDscto || value < minDscto) {
        setIsDescuentoOutOfRange(true);
      } else {
        setIsDescuentoOutOfRange(false);
      }
      porcentajeDescuentoPrecioLista = value || 0
    } else if (tipoDescuento === PESOS_ARS) {
      if (value < 10000) {
        return;
      }
      const descuento = (value / operacion.precioListaOperacion) * 100
      porcentajeDescuentoPrecioLista = descuento;
    } else if (tipoDescuento === DOLARES) {
      const totalDolares = lotesSeleccionados.reduce((total, curr) => total + curr.precioLista, 0);
      const descuento = (value / totalDolares) * 100
      porcentajeDescuentoPrecioLista = descuento;
    }

    const valoresLotes = lotesSeleccionados.map(lote => {
      const { precioLista } = lote;
      return {
        idLote: lote.id,
        valores: {
          fechaPropuesto: Date.now(),
          cambioPropuesto: valorDolar,
          pesosPropuesto: precioLista * valorDolar * (1 - porcentajeDescuentoPrecioLista / 100),
          dolaresPropuesto: precioLista * (1 - porcentajeDescuentoPrecioLista / 100),

          fechaMercado: Date.now(),
          cambioMercado: valorDolarMercado,
          pesosMercado: valorDolarMercado * precioLista,
          dolaresMercado: precioLista,
        }
      }
    });

    setOperacion({
      ...operacion,
      lote: lotesSeleccionados,
      porcentajeDescuentoPrecioLista,
      valoresLotes,
    });
  }

  useEffect(()=> {
    setPorcentajeDescuento();
  }, [lotesSeleccionados, valorDolar, valorDescuento])

  const selectOptions = [
    {
      label: PORCENTAJE,
      value: PORCENTAJE
    },
    {
      label: DOLARES,
      value: DOLARES
    },
    {
      label: PESOS_ARS,
      value: PESOS_ARS
    }
  ];

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <label>Dto Tipo:</label>
          <Select
            options={selectOptions}
            onChange={(e) => setTipoDescuento(e.value)}
          />
        </div>
        <div className="col-md-6">
          <label>Dto Valor: {tipoDescuento === PORCENTAJE && `[${minDscto}~${maxDscto}] %`}</label>
          <NumberFormat
            className={`form-control ${isDescuentoOutOfRange ? 'red-border' : ''}`}
            // value={operacion.porcentajeDescuentoPrecioLista}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={` ${tipoDescuento !== PORCENTAJE ? `${tipoDescuento}  ` : ''}`}
            suffix={` ${tipoDescuento === PORCENTAJE ? ` ${tipoDescuento}` : ''}`}
            min={0}
            max={15}
            onValueChange={(e) => setValorDescuento(e.floatValue)}
            disabled={!tipoDescuento}
          />
        </div>
      </div>
    </div>
  );
};

export default DescuentoLotes;